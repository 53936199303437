import { useState, useRef, useEffect, useCallback } from 'react';
import clsx from 'clsx';
import styles from './TextVariants.module.scss';

const TextVariants = ({ variants: initialVariants }) => {
	const [variants, setVariants] = useState(initialVariants);
	const [hidden, setHidden] = useState(false);
	const listRef = useRef(null);
	// const resizeTimeoutId = useRef(null);

	// const updateVariants = useCallback(() => {
	//   const listWidth = listRef?.current?.clientWidth ?? null;
	//   const scrollWidth = listRef?.current?.scrollWidth;
	//   if (scrollWidth && listWidth && (scrollWidth > listWidth)) {
	//     const listItems = Array.from(listRef.current.children);
	//     let commonItemsWidth = 0;
	//     let visibleItemsQuantity = 0;
	//     listItems.forEach(item => {
	//       const width = item.clientWidth;
	//       const mr = parseInt(getComputedStyle(item).getPropertyValue('margin-right'), 10) || 0;
	//       const size = width + mr;
	//       commonItemsWidth += size;
	//       if (commonItemsWidth <= listWidth) {
	//         visibleItemsQuantity += 1;
	//       }
	//     });

	//     const variantsRestQuantity = initialVariants.length - visibleItemsQuantity + 1;
	//     const variantsNext = initialVariants.slice(0, visibleItemsQuantity - 1);
	//     if (variantsRestQuantity > 0) {
	//       variantsNext.push({ type: 'rest', value: variantsRestQuantity });
	//     }
	//     debugger

	//     setVariants(variantsNext);
	//   }
	//   setHidden(false);
	// }, [initialVariants]);

	// const onResize = useCallback(() => {
	//   clearTimeout(resizeTimeoutId.current);
	//   resizeTimeoutId.current = setTimeout(() => {
	//     updateVariants();
	//   }, 100);
	// }, [updateVariants]);

	// useEffect(() => {
	//   window.addEventListener('resize', onResize);
	//   updateVariants();

	//   return () => {
	//     window.removeEventListener('resize', onResize);
	//   };
	// }, [updateVariants, onResize]);

	useEffect(() => {
		setVariants(initialVariants);

		// setTimeout(() => {
		//   if (listRef.current) {
		//     updateVariants();
		//   }
		// }, 200);
	}, [initialVariants]);

	return (
		<ul className={styles.textVariants} ref={listRef}>
			{variants.map(variant => {
				return (
					<li className={styles.item} key={variant}>
						{variant}
					</li>
				);
			})}
		</ul>
	);
};

export default TextVariants;
