import getConfig from 'next/config';
import Cookie from 'js-cookie';

const { publicRuntimeConfig } = getConfig();

export const MINDBOX_ENDPOINT_ID = publicRuntimeConfig.trackers.MindboxEndpointId;
export const MINDBOX_SENDER_ID = publicRuntimeConfig.trackers.MindboxSenderId;

const COOKIE_MINDBOX_SUBSCRIBE_LOCK = 'fh_subscribe_lock';
const isMindboxEnable = !!MINDBOX_ENDPOINT_ID;

const send = (name = 'mindbox', data) => {
	if (isMindboxEnable) {
		try {
			window.mindbox('sync', {
				operation: name,
				data,
			});
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(`Can't track mindbox ${name} event:`, error);
		}
	}
};

const displayProductDetail = ({ product }) => {
	if (product.uuid) {
		send('Website.ViewProduct', {
			viewProduct: {
				product: {
					ids: {
						eRP: product.uuid,
					},
				},
			},
		});
	}
};

const displayCategory = ({ category }) => {
	if (category && category.route) {
		const routeInfo = category.route;

		const params = {
			pointOfContact: routeInfo?.menu_id ? `menu${routeInfo?.menu_id}` : 'Site',
		};

		if (routeInfo?.category_id) {
			params.viewProductCategory = {
				productCategory: {
					ids: {
						eRP: routeInfo.category_id,
					},
				},
			};

			if (routeInfo?.brand_name) {
				params.viewProductCategory.customerAction = {
					customFields: {
						brand: routeInfo.brand_name,
					},
				};
			}

			send('Website.ViewCategory', params);
		} else {
			if (routeInfo?.brand_name) {
				params.customerAction = {
					customFields: {
						brand: routeInfo.brand_name,
					},
				};
			}

			send('Website.ViewSectionCatalog', params);
		}
	}
};

const viewPage = ({ page }) => {
	if (page) {
		send(`Website.ViewPage${page.charAt(0).toUpperCase() + page.slice(1)}`, {});
	}
};

const viewCustomPage = id => {
	if (id) {
		send(`Website.ViewPage`, {
			pointOfContact: `page${id}`,
		});
	}
};

const viewPageNews = ({ article }) => {
	if (article) {
		send('Website.ViewPageNews', {
			pointOfContact: `page${article.id}`,
		});
	}
};

const viewNews = () => {
	send('Website.ViewNews', {});
};

const webpushSubscribe = () => {
	if (isMindboxEnable) {
		try {
			if (!Cookie.get(COOKIE_MINDBOX_SUBSCRIBE_LOCK)) {
				window.mindbox('webpush.subscribe', {
					getSubscriptionOperation: 'Website.GetWebPushSubscription',
					subscribeOperation: 'Website.SubscribeToWebPush',
					onGranted() {},
					onDenied() {},
				});

				Cookie.set(COOKIE_MINDBOX_SUBSCRIBE_LOCK, true, { expires: 3 });
			}
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(`Mindbox webpush subscribe error:`, error);
		}
	}
};

export default {
	displayProductDetail,
	displayCategory,
	viewPage,
	viewPageNews,
	viewNews,
	webpushSubscribe,
	viewCustomPage,
};
