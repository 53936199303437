import { useState, useCallback, useEffect, useMemo } from 'react';
import Image from 'next/image';
import { isLoggedInSelector, cartCountSelector, wishlistCountSelector } from '@/store';
import { changeBannerState } from '@fh-components/fh-js-api-core/store/slices/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { ActiveLink, Burger, Control, SmartBanner } from '@/components';
import { useNav } from '@/contexts/NavContext';
import getLinkProps from '@/lib/getLinkProps';
import { Container, Navigate } from '@/containers';
import useWindowSize from '@/hooks/use-window-size';
import SearchControl from './searchControl';
import UserControl from './userControl';
import clsx from 'clsx';

import styles from './header.module.scss';

const Header = ({ hasServerBanner }) => {
	const dispatch = useDispatch();
	const [wishlistHref, wishlistAs] = getLinkProps({ type: 'wishlist' });
	const [loginHref, loginAs] = getLinkProps({ type: 'login' });
	const [searchVisible, setSearchVisible] = useState(false);
	const [cartHref, cartAs] = getLinkProps({ type: 'cart' });
	const loggedIn = useSelector(isLoggedInSelector);
	const { isMobile } = useWindowSize();
	const wishlistItemsQuantity = useSelector(wishlistCountSelector);
	const cartItemsQuantity = useSelector(cartCountSelector);

	const { toggleMobileUserNav, visibility, toggleMobileNav } = useNav();

	const [isShowSearch, setIsShowSearch] = useState(false);

	const [isVisibleBanner, setIsVisibleBanner] = useState(hasServerBanner);

	const showSearch = useCallback(() => {
		setIsShowSearch(true);
	}, []);

	const hideSearch = useCallback(() => {
		setIsShowSearch(false);
	}, []);

	const onClickMobileProfile = useCallback(
		e => {
			e?.stopPropagation();
			toggleMobileUserNav();
		},
		[toggleMobileUserNav],
	);

	const onClickBurger = useCallback(
		e => {
			e.stopPropagation();
			toggleMobileNav();
		},
		[toggleMobileNav],
	);

	const onToggleSearch = useCallback(() => {
		setSearchVisible(prev => !prev);
	}, []);

	const onUnVisibleSearch = useCallback(() => {
		setSearchVisible(false);
	}, []);

	// useEffect(() => {
	// 	if (typeof window !== 'undefined') {
	// 		console.log('!!!! ---- ', !!sessionStorage.getItem('smart-banner'))
	// 		debugger
	// 		setIsVisibleBanner(!!sessionStorage.getItem('smart-banner'));
	// 	}
	// }, []);

	const hasBanner = useMemo(() => isMobile && isVisibleBanner, [isMobile, isVisibleBanner]);
	const loadBanner = useMemo(() => {
		if (typeof window !== 'undefined') {
			return isMobile;
		}

		return hasServerBanner;
	}, [isMobile, hasServerBanner]);

	useEffect(() => {
		dispatch(changeBannerState(hasBanner));
	}, [hasBanner, dispatch]);

	return (
		<header
			id='header-id'
			className={clsx(styles.header, {
				[styles.banner]: hasBanner,
			})}
		>
			{loadBanner && (
				<SmartBanner isVisibleBanner={isVisibleBanner} setIsVisibleBanner={setIsVisibleBanner} />
			)}
			<Container>
				<div className={styles.bar}>
					<div className={styles.left}>
						<div className={styles['mobile-nav-toggle']}>
							<Burger open={visibility.mobileNav} onClick={onClickBurger} />
						</div>

						<div className={styles['nav-wrapper']}>
							<Navigate />
						</div>

						<UserControl label='+375 (29) 633-2-633' phoneType />
					</div>

					<div className={styles.logo}>
						<ActiveLink href='/' as='/'>
							<Control>
								<div className={styles.logoImg}>
									<Image
										src='/images/fhby.webp'
										height={24}
										width={77}
										alt='FH.BY - интернет-магазин одежды, обуви и аксессуаров мировых брендов'
									/>
								</div>
							</Control>
						</ActiveLink>
					</div>

					<div className={styles.controls}>
						<SearchControl
							label='Поиск'
							type='desktop'
							hideSearch={hideSearch}
							showSearch={showSearch}
							isShowSearch={isShowSearch}
							onUnVisibleSearch={onUnVisibleSearch}
						/>

						{loggedIn ? (
							<UserControl
								label='Профиль'
								iconName='user'
								loginType
								onClickMobileProfile={onClickMobileProfile}
							/>
						) : (
							<UserControl label='Войти' iconName='login' href={loginHref} as={loginAs} />
						)}

						<UserControl
							label='Избранное'
							iconName='heart'
							href={wishlistHref}
							as={wishlistAs}
							itemsQuantity={wishlistItemsQuantity}
						/>

						<UserControl
							label='Корзина'
							iconName='cart'
							href={cartHref}
							as={cartAs}
							itemsQuantity={cartItemsQuantity}
						/>

						<SearchControl
							label='Поиск'
							type='mobile'
							searchVisible={searchVisible}
							hideSearch={hideSearch}
							onUnVisibleSearch={onUnVisibleSearch}
							onToggleSearch={onToggleSearch}
						/>
					</div>
				</div>
			</Container>
		</header>
	);
};

export default Header;
