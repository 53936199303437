import styled, { css } from 'styled-components';
import { maxWidth, minWidth, noTouch } from '@/assets/styles/mixins.css';
import vars from '@/assets/styles/vars';
import Container from './Container';

const FullWidthSlider = styled.div`
	${props =>
		props.mod === 'mini' &&
		css`
			${FullWidthSlider.Swiper} {
				.swiper-slide {
					width: 101px;
					margin: 0;

					${maxWidth(vars.breakpoints.mTablet)`
                width: 158px;
                margin: 0;
            `}

					${maxWidth(vars.breakpoints.sMobile)`
                width: 101px;
                margin: 0;
            `}

            &:first-child {
						margin-left: 0px !important;
					}

					&:not(:last-child) {
						margin-right: 16px;

						${maxWidth(vars.breakpoints.mTablet)`
                margin-right: 24px;
            `}

						${maxWidth(vars.breakpoints.sMobile)`
                margin-right: 16px;
            `}
					}
				}
			}

			${FullWidthSlider.Prev} {
				left: -22px;
				svg {
					display: inline-block !important;
					transform: rotate(-90deg);
				}
			}

			${FullWidthSlider.Next} {
				right: 0;
				svg {
					display: inline-block !important;
					transform: rotate(90deg);
				}
			}

			${FullWidthSlider.Btn} {
				box-shadow: none;
				&::after {
					display: none;
				}
				&:hover {
					svg {
						path {
							stroke: #fff;
						}
					}
				}
			}
		`};
`;

FullWidthSlider.Container = styled(Container)`
    position: relative;

    ${maxWidth(vars.breakpoints.tablet + 1)`
        width: 100%;

        .swiper-slide{
        &:first-child {
            margin-left: ${vars.sideOffset.tablet}%;
        }
        }
    `}

    ${maxWidth(vars.breakpoints.mobile)`
        .swiper-slide{
        &:first-child {
            margin-left: ${vars.sideOffset.lMobile}%;
        }
        }
    `}

    ${minWidth(vars.breakpoints.tablet)`
        overflow: hidden;
    `}

    ${props =>
			props.noFull &&
			css`
				${minWidth(vars.breakpoints.tablet + 1)`
            overflow: hidden;
        `}
			`};

        ${props =>
					props.fullWidth &&
					css`
						width: 100%;
					`};
`;

FullWidthSlider.Swiper = styled.div`
    position: relative;
    overflow: hidden;
    
    .swiper-container {
        overflow: visible;
    }

    .swiper-slide {
        width: 292px;

        &:not(:last-child) {
        margin-right: 32px;

        ${maxWidth(vars.breakpoints.tablet + 1)`
            margin-right: 24px;
        `}

        ${maxWidth(vars.breakpoints.sMobile)`
            margin-right: 6px;
        `}

				

        ${props =>
					props.bigMargin &&
					css`
						margin-right: 32px;
					`}
        }

        ${props =>
					props.isBrandDescription &&
					css`
						margin-right: 0 !important;
						margin-left: 0 !important;
					`}
        }
    }

		
		${maxWidth(vars.breakpoints.sDesktop)`
        .swiper-slide {
        	width: 250px;
        }
    `}

    ${maxWidth(vars.breakpoints.tablet + 1)`
        .swiper-slide {
        	width: 206px;
        }
    `}

    ${maxWidth(vars.breakpoints.mTablet + 1)`
        .swiper-slide {
        	width: 190px;
        }
    `}

    ${maxWidth(vars.breakpoints.mobile)`
        .swiper-slide {
        	width: 158px;
        }
    `}

    ${maxWidth(vars.breakpoints.sMobile - 1)`
        .swiper-slide {
        	width: 158px;
        }
    `}

		${props => props.noSlideWidth &&
			css`
				.swiper-slide {
					width: auto;
				}
			`
		}

    ${props =>
			props.widthLimit &&
			css`
				max-width: ${props.widthLimit || '100%'};
				margin-left: auto;
				margin-right: auto;
			`};

    ${props =>
			props.locked &&
			css`
				${FullWidthSlider.Prev}, ${FullWidthSlider.Next} {
					display: none;
				}
			`}
    `;

FullWidthSlider.BtnWrap = styled.div`
	position: absolute;
	top: 50%;
	left: 0;
	pointer-events: none;

	${props =>
		props.hide &&
		css`
			display: none;
		`};

	${props =>
		props.centered &&
		css`
        top: 168px;

        ${maxWidth(vars.breakpoints.tablet + 1)`
        top: 155px;
    `}

    ${maxWidth(vars.breakpoints.mTablet + 1)`
        top: 143px;
    `}

    ${maxWidth(vars.breakpoints.mobile + 1)`
        top: 98px;
    `}
        `};

	&::after {
		content: '';
		display: block;
		width: 100%;
		padding-top: 150%;
	}

	${props =>
		props.right &&
		css`
			left: auto;
			right: 0;
		`};
`;

FullWidthSlider.Btn = styled.button`
    position: absolute;
    top: 50%;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    overflow: hidden;
    background: ${themeProps => themeProps.theme.bg.default};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    transition: background 0.25s ease;
    pointer-events: all;
    box-shadow: 1px 1px 24px rgba(0, 0, 0, 0.16);

    svg {
        display: none;
    }

    &::after {
        content: '';
        display: block;
        width: 7px;
        height: 7px;
        border-right: ${props => `1px solid ${props.theme.bg.accent}`};
        border-bottom: ${props => `1px solid ${props.theme.bg.accent}`};
        transition: border-color 0.25s ease;
    }

    ${maxWidth(vars.breakpoints.tablet)`
        display: ${props => (props.isBigSlider ? 'block' : 'none')};
    `}

    ${noTouch()`
        &:hover {
        background: ${props => !props.disabled && props.theme.bg.accent};

        &::after {
            border-color: ${props => !props.disabled && props.theme.bg.default};
        }
        }
    `}

    ${props =>
			props.disabled &&
			css`
				opacity: 0;

				&::after {
					border-color: ${themeProps => themeProps.theme.bg.divider};
				}
			`}
    `;

FullWidthSlider.Prev = styled(FullWidthSlider.Btn)`
	left: 15px;
	transform: translateY(-50%);

	&::after {
		transform: translateX(2px) rotate(-225deg);

		${maxWidth(vars.breakpoints.tablet)`
        transform: translateX(12px) rotate(-225deg);
        `}
	}
`;

FullWidthSlider.Next = styled(FullWidthSlider.Btn)`
	right: 15px;
	transform: translateY(-50%);

	&::after {
		transform: translateX(-2px) rotate(-45deg);

		${maxWidth(vars.breakpoints.tablet)`
        transform: translateX(10px) rotate(-45deg);
        `}
	}
`;

export default FullWidthSlider;
