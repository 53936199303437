const SVG = ({ fill = 'currentColor', width = '11', height = '11', viewBox = '0 0 11 11' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M9.68 5.687V8.29c0 .144.002.287-.016.43l.024-.176c-.022.163-.065.321-.127.473l.066-.158a1.927 1.927 0 01-.24.414l.104-.133c-.101.13-.218.247-.349.348l.134-.103a1.927 1.927 0 01-.414.24l.157-.067c-.152.063-.31.105-.473.127l.176-.023c-.173.021-.35.015-.524.015H2.743c-.155 0-.31.004-.465-.015l.176.023a1.916 1.916 0 01-.473-.127l.157.067a1.928 1.928 0 01-.414-.24l.134.103a1.937 1.937 0 01-.349-.348l.104.133a1.926 1.926 0 01-.24-.414l.066.158a1.915 1.915 0 01-.127-.473l.024.176c-.022-.174-.016-.35-.016-.524v-.87-2.561-2.023c0-.155-.003-.31.016-.465l-.024.175c.022-.162.065-.32.127-.472l-.066.157c.062-.148.143-.286.24-.414l-.104.134c.101-.131.218-.248.349-.349l-.134.103c.128-.097.267-.177.414-.24l-.157.067c.152-.062.31-.105.473-.127l-.176.024c.255-.032.52-.016.777-.016h2.258c.36 0 .66-.3.66-.66 0-.36-.3-.66-.66-.66H2.532C1.838 0 1.149.29.678.802A2.565 2.565 0 000 2.54v5.867c0 .478.108.944.353 1.359.203.343.489.624.82.843.167.11.355.188.54.257.194.07.398.099.602.122.164.02.334.011.499.011h5.454c.279 0 .553-.01.828-.076.393-.094.741-.298 1.049-.555.153-.128.273-.282.393-.44.09-.118.163-.248.222-.384a2.539 2.539 0 00.24-1.07V8.23 5.91v-.223c0-.36-.3-.66-.66-.66-.36 0-.66.3-.66.66zM7.637 1.32h2.703c.36 0 .66-.3.66-.66 0-.36-.3-.66-.66-.66H7.636c-.359 0-.66.3-.66.66 0 .36.301.66.66.66z' />
		<path d='M11 3.363V1.369.66c0-.36-.3-.66-.66-.66-.36 0-.66.3-.66.66v2.703c0 .36.3.66.66.66.36 0 .66-.3.66-.66z' />
		<path d='M5.977 5.956l.825-.825 1.775-1.775 1.673-1.672.557-.557a.666.666 0 000-.934.667.667 0 00-.934 0 25695.226 25695.226 0 00-2.6 2.6L5.6 4.466l-.557.557a.666.666 0 000 .933c.254.254.68.254.934 0z' />
	</svg>
);

export default SVG;
