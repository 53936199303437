const SVG = ({ fill = 'currentColor', width = '11', height = '11', viewBox = '0 0 11 11' }) => (
	<svg width='23' height='9' viewBox='0 0 23 9' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M3.19062 4.96328L5.47734 7.25L4.57578 8.15156L0.75 4.32578L4.57578 0.5L5.47734 1.40156L3.19062 3.68828H22.6516V4.96328H3.19062Z'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
