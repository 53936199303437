import { useEffect } from 'react';
import { ProductCard, FullWidthSlider } from '@/components';
import { Container, Block } from '@/containers';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { useSelector } from 'react-redux';
import getProductWithoutQuantity from '@/lib/getProductWithoutQuantity';
import ecommerce from '@/seo/Ecommerce';
import styles from './LookSlider.module.scss';

const LookSlider = ({ products }) => {
	if (!products || products.length === null) return null;
	const currency = useSelector(getCurrency);

	useEffect(() => {
		if (Object.keys(currency).length > 0 && products && products.length) {
			ecommerce.viewItemList(getProductWithoutQuantity(products), currency, 'Идеи для образа');
		}
	}, [currency]);

	const handleClickCard = (product, index) => {
		const newProducts = getProductWithoutQuantity([product]);

		ecommerce.viewItemList(newProducts, currency, 'Идеи для образа', 'select_item', index);
	};

	return (
		<Block>
			<Container>
				<FullWidthSlider fullWidth>
					{products.map((product, index) => (
						<div
							key={product?.external_id}
							className={styles.cart}
							onClick={() => {
								handleClickCard(product, index + 1);
							}}
						>
							<ProductCard
								{...product}
								mod='looks'
								pageName={'Идеи для образа'}
								indexCard={index + 1}
							/>
						</div>
					))}
				</FullWidthSlider>
			</Container>
		</Block>
	);
};

export default LookSlider;
