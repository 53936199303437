import {
	getAuthData,
	subscribeByEmail,
} from '@fh-components/fh-js-api-core/store/slices/userSlice';
import { useSelector, useDispatch } from 'react-redux';
import { useState, useEffect, useRef, useMemo } from 'react';
import clsx from 'clsx';
import Cookie from 'js-cookie';
import { Text } from '@/components';
import ecommerce from '@/seo/Ecommerce';
import styles from './Subscribe.module.scss';

const Subscribe = () => {
	const dispatch = useDispatch();
	const emailRef = useRef(null);

	const { subscribeError } = useSelector(getAuthData);

	const errorText = useMemo(() => {
		let error = 'Произошла ошибка, попробуйте позже';

		if (subscribeError?.errors?.email) {
			error = subscribeError?.errors?.email[0];
		}

		return error;
	}, [subscribeError]);

	const [error, setError] = useState('');
	const [email, setEmail] = useState('');
	const [formSubmitState, setFormSubmitState] = useState({ visible: false, type: 'success' });

	useEffect(() => {
		if (subscribeError) {
			setError(errorText);
			setFormSubmitState({ visible: true, type: 'error' });
		} else {
			setError('');
		}
	}, [errorText, subscribeError]);

	const onSubmit = e => {
		e.preventDefault();
		const mindboxDeviceUUID = Cookie.get('mindboxDeviceUUID');

		setFormSubmitState({ ...formSubmitState, visible: false });

		dispatch(
			subscribeByEmail({
				email,
				mindboxDeviceUUID: mindboxDeviceUUID || '',
				onSuccess: () => {
					setError('');
					setFormSubmitState({ visible: true, type: 'success' });
					setEmail('');
					ecommerce.setSubscribe();
				},
			}),
		);
	};

	const onChange = e => {
		if (formSubmitState.visible) {
			setFormSubmitState({ ...formSubmitState, visible: false });
		}

		const value = e?.target?.value || '';
		setEmail(value);
	};

	return (
		<div className={styles.wrapper}>
			<Text mb='mb-x-small' size='size-small' className={styles.label}>
				Получайте уведомления об&nbsp;акциях и скидках:
			</Text>

			<form action='/' onSubmit={onSubmit} noValidate className={styles['subscribe-form']}>
				<input
					type='email'
					name='email'
					ref={emailRef}
					aria-label='email'
					placeholder='Ваш e-mail'
					value={email}
					onChange={onChange}
					className={styles.input}
				/>

				<button type='submit' aria-label='subscribe' className={styles['subscribe-btn']}>
					<svg width='6' height='9' fill='currentColor'>
						<path d='M.707.707a1 1 0 0 1 1.414 0l3.114 3.114a1 1 0 0 1-1.414 1.414L.707 2.121a1 1 0 0 1 0-1.414z' />
						<path d='M.707 8.35a1 1 0 0 0 1.414 0l3.114-3.115a1 1 0 0 0-1.414-1.414L.707 6.935a1 1 0 0 0 0 1.414z' />
					</svg>
				</button>

				{formSubmitState.visible && (
					<Text className={clsx(styles.text, styles[`submit-${formSubmitState.type}`])}>
						{formSubmitState.type === 'success' ? 'Вы успешно подписались на рассылку.' : error}
					</Text>
				)}
			</form>
		</div>
	);
};

export default Subscribe;
