import clsx from 'clsx';
import styles from './Container.module.scss';

const Container = ({ children, className, center }) => {
	return (
		<div className={clsx(styles.container, center && styles.center, className)}>{children}</div>
	);
};

export default Container;
