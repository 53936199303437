import { createSelector } from '@reduxjs/toolkit';

export const isLoggedInSelector = createSelector(
	state => state.user.loggedIn ?? false,
	loggedIn => loggedIn,
);

export const hasSmartBannerSelector = createSelector(
	state => state.user.hasBanner ?? false,
	hasBanner => hasBanner,
);

export const isLoggingInSelector = createSelector(
	state => state.user.loggingIn ?? false,
	loggingIn => loggingIn,
);

export const userSelector = createSelector(
	state => state.user?.user || {},
	user => user,
);

export const hasGotIPSelector = createSelector(
	state => state.user.hasGotIP || {},
	hasGotIP => hasGotIP,
);

export const favoriteBrandsSelector = createSelector(
	state => state.user.favoriteBrands.data || [],
	favoriteBrands => favoriteBrands.map(item => `${item}`),
);

export const userPhoneSelector = createSelector(
	state => state.user.user?.phone || null,
	phone => phone,
);

export const userErrorSelector = createSelector(
	state => state.user.error ?? null,
	error => error,
);

export const profileSelector = createSelector(
	state => state.user.profile || {},
	profile => profile,
);

export const redirectUrlSelector = createSelector(
	state => state.user.redirectUrl,
	redirectUrl => redirectUrl,
);

export const userLoadingInfoSelector = createSelector(
	userSelector,
	isLoggingInSelector,
	userErrorSelector,
	(user, loggingIn, error) => ({ user, loggingIn, error }),
);

export const userAndProfileSelector = createSelector(
	userSelector,
	profileSelector,
	(user, profile) => ({ user, profile }),
);
