import { useCallback, useRef, useEffect, createElement, memo } from 'react';

const ClickOutside = memo(({ onClickOutside, as = 'div', children, ...props }) => {
	const ref = useRef();

	const handleClickOutside = useCallback(
		e => {
			if (ref.current && !(ref?.current?.contains(e.target) || ref.current === e.target)) {
				onClickOutside();
			}
		},
		[onClickOutside],
	);

	useEffect(() => {
		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, [handleClickOutside]);

	return createElement(
		as,
		{
			...props,
			ref,
		},
		children,
	);
});

export default ClickOutside;
