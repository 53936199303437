const SVG = ({ fill = '#CCCDD0', width = '18', height = '20', viewBox = '0 0 18 20' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M8.0001 0.0999756C6.39847 0.0999756 5.1001 1.39835 5.1001 2.99998V3.09998H2.0001H1.0001C0.503041 3.09998 0.100098 3.50292 0.100098 3.99998C0.100098 4.49703 0.503041 4.89998 1.0001 4.89998H2.0001H2.60962L3.43377 17.1528L3.43384 17.1539C3.51509 18.4446 4.58562 19.45 5.879 19.45H12.1212C13.4146 19.45 14.4851 18.4446 14.5664 17.1539L14.5664 17.1528L15.3906 4.89998H16.0001H17.0001C17.4972 4.89998 17.9001 4.49703 17.9001 3.99998C17.9001 3.50292 17.4972 3.09998 17.0001 3.09998H16.0001H12.9001V2.99998C12.9001 1.39835 11.6017 0.0999756 10.0001 0.0999756H8.0001ZM11.1001 3.09998H6.9001V2.99998C6.9001 2.39246 7.39258 1.89998 8.0001 1.89998H10.0001C10.6076 1.89998 11.1001 2.39246 11.1001 2.99998V3.09998ZM7.59927 7.96144C7.57799 7.46484 7.15816 7.07952 6.66156 7.1008C6.16496 7.12208 5.77964 7.54191 5.80092 8.03851L6.10092 15.0385C6.12221 15.5351 6.54203 15.9204 7.03863 15.8991C7.53523 15.8779 7.92056 15.458 7.89927 14.9614L7.59927 7.96144ZM12.1993 8.03851C12.2206 7.54191 11.8352 7.12208 11.3386 7.1008C10.842 7.07952 10.4222 7.46484 10.4009 7.96144L10.1009 14.9614C10.0796 15.458 10.465 15.8779 10.9616 15.8991C11.4582 15.9204 11.878 15.5351 11.8993 15.0385L12.1993 8.03851Z'
			fill={fill}
		/>
	</svg>
);

export default SVG;
