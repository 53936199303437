const SVG = ({ fill = 'currentColor', width = '24', height = '30', viewBox = '0 0 24 30' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<rect x='0' y='13' width='4' height='5' fill='#333'>
			<animate
				attributeName='height'
				attributeType='XML'
				values='5;21;5'
				begin='0s'
				dur='0.6s'
				repeatCount='indefinite'
			/>
			<animate
				attributeName='y'
				attributeType='XML'
				values='13; 5; 13'
				begin='0s'
				dur='0.6s'
				repeatCount='indefinite'
			/>
		</rect>
		<rect x='10' y='13' width='4' height='5' fill='#333'>
			<animate
				attributeName='height'
				attributeType='XML'
				values='5;21;5'
				begin='0.15s'
				dur='0.6s'
				repeatCount='indefinite'
			/>
			<animate
				attributeName='y'
				attributeType='XML'
				values='13; 5; 13'
				begin='0.15s'
				dur='0.6s'
				repeatCount='indefinite'
			/>
		</rect>
		<rect x='20' y='13' width='4' height='5' fill='#333'>
			<animate
				attributeName='height'
				attributeType='XML'
				values='5;21;5'
				begin='0.3s'
				dur='0.6s'
				repeatCount='indefinite'
			/>
			<animate
				attributeName='y'
				attributeType='XML'
				values='13; 5; 13'
				begin='0.3s'
				dur='0.6s'
				repeatCount='indefinite'
			/>
		</rect>
	</svg>
);

export default SVG;
