import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useNotification } from '@/contexts/NotificationContext';
import { StyledNotifications } from '@/components/styles';
import { Notification } from '@/components';

export default function NotificationHandler() {
	const notification = useNotification();
	const { list, close } = notification;

	return (
		<StyledNotifications>
			<StyledNotifications.List>
				<TransitionGroup component={null}>
					{list.map((item, ind) => {
						return (
							<CSSTransition
								in
								key={ind}
								timeout={{
									appear: 0,
									enter: 230,
									exit: 230,
								}}
								classNames='on'
							>
								<Notification {...item} closeFn={id => close(id)} />
							</CSSTransition>
						);
					})}
				</TransitionGroup>
			</StyledNotifications.List>
		</StyledNotifications>
	);
}
