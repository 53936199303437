import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { getCategoriesTree } from '@fh-components/fh-js-api-core/store/slices/categoriesSlice';
import Skeleton from 'react-loading-skeleton';
import ItemWithDropdown from '../itemWithDropdown';
import styles from './NavList.module.scss';

const NavList = ({ toggleVisibility, savedQuery, visible }) => {
	const dispatch = useDispatch();
	const [tree, setTree] = useState([]);
	const [loading, setLoading] = useState(false);
	const [lastItemId, setLastItemId] = useState({
		opened: tree && tree.length > 0 ? tree[0].id : null,
		leaved: null,
	});

	useEffect(() => {
		if (visible) {
			setLoading(true);
			dispatch(
				getCategoriesTree({
					onSuccess: res => {
						setLoading(false);
						setTree(res.data);
					},
				}),
			);
		}
	}, [visible, dispatch]);

	useEffect(() => {
		if (tree && tree.length > 0) {
			setLastItemId({
				opened: tree[0].id,
				leaved: null,
			});
		}
	}, [tree]);

	if (loading) {
		return (
			<div style={{ marginTop: '31px' }}>
				<Skeleton count={10} height={23} width='90%' style={{ marginBottom: '16px' }} />
			</div>
		);
	}

	return (
		<ul className={styles.list}>
			{tree.map((category, ind) => (
				<ItemWithDropdown
					key={category.id}
					{...category}
					onClick={toggleVisibility}
					lastItemId={lastItemId}
					initiallyVisible={ind === 0}
					setLastItemId={setLastItemId}
					savedQuery={savedQuery}
					is_active
				/>
			))}
		</ul>
	);
};

export default NavList;
