import clsx from 'clsx';
import styles from './Button.module.scss';

const Button = ({
	children,
	onClick,
	type = 'default',
	action = 'submit',
	classNames,
	status,
	loading,
	disabled,
	artObj,
	width,
}) => {
	const generalClass = classNames?.general;
	const contentClass = classNames?.content;

	return (
		<button
			style={{ width: width ? `${width}px` : '' }}
			onClick={onClick}
			// eslint-disable-next-line react/button-has-type
			type={action}
			disabled={disabled || loading}
			className={clsx({
				[styles.button]: true,
				[styles[type]]: type,
				[styles[status]]: status,
				[styles.loading]: loading,
				[styles.disabled]: disabled || status === 'disabled',
				[generalClass]: true,
			})}
		>
			{artObj}
			<span className={clsx(styles.buttonContent, contentClass)}>{children}</span>
		</button>
	);
};

export default Button;
