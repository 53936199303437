import { useRouter } from 'next/router';
import { Children, cloneElement } from 'react';
import Link from 'next/link';
import qs from 'qs';

const ActiveLink = ({
	href: hrefPath,
	as: asPath = hrefPath,
	children,
	shallow = false,
	activeAs = 'span',
	disableActive = false,
	activeClassName,
	...props
}) => {
	const router = useRouter();

	const href = hrefPath.pathname || hrefPath;
	const as = asPath.pathname || asPath;
	const query = hrefPath?.query ?? null;
	const queriesStringified = qs.stringify(query, {
		encode: false,
	});

	const isActive = router.asPath.split('?')[0] === as;
	const componentProps = isActive ? { active: 'true', as: activeAs, ...props } : { ...props };

	const elements = Children.toArray(children);
	if (elements.length > 1) {
		throw new Error('ActiveLink component accepts only one child');
	}
	const component = cloneElement(elements[0], componentProps);

	if (isActive && !disableActive) {
		return typeof component.type === 'string' ? (
			<span className={activeClassName}>{component.props.children}</span>
		) : (
			<>{component}</>
		);
	}
	return (
		<Link
			href={queriesStringified ? `${href}?${queriesStringified}` : href}
			as={queriesStringified ? `${as}?${queriesStringified}` : as}
			shallow={shallow}
			passHref
		>
			{component}
		</Link>
	);
};

export default ActiveLink;
