const numberWithSpaces = (x, floatSep = ',') => {
	const number = x ?? 0;
	const parts = number.toString().split('.');

	parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

	return parts.join(floatSep);
};

export default numberWithSpaces;
