/* eslint-disable */
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();

export const CONTAINER_ID = publicRuntimeConfig.trackers.GTMTrackingId;
export const disabledMetrics = publicRuntimeConfig.trackers.disabledMetrics;
const isGTMEnable = !!CONTAINER_ID;

const PAGE_TYPES = {
	HOME: 'home',
	SEARCH_RESULTS: 'search',
	CATEGORY: 'category',
	BRAND: 'brand',
	PRODUCT: 'product',
	CART: 'cart',
	CHECKOUT: 'checkout',
	PURCHASE: 'purchase',
	PROFILE: 'profile',
	WISHLIST: 'wishlist',
	SPECIAL: 'special',
	NEWS: 'news',
	CONTACTS: 'contacts',
	OTHER: 'other',
};

const SOCIAL_BANNER_TYPE = {
	GOOGLE_PLAY: 'google play',
	APP_STORE: 'appstore',
};

const convertBYNToUSD = (price, currency) => {
	return currency && Object.keys(currency).length > 0
		? Math.round((price / currency.USD.BYN) * 100) / 100
		: price;
};

const withErrorHandler = (f, name = 'ecommerce') => {
	if (isGTMEnable) {
		try {
			f();
		} catch (error) {
			console.error(`Can't track ${name} event:`, error);
		}
	}
};

const subscribeByEmail = () => {
	withErrorHandler(() => {
		setDataLayer({
			event: 'onSubscribe',
		});
	}, 'onSubscribe');
};

const viewItemList = (products, currency, listName, event, index, value, successInfo = {}) => {
	withErrorHandler(() => {
		const listNameObj = {
			item_list_name: listName,
		};
		const itemListName = event === 'view_cart' || event === 'begin_checkout' ? {} : listNameObj;
		let itemListNameProduct =
			event === 'view_cart' || event === 'begin_checkout' ? listNameObj : {};

		const valueObj = value ? { value: convertBYNToUSD(value, currency) } : {};
		const items = products.map(
			(
				{
					external_id,
					name,
					price,
					old_price,
					price_diff_percent,
					brand,
					category = '',
					categories: categoriesList,
					switchable_attributes = [],
					quantity = null,
					cart_quantity = null,
					sex = null,
					pol = null,
					name_marketing = null,
					meta = null,
					model,
				},
				idx,
			) => {
				let categories = {
					item_category: sex || pol,
					item_category2: '',
					item_category3: '',
					item_category4: '',
				};

				if (meta && Object.keys(meta).length > 0 && event !== 'remove_from_cart') {
					itemListNameProduct = {
						item_list_name: meta?.pageName ? meta.pageName : 'Страница товара',
						index: meta?.indexCard ? meta.indexCard : index,
					};
				}

				let counter = 4;
				if (categoriesList && categoriesList.length === 2) {
					counter = 3;
				} else if (categoriesList && categoriesList.length === 1) {
					counter = 2;
				}

				if (categoriesList && categoriesList.length) {
					for (let i = categoriesList.length - 1, j = counter; i >= 0, j > 1; i--, j--) {
						if (categoriesList[i]) {
							categories[`item_category${j}`] = categoriesList[i];
						}
					}
				}

				let item_variant = {};

				if (switchable_attributes?.length) {
					let itemVariant = '';
					for (let i = 0; i < switchable_attributes.length; i++) {
						if (i !== 0) {
							itemVariant += '-';
						}
						itemVariant += switchable_attributes[i].value;
					}
					item_variant = {
						item_variant: itemVariant,
					};
				}

				const quantityObj =
					quantity || cart_quantity ? { quantity: cart_quantity || quantity } : {};

				return {
					item_id: model?.slug || external_id,
					item_name: name_marketing || name,
					price: convertBYNToUSD(price, currency),
					discount: convertBYNToUSD(old_price - price, currency),
					item_brand: brand,
					item_category: category?.name,
					...categories,
					coupon: price_diff_percent ? `${price_diff_percent}%` : '0%',
					...quantityObj,
					...item_variant,
					index: index ? index : idx + 1,
					...itemListNameProduct,
				};
			},
		);

		setDataLayer({
			event: event ? event : 'view_item_list',
			ecommerce: {
				currency: 'USD',
				...valueObj,
				...itemListName,
				...successInfo,
				items,
			},
			_clear: true,
		});
	}, 'viewItemList');
};

const setPageType = pageType => {
	withErrorHandler(() => {
		setDataLayer({
			pageType,
		});
	}, 'setPageType');
};

const setLogin = (event, user_id) => {
	withErrorHandler(() => {
		setDataLayer({
			event,
			user_id,
		});
	}, 'setLogin');
};

const setSubscribe = check => {
	withErrorHandler(() => {
		setDataLayer({
			event: check ? 'subscribe' : 'unsubscribe',
		});
	}, 'setSubscribe');
};

const setSearch = searchString => {
	withErrorHandler(() => {
		setDataLayer({
			event: 'search',
			search_term: searchString,
		});
	}, 'setSearch');
};

const setDataLayer = config => {
	if (typeof window !== 'undefined') {
		window.dataLayer = window.dataLayer || [];
		dataLayer.push(config);
	}
};

export default {
	PAGE_TYPES,
	SOCIAL_BANNER_TYPE,
	subscribeByEmail,
	setPageType,
	viewItemList,
	setLogin,
	setSubscribe,
	setSearch,
};
