const SVG = () => (
	<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M1.00034 14.1349C0.987529 14.5225 1.33904 14.8666 1.85286 14.9696C2.36668 15.0725 2.91089 14.9079 3.17275 14.5703L4.56986 12.7688C6.22677 10.6324 9.52513 9.46197 12.8599 9.82703L13.0911 9.85234C13.1327 10.6158 13.1917 11.3788 13.2681 12.1412L13.3731 13.1893C13.4585 14.0416 14.7652 14.5181 15.7553 14.0579C19.0033 12.5484 21.8269 10.6033 24.0756 8.32632L24.7819 7.61124C25.0727 7.31676 25.0727 6.92012 24.7819 6.62564L24.0756 5.91055C21.8269 3.63359 19.0033 1.6885 15.7553 0.178945C14.7652 -0.281212 13.4585 0.195261 13.3731 1.04761L13.2681 2.09572C13.2065 2.71034 13.1562 3.3254 13.1172 3.94075L11.5407 3.94075C5.86626 3.94075 1.22845 7.23396 1.09206 11.3601L1.00034 14.1349ZM13.2056 8.1565C9.45955 7.74643 5.74926 8.84336 3.451 10.9589C3.85608 7.9596 7.33175 5.63009 11.5407 5.63009L14.2328 5.63009C14.8588 5.63009 15.3721 5.26929 15.3935 4.8143C15.4342 3.94846 15.4979 3.08304 15.5845 2.21854L15.5929 2.13469C18.1376 3.44298 20.3677 5.05167 22.1893 6.89615L22.4088 7.11844L22.1893 7.34073C20.3677 9.1852 18.1376 10.7939 15.5929 12.1022L15.5845 12.0183C15.487 11.0454 15.4186 10.0713 15.3792 9.09677C15.3627 8.68838 14.9467 8.34711 14.3913 8.2863L13.2056 8.1565Z'
			fill='#74849C'
		/>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M27.9997 15.8074C28.0125 15.4457 27.661 15.1245 27.1471 15.0284C26.6333 14.9323 26.0891 15.086 25.8273 15.4011L24.4301 17.0824C22.7732 19.0764 19.4749 20.1688 16.1401 19.8281L15.9089 19.8045C15.8673 19.092 15.8083 18.3798 15.7319 17.6683L15.6269 16.69C15.5415 15.8945 14.2348 15.4498 13.2447 15.8793C9.99674 17.2882 7.17311 19.1036 4.92435 21.2288L4.21812 21.8962C3.92729 22.171 3.92729 22.5412 4.21812 22.8161L4.92435 23.4835C7.17311 25.6086 9.99674 27.4241 13.2447 28.833C14.2348 29.2625 15.5415 28.8178 15.6269 28.0222L15.7319 27.044C15.7935 26.4704 15.8438 25.8963 15.8828 25.322L17.4593 25.322C23.1337 25.322 27.7716 22.2483 27.9079 18.3973L27.9997 15.8074ZM15.7944 21.3873C19.5404 21.77 23.2507 20.7462 25.549 18.7717C25.1439 21.571 21.6683 23.7452 17.4593 23.7452L14.7672 23.7452C14.1412 23.7452 13.6279 24.082 13.6065 24.5067C13.5658 25.3148 13.5021 26.1225 13.4155 26.9294L13.4071 27.0076C10.8624 25.7865 8.63231 24.2851 6.81068 22.5636L6.59115 22.3561L6.81068 22.1487C8.63231 20.4271 10.8624 18.9257 13.4071 17.7046L13.4155 17.7829C13.513 18.691 13.5814 19.6001 13.6208 20.5097C13.6373 20.8908 14.0533 21.2094 14.6087 21.2661L15.7944 21.3873Z'
			fill='#74849C'
		/>
	</svg>
);

export default SVG;
