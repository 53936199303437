import React, { useMemo, useState } from 'react';
import dynamic from 'next/dynamic';
import Image from 'next/image';
import { getCitiesSelector, menuFooterSelector, sessionIdSelector } from '@/store';
import { useDispatch, useSelector } from 'react-redux';
import { getSessionData, setRegion } from '@fh-components/fh-js-api-core/store/slices/sessionSlice';
import { fetchCart } from '@fh-components/fh-js-api-core/store/slices/cartSlice';
import Link from 'next/link';
import { socialList, paymentImages, messengersList } from '@/lib/config';
import { RoutedLink, Title, Text, SocialList, Control, Loader } from '@/components';
import Icon from '@/assets/icons';
import Linklist from './linksList';
import Subscribe from './subscribe';
import clsx from 'clsx';

import styles from './footer.module.scss';
import { useRouter } from 'next/router';

const Accordion = dynamic(() => import('../../components/accordion'), {
	loading: () => <Loader />,
});

const getHeight = index => {
	let height = '12px';
	switch (index) {
		case 1:
			height = '22px';
			break;
		case 2:
			height = '20px';
			break;
		case 3:
			height = '13px';
			break;
		case 4:
			height = '23px';
			break;
		case 5:
			height = '12px';
			break;
		case 6:
			height = '32px';
			break;
		case 7:
			height = '21px';
			break;
		case 8:
			height = '18px';
			break;
		case 9:
			height = '9px';
			break;
		case 10:
			height = '44px';
			break;
		default:
			break;
	}

	return height;
};

const getWidth = index => {
	let width = '34px';
	switch (index) {
		case 1:
			width = '32px';
			break;
		case 2:
			width = '30px';
			break;
		case 3:
			width = '40px';
			break;
		case 4:
			width = '20px';
			break;
		case 5:
			width = '48px';
			break;
		case 6:
			width = '33px';
			break;
		case 7:
			width = '32px';
			break;
		case 8:
			width = '32px';
			break;
		case 9:
			width = '38px';
			break;
		case 10:
			width = '66px';
			break;
		default:
			break;
	}

	return width;
};

const Footer = () => {
	const router = useRouter();
	const dispatch = useDispatch();
	const footer = useSelector(menuFooterSelector);
	const sessionData = useSelector(getSessionData);
	const citiesData = useSelector(getCitiesSelector);
	const sessionID = useSelector(sessionIdSelector);

	const [locationModalOpen, setLocationModalOpen] = useState(false);

	const currentCity = useMemo(() => {
		if (!sessionData.regionId) {
			return citiesData.find(city => city.name === 'Минск') ?? null;
		}

		return citiesData.find(city => city.id === sessionData.regionId) ?? null;
	}, [sessionData.regionId, citiesData]);

	const otherCity = useMemo(() => citiesData.find(city => city.id !== currentCity?.id), [
		currentCity,
		sessionData.regionId,
	]);

	const firstColLinks = footer.find(item => item?._id === 'first_col')?.links ?? [];
	const secondColLinks = footer.find(item => item?._id === 'second_col')?.links ?? [];
	const thirdColLinks = footer.find(item => item?._id === 'third_col')?.links ?? [];

	const handleCurrentCityClick = e => {
		e.stopPropagation();

		if (currentCity && sessionData.regionId !== currentCity.id) {
			dispatch(
				setRegion({
					regionId: currentCity.id,
					onSuccess: () => {
						if (router.pathname === '/cart' && sessionID) {
							dispatch(
								fetchCart({
									onSuccess: () => {
										dispatch(createSession(true, undefined, { forceRefresh: true }));
									},
								}),
							);
						}
					},
				}),
			);
		}
		setLocationModalOpen(false);
	};

	const handleOtherCityClick = e => {
		e.stopPropagation();

		if (otherCity) {
			dispatch(
				setRegion({
					regionId: otherCity.id,
					onSuccess: () => {
						if (router.pathname === '/cart' && sessionID) {
							dispatch(
								fetchCart({
									onSuccess: () => {
										dispatch(createSession(true, undefined, { forceRefresh: true }));
									},
								}),
							);
						}
					},
				}),
			);
		}
		setLocationModalOpen(false);
	};

	// const onClickPhone = () => {
	//   ecommerce.onClickPhoneNumber();
	// };

	return (
		<footer id='footer' className={styles.footer}>
			<div className={styles.container}>
				<div className={clsx(styles.links, styles['order-1'], styles.desktopLinks)}>
					<Title component='h3' className={styles.title}>
						Популярные категории
					</Title>

					<div className={styles.content}>
						<Linklist links={firstColLinks} />
						<div className={styles.location} onClick={() => setLocationModalOpen(true)}>
							<Icon name='gps' />
							<span className={styles.locationTitle}>{`Самовывоз: г. ${currentCity?.name ?? ''}`}</span>
							<div className={clsx(styles.controlDropdown, locationModalOpen && styles.active)}>
								<div className={styles.controlInner}>
									<div className={styles.question}>
										Отображать товары, доступные к&nbsp;самовывозу только из{' '}
										{`г. ${currentCity?.name ?? ''}?`}
									</div>
									<div className={styles.btns}>
										<span
											className={clsx(styles.btn, styles.active)}
											onClick={handleCurrentCityClick}
										>
											Да, всё верно
										</span>
										<span
											className={clsx(styles.btn)}
											onClick={handleOtherCityClick}
										>{`Выбрать г. ${otherCity?.name}`}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className={clsx(styles.links, styles['order-2'], styles.desktopLinks)}>
					<Title component='h3' className={styles.title}>
						Покупателям
					</Title>
					<Linklist links={secondColLinks} />
				</div>

				<div className={clsx(styles.links, styles['order-3'], styles.desktopLinks)}>
					<Title component='h3' className={styles.title}>
						Информация
					</Title>
					<Linklist links={thirdColLinks} />
				</div>
				<div className={clsx(styles.links, styles['order-1'], styles.mobileLinks)}>
					<Accordion
						title={
							<Title component='h3' className={styles.title}>
								Популярные категории
							</Title>
						}
						unmountOnClose
						isFooter
					>
						<Linklist links={firstColLinks} />
					</Accordion>
				</div>

				<div className={clsx(styles.links, styles['order-2'], styles.mobileLinks)}>
					<Accordion
						title={
							<Title component='h3' className={styles.title}>
								Покупателям
							</Title>
						}
						unmountOnClose
						isFooter
					>
						<Linklist links={secondColLinks} />
					</Accordion>
				</div>

				<div className={clsx(styles.links, styles['order-3'], styles.mobileLinks)}>
					<Accordion
						title={
							<Title component='h3' className={styles.title}>
								Информация
							</Title>
						}
						unmountOnClose
						isFooter
					>
						<Linklist links={thirdColLinks} />
					</Accordion>
				</div>

				<div className={clsx(styles.links, styles['order-5'], styles.mobileLinks)}>
					<Accordion
						title={
							<Title component='h3' className={styles.title}>
								<div className={styles.mobileLocationTitle}>
									<Icon name='gps' />
									<span>{`Самовывоз: г. ${currentCity?.name ?? ''}`}</span>
								</div>
							</Title>
						}
						unmountOnClose
						isFooter
					>
						<div className={styles.modileLocationContent}>
							<div className={styles.question}>
								Отображать товары, доступные к&nbsp;самовывозу только из{' '}
								{`г. ${currentCity?.name ?? ''}?`}
							</div>
							<div className={styles.btns}>
								<span className={clsx(styles.btn, styles.active)} onClick={handleCurrentCityClick}>
									Да, всё верно
								</span>
								<span className={clsx(styles.btn)} onClick={handleOtherCityClick}>{`Выбрать г. ${otherCity?.name
									}`}</span>
							</div>
						</div>
					</Accordion>
				</div>

				<div className={styles.info}>
					<div className={styles.workAndSubscribe}>
						<div className={styles.work}>
							<a href='tel:+375296332633' onClick={() => { }} className={styles.phone}>
								<Icon name='phone-fill' width={16} height={16} viewBox='0 0 16 16' />
								+375 (29) 633-2-633
							</a>
							<Text className={styles['work-time']}>
								Время работы: пн-вс с 09:00 до 21:00,
								<br /> Заказы через корзину круглосуточно
							</Text>
						</div>

						<Subscribe />
					</div>
					<div className={styles.socialList}>
						<SocialList list={[...messengersList, ...socialList]} type='messengers' />
					</div>
					<div className={styles.app}>
						<div className={styles['app-content']}>
							<div className={styles.appLinks}>
								<a
									href='https://apps.apple.com/us/app/id1518240278'
									rel='noopener noreferrer'
									target='_blank'
									className={styles['app-link']}
									onClick={() => { }}
								>
									<Icon name='ios' />
									Скачать
									<br />в App Store
								</a>

								<a
									href='https://play.google.com/store/apps/details?id=com.websecret.fh'
									rel='noopener noreferrer'
									target='_blank'
									className={styles['app-link']}
									onClick={() => { }}
								>
									<Icon name='google-play' />
									Скачать
									<br />в Google Play
								</a>
							</div>
						</div>
					</div>
				</div>

				{/* <div className={clsx(styles.divider, styles.first)} /> */}

				<div className={styles.features}>
					<div className={styles['features-text']}>
						<div className={styles.text}>
							Интернет-магазин одежды, обуви и аксессуаров мировых брендов. Бесплатная доставка с
							примеркой по всей Беларуси*. Самовывоз из фирменных салонов сети. Быстрая доставка в
							Россию.
						</div>
						<div className={styles.text}>
							*Подробнее на странице «
							<RoutedLink to='/delivery'>
								<span>Доставка и оплата</span>
							</RoutedLink>
							»
						</div>
					</div>
				</div>

				<div className={styles.payment}>
					<ul className={styles.paymentList}>
						{paymentImages.map((image, ind) => (
							<li
								key={image.alt}
								className={clsx(styles.paymentListItem, ind === 9 && styles.bePaid)}
							>
								<Image
									width={getWidth(ind)}
									height={getHeight(ind)}
									src={image.src}
									alt={image.alt}
									className={styles.paymentImg}
									layout='intrinsic'
								/>
							</li>
						))}
					</ul>
				</div>

				<div className={clsx(styles.divider, styles.second)} />

				<div className={styles.legal}>
					<div className={styles.copy}>
						&copy; {new Date().getFullYear()} FH.BY
						<Link href='/catalog' passHref>
							<Control>
								<span>Карта сайта</span>
							</Control>
						</Link>
					</div>

					<div className={styles.copyText}>
						<p>
							Общество с дополнительной ответственностью «БелВиринея» зарегистрировано 06.04.2006
							Минским горисполкомом. УНП 190706320. Юр.адрес: г. Минск, ул. Немига, 5, пом. 39.
							Интернет-магазин fh.by зарегистрирован в Торговом реестре Республики Беларусь
							14.11.2019 года. Регистрационный номер 465593. Время работы Пн-Вс, круглосуточно.
							Тел.: +375 (29) 633-2-633, +375 (17) 328-60-79. E-mail: fh@fh.by
						</p>
						<p>
							Контакты лица, уполномоченного рассматривать обращения покупателей о нарушении прав,
							предусмотренных законодательством о защите прав потребителей: тел.: +375 (17)
							243-20-79, e-mail: o.boris@fh.by
						</p>
						<p>
							Контакты отдела торговли и услуг администрации Центрального района г. Минска для
							рассмотрения обращений покупателей: тел.: +375 (17) 390-42-95, тел./факс: +375 (17)
							234-42-65, +375 (17) 272-53-46.
						</p>
					</div>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
