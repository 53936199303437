import React, { useEffect, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { usePrivateChannels } from '@/contexts/ChannelsContext';
import {
	removeWishlistId,
	addWishlistId,
	fetchWishlist,
	updateWishlistIds,
} from '@fh-components/fh-js-api-core/store/slices/wishlistSlice';
import {
	removeCartId,
	addCartId,
	updateCartCount,
	fetchCart,
	updateCartIds,
} from '@fh-components/fh-js-api-core/store/slices/cartSlice';
import { sessionIdSelector, cartCountSelector } from '@/store';

const EventsContext = React.createContext(undefined);

const events = {
	FAVORITE_ADD: 'SessionFavoriteAddEvent',
	FAVORITE_REMOVE: 'SessionFavoriteRemoveEvent',
	FAVORITE_UPDATE: 'SessionFavoriteUpdateEvent',
	FAVORITE_UNAVAILABLE: 'SessionFavoriteChangeUnavailableEvent',
	CART_ADD: 'SessionCartAddEvent',
	CART_REMOVE: 'SessionCartRemoveEvent',
	CART_UPDATE: 'SessionCartUpdateEvent',
	CART_REFRESH: 'SessionCartRefreshEvent',
	CART_FAVORITE_REFRESH: 'SessionCartFavoriteRefreshRequiredEvent',
};

export const EventsProvider = ({ children }) => {
	const dispatch = useDispatch();
	const router = useRouter();
	const sessionID = useSelector(sessionIdSelector);
	const channels = usePrivateChannels(sessionID, dispatch);
	const cartItemsQuantity = useSelector(cartCountSelector);

	const onChange = useCallback(
		(e, type) => {
			console.log('event type --- ', type);
			console.log('event value --- ', e);
			const isFavorites = router.pathname.includes('wishlist');
			const isCart = router.pathname.includes('cart');

			switch (type) {
				case events.FAVORITE_ADD: {
					dispatch(
						addWishlistId({
							id: {
								product_id: e.value,
								model_id: e.model_id,
							},
						}),
					);

					if (isFavorites) {
						dispatch(fetchWishlist({ page: 1 }));
					}

					break;
				}
				case events.FAVORITE_REMOVE: {
					dispatch(
						removeWishlistId({ id: e.value ? e.value : e.model_id, isVariation: !!e.value }),
					);

					if (isFavorites) {
						dispatch(fetchWishlist({ page: 1 }));
					}

					break;
				}
				case events.FAVORITE_UPDATE: {
					dispatch(
						removeWishlistId({
							id: e.old_value ? e.old_value : e.model_id,
							isVariation: !!e.old_value,
						}),
					);
					dispatch(
						addWishlistId({
							id: {
								product_id: e.value,
								model_id: e.model_id,
							},
						}),
					);

					if (isFavorites) {
						dispatch(fetchWishlist({ page: 1 }));
					}

					break;
				}
				case events.FAVORITE_UNAVAILABLE: {
					if (isFavorites) {
						dispatch(fetchWishlist({ page: 1 }));
					}

					break;
				}
				case events.CART_ADD: {
					dispatch(addCartId({ id: e.value }));
					dispatch(updateCartCount({ count: cartItemsQuantity + 1 }));

					if (isCart) {
						dispatch(fetchCart());
					}

					break;
				}
				case events.CART_REMOVE: {
					dispatch(removeCartId({ id: e.value }));
					dispatch(updateCartCount({ count: cartItemsQuantity - 1 }));

					break;
				}
				case events.CART_UPDATE: {
					dispatch(updateCartCount({ count: e.total_quantity }));

					break;
				}
				case events.CART_FAVORITE_REFRESH: {
					dispatch(updateWishlistIds({ ids: e.favorites }));
					dispatch(updateCartCount({ count: e.cartCount }));
					dispatch(updateCartIds({ ids: e.cartProducts }));

					if (isFavorites) {
						dispatch(fetchWishlist({ page: 1 }));
					}
					break;
				}

				default:
			}
		},
		[dispatch, cartItemsQuantity, router],
	);

	useEffect(() => {
		if (channels) {
			channels.listen(`.${events.FAVORITE_ADD}`, e => onChange(e, events.FAVORITE_ADD));
			channels.listen(`.${events.FAVORITE_REMOVE}`, e => onChange(e, events.FAVORITE_REMOVE));
			channels.listen(`.${events.FAVORITE_UPDATE}`, e => onChange(e, events.FAVORITE_UPDATE));
			channels.listen(`.${events.FAVORITE_UNAVAILABLE}`, e =>
				onChange(e, events.FAVORITE_UNAVAILABLE),
			);
			channels.listen(`.${events.CART_ADD}`, e => onChange(e, events.CART_ADD));
			channels.listen(`.${events.CART_REMOVE}`, e => onChange(e, events.CART_REMOVE));
			channels.listen(`.${events.CART_UPDATE}`, e => onChange(e, events.CART_UPDATE));
			channels.listen(`.${events.CART_FAVORITE_REFRESH}`, e =>
				onChange(e, events.CART_FAVORITE_REFRESH),
			);

			return () => {
				channels.stopListening(`.${events.FAVORITE_ADD}`);
				channels.stopListening(`.${events.FAVORITE_REMOVE}`);
				channels.stopListening(`.${events.FAVORITE_UPDATE}`);
				channels.stopListening(`.${events.FAVORITE_UNAVAILABLE}`);
				channels.stopListening(`.${events.CART_ADD}`);
				channels.stopListening(`.${events.CART_REMOVE}`);
				channels.stopListening(`.${events.CART_UPDATE}`);
				channels.stopListening(`.${events.CART_FAVORITE_REFRESH}`);
			};
		}
	}, [channels, onChange]);

	return <EventsContext.Provider>{children}</EventsContext.Provider>;
};
