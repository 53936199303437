import { Block, Container } from '@/containers';
import { RoutedLink, PageTitle, PageText, LinkButton, Title, Text } from '@/components';

import styles from './TitleDescLink.module.scss';

const SHOPS_ADDRESS_TITLE = 'Адреса магазинов';
const SHOPS_URL = '/shops';

const TitleDescLink = props => {
	const { title, description, url_text: linkTitle, url: linkUrl, indent } = props;
	const withLink = linkTitle && linkUrl;

	if (!title && !description && !withLink) return null;

	const redirectLink =
		linkTitle.indexOf(SHOPS_ADDRESS_TITLE) !== -1 && linkUrl.indexOf(SHOPS_URL) !== -1
			? SHOPS_URL
			: linkUrl;

	return (
		<Block>
			<Container>
				<div className={styles['content-limiter']}>
					{title && (
						<PageTitle>
							<Title mb='none' align='center' component='h1'>
								{title}
							</Title>
						</PageTitle>
					)}

					{description && (
						<PageText>
							<Text size='size-large' mb='none' align='center'>
								{description}
							</Text>
						</PageText>
					)}

					{withLink && (
						<div className={styles.link}>
							<RoutedLink to={redirectLink} component={LinkButton} as='a'>
								{linkTitle}
							</RoutedLink>
						</div>
					)}
				</div>
			</Container>
		</Block>
	);
};

export default TitleDescLink;
