import { useState, useEffect } from 'react';
import { Modal, Button } from '@/components';
import { logout } from '@fh-components/fh-js-api-core/store/slices/userSlice';
import noScroll from 'no-scroll';
import useWindowSize from '@/hooks/use-window-size';
import {
	updateCartIds,
	updateCartCount,
} from '@fh-components/fh-js-api-core/store/slices/cartSlice';
import { updateWishlistIds } from '@fh-components/fh-js-api-core/store/slices/wishlistSlice';
import {
	removeToken,
	createSession,
} from '@fh-components/fh-js-api-core/store/slices/sessionSlice';
import { useDispatch } from 'react-redux';
import Router from 'next/router';

import styles from './LogoutModal.module.scss';

const ModalTrigger = ({ isProfile, ...props }) => (
	<span {...props} className={isProfile && styles.isProfile}>
		Выход
	</span>
);

const LogoutModal = ({ isProfile }) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(true);
	const { isMobileMenu } = useWindowSize();
	const [mobileVisible, setMobileVisible] = useState(false);

	const cancelClick = () => {
		setVisible(false);
		if (mobileVisible && isMobileMenu) {
			noScroll.toggle();
		}
	};

	const logoutClick = () => {
		dispatch(
			logout(() => {
				if (typeof window !== 'undefined' && sessionStorage.getItem('country')) {
					sessionStorage.removeItem('country');
				}

				dispatch(updateCartIds({ ids: [] }));
				dispatch(updateCartCount({ count: 0 }));
				dispatch(updateWishlistIds({ ids: [] }));
				dispatch(
					removeToken({
						onSuccess: () => {
							setVisible(false);
							dispatch(createSession(true));
							Router.push({ pathname: '/' }, { pathname: '/' }).then(() => window.scrollTo(0, 0));
						},
					}),
				);
			}),
		);
	};

	useEffect(() => {
		if (mobileVisible && isMobileMenu) {
			noScroll.toggle();
		}
	}, [mobileVisible, isMobileMenu]);

	useEffect(() => {
		if (!visible) {
			setVisible(true);
		}
	}, [visible]);

	return (
		<div>
			<Modal
				isSizeTable
				generalVisible={visible}
				setMobileVisible={setMobileVisible}
				render={props => {
					return <ModalTrigger {...props} isProfile={isProfile} />;
				}}
			>
				<div className={styles.block}>
					<div className={styles.title}>Вы уверены, что хотите выйти?</div>
					<div className={styles.buttonContainer}>
						<Button onClick={cancelClick} classNames={{ general: styles.buttonCancel }}>
							Отмена
						</Button>
						<Button
							onClick={logoutClick}
							type='defaultBlack'
							classNames={{ general: styles.buttonLogout }}
						>
							Выйти
						</Button>
					</div>
				</div>
			</Modal>
		</div>
	);
};

export default LogoutModal;
