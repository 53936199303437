const SVG = ({
	fill = 'currentColor',
	width = '11',
	height = '11',
	viewBox = '0 0 11 11',
	className,
	onClick,
}) => (
	<svg width='18' height='19' viewBox='0 0 18 19' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='9' cy='9.5' r='9' fill='#F0F0F0' />
		<path
			d='M9.93066 11.5674H8.40625C8.41081 11.1436 8.44727 10.7881 8.51562 10.501C8.58398 10.2093 8.69792 9.94499 8.85742 9.70801C9.02148 9.47103 9.23796 9.22038 9.50684 8.95605C9.71647 8.75553 9.9056 8.56641 10.0742 8.38867C10.2428 8.20638 10.3773 8.01497 10.4775 7.81445C10.5778 7.60938 10.6279 7.37467 10.6279 7.11035C10.6279 6.82324 10.5801 6.57943 10.4844 6.37891C10.3887 6.17839 10.2474 6.02572 10.0605 5.9209C9.87826 5.81608 9.65039 5.76367 9.37695 5.76367C9.14909 5.76367 8.9349 5.80924 8.73438 5.90039C8.53385 5.98698 8.37207 6.1237 8.24902 6.31055C8.12598 6.49284 8.0599 6.73438 8.05078 7.03516H6.40332C6.41243 6.46094 6.54915 5.97786 6.81348 5.58594C7.0778 5.19401 7.43327 4.90007 7.87988 4.7041C8.3265 4.50814 8.82552 4.41016 9.37695 4.41016C9.98763 4.41016 10.5094 4.51497 10.9424 4.72461C11.3753 4.92969 11.7057 5.23047 11.9336 5.62695C12.166 6.01888 12.2822 6.49284 12.2822 7.04883C12.2822 7.44987 12.2025 7.81445 12.043 8.14258C11.8835 8.46615 11.6761 8.76921 11.4209 9.05176C11.1657 9.32975 10.89 9.60775 10.5938 9.88574C10.3385 10.1182 10.1654 10.3711 10.0742 10.6445C9.98307 10.9134 9.93522 11.221 9.93066 11.5674ZM8.26953 13.7002C8.26953 13.4541 8.35384 13.2467 8.52246 13.0781C8.69108 12.9049 8.92122 12.8184 9.21289 12.8184C9.50456 12.8184 9.7347 12.9049 9.90332 13.0781C10.0719 13.2467 10.1562 13.4541 10.1562 13.7002C10.1562 13.9463 10.0719 14.1559 9.90332 14.3291C9.7347 14.4977 9.50456 14.582 9.21289 14.582C8.92122 14.582 8.69108 14.4977 8.52246 14.3291C8.35384 14.1559 8.26953 13.9463 8.26953 13.7002Z'
			fill='#585858'
		/>
	</svg>
);

export default SVG;
