import { useRef, useState, useEffect, useCallback, useMemo } from 'react';
import {
	newFetchSearchResults,
	getSearchData,
} from '@fh-components/fh-js-api-core/store/slices/searchSlice';
import dynamic from 'next/dynamic';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { useDispatch, useSelector } from 'react-redux';
import Link from 'next/link';
import { Block, Container } from '@/containers';
import { hasSmartBannerSelector } from '@/store';
import getLinkProps from '@/lib/getLinkProps';
import { Control, Picture, Price, Text, ProductFeature, Loader, Input, Button } from '@/components';
import { useRouter } from 'next/router';
import { useWindowSize } from '@/hooks';
import ecommerce from '@/seo/Ecommerce';
import clsx from 'clsx';

import styles from './search.module.scss';

const Html = dynamic(() => import('../../../components/html'), {
	loading: () => <Loader />,
});

const Dialog = dynamic(() => import('../../../components/dialog'), {
	loading: () => <Loader />,
});

const Search = ({ visible, hideSearch, ...props }) => {
	const router = useRouter();
	const { isCollectionMobile } = useWindowSize();
	const dispatch = useDispatch();
	const hasSmartBanner = useSelector(hasSmartBannerSelector);
	const { results } = useSelector(getSearchData);
	const {
		categories = [],
		products = [],
		redirectUrl = '',
		query,
		brands,
		searchOften,
		correction,
	} = results;
	const [searchQuery, setSearchQuery] = useState('');
	const [loading, setLoading] = useState(false);
	const input = useRef();
	const requestTimeoutId = useRef();
	const isSmallWindow = useMemo(
		() => redirectUrl.length > 0 && redirectUrl !== '/search/no-result',
		[redirectUrl],
	);

	const withoutResults = useMemo(() => !categories && !products, [categories, products]);

	const countResults = isCollectionMobile ? 3 : 4;

	useEffect(() => {
		setTimeout(() => {
			const isSearchPage = router.pathname.includes('search');

			if (visible && !isSearchPage) {
				dispatch(
					newFetchSearchResults({
						query: '',
						onSuccess: () => {
							setLoading(false);
						},
					}),
				);
				setSearchQuery('');
			}
		}, 0);
	}, [visible]);

	useEffect(() => {
		if (!loading && results?.query !== '' && !isSmallWindow) {
			window?.Digi?.api.sendSearchEvent({
				searchTerm: results.query,
				correction: results.correction,
				pageProducts: results.products ? results.products.map(({ model }) => model.slug) : [],
			});
		}
	}, [loading, results]);

	useEffect(() => {
		const handleRouteChange = url => {
			if (visible) {
				hideSearch();
			}
		};

		router.events.on('routeChangeStart', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [visible, hideSearch]);

	const search = value => {
		clearTimeout(requestTimeoutId.current);
		const query = value;

		// if (query.trim().length === 0) {
		//     dispatch(resetSearch());
		//     return;
		// }

		requestTimeoutId.current = setTimeout(() => {
			dispatch(
				newFetchSearchResults({
					query,
					onSuccess: () => {
						setLoading(false);
					},
				}),
			);
			ecommerce.setSearch(query);
		}, 300);
	};

	const onResetSearch = e => {
		setSearchQuery('');
		dispatch(newFetchSearchResults({ query: '' }));
	};

	const onChange = e => {
		if (e) {
			e.preventDefault();
		}
		const value = e?.target?.value ?? '';
		setSearchQuery(value);

		search(value);
	};

	const isNotOnlySpaces = useMemo(() => {
		if (searchQuery.length > 0) {
			return searchQuery.split('').some(item => item !== ' ');
		}

		return true;
	}, [searchQuery]);

	const goToResults = useCallback(() => {
		if (isNotOnlySpaces) {
			hideSearch();
			// setSearchString(searchQuery);

			// if (redirectUrl.length === 0) {
			if (searchQuery.length > 0) {
				if (isSmallWindow) {
					window?.Digi?.api.sendAutocompleteRedirectEvent({
						searchTerm: searchQuery,
						correction,
						item: searchQuery,
					});
				}

				router.push(`/search?q=${searchQuery}`);
			}
		}
		// } else {
		//     router.push(redirectUrl);
		// }
	}, [searchQuery, router, hideSearch, isSmallWindow, correction]);

	const onKeyDown = useCallback(
		event => {
			const { keyCode, target } = event || {};

			if (keyCode === 13 && target?.name === 'search-string') {
				event?.preventDefault();
				goToResults();
			}
		},
		[goToResults, query],
	);

	useEffect(() => {
		document.addEventListener('keydown', onKeyDown, false);

		return () => {
			document.removeEventListener('keydown', onKeyDown, false);
		};
	}, [onKeyDown]);

	useEffect(() => {
		const isSearchPage = router.pathname.includes('search');
		const searchString = localStorage.getItem('searchString');

		setLoading(true);

		if (isSearchPage && visible) {
			setTimeout(() => {
				setSearchQuery(searchString);
			}, 0);
			search(searchString);
		}
	}, [router.pathname, visible]);

	const goods = useMemo(() => {
		const handleProductClick = (name, id) => {
			window?.Digi?.api.sendAutocompleteProductClickEvent({
				searchTerm: searchQuery,
				correction,
				item: name,
				productId: `${id}`,
			});
		};

		return (
			<div className={styles.goods}>
				<div className={styles.blockTitle}>Товары</div>
				<ul className={styles.results}>
					{products?.map((result, index) => {
						const {
							name,
							model,
							meta,
							brand,
							price,
							old_price,
							media,
							price_diff_percent,
							external_id,
						} = result;
						const [href, as] = getLinkProps({ type: 'product', value: model.slug });

						return index < countResults ? (
							<li
								key={`${name}-${model.slug}`}
								onClick={() => {
									handleProductClick(name, model.slug);
								}}
							>
								<Link href={href} as={as} passHref>
									<Control className={clsx(styles.result)}>
										<div className={styles.image}>
											<Picture {...media?.[0]} />
										</div>
										<div>
											<div className={styles.brand}>{brand}</div>
											<Text
												className={clsx(
													styles.resultTitle,
													!!price_diff_percent && styles.noMargin,
												)}
											>
												{name}
											</Text>
											{price && (
												<div
													className={clsx(styles.price, {
														[styles.withDiscount]: price < old_price,
													})}
												>
													{!!price_diff_percent && (
														<ProductFeature title={`${price_diff_percent}%`} styledType='sale' />
													)}
													<Price price={price} oldPrice={old_price} isSearch />
												</div>
											)}
										</div>
									</Control>
								</Link>
							</li>
						) : null;
					})}
				</ul>
				{searchQuery.length > 0 && products && products.length && isNotOnlySpaces && (
					<Button
						classNames={{ general: styles.allResults }}
						type='primary'
						onClick={goToResults}
						disabled={searchQuery.length < 1}
					>
						Все результаты
					</Button>
				)}
			</div>
		);
	}, [products, goToResults, searchQuery, isNotOnlySpaces]);

	const catalog = useMemo(() => {
		const handleCategoryClick = name => {
			window?.Digi?.api.sendAutocompleteCategoryClickEvent({
				searchTerm: searchQuery,
				correction,
				item: name,
			});
		};

		const handleBrandClick = name => {
			window?.Digi?.api.sendAutocompleteBrandClickEvent({
				searchTerm: searchQuery,
				correction,
				item: name,
			});
		};

		return (
			<div className={styles.catalog}>
				<div className={styles.categories}>
					<div className={styles.blockTitle}>Категории</div>
					<ul className={styles.categoriesList}>
						{categories?.map(({ name, slug }, index) => {
							return index < countResults ? (
								<li
									key={slug}
									onClick={() => {
										handleCategoryClick(name);
									}}
								>
									<a href={slug} className={styles.catalogLink}>
										{name}
										<button />
									</a>
								</li>
							) : null;
						})}
					</ul>
				</div>
				<div className={styles.brands}>
					<div className={styles.blockTitle}>Бренды</div>
					<ul>
						{brands?.map(({ name, slug }, index) => {
							return index < countResults ? (
								<li
									key={slug}
									onClick={() => {
										handleBrandClick(name);
									}}
								>
									<a href={slug} className={styles.catalogLink}>
										{name}
										<button />
									</a>
								</li>
							) : null;
						})}
					</ul>
				</div>
			</div>
		);
	}, [categories, brands]);

	const oftenSearches = useMemo(() => {
		const handleOftenSearchClick = name => {
			window?.Digi?.api.sendAutocompleteQueryClickEvent({
				searchTerm: searchQuery,
				correction,
				item: name,
			});
		};

		return (
			<div className={styles.oftenSearch}>
				<div className={styles.blockTitle}>Часто ищут</div>
				<ul>
					{searchOften?.map(({ name }, index) => {
						let newName = name;
						const indexStart = name.indexOf(searchQuery);

						if (searchQuery.length > 0 && indexStart !== -1) {
							newName =
								name.substr(0, indexStart) +
								'<b>' +
								name.substr(indexStart, searchQuery.length) +
								'</b>' +
								name.substr(indexStart + searchQuery.length);
						}

						return index < countResults ? (
							<li
								key={name}
								onClick={() => {
									handleOftenSearchClick(name);
								}}
							>
								<a href={`/search?q=${name}`} className={styles.catalogLink}>
									<Html html={newName} />
								</a>
							</li>
						) : null;
					})}
				</ul>
			</div>
		);
	}, [searchOften, searchQuery]);

	const main = useMemo(() => {
		return (
			<Block className={styles.mainBlock}>
				<Container>
					<div className={styles.inputBlock}>
						<Input
							onChange={onChange}
							value={searchQuery}
							label='Поиск'
							name='search-string'
							ref={input}
							isClear
							clearClick={onResetSearch}
							classNames={{ general: styles.inputGeneral, field: styles.inputField }}
							isSearch
						/>
						<Button classNames={{ general: styles.cancelSearch }} type='link' onClick={hideSearch}>
							Отменить
						</Button>
					</div>
					{withoutResults ? (
						<h3 className={styles.warningInfo}>К сожалению, по вашему запросу ничего не найдено</h3>
					) : (
						<>
							<div className={clsx(styles.resultBlock, styles.resultBlockDesktop)}>
								{oftenSearches}
								{goods}
								{catalog}
							</div>
							<div className={clsx(styles.resultBlock, styles.resultBlockMobile)}>
								<div className={styles.resultCatInfoBlock}>
									{oftenSearches}
									{catalog}
								</div>
								{goods}
							</div>
						</>
					)}
				</Container>
			</Block>
		);
	}, [searchQuery, goods, catalog, oftenSearches, withoutResults]);

	return (
		<div className={clsx(styles.container, props.className)} tabIndex='-1'>
			<Dialog
				showDialog={visible}
				onDismiss={hideSearch}
				rootClassName={clsx(styles.dialogRoot, hasSmartBanner && styles.smartBanner)}
				dialogClassName={clsx(styles.dialog, isSmallWindow && styles.smallDialog)}
				mainClassName={styles.dialogMain}
				main={loading ? <Loader /> : main}
			/>
		</div>
	);
};

export default Search;
