import { memo } from 'react';
import clsx from 'clsx';
import styles from './checkbox.module.scss';

const Checkbox = memo(
	({ name, label, children, checked, onChange, disabled, isError, className }) => (
		<div className={clsx(styles.wrapper, className)}>
			<label className={clsx(styles.check, disabled && styles.checkDisabled)} htmlFor={name}>
				<input
					type='checkbox'
					name={name}
					id={name}
					disabled={disabled}
					checked={checked}
					onChange={onChange}
					className={styles.input}
				/>
				<span
					className={clsx(styles.box, {
						[styles.isError]: isError,
						[styles.boxDisabled]: disabled,
					})}
				/>
				{label && <span className={clsx(styles.label, disabled && styles.disabled)}>{label}</span>}
			</label>
			{children}
		</div>
	),
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
