import clsx from 'clsx';
import { forwardRef } from 'react';
import styles from './Link.module.scss';

const Link = forwardRef((props, ref) => {
	const { children, className, ...rest } = props;
	return (
		<a ref={ref} className={clsx(styles.link, className)} {...rest}>
			{children}
		</a>
	);
});

export default Link;
