import React from 'react';
import dynamic from 'next/dynamic';
import {
	getWishlistData,
	fetchWishlist,
} from '@fh-components/fh-js-api-core/store/slices/wishlistSlice';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { createSession } from '@fh-components/fh-js-api-core/store/slices/sessionSlice';
import { useEffect, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import getLinkProps from '@/lib/getLinkProps';
import { animateScroll } from 'react-scroll';
import Router from 'next/router';
import Link from 'next/link';
import { BreadCrumbs, Container } from '@/containers';
import { Title, PageText, Button, Loader } from '@/components';
import mindbox from '@/seo/Mindbox';
import getProductWithoutQuantity from '@/lib/getProductWithoutQuantity';
import { useRouter } from 'next/router';
import qs from 'qs';
import ecommerce from '@/seo/Ecommerce';
import clsx from 'clsx';

import styles from './WishlistPage.module.scss';

const Wishlist = dynamic(() => import('../../containers/wishlist'), {
	loading: () => <Loader />,
});

const ScrollBtn = dynamic(() => import('../../components/scrollBtn'), {
	loading: () => <Loader />,
});

const Pagination = dynamic(() => import('../../components/pagination'), {
	loading: () => <Loader />,
});

const WishlistPage = () => {
	const { products, meta, firstLoading: isLoading, loading, updating, ids } = useSelector(
		getWishlistData,
	);
	const router = useRouter();
	const { query } = router;
	const { page = 1 } = qs.parse(query);
	const currency = useSelector(getCurrency);

	const pagination = useMemo(() => {
		if (meta && meta.total > meta.per_page) {
			return (
				<div className={styles.wishlistPagination}>
					<Pagination lastPage={meta.last_page} currentPage={page} />
				</div>
			);
		}
		return null;
	}, [meta, page]);

	const [wishlistHref, _wishlistAs, wishlistTitle] = getLinkProps({ type: 'wishlist' });
	const breadCrumbsWishlist = [{ slug: wishlistHref, name: wishlistTitle }];

	const isEmpty = products.length === 0;
	const dispatch = useDispatch();

	useEffect(() => {
		mindbox?.viewPage({ page: 'Wishlist' });
		dispatch(createSession(true, undefined, { forceRefresh: true }));
	}, []);

	useEffect(() => {
		setTimeout(() => {
			if (!isLoading && products) {
				ecommerce.viewItemList(
					getProductWithoutQuantity(products.map(item => item.product)),
					currency,
					'Каталог товаров: Избранное',
				);
			}
		}, 0);
	}, [isLoading, products, currency]);

	useEffect(() => {
		if (isEmpty) {
			animateScroll.scrollToTop();
		}
	}, [isEmpty]);

	useEffect(() => {
		if (loading || updating) {
			animateScroll.scrollToTop();
		}
	}, [loading, updating]);

	const getWishlist = useCallback(() => {
		dispatch(fetchWishlist({ page }));
	}, [dispatch, page]);

	useEffect(() => {
		if (page !== 1 || (meta && meta.current_page && meta.current_page !== 1)) {
			getWishlist();
		}
	}, [page, getWishlist]);

	useEffect(() => {
		if (page == 1) {
			getWishlist();
		}
	}, []);

	const refreshWishlist = () => {
		if (page != 1 && products.length === 1) {
			Router.push({ pathname: '/wishlist', query: { page: page - 1 } });
		} else {
			getWishlist();
		}
	};

	//  !isLoading
	return (
		<section className={styles.wishlistSection}>
			<Container>
				<div className={styles.breadCrumbs}>
					<BreadCrumbs type='wishlist' breadcrumbs={breadCrumbsWishlist} />
				</div>
			</Container>
			{!loading && !isLoading && !updating ? (
				<Container className={clsx(isEmpty && styles.wishlistContainer)}>
					<Title mb='large' component='h1' {...(isEmpty ? { align: 'center' } : { align: 'left' })}>
						{isEmpty ? 'Список желаний пуст' : 'Избранное'}
					</Title>

					{isEmpty ? (
						<>
							<PageText className={styles.emptyText}>
								Сохраняйте понравившиеся товары нажав ♡ на карточке товара.
							</PageText>
							<Link href='/' as='/' passHref>
								<Button type='primary'>Перейти на главную</Button>
							</Link>
						</>
					) : (
						<>
							<Wishlist
								products={products}
								currency={currency}
								getWishlist={refreshWishlist}
								ids={ids}
							/>
							{pagination}
							<ScrollBtn isWishlist />
						</>
					)}
				</Container>
			) : (
				<Loader visible />
			)}
		</section>
	);
};

export default WishlistPage;
