import sbjs from 'sourcebuster';
import axios from 'axios';

export const settingGlobalParams = ({ deliveryUrl, query, clientIp }) => {
	setTimeout(() => {
		if (!localStorage.getItem('currency') || !sessionStorage.getItem('country')) {
			axios
				.get(`${deliveryUrl}/geocode/geoip`, { ip: clientIp })
				.then(response => {
					const iso = response.data.message.country.iso;

					if (!localStorage.getItem('currency')) {
						localStorage.setItem('currency', iso === 'RU' ? iso : 'BY');
					}

					if (!sessionStorage.getItem('country')) {
						sessionStorage.setItem('country', iso);
					}
				})
				.catch(err => {
					console.error(err);
				});
		}

		if (!sbjs?.get) {
			sbjs.init();
		}

		if (Object.keys(query).length > 0) {
			if (
				query?.utm_source ||
				query?.utm_medium ||
				query?.utm_campaign ||
				query?.utm_content ||
				query?.utm_term
			) {
				sessionStorage.setItem(
					'utm_source',
					query?.utm_source ??
						(sbjs?.get?.current?.src && sbjs.get.current.src !== '(direct)'
							? sbjs.get.current.src
							: 'google'),
				);
				sessionStorage.setItem('utm_medium', query?.utm_medium ?? null);
				sessionStorage.setItem('utm_campaign', query?.utm_campaign ?? null);
				sessionStorage.setItem('utm_content', query?.utm_content ?? null);
				sessionStorage.setItem('utm_term', query?.utm_term ?? null);
			}

			if (query?.gclid) {
				sessionStorage.setItem('gclid', query.gclid);
				sessionStorage.setItem(
					'utm_source',
					query?.utm_source ?? (sbjs?.get?.current?.src || 'google'),
				);
			}
		}
	}, 0);
};
