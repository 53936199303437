const SVG = ({
	fill = 'currentColor',
	width = '14',
	height = '8',
	viewBox = '0 0 11 11',
	className,
	onClick,
}) => (
	<svg
		className={className}
		onClick={onClick}
		width={width}
		height={height}
		viewBox='0 0 14 8'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M0.707107 3.05176e-05L7.36396 6.65688L6.65685 7.36399L4.37114e-08 0.707137L0.707107 3.05176e-05Z'
			fill='#585858'
		/>
		<path
			d='M12.6038 0L5.94698 6.65685L6.65409 7.36396L13.3109 0.707107L12.6038 0Z'
			fill='#585858'
		/>
	</svg>
);

export default SVG;
