import { Picture, RoutedLink, Link } from '@/components';
import { Block, Container } from '@/containers';
import styles from './NavigationPictures.module.scss';

const NavigationPictures = ({ items, noMargin }) => {
	if (!items || items.length === 0) return null;
	if (items.some(item => !item.image)) return null;

	return (
		<Block noMargin={noMargin}>
			<Container>
				<div className={styles.main}>
					{items.map((item, index) => {
						const { url, url_text = '', title, description, image } = item || {};

						return (
							<div className={styles.item} key={`${image}-${url}-${title}-${index}`}>
								{url && <RoutedLink title={url_text} to={url} component={Link} />}

								<figure className={styles.image}>
									{image && <Picture {...image} title={url_text} />}
								</figure>

								{(title || description) && (
									<div className={styles.content}>
										{title && <span className={styles.title}>{title}</span>}
										{description && <p className={styles.desc}>{description}</p>}
									</div>
								)}
							</div>
						);
					})}
				</div>
			</Container>
		</Block>
	);
};

export default NavigationPictures;
