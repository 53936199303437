const SVG = ({ fill = 'currentColor', width = '11', height = '11', viewBox = '0 0 11 11' }) => (
	<svg width='14' height='21' viewBox='0 0 14 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<mask id='path-1-inside-1_11353_272987' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.21846 1.11784C11.3104 1.78894 13.0123 3.55348 13.6596 5.72194C14.357 8.05616 13.972 10.4792 12.6041 12.3697L7.79982 18.7561C7.39978 19.2879 6.60164 19.2879 6.20158 18.7561L1.39732 12.3702C0.483301 11.1077 0 9.60323 0 8.01963C0 5.72409 1.06548 3.53927 2.85038 2.17539C4.64853 0.80203 7.00431 0.406904 9.21846 1.11784ZM7 11.2469C8.61083 11.2469 9.91667 9.91446 9.91667 8.27075C9.91667 6.62705 8.61083 5.29456 7 5.29456C5.38917 5.29456 4.08333 6.62705 4.08333 8.27075C4.08333 9.91446 5.38917 11.2469 7 11.2469Z'
			/>
		</mask>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.21846 1.11784C11.3104 1.78894 13.0123 3.55348 13.6596 5.72194C14.357 8.05616 13.972 10.4792 12.6041 12.3697L7.79982 18.7561C7.39978 19.2879 6.60164 19.2879 6.20158 18.7561L1.39732 12.3702C0.483301 11.1077 0 9.60323 0 8.01963C0 5.72409 1.06548 3.53927 2.85038 2.17539C4.64853 0.80203 7.00431 0.406904 9.21846 1.11784ZM7 11.2469C8.61083 11.2469 9.91667 9.91446 9.91667 8.27075C9.91667 6.62705 8.61083 5.29456 7 5.29456C5.38917 5.29456 4.08333 6.62705 4.08333 8.27075C4.08333 9.91446 5.38917 11.2469 7 11.2469Z'
			fill='#0FB769'
		/>
		<path
			d='M13.6596 5.72194L12.7014 6.00799L12.7015 6.00819L13.6596 5.72194ZM9.21846 1.11784L8.91274 2.06996L8.913 2.07005L9.21846 1.11784ZM12.6041 12.3697L13.4032 12.9708L13.4088 12.9634L13.4143 12.9559L12.6041 12.3697ZM7.79982 18.7561L7.00069 18.155H7.00069L7.79982 18.7561ZM6.20158 18.7561L7.00069 18.155L7.00069 18.155L6.20158 18.7561ZM1.39732 12.3702L0.587319 12.9567L0.592698 12.9641L0.598213 12.9714L1.39732 12.3702ZM2.85038 2.17539L2.2434 1.38068L2.24323 1.38081L2.85038 2.17539ZM14.6178 5.43588C13.8795 2.96276 11.9413 0.941108 9.52392 0.165637L8.913 2.07005C10.6796 2.63676 12.145 4.14421 12.7014 6.00799L14.6178 5.43588ZM13.4143 12.9559C14.9736 10.8009 15.3996 8.05275 14.6178 5.43569L12.7015 6.00819C13.3143 8.05958 12.9704 10.1575 11.794 11.7835L13.4143 12.9559ZM8.59895 19.3573L13.4032 12.9708L11.805 11.7685L7.00069 18.155L8.59895 19.3573ZM5.40247 19.3573C6.20258 20.4209 7.79887 20.4208 8.59895 19.3573L7.00069 18.155H7.00069L5.40247 19.3573ZM0.598213 12.9714L5.40247 19.3573L7.00069 18.155L2.19643 11.769L0.598213 12.9714ZM-1 8.01963C-1 9.81027 -0.452281 11.5207 0.587319 12.9567L2.20733 11.7838C1.41888 10.6948 1 9.39619 1 8.01963H-1ZM2.24323 1.38081C0.204394 2.93872 -1 5.42056 -1 8.01963H1C1 6.02763 1.92657 4.13982 3.45754 2.96998L2.24323 1.38081ZM9.52418 0.165719C7.00669 -0.642617 4.30855 -0.196616 2.2434 1.38068L3.45736 2.97011C4.98851 1.80068 7.00193 1.45642 8.91274 2.06996L9.52418 0.165719ZM8.91667 8.27075C8.91667 9.38131 8.0396 10.2469 7 10.2469V12.2469C9.18207 12.2469 10.9167 10.4476 10.9167 8.27075H8.91667ZM7 6.29456C8.0396 6.29456 8.91667 7.16019 8.91667 8.27075H10.9167C10.9167 6.0939 9.18207 4.29456 7 4.29456V6.29456ZM5.08333 8.27075C5.08333 7.16019 5.9604 6.29456 7 6.29456V4.29456C4.81793 4.29456 3.08333 6.0939 3.08333 8.27075H5.08333ZM7 10.2469C5.9604 10.2469 5.08333 9.38131 5.08333 8.27075H3.08333C3.08333 10.4476 4.81793 12.2469 7 12.2469V10.2469Z'
			fill='#0FB769'
			mask='url(#path-1-inside-1_11353_272987)'
		/>
	</svg>
);

export default SVG;
