import { RoutedLink, ContentSlider, GalleryContainer, GallerySlider, Button } from '@/components';
import { Block, Container } from '@/containers';

import styles from './DoubleGallery.module.scss';

const GalleryContent = ({ children, ...props }) => {
	return (
		<div className={styles['double-gallery-content']} {...props}>
			{children}
		</div>
	);
};

const DoubleGallery = ({ items: sliders }) => {
	if (!sliders || sliders.length === 0) {
		return null;
	}

	if (sliders.some(slider => !slider?.images || slider?.images.length === 0)) {
		return null;
	}

	return (
		<Block>
			<Container>
				<GalleryContainer>
					{sliders.map(slider => {
						const { images, title, description, url, url_text } = slider;
						const slides = images.map(image => ({
							image,
							url,
						}));

						const hasContent = title || description || url_text;
						const ContentComponent = url ? RoutedLink : GalleryContent;
						const contentComponentProps = url
							? { to: url, component: GalleryContent, as: 'a' }
							: null;

						return (
							<GallerySlider key={title}>
								<ContentSlider slides={slides} />

								{hasContent && (
									<ContentComponent {...contentComponentProps}>
										{title && <span className={styles['double-gallery-title']}>{title}</span>}
										{description && (
											<p className={styles['double-gallery-description']}>{description}</p>
										)}
										{url_text && url && (
											<div className={styles['double-gallery-button']}>
												<Button type='primary'>{url_text}</Button>
											</div>
										)}
									</ContentComponent>
								)}
							</GallerySlider>
						);
					})}
				</GalleryContainer>
			</Container>
		</Block>
	);
};

export default DoubleGallery;
