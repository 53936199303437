import React, { useCallback, useState } from 'react';
import Link from 'next/link';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { Picture, PictureHtmlImage, Button, CurrencyPrice, Loader } from '@/components';
import { Block, Container } from '@/containers';
import getLinkProps from '@/lib/getLinkProps';
import styles from './LookImage.module.scss';

const LooksModal = dynamic(() => import('../../../containers/looksModal'), {
	loading: () => <Loader />,
});

const LookImage = ({ products, image, selection }) => {
	const [openModal, setOpenModal] = useState(false);

	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
	}, []);

	const handleOpenModal = useCallback(() => {
		setOpenModal(true);
	}, []);

	return products?.length > 0 ? (
		<Block>
			<Container>
				<div className={styles.lookImageLabelsBlock}>
					<div className={styles.lookImagePictureHtml}>
						{image && (
							<PictureHtmlImage isLook>
								<Picture {...image.index} />
							</PictureHtmlImage>
						)}
					</div>
					{products.map(({ params, model, price }) => {
						const [linkHref, linkAs] = getLinkProps({ type: 'product', value: model.slug });

						return (
							params?.x &&
							params?.y && (
								<Link key={model.slug} href={{ pathname: linkHref }} as={{ pathname: linkAs }}>
									<div
										className={clsx(
											styles.lookImageLabel,
											params.x < 50 ? styles.left : styles.right,
										)}
										style={{ left: `${params.x}%`, top: `${params.y}%` }}
									>
										<span className={styles.text}>
											<CurrencyPrice price={price} />
										</span>
										<img alt='arrow' src='/images/svg/price-arrow.svg' />
									</div>
								</Link>
							)
						);
					})}
					<Button
						classNames={{ general: styles.lookImageButton, content: styles.content }}
						type='primary'
						image
						isImage
						onClick={handleOpenModal}
					>
						<span> Заказать весь образ</span>
						<img style={{ marginLeft: '8px' }} alt='arrow' src='/images/svg/price-arrow.svg' />
					</Button>
					{openModal && (
						<LooksModal showDialog={openModal} onDismiss={handleCloseModal} id={selection} />
					)}
				</div>
			</Container>
		</Block>
	) : null;
};

export default LookImage;
