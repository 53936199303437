import { Block, Container } from '@/containers';
import { FullWidthSlider, PageTitle, Title } from '@/components';
import styles from './FourPictures.module.scss';

const FourPictures = ({ items, title: blockTitle, alignment }) => {
	return (
		<Block>
			<Container>
				{blockTitle && (
					<PageTitle>
						<Title mb='none' component='h1' align={alignment}>
							{blockTitle}
						</Title>
					</PageTitle>
				)}
			</Container>
			<div className={styles['fp-products-slider']}>
				<FullWidthSlider bigMargin>
					{items.map(({ image, title, url_text = '', url }) => {
						const imageComponent = <img title={url_text} key={title} alt={title} src={image?.x2} />;

						return url ? (
							<a href={url} key={title}>
								{imageComponent}
							</a>
						) : (
							imageComponent
						);
					})}
				</FullWidthSlider>
			</div>
		</Block>
	);
};

export default FourPictures;
