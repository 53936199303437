import { css } from 'styled-components';
import theme from './theme';

export const maxWidth = breakpoint => {
	return function(...args) {
		return css`
			@media only screen and (max-width: ${breakpoint}px) {
				${css(...args)};
			}
		`;
	};
};

export const minWidth = breakpoint => {
	return function(...args) {
		return css`
			@media only screen and (min-width: ${breakpoint}px) {
				${css(...args)};
			}
		`;
	};
};

export const noTouch = () => {
	return function(...args) {
		return css`
			@media not all and (-moz-touch-enabled: 1),
				not all and (pointer: coarse),
				not all and (-ms-high-contrast: none) and (max-width: 600px) {
				${css(...args)};
			}
		`;
	};
};

export const scrollBar = ({
	barColor = `${theme.bg.default}`,
	thumbColor = `${theme.bg.dividerDark}`,
	radius = '0px',
	size = '6px',
} = {}) => {
	return css`
		scrollbar-color: ${thumbColor} ${barColor};
		scrollbar-width: thin;

		&::-webkit-scrollbar {
			background-color: ${barColor};
			width: ${size};
			height: ${size};
			border-radius: ${radius};
		}

		&::-webkit-scrollbar-thumb {
			border-radius: ${radius};
			background-color: ${thumbColor};
		}
	`;
};

export const rgba = (hex, alpha) => {
	const rgb = hexToRgb(hex);
	return `rgba(${rgb}, ${alpha})`;
};

function hexToRgb(args) {
	const match = args.toString(16).match(/[a-f0-9]{6}|[a-f0-9]{3}/i);
	if (!match) {
		return [0, 0, 0];
	}
	let colorString = match[0];
	if (match[0].length === 3) {
		colorString = colorString
			.split('')
			.map(char => {
				return char + char;
			})
			.join('');
	}

	const integer = parseInt(colorString, 16);
	const r = (integer >> 16) & 0xff;
	const g = (integer >> 8) & 0xff;
	const b = integer & 0xff;

	return [r, g, b];
}
