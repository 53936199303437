import { useRef, useEffect } from 'react';

const useEventListener = (eventName, handler, element, options) => {
	const storedCallback = useRef();

	useEffect(() => {
		storedCallback.current = handler;
	}, [handler]);

	useEffect(() => {
		const isSupported = element && element.addEventListener;
		if (!isSupported) {
			return;
		}

		const eventListener = event => storedCallback.current && storedCallback.current(event);

		element.addEventListener(eventName, eventListener, options);

		return () => {
			element.removeEventListener(eventName, eventListener, options);
		};
	}, [eventName, element, options]);
};

export default useEventListener;
