import React from 'react';
import { Container, LoginForm } from '@/containers';

import styles from './LoginPage.module.scss';

const LoginPage = () => {
	return (
		<section className={styles.section}>
			<Container className={styles.container}>
				<LoginForm />
			</Container>
		</section>
	);
};

export default LoginPage;
