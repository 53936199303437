const SVG = ({ fill = 'currentColor', width = '4', height = '6', viewBox = '1 1 4 6' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M2.327 5.522l.002.063H3.195l.001-.064c.006-.307.027-.522.062-.65a1.03 1.03 0 01.16-.342c.073-.1.231-.259.482-.481.375-.332.632-.618.766-.859.134-.24.2-.504.2-.788 0-.492-.19-.912-.568-1.257C3.92.797 3.414.627 2.79.627c-.59 0-1.072.159-1.44.482h0c-.365.324-.581.787-.654 1.383l-.008.064.065.008.79.094.063.007.01-.061c.067-.428.206-.733.411-.924a1.09 1.09 0 01.776-.29c.323 0 .592.107.813.323h0c.223.214.331.46.331.74a.84.84 0 01-.111.424c-.074.13-.24.312-.508.548a5.76 5.76 0 00-.558.541h0a1.845 1.845 0 00-.314.518 2.216 2.216 0 00-.132.805c0 .053.001.13.004.233zm-.047 1.54v.066h1.006V6.122H2.28v.94z' />
	</svg>
);

export default SVG;
