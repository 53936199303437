import { useEffect, useState, useCallback, useMemo, useRef } from 'react';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import numberWithSpaces from '@/lib/numberWithSpaces';
import { useSelector } from 'react-redux';
import useWindowWidth from '@/lib/hooks/useWindowWidth';
import Tippy from '@tippyjs/react';
import Icon from '@/assets/icons';
import styled from 'styled-components';
import styles from './CurrencyPrice.module.scss';
import vars from '@/assets/styles/vars';

const mobileBreakPoint = vars.breakpoints.tablet + 1;

const text =
	'Стоимость в RUB является справочной. Расчеты будут произведены в BYN по курсу вашего банка на дату транзакции';

const PurpleTippy = styled(Tippy)`
	.tippy-content {
		z-index: 1000000000;
	}
	.tippy-arrow {
		width: 16px;
		height: 16px;
	}

	&[data-placement^='bottom'] > .tippy-arrow {
		top: -12px;
		border-bottom: 11px solid white;
		border-top: none;
	}
`;

const CurrencyPrice = ({ price = '', isDiscount = false }) => {
	const style = useMemo(
		() => ({
			color: '#585858',
			backgroundColor: 'white',
			boxShadow: '0px 6px 20px rgb(0 0 0 / 8%)',
			padding: '12px 15px',
			textAlign: 'center',
			zIndex: 10000,
		}),
		[],
	);
	const windowWidth = useWindowWidth();
	const [visible, setVisible] = useState(false);
	const show = () => setVisible(true);
	const hide = () => setVisible(false);
	const currency = useSelector(getCurrency);

	const handleClick = useCallback(e => {
		e.stopPropagation();
		e.preventDefault();
		show();
	}, []);

	useEffect(() => {
		if (windowWidth < mobileBreakPoint) {
			document.addEventListener('touchstart', hide);
			return () => {
				document.removeEventListener('touchstart', hide);
			};
		}
	}, [windowWidth]);

	const content = useCallback(
		isMobile => {
			return !isDiscount ? (
				<span onClick={isMobile ? handleClick : null} className={styles.icon}>
					<Icon name='question-currency' />
				</span>
			) : null;
		},
		[handleClick, isDiscount],
	);

	if (typeof window !== 'undefined') {
		const currencyState = localStorage.getItem('currency');

		if (currencyState === 'RU' && currency && Object.keys(currency).length) {
			const count = numberWithSpaces(
				Math.ceil(Math.ceil(parseFloat(price) * currency.BYN.RUB) / 10) * 10,
			);

			return (
				<>
					{`${count} ₽`}
					{windowWidth > mobileBreakPoint ? (
						<PurpleTippy content={<div style={style}>{text}</div>}>{content(false)}</PurpleTippy>
					) : (
						<PurpleTippy
							visible={visible}
							onClickOutside={hide}
							content={<div style={style}>{text}</div>}
						>
							{content(true)}
						</PurpleTippy>
					)}
				</>
			);
		}
	}

	return <>{`${numberWithSpaces(price)} BYN`}</>;
};

export default CurrencyPrice;
