const SVG = ({ fill = 'currentColor', width = '32', height = '32', viewBox = '0 0 32 32' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M21.6 24.3c-.6 0-1.2-.2-1.6-.5-.5-.3-.8-.8-1-1.4l-.1-.2h-5.2l-.1.2c-.2.6-.6 1-1 1.4-.5.3-1.1.5-1.6.5s-1.2-.2-1.6-.5c-.5-.3-.8-.8-1-1.4l-.1-.2H6.7c-.2 0-.4-.1-.5-.2-.1-.2-.2-.3-.2-.5V8.7c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2h11.7c.2 0 .4.1.5.2.1.1.2.3.2.5s-.1.4-.2.5c-.1.1-.3.2-.5.2h-11v11.3h.8l.1-.2c.2-.6.6-1 1-1.4.5-.3 1.1-.5 1.6-.5s1.2.2 1.6.5c.5.3.8.8 1 1.4l.1.2h5.1l.1-.2c.2-.6.6-1 1-1.4.5-.3 1.1-.5 1.6-.5s1.2.2 1.6.5c.5.3.8.8 1 1.4l.1.2h.8v-4.2l-2.8-2.8h-3.2v2.5c0 .2-.1.4-.2.5-.1.1-.3.2-.5.2s-.2-.1-.3-.2c-.1-.1-.2-.3-.2-.5V13c0-.2.1-.4.2-.5.1-.1.3-.2.5-.2h4.3c.2 0 .4.1.5.2l3.2 3.2c.1.1.2.3.2.5v5.3c0 .2-.1.4-.2.5-.1.1-.3.2-.5.2h-1.5l-.1.2c-.2.6-.6 1-1 1.4-.5.3-1.1.5-1.7.5zm0-4.2c-.4 0-.7.1-1 .4-.3.3-.4.6-.4 1s.1.7.4 1c.3.3.6.4 1 .4s.7-.1 1-.4c.3-.3.4-.6.4-1s-.1-.7-.4-1c-.3-.3-.6-.4-1-.4zm-10.6 0c-.4 0-.7.1-1 .4-.3.3-.4.6-.4 1s.1.7.4 1c.3.3.6.4 1 .4s.7-.1 1-.4c.3-.3.4-.6.4-1s-.1-.7-.4-1c-.3-.3-.7-.4-1-.4z' />
	</svg>
);

export default SVG;
