import {
	FacebookIcon,
	FacebookShareButton,
	TwitterShareButton,
	TwitterIcon,
	TelegramIcon,
	TelegramShareButton,
	VKShareButton,
	VKIcon,
	ViberIcon,
	ViberShareButton,
} from 'react-share';
import clsx from 'clsx';

import styles from './Share.module.scss';

const Share = ({ url }) => {
	return (
		<div>
			<p className={styles.title}>Поделиться:</p>

			<ul className={styles.list}>
				<li className={clsx(styles.item, styles.vk)}>
					<VKShareButton url={url}>
						<VKIcon />
					</VKShareButton>
				</li>

				<li className={clsx(styles.item, styles.fb)}>
					<FacebookShareButton url={url}>
						<FacebookIcon />
					</FacebookShareButton>
				</li>

				<li className={styles.item}>
					<TwitterShareButton url={url}>
						<TwitterIcon />
					</TwitterShareButton>
				</li>

				<li className={styles.item}>
					<ViberShareButton url={url}>
						<ViberIcon />
					</ViberShareButton>
				</li>

				<li className={styles.item}>
					<TelegramShareButton url={url}>
						<TelegramIcon />
					</TelegramShareButton>
				</li>
			</ul>
		</div>
	);
};

export default Share;
