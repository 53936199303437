import { StyledNotification } from '@/components/styles';

export default function Notification({ content, closable, id, style, closeFn }) {
	const handleClose = e => {
		e?.preventDefault();
		closeFn && closeFn(id);
	};

	return (
		<StyledNotification viewType={style} closable={closable}>
			<StyledNotification.Body>
				<StyledNotification.Content>{content}</StyledNotification.Content>
				{closable && <StyledNotification.Close onClick={handleClose} />}
			</StyledNotification.Body>
		</StyledNotification>
	);
}
