const SVG = ({
	fill = 'currentColor',
	width = '11',
	height = '11',
	viewBox = '0 0 11 11',
	className,
	onClick,
}) => (
	<svg
		className={className}
		onClick={onClick}
		width='14'
		height='8'
		viewBox='0 0 14 8'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M0.707107 7.99997L7.36396 1.34312L6.65685 0.636009L4.37114e-08 7.29286L0.707107 7.99997Z'
			fill='#585858'
		/>
		<path
			d='M12.6038 8L5.94698 1.34315L6.65409 0.636039L13.3109 7.29289L12.6038 8Z'
			fill='#585858'
		/>
	</svg>
);

export default SVG;
