export default function getDate(date, withLeadingZeros = false) {
	const result = {};
	const dateObj = new Date(date);
	result.day = dateObj.getDate();
	result.month = dateObj.getMonth() + 1;
	switch (result.month) {
		case 1:
			result.month_rus = 'января';
			break;
		case 2:
			result.month_rus = 'февраля';
			break;
		case 3:
			result.month_rus = 'марта';
			break;
		case 4:
			result.month_rus = 'апреля';
			break;
		case 5:
			result.month_rus = 'мая';
			break;
		case 6:
			result.month_rus = 'июня';
			break;
		case 7:
			result.month_rus = 'июля';
			break;
		case 8:
			result.month_rus = 'августа';
			break;
		case 9:
			result.month_rus = 'сентября';
			break;
		case 10:
			result.month_rus = 'октября';
			break;
		case 11:
			result.month_rus = 'ноября';
			break;
		case 12:
			result.month_rus = 'декабря';
			break;
		default:
			break;
	}

	result.year = dateObj.getFullYear();
	result.hours = dateObj.getHours();
	result.minutes = dateObj.getMinutes();

	if (withLeadingZeros) {
		result.day = result.day >= 10 ? result.day : `0${result.day}`;
		result.month = result.month >= 10 ? result.month : `0${result.month}`;
		result.hours = result.hours >= 10 ? result.hours : `0${result.hours}`;
		result.minutes = result.minutes >= 10 ? result.minutes : `0${result.minutes}`;
	}
	return result;
}
