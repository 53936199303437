import { useState, useEffect } from 'react';
import {
	createInWishlist,
	removeFromWishlist,
	updateWishlistIds,
} from '@fh-components/fh-js-api-core/store/slices/wishlistSlice';
import { wishlistIdsSelector } from '@/store';
import { createSession } from '@fh-components/fh-js-api-core/store/slices/sessionSlice';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { useDispatch, useSelector } from 'react-redux';
import getProductWithoutQuantity from '@/lib/getProductWithoutQuantity';
import ecommerce from '@/seo/Ecommerce';
import Cookie from 'js-cookie';
import { LikeButton } from '@/containers';

const WishlistBtn = ({
	product,
	large = true,
	border = false,
	getWishlist = null,
	isCatalog = false,
	isHideSizes = false,
	isCart = false,
	index = 1,
	currentSize = null,
	ids: configIds = null,
	pageName = null,
	favoriteId = null,
	selectedVariantId,
}) => {
	const dispatch = useDispatch();
	const currency = useSelector(getCurrency);

	const productId = isHideSizes
		? product.model.id
		: selectedVariantId || product.external_id || product.id;

	const ids = useSelector(wishlistIdsSelector);

	const isActive = (configIds || ids).some(item =>
		currentSize && currentSize.value !== 'none' && !isHideSizes
			? item.product_id == productId
			: item.model_id == product.model.id,
	);

	const [active, setActive] = useState(isActive);
	const mindboxDeviceUUID = Cookie.get('mindboxDeviceUUID');

	useEffect(() => {
		setActive(isActive);
	}, [isActive]);

	const toggle = e => {
		e.preventDefault();
		e.stopPropagation();

		// const favorites = products.slice().filter(item => item.external_id !== product.external_id);

		if (!active) {
			const putIntoWishlist = () => {
				dispatch(
					createInWishlist({
						id: !isHideSizes && currentSize ? productId : null,
						modelId: product.model.id,
						mindboxDeviceUUID: mindboxDeviceUUID || '',
						onSuccess: () => {
							let name = pageName ?? 'Страница товара';

							if (isCatalog) {
								name = 'Каталог товаров';
							} else if (isCart) {
								name = 'Корзина';
							}

							ecommerce.viewItemList(
								getProductWithoutQuantity([product]),
								currency,
								name,
								'add_to_wishlist',
								index,
							);
						},
						onError: () => {
							setActive(false);
							dispatch(createSession(true, undefined, { forceRefresh: true }));
						},
					}),
				);
			};

			setActive(prev => !prev);

			const newIds = ids.filter(item => item.model_id != product.model.id);

			dispatch(
				updateWishlistIds({
					ids: [
						...newIds,
						currentSize
							? {
									product_id: productId,
									model_id: product.model.id,
							  }
							: {
									model_id: product.model.id,
							  },
					],
				}),
			);
			putIntoWishlist();

			// favorites.push(product);
		} else {
			setActive(prev => !prev);
			dispatch(
				updateWishlistIds({
					ids: ids.filter(item =>
						!isHideSizes && currentSize
							? item.product_id != productId
							: item.model_id != product.model.id,
					),
				}),
			);

			dispatch(
				removeFromWishlist({
					id: !isHideSizes && currentSize ? productId : null,
					modelId: product.model.id,
					favoriteId: favoriteId ? favoriteId : null,
					mindboxDeviceUUID: mindboxDeviceUUID || '',
					onSuccess: () => {
						if (getWishlist) {
							getWishlist();
						}
					},
					onError: () => {
						setActive(true);
						dispatch(createSession(true, undefined, { forceRefresh: true }));
					},
				}),
			);
		}
	};

	return (
		<LikeButton
			active={active}
			onClick={toggle}
			large={large}
			border={border}
			isCatalog={isCatalog}
		/>
	);
};

export default WishlistBtn;
