const SVG = ({ fill = 'currentColor', width = '24', height = '23', viewBox = '0 0 40 40' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M12.7881 0C6.10839 0 0.673828 5.43456 0.673828 12.1143V27.9155C0.673828 34.5952 6.10839 40.0297 12.7881 40.0297H28.5893C35.269 40.0297 40.7036 34.5952 40.7036 27.9155V12.1143C40.7036 5.43456 35.269 0 28.5893 0H12.7881ZM31.2228 7.3739C32.3869 7.3739 33.3297 8.31671 33.3297 9.48073C33.3297 10.6437 32.3869 11.5876 31.2228 11.5876C30.0588 11.5876 29.116 10.6437 29.116 9.48073C29.116 8.31671 30.0588 7.3739 31.2228 7.3739ZM20.6887 9.48073C26.4972 9.48073 31.2228 14.2063 31.2228 20.0149C31.2228 25.8234 26.4972 30.549 20.6887 30.549C14.8802 30.549 10.1546 25.8234 10.1546 20.0149C10.1546 14.2063 14.8802 9.48073 20.6887 9.48073ZM20.6887 12.641C19.7203 12.641 18.7615 12.8317 17.8668 13.2023C16.9722 13.5728 16.1593 14.116 15.4746 14.8007C14.7898 15.4855 14.2467 16.2984 13.8761 17.193C13.5055 18.0876 13.3148 19.0465 13.3148 20.0149C13.3148 20.9832 13.5055 21.9421 13.8761 22.8367C14.2467 23.7314 14.7898 24.5443 15.4746 25.229C16.1593 25.9137 16.9722 26.4569 17.8668 26.8275C18.7615 27.198 19.7203 27.3888 20.6887 27.3888C21.6571 27.3888 22.6159 27.198 23.5106 26.8275C24.4052 26.4569 25.2181 25.9137 25.9028 25.229C26.5876 24.5443 27.1307 23.7314 27.5013 22.8367C27.8719 21.9421 28.0626 20.9832 28.0626 20.0149C28.0626 19.0465 27.8719 18.0876 27.5013 17.193C27.1307 16.2984 26.5876 15.4855 25.9028 14.8007C25.2181 14.116 24.4052 13.5728 23.5106 13.2023C22.6159 12.8317 21.6571 12.641 20.6887 12.641V12.641Z'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
