const SVG = ({ className }) => (
	<svg
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		className={className}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M10 8L19 2.40863C18.6382 2.14616 18.1988 2.00293 17.7465 2H2.25352C1.80122 2.00293 1.36184 2.14616 1 2.40863L10 8Z'
			fill='#0FB769'
		/>
		<path
			d='M10.3857 9.80383L10.2643 9.85795H10.2071C10.1414 9.88578 10.0715 9.90402 10 9.91206C9.94071 9.91914 9.88072 9.91914 9.82143 9.91206H9.76429L9.64286 9.85795L0.0714285 4C0.0256986 4.16339 0.00170336 4.33154 0 4.50056V13.9707C0 14.5089 0.225765 15.0251 0.627628 15.4056C1.02949 15.7862 1.57454 16 2.14286 16H17.8571C18.4255 16 18.9705 15.7862 19.3724 15.4056C19.7742 15.0251 20 14.5089 20 13.9707V4.50056C19.9983 4.33154 19.9743 4.16339 19.9286 4L10.3857 9.80383Z'
			fill='#0FB769'
		/>
	</svg>
);

export default SVG;
