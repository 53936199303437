const SVG = ({ fill = 'currentColor', width = '32', height = '32', viewBox = '0 0 32 32' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M7 22H5a1 1 0 01-1-1V8a1 1 0 011-1h13a1 1 0 011 1v2h4.667c1.117 0 1.6.576 1.936 1.107.594.94 1.536 2.432 2.109 3.378.188.312.288.67.288 1.035V20c0 1.089-.743 2-2 2h-1a3.001 3.001 0 01-6 0h-6a3.001 3.001 0 01-6 0zm3-1.2a1.201 1.201 0 010 2.4 1.201 1.201 0 010-2.4zm12 0a1.201 1.201 0 010 2.4 1.201 1.201 0 010-2.4zM19 18H6v2h1.765A2.99 2.99 0 0110 19a2.99 2.99 0 012.235 1h7.53A2.99 2.99 0 0122 19a2.99 2.99 0 012.235 1H26v-4.575l-1.711-2.929a1 1 0 00-.863-.496H19v6zm-2-9H6v7h11V9zm3 4v3h5l-1.427-2.496a.999.999 0 00-.868-.504H20z' />
	</svg>
);

export default SVG;
