import { useEffect } from 'react';
import {
	FullWidthSlider,
	ProductCard,
	RoutedLink,
	PageTitle,
	PageText,
	LinkButton,
	Title,
	Text,
} from '@/components';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { useSelector } from 'react-redux';
import getProductWithoutQuantity from '@/lib/getProductWithoutQuantity';
import { Block, Container } from '@/containers';
import ecommerce from '@/seo/Ecommerce';

import styles from './ProductsSlider.module.scss';

const ProductsSlider = props => {
	const { title, description, url, url_text, full_row, products, hideNavigationCount } = props;
	const withLink = url && url_text;

	if (!products || products.length === null) return null;

	const currency = useSelector(getCurrency);

	useEffect(() => {
		if (Object.keys(currency).length > 0 && products && products.length) {
			ecommerce.viewItemList(getProductWithoutQuantity(products), currency, title);
		}
	}, [currency]);

	const handleClickCard = (product, index) => {
		const newProducts = getProductWithoutQuantity([product]);

		ecommerce.viewItemList(newProducts, currency, title, 'select_item', index);
	};

	return (
		<Block>
			<Container>
				{title && (
					<PageTitle>
						<Title mb='none' component='h1'>
							{title}
						</Title>
					</PageTitle>
				)}
				{description && (
					<PageText>
						<Text size='size-large'>{description}</Text>
					</PageText>
				)}
			</Container>

			{products && products.length > 0 && (
				<div className={styles['products-slider']}>
					<FullWidthSlider hideNavigationCount={hideNavigationCount} noFull={full_row}>
						{products.map((product, index) => {
							return (
								<div
									key={product?.external_id}
									onClick={() => {
										handleClickCard(product, index + 1);
									}}
								>
									<ProductCard
										{...product}
										mod='productSlider'
										pageName={title ?? 'Страница товара'}
										indexCard={index + 1}
									/>
								</div>
							);
						})}
					</FullWidthSlider>
				</div>
			)}

			{withLink && (
				<Container center>
					<div className={styles['products-slider-link']}>
						<RoutedLink extraPaddings to={url} component={LinkButton} as='a'>
							{url_text}
						</RoutedLink>
					</div>
				</Container>
			)}
		</Block>
	);
};

export default ProductsSlider;
