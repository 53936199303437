const SVG = ({ fill = '#292C33', width = '20', height = '19', viewBox = '0 0 20 19' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M17.6 4.423l-.01-.09h-3.385C13.679 2.143 11.995.9 10 .9 8.004.9 6.32 2.142 5.795 4.334H2.41l-.01.089L.9 17.989l-.012.111H19.112l-.013-.111-1.5-13.566zm-5.535-.09h-4.13c.199-.541.481-.922.815-1.172.36-.27.787-.391 1.25-.391.463 0 .89.122 1.25.391.334.25.616.63.815 1.173zM15.887 6.1l1.132 10.236H2.98L4.113 6.099h11.774z'
			fill={fill}
			stroke={fill}
			strokeWidth='0.2'
		/>
	</svg>
);

export default SVG;
