import React, { useEffect, useState, useCallback } from 'react';
import dynamic from 'next/dynamic';
import {
	getCartData,
	fetchCart,
	setNewCartData,
} from '@fh-components/fh-js-api-core/store/slices/cartSlice';
import { createSession } from '@fh-components/fh-js-api-core/store/slices/sessionSlice';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { useSelector, useDispatch } from 'react-redux';
import { animateScroll } from 'react-scroll';
import Link from 'next/link';
import getLinkProps from '@/lib/getLinkProps';
import { Title, PageText, Button, Loader } from '@/components';
import { BreadCrumbs, Container } from '@/containers';
import mindbox from '@/seo/Mindbox';
import ecommerce from '@/seo/Ecommerce';
import { sessionIdSelector, cartCountSelector, sessionIsSubscribedChannel } from '@/store';
import { usePrivateChannels } from '@/contexts/ChannelsContext';
import * as Sentry from '@sentry/nextjs';
import clsx from 'clsx';

import styles from './CartPage.module.scss';

const Cart = dynamic(() => import('../../containers/cart'), {
	loading: () => <Loader />,
});

const events = {
	CART_REFRESH: 'SessionCartRefreshEvent',
	CART_UNAVAILABLE: 'SessionCartChangeUnavailableEvent',
};

const getProducts = (firstProducts, secondProducts) => {
	return firstProducts
		.map(product => {
			for (let i = 0; i < secondProducts.length; i++) {
				if (product.external_id === secondProducts[i].external_id) {
					return {
						...product,
						...secondProducts[i],
					};
				}
			}

			return null;
		})
		.filter(product => product != null);
};

const CartPage = () => {
	const cart = useSelector(getCartData);
	const currency = useSelector(getCurrency);
	const cartItemsQuantity = useSelector(cartCountSelector);
	const isSubscribedChannel = useSelector(sessionIsSubscribedChannel);
	const dispatch = useDispatch();
	const [totalAmount, setTotalAmount] = useState(true);
	const [loading, setLoading] = useState(false);
	const [seconds, setSeconds] = useState(10);

	const [cartHref, _cartAs, cartTitle] = getLinkProps({ type: 'cart' });
	const breadCrumbsCart = [{ slug: cartHref, name: cartTitle }];
	const isEmpty = cart.data.products.length === 0;
	const isLoading = cart.firstLoading;

	const sessionID = useSelector(sessionIdSelector);
	const channels = usePrivateChannels(sessionID, dispatch);

	const refreshCart = useCallback(e => {
		setLoading(true);
		dispatch(fetchCart());
	}, []);

	const onChange = useCallback(
		e => {
			if (!cart.loading) {
				if (e.cart.products?.length > cart.data.products?.length) {
					refreshCart();
				}

				const newCartData = {
					...cart.data,
					...e.cart,
					products: getProducts(cart.data.products, e.cart.products),
					count: cartItemsQuantity,
				};
				dispatch(setNewCartData(newCartData));

				setLoading(false);
			}
		},
		[dispatch, cart, cartItemsQuantity, refreshCart],
	);

	useEffect(() => {
		if (typeof window !== 'undefined') {
			setTimeout(() => {
				const cartId = localStorage.getItem('cartId');

				if (!cartId) {
					localStorage.setItem(
						'cartId',
						Math.random()
							.toString()
							.slice(2),
					);
				}
			}, 0);
		}
	}, []);

	useEffect(() => {
		if (channels) {
			channels.listen(`.${events.CART_REFRESH}`, onChange);
			channels.listen(`.${events.CART_UNAVAILABLE}`, refreshCart);

			return () => {
				channels.stopListening(`.${events.CART_REFRESH}`);
				channels.stopListening(`.${events.CART_UNAVAILABLE}`);
			};
		}
	}, [channels, onChange, refreshCart]);

	useEffect(() => {
		if (isEmpty) {
			animateScroll.scrollToTop();
		}
	}, [isEmpty]);

	const sendError = () => {
		Sentry.captureException('The socket event for the cart update did not arrive!');
	};

	useEffect(() => {
		if (loading) {
			if (seconds > 0) {
				setTimeout(setSeconds, 1000, seconds - 1);
			} else {
				sendError();
			}
		} else {
			setSeconds(10);
		}
	}, [seconds, loading]);

	useEffect(() => {
		if (sessionID && isSubscribedChannel) {
			mindbox.viewPage({ page: 'Cart' });
			setLoading(true);

			dispatch(
				fetchCart({
					onSuccess: () => {
						dispatch(createSession(true, undefined, { forceRefresh: true }));
					},
				}),
			);
		}
	}, [sessionID, isSubscribedChannel]);

	useEffect(() => {
		setTimeout(() => {
			if (
				cart &&
				cart.data &&
				cart.data.total_amount_with_discount &&
				totalAmount &&
				totalAmount !== cart.data.total_amount_with_discount
			) {
				ecommerce.viewItemList(
					cart.data.products.map(item => ({ ...item, external_id: item.model.slug })),
					currency,
					'Корзина',
					'view_cart',
					null,
					cart.data.total_amount_with_discount,
				);
				setTotalAmount(cart.data.total_amount_with_discount);
			}
		}, 0);
	}, [cart.data, totalAmount]);

	return (
		<section className={styles.cartSection}>
			<Container>
				<div className={styles.breadCrumbs}>
					<BreadCrumbs type='cart' breadcrumbs={breadCrumbsCart} />
				</div>
			</Container>
			{!isLoading ? (
				<Container className={clsx(isEmpty && styles.cartContainer)}>
					<Title
						mb='large'
						component='h1'
						{...(isEmpty ? { align: 'center' } : { align: 'left' })}
						className={styles.title}
					>
						{isEmpty ? 'В корзине нет товаров' : 'Корзина'}
					</Title>

					{isEmpty ? (
						<>
							<PageText className={styles.emptyText}>
								Получайте уникальные промокоды при регистрации и заказе через наш интернет-магазин.
							</PageText>
							<Link href='/' as='/' passHref>
								<Button type='primary'>Перейти на главную</Button>
							</Link>
						</>
					) : (
						<Cart {...cart} loading={loading} setLoading={setLoading} />
					)}
				</Container>
			) : (
				<Loader visible />
			)}
		</section>
	);
};

export default CartPage;
