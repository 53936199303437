import styled, { css } from 'styled-components';
import { maxWidth, noTouch } from '@/assets/styles/mixins.css.js';
import theme from '@/assets/styles/theme';
import vars from '@/assets/styles/vars';

const StyledTabs = styled.div``;

StyledTabs.Buttons = styled.div`
	display: flex;
	padding-bottom: 2px;
	margin-bottom: var(--tabs-buttons-mb, 30px);
	overflow-x: auto;

	${maxWidth(vars.breakpoints.mobile)`
    flex-wrap: wrap;
  `}
`;

StyledTabs.Button = styled.button`
  display: block;
  padding: 9px 16px 11px;
  color: ${theme.text.accent};
  font-family: ${vars.fonts.futura};
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  text-align: center;
  border: 1px solid ${theme.input.border};
  border-right: none;
  background: ${theme.bg.default};
  transition: color 0.25s ease, background 0.25s ease, border-color 0.25s ease;

  &:first-child {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }

  &:last-child {
    border-right: ${props => !props.active && `1px solid ${theme.input.border}`};
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  ${props =>
		props.active &&
		css`
			color: ${theme.text.reverse};
			background: ${theme.input.selected};
			border-color: ${theme.input.selected};
			cursor: default;

			& + & {
				border-left: none;
				padding-left: 17px;

				${maxWidth(vars.breakpoints.mobile)`
          border-left: 1px solid ${theme.input.border};
          padding-left: 16px;
          border-top: none;
          padding-top: 9px;
        `}
			}
		`}

  ${noTouch()`
    &:hover {
      border-color: ${props => !props.active && theme.input.hover};
    }
  `}


  ${maxWidth(vars.breakpoints.mobile)`
    width: 100%;
    border-bottom: none;
    border-right: ${props =>
			props.active ? `1px solid ${theme.input.selected}` : `1px solid ${theme.input.border}`};

    &:first-child {
      border-top-left-radius: 4px;
      border-bottom-left-radius: 0px;
      border-top-right-radius: 4px;
    }

    &:last-child {
      border-bottom: ${props => !props.active && `1px solid ${theme.input.border}`};
      border-top-right-radius: 0px;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
    }
  `}
`;

StyledTabs.Content = styled.div`
	&.on-enter {
		opacity: 0;
		transform: translateY(10px);
	}
	&.on-enter-active {
		opacity: 1;
		transform: none;
		transition: opacity 0.2s ease-in, transform 0.2s ease-in;
	}
	&.on-exit {
		opacity: 1;
	}
	&.on-exit-active {
		opacity: 0;
		transition: opacity 0.2s ease-out, transform 0.2s ease-out;
	}
`;

export default StyledTabs;
