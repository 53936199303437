import React, { useRef, useEffect, useCallback, memo, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { CSSTransition } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { hasSmartBannerSelector } from '@/store';
import { Loader, Button, Portal } from '@/components';
import { useRouter } from 'next/router';
import Icon from '@/assets/icons';
import clsx from 'clsx';

import styles from './MobileSort.module.scss';

const FilterRadio = dynamic(() => import('../filters/FilterRadio'), {
	loading: () => <Loader />,
});

const MemoizedMobileSort = memo(function MobileSort({
	sortItems,
	sortName,
	selectedSorting,
	onSortChange,
	loading,
	visibility,
	toggleMobileSort,
}) {
	const hasSmartBanner = useSelector(hasSmartBannerSelector);
	const isVisible = visibility;
	const wrapper = useRef(null);

	useEffect(() => {
		window.addEventListener('click', onClickOutside);
		window.addEventListener('orientationchange', onOrientationChange);
		return () => {
			window.removeEventListener('click', onClickOutside);
			window.removeEventListener('orientationchange', onOrientationChange);
		};
	}, [isVisible]);

	const onClickOutside = e => {
		if (isVisible && (!wrapper?.current?.contains(e.target) || wrapper.current === e.target)) {
			toggleMobileSort(e);
		}
	};

	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = url => {
			if (isVisible) {
				if (!url.includes('sort') && url.slice(0, 4) !== router.asPath.slice(0, 4)) {
					toggleMobileSort();
				}
			}
		};

		router.events.on('routeChangeStart', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [isVisible]);

	const onOrientationChange = useCallback(
		e => {
			e.stopPropagation();
			if (isVisible) toggleMobileSort();
		},
		[isVisible],
	);

	const onToggleClick = useCallback(e => {
		e.stopPropagation();
		toggleMobileSort();
	}, []);

	const sortingName = useMemo(() => {
		if (!selectedSorting) {
			return sortItems[0]?.name;
		}

		for (let i = 0; i < sortItems.length; i++) {
			const item = sortItems[i];

			if (item.value === selectedSorting) {
				return item.name;
			}
		}
	}, [selectedSorting, sortItems]);

	return (
		<div>
			<div onClick={onToggleClick} className={styles.sortContainer}>
				<Icon name='sort' className={styles.tabletIcon} />
				<div className={styles.titleSorted}>Сортировка:</div>
				<div className={styles.titleSortedName}>{sortingName}</div>
				<Icon name='arrow-down' className={styles.arrowDownIcon} width='11' />
				<Icon name='sort' className={styles.mobileIcon} />
			</div>

			<CSSTransition
				in={isVisible}
				timeout={{ appear: 0, enter: 180, exit: 180 }}
				classNames={{
					enter: styles.onEnter,
					enterActive: styles.onEnterActive,
					enterDone: styles.onEnterDone,
					exit: styles.onExit,
					exitActive: styles.onExitActive,
				}}
				unmountOnExit
			>
				<Portal>
					<div className={clsx(styles.wrapper, hasSmartBanner && styles.smartBanner)} ref={wrapper}>
						<div className={styles.container}>
							<div className={styles.header}>
								<div className={styles.title}>
									<div>Сортировка</div>
								</div>
							</div>

							<div className={styles.main}>
								<FilterRadio
									name={sortName}
									items={sortItems}
									activeItem={selectedSorting}
									onChange={onSortChange}
								/>
							</div>

							<div className={styles.footer}>
								<Button
									withLoader
									onClick={onToggleClick}
									{...loading && { className: 'loading', disabled: true }}
									classNames={{ general: styles.showAllButton }}
									type='primary'
								>
									<span>Показать</span>
								</Button>
							</div>
						</div>
					</div>
				</Portal>
			</CSSTransition>
		</div>
	);
});

export default MemoizedMobileSort;
