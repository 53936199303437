const SVG = ({ className }) => (
	<svg
		className={className}
		width='15'
		height='22'
		viewBox='0 0 15 22'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask id='path-1-inside-1_11436_267735' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.87692 1.12372C12.1183 1.82837 13.9417 3.68114 14.6353 5.95802C15.3825 8.40896 14.97 10.9531 13.5044 12.9381L8.29398 19.7259C7.89371 20.2474 7.10781 20.2474 6.70752 19.726L1.49713 12.9387C0.517822 11.6131 0 10.0334 0 8.3706C0 5.96029 1.14159 3.66622 3.05398 2.23415C4.98057 0.79212 7.50461 0.377237 9.87692 1.12372ZM7.5 11.7593C9.22589 11.7593 10.625 10.3602 10.625 8.63428C10.625 6.90839 9.22589 5.50928 7.5 5.50928C5.77411 5.50928 4.375 6.90839 4.375 8.63428C4.375 10.3602 5.77411 11.7593 7.5 11.7593Z'
			/>
		</mask>
		<path
			d='M14.6353 5.95802L13.6787 6.24943L13.6788 6.24962L14.6353 5.95802ZM9.87692 1.12372L9.57677 2.07761L9.57701 2.07769L9.87692 1.12372ZM13.5044 12.9381L14.2976 13.547L14.3033 13.5396L14.3089 13.5321L13.5044 12.9381ZM8.29398 19.7259L9.08722 20.3348H9.08722L8.29398 19.7259ZM6.70752 19.726L5.9143 20.3349L5.9143 20.3349L6.70752 19.726ZM1.49713 12.9387L0.69281 13.5329L0.698292 13.5403L0.703911 13.5477L1.49713 12.9387ZM3.05398 2.23415L2.45476 1.43357L2.45458 1.4337L3.05398 2.23415ZM15.5919 5.66662C14.8037 3.0791 12.738 0.974943 10.1768 0.169753L9.57701 2.07769C11.4986 2.6818 13.0797 4.28319 13.6787 6.24943L15.5919 5.66662ZM14.3089 13.5321C15.9707 11.2814 16.4271 8.40644 15.5918 5.66642L13.6788 6.24962C14.3378 8.41148 13.9693 10.6249 12.6999 12.3442L14.3089 13.5321ZM9.08722 20.3348L14.2976 13.547L12.7112 12.3292L7.50074 19.117L9.08722 20.3348ZM5.9143 20.3349C6.71488 21.3778 8.28668 21.3777 9.08722 20.3348L7.50074 19.117L5.9143 20.3349ZM0.703911 13.5477L5.9143 20.3349L7.50074 19.117L2.29035 12.3298L0.703911 13.5477ZM-1 8.3706C-1 10.2453 -0.414773 12.0337 0.69281 13.5329L2.30145 12.3445C1.45042 11.1925 1 9.82147 1 8.3706H-1ZM2.45458 1.4337C0.287354 3.0566 -1 5.64862 -1 8.3706H1C1 6.27195 1.99582 4.27585 3.65339 3.0346L2.45458 1.4337ZM10.1771 0.169832C7.50796 -0.67005 4.64743 -0.207624 2.45476 1.43357L3.65321 3.03473C5.31371 1.79186 7.50127 1.42452 9.57677 2.07761L10.1771 0.169832ZM9.625 8.63428C9.625 9.80788 8.6736 10.7593 7.5 10.7593V12.7593C9.77818 12.7593 11.625 10.9125 11.625 8.63428H9.625ZM7.5 6.50928C8.6736 6.50928 9.625 7.46067 9.625 8.63428H11.625C11.625 6.3561 9.77818 4.50928 7.5 4.50928V6.50928ZM5.375 8.63428C5.375 7.46067 6.3264 6.50928 7.5 6.50928V4.50928C5.22183 4.50928 3.375 6.3561 3.375 8.63428H5.375ZM7.5 10.7593C6.3264 10.7593 5.375 9.80788 5.375 8.63428H3.375C3.375 10.9125 5.22183 12.7593 7.5 12.7593V10.7593Z'
			fill='#0FB769'
			mask='url(#path-1-inside-1_11436_267735)'
		/>
	</svg>
);

export default SVG;
