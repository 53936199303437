import { Block, Container } from '@/containers';
import styles from './Divider.module.scss';

const Divider = () => {
	return (
		<Block>
			<Container>
				<div className={styles.divider} />
			</Container>
		</Block>
	);
};

export default Divider;
