import { memo } from 'react';
import clsx from 'clsx';
import { socialList, messengersList } from '@/lib/config.js';
import getLinkProps from '@/lib/getLinkProps';
import { ActiveLink, SocialList, Text, Control } from '@/components';
import Icon from '@/assets/icons';
import ecommerce from '@/seo/Ecommerce';
import styles from './ContactsOverview.module.scss';

const links = ['shops-list', 'news', 'special-offers'];

const ContactsOverview = memo(({ onMenuLinkClick, isMobile }) => {
	// const onClickPhone = () => {
	//   ecommerce.onClickPhoneNumber();
	// };

	const onLinkClick = () => {
		if (onMenuLinkClick) {
			onMenuLinkClick();
		}
	};

	return (
		<div className={clsx(styles.wrapper, { [styles.mobile]: isMobile })}>
			<div className={styles.header}>
				<a className={styles.phone} href='tel:+375296332633'>
					+375 (29) 633-2-633
				</a>
				<Text className={styles['work-time']}>Время работы: пн-вс, с&nbsp;09:00 до 21:00</Text>

				<SocialList list={messengersList} top type='messengers' />
			</div>

			<div className={styles.content}>
				{links.map(type => {
					const [href, as, title] = getLinkProps({ type });
					return (
						<ActiveLink href={href} as={as} key={type}>
							<Control className={styles.link} onClick={onLinkClick}>
								{title}
							</Control>
						</ActiveLink>
					);
				})}
			</div>

			<div className={styles.footer}>
				<SocialList list={socialList} top type='socials' />

				<ul className={styles['app-links']}>
					<li className={styles['app-links-item']}>
						<a
							href='https://apps.apple.com/us/app/id1518240278'
							target='_blank'
							rel='noreferrer noopener'
							// onClick={() => ecommerce.onClickSocialLink(ecommerce.SOCIAL_BANNER_TYPE.APP_STORE)}
							onClick={() => {}}
						>
							<Icon name='ios' />
							<span>
								Скачать
								<br /> в App Store
							</span>
						</a>
					</li>

					<li className={styles['app-links-item']}>
						<a
							href='https://play.google.com/store/apps/details?id=com.websecret.fh'
							target='_blank'
							rel='noreferrer noopener'
							// onClick={() => ecommerce.onClickSocialLink(ecommerce.SOCIAL_BANNER_TYPE.GOOGLE_PLAY)}
							onClick={() => {}}
						>
							<Icon name='google-play' />
							<span>
								Скачать
								<br /> в Google Play
							</span>
						</a>
					</li>
				</ul>
			</div>
		</div>
	);
});

export default ContactsOverview;
