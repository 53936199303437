import { useMemo } from 'react';
import { Block, Container } from '@/containers';
import Image from 'next/image';
import { FullWidthSlider, PageTitle } from '@/components';
import clsx from 'clsx';

import styles from './Carousel.module.scss';

const Carousel = ({ pictures, title, isBigSlider = false, slides, url: titleUrl = '' }) => {
	const hasSubtitle = useMemo(() => pictures?.some(picture => picture.subtitle), [pictures]);
	const getCarouselImage = (carouselImage, url) => {
		const isActiveLink = url && url.length > 0;

		return (
			<a
				className={clsx(
					!isBigSlider && styles.link,
					hasSubtitle && styles.linkWithSubtitle,
					!isActiveLink && styles.notActiveLink,
					isBigSlider && styles.bigSliderLink,
				)}
				href={url}
				key={url}
			>
				{carouselImage}
			</a>
		);
	};

	const titleComponent = useMemo(() => <h2 className={styles.title}>{title}</h2>, [title]);

	const images = useMemo(
		() => (pictures || slides).filter(({ image }) => !!(image?.webp_x2 || image?.x2)),
		[pictures, slides],
	);

	return (
		<Block>
			<Container>
				{title && (
					<PageTitle>
						{isBigSlider && titleUrl?.length > 0 ? (
							<a href={titleUrl}>{titleComponent}</a>
						) : (
							<>{titleComponent}</>
						)}
					</PageTitle>
				)}
			</Container>
			<div
				className={clsx(
					styles.carouselProductsSlider,
					isBigSlider && styles.carouselProductsBigSlider,
				)}
			>
				<FullWidthSlider bigMargin widthLimit='100%' isBigSlider={isBigSlider} isCarousel>
					{images.map(({ image, url = '', padding, url_text = '', subtitle }) => {
						const carouselImage = (
							<>
								<Image
									// style={{ padding }}
									padding={padding}
									layout='fill'
									key={`${url}-image`}
									alt={url_text}
									title={url_text}
									src={image?.webp_x2 || image?.x2}
								/>
								{subtitle && <div className={styles.subtitle}>{subtitle}</div>}
							</>
						);

						return getCarouselImage(carouselImage, url);
					})}
				</FullWidthSlider>
			</div>
		</Block>
	);
};

export default Carousel;
