const SVG = ({ fill = 'currentColor', width = '22', height = '26', viewBox = '0 0 22 26' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M22 19.074c-.863 2.472-3.453 6.842-6.118 6.892-1.768.033-2.338-1.032-4.358-1.032-2.02 0-2.653.999-4.325 1.065C4.366 26.106 0 19.685 0 14.085c0-5.142 3.637-7.689 6.818-7.738 1.705-.029 3.315 1.13 4.354 1.13 1.043 0 3-1.398 5.053-1.192.86.037 3.277.343 4.828 2.575-4.11 2.65-3.47 8.18.947 10.214zM16.255 0c-3.11.124-5.645 3.334-5.293 5.992 2.875.219 5.632-2.95 5.293-5.992z' />
	</svg>
);

export default SVG;
