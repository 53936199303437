import styled, { css } from 'styled-components';
import { maxWidth } from '@/assets/styles/mixins.css.js';
import vars from '@/assets/styles/vars';

const StyledSection = styled.section`
  & + & {
    margin-top: 0;
  }

  ${props =>
		props.align === 'center' &&
		css`
			display: flex;
			justify-content: center;
			align-items: center;
			align-content: center;
			flex-wrap: wrap;
			flex-grow: 1;
			text-align: center;
		`}

  ${props =>
		props.maxWidth &&
		css`
			max-width: ${props.maxWidth};
		`}

  ${({ pt }) => {
		switch (pt) {
			case 'xl-large':
				return css`
					margin-top: 96px;
					${maxWidth(vars.breakpoints.tablet)`
            margin-top: 64px;
          `}
				`;
			case 'x-large':
				return css`
					margin-top: 56px;
					${maxWidth(vars.breakpoints.tablet)`
            margin-top: 48px;
          `}
					${maxWidth(vars.breakpoints.lMobile)`
            margin-top: 40px;
          `}
				`;
			case 'large':
				return css`
					margin-top: 64px;
					${maxWidth(vars.breakpoints.tablet)`
            margin-top: 48px;
          `}
				`;
			case 'small':
				return css`
					margin-top: 32px;
					${maxWidth(vars.breakpoints.lMobile)`
            margin-top: 24px;
          `}
				`;
			case 'none':
				return;
			case 'medium':
			default:
				return css`
					margin-top: 40px;
					${maxWidth(vars.breakpoints.lMobile)`
            margin-top: 32px;
          `}
				`;
		}
	}}

  ${({ pb }) => {
		switch (pb) {
			case 'xl-large':
				return css`
					margin-bottom: 64px;
					${maxWidth(vars.breakpoints.tablet + 1)`
            margin-bottom: 48px;
          `}
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 40px;
          `}
				`;
			case 'x-large':
				return css`
					margin-bottom: 56px;
					${maxWidth(vars.breakpoints.tablet)`
            margin-bottom: 48px;
          `}
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 40px;
          `}
				`;
			case 'large':
				return css`
					margin-bottom: 48px;
					${maxWidth(vars.breakpoints.tablet)`
            margin-bottom: 40px;
          `}
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 32px;
          `}
				`;
			case 'small':
				return css`
					margin-bottom: 32px;
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 24px;
          `}
				`;
			case 'none':
				return;
			case 'medium':
			default:
				return css`
					margin-bottom: 40px;
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 32px;
          `}
				`;
		}
	}}

  ${({ mb }) => {
		switch (mb) {
			case 'x-large':
				return css`
					margin-bottom: 72px;
					${maxWidth(vars.breakpoints.tablet)`
            margin-bottom: 48px;
          `}
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 32px;
          `}
				`;
			default:
				break;
		}
	}}
`;

export default StyledSection;
