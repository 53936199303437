const theme = {
	text: {
		main: '#585858', //$black-2
		accent: '#171717',
		reverse: '#FFFFFF',
		secondary: '#959595', //$black-3
		extra: '#BBBBBB', //$black-4
		light: '#F0F0F0',
		disabled: '#F0F0F0',
		saleRed: '#FE0000',
		header: '#171717',
		link: '#59896D',
	},
	bg: {
		default: '#FFFFFF',
		accent: '#171717',
		secondary: '#3B404A',
		extra: '#BBBBBB', //$black-4
		light: '#F0F0F0', //$gray-1
		divider: '#F0F0F0', //$gray-1
		insistent: '#F8F8F8',
		dividerDark: '#F0F0F0',
		active: '#74849C',
		sale: '#FDE5E5',
		header: '#F8F8F8',
		newYearHeader: '#585858',
		disabled: '#CCCDD0',
	},
	input: {
		border: '#CCCDD0',
		selected: '#74849C',
		checked: '#59896D',
		hover: '#BBBBBB', // $black-4
		error: '#EA5656',
	},
	brand: {
		default: '#0FB769', //$green-1
		light: '#43A084',
		extraLight: '#1FBA8B',
		dark: '#59896D',
	},
	warn: {
		default: '#EA5656',
	},
	notification: {
		error: '#ef5a5a',
		warning: '#0FB769',
	},
	social: {
		vk: '#45668e',
		fb: '#3b5998',
	},
};

export default theme;
