import { memo } from 'react';
import clsx from 'clsx';
import styles from './countrySelectItem.module.scss';

const CountrySelectItem = memo(({ countryISO, name, code, selectedCountry, onCodeClick }) => (
	<li
		className={clsx(styles['country-select-item'], {
			[styles.active]: selectedCountry === countryISO,
		})}
	>
		<button onClick={onCodeClick(countryISO)} type='button'>
			<p>
				<span
					className={styles.icon}
					style={{ backgroundImage: `url('/images/flags/${countryISO}.png')` }}
				/>
				<span>{name}</span>
			</p>

			<span>{code}</span>
		</button>
	</li>
));

CountrySelectItem.displayName = 'CountrySelectItem';

export default CountrySelectItem;
