const SVG = () => (
	<svg width='30' height='30' viewBox='0 0 30 30' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M5.73112 16.5619C5.90563 16.16 6.30198 15.9 6.7401 15.9H22.1714C22.6114 15.9 23.0092 16.1623 23.1825 16.5668L23.9994 16.2167L23.1825 16.5668L26.8245 25.0668C27.1355 25.7926 26.603 26.6 25.8134 26.6H3.04894C2.25697 26.6 1.72451 25.7883 2.03997 25.0619L5.73112 16.5619Z'
			stroke='#0FB769'
			strokeWidth='1.8'
		/>
		<rect x='11.25' y='13.75' width='7.5' height='5' rx='1' fill='white' />
		<path
			d='M7.72402 10.8952L7.82101 10.8708L7.72402 10.8952C8.10552 12.415 9.20116 14.218 10.9991 16.3075C11.535 16.9304 13.4141 18.8417 14.0257 19.3861C14.2617 19.5961 14.4153 19.7255 14.5381 19.8017C14.6684 19.8826 14.7674 19.9059 14.89 19.9076C15.0221 19.9095 15.1348 19.8803 15.2776 19.7917C15.4144 19.7068 15.5826 19.565 15.8288 19.3418C16.4753 18.7555 18.2602 16.933 18.8 16.3076C20.7363 14.0646 21.9066 12.0524 22.1539 10.5037C22.4181 8.84957 21.8005 6.69808 20.6462 5.22452C20.3769 4.88066 19.7531 4.25176 19.4125 3.98059L19.3503 4.05883L19.4125 3.98059C17.8762 2.75754 15.8795 2.20123 13.964 2.46377C11.47 2.80553 9.3614 4.36258 8.28194 6.65852C7.64722 8.00851 7.4221 9.69222 7.72402 10.8952ZM10.8067 5.66676L10.7355 5.59655L10.8067 5.66676C11.7321 4.72835 12.8683 4.15414 14.1561 3.97355L14.1561 3.97355C14.3383 3.94799 14.7018 3.94264 15.0717 3.954C15.4417 3.96537 15.803 3.99299 15.9811 4.02957C18.0552 4.45553 19.7417 5.95208 20.4024 7.95198C20.6521 8.7078 20.762 9.68953 20.6668 10.2635C20.4524 11.5573 19.4317 13.2586 17.5376 15.4368C17.2895 15.7221 16.7158 16.3247 16.1704 16.8824C15.898 17.1608 15.6335 17.4273 15.4212 17.6364C15.2065 17.848 15.0507 17.9953 14.9923 18.0404C14.9923 18.0404 14.9923 18.0404 14.9923 18.0404L14.9023 18.11L14.7695 18.0001C14.6673 17.9155 14.167 17.4263 13.6552 16.9094C11.7431 14.9782 10.4698 13.3521 9.72448 11.8971L9.63548 11.9427L9.72448 11.8971C9.23521 10.942 9.06394 10.2656 9.10736 9.50234C9.19154 8.02323 9.7697 6.71842 10.8067 5.66676Z'
			fill='#0FB769'
			stroke='#0FB769'
			strokeWidth='0.2'
		/>
		<path
			d='M11.974 8.22091L11.974 8.22091C11.704 8.78884 11.6116 9.28011 11.6459 9.98161L11.6459 9.98162C11.6585 10.24 11.6747 10.4008 11.7146 10.5552C11.7546 10.7098 11.8195 10.8625 11.9361 11.103L11.9361 11.103C13.2221 13.7577 17.023 13.5445 18.0192 10.7593C18.1114 10.5015 18.163 10.1521 18.1692 9.79185C18.1754 9.43196 18.1362 9.0699 18.0526 8.78767M11.974 8.22091L14.274 6.50416C14.6945 6.41845 15.1189 6.42632 15.621 6.53222L15.621 6.53222C16.7991 6.78062 17.7075 7.62279 18.0526 8.78767M11.974 8.22091C12.3933 7.33886 13.2181 6.71952 14.274 6.50416L11.974 8.22091ZM18.0526 8.78767L18.1485 8.75927L18.0526 8.78768C18.0526 8.78768 18.0526 8.78767 18.0526 8.78767ZM15.6417 6.43438L15.6417 6.43438L15.6417 6.43438Z'
			fill='#0FB769'
			stroke='white'
			strokeWidth='0.2'
		/>
		<ellipse cx='14.9052' cy='9.71184' rx='1.44231' ry='1.44231' fill='white' />
		<path d='M11.0562 15.9609L8.65234 26.5379' stroke='#0FB769' strokeWidth='1.8' />
		<path d='M17.7876 15.9609L20.1914 26.5379' stroke='#0FB769' strokeWidth='1.8' />
	</svg>
);

export default SVG;
