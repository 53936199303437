import { Component } from 'react';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/nextjs';
import Router from 'next/router';
import { Title, Text, Button, MobileNav, MobileUserNav } from '@/components';
import styles from './ErrorBoundary.module.scss';
import Footer from '../footer';
import Header from '../header';
import NavPopular from '../navPopular';
import Head from 'next/head';

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {
			hasError: false,
		};
	}

	static getDerivedStateFromError() {
		return { hasError: true };
	}

	componentDidCatch(error, errorInfo) {
		const { user } = this.props;

		Sentry.captureException({ error, errorInfo });
		Sentry.setUser({ id: user?.id ?? '', email: user?.email ?? '', username: user?.phone ?? '' });
	}

	render() {
		if (this.state.hasError) {
			return (
				<>
				<Head>
					<title>Похоже, что-то сломалось...</title>
				</Head>
					{/* <MobileNav />
					<MobileUserNav />
					<Header />
					<NavPopular /> */}
					
					<div className={styles.wrapp}>
						<img className={styles.image} src='/images/svg/frontend_error.svg' alt='error' />
						<h1 className={styles.title}>Похоже, что-то сломалось...</h1>

						<span className={styles.text}>Попробуйте вернуться назад или обновить страницу</span>

						<div className={styles.btnContainer}>
							<Button
								type='primary'
								onClick={() => {
									window.location.assign('/');
								}}
								classNames={{ general: styles.btn }}
							>
								Вернуться на главную
							</Button>
						</div>
					</div>
					<Footer />
				</>
			);
		}

		return this.props.children;
	}
}

const mapState = state => ({
	user: state.user.user,
});

export default connect(mapState)(ErrorBoundary);
