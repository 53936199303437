const SVG = ({
	fill = 'none',
	stroke = 'currentColor',
	width = '24',
	height = '24',
	viewBox = '0 0 24 24',
}) => (
	<svg width={width} height={height} viewBox={viewBox}>
		<path
			className='path'
			d='M12.525 6.657c.24-.24.481-.482.802-.722 1.524-1.203 3.69-1.123 5.053.24.882.803 1.363 1.845 1.444 3.048.08 1.284-.241 2.487-1.123 3.45-.482.56-.963 1.042-1.444 1.603-.642.722-1.283 1.444-1.925 2.086-.722.802-1.524 1.604-2.246 2.406a.775.775 0 0 1-1.123 0L9.076 15.64c-.882-.963-1.845-2.005-2.727-2.968-.642-.722-1.043-1.604-1.123-2.486-.16-.963 0-1.845.4-2.727.643-1.284 1.605-2.166 3.049-2.406 1.363-.241 2.486.24 3.449 1.203l.4.4z'
			fill={fill}
			stroke={stroke}
			strokeWidth='2'
		/>
	</svg>
);

export default SVG;
