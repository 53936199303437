import Link from 'next/link';
import getLinkProps from '@/lib/getLinkProps';

const ViewDefault = ({ link }) => {
	const { href, as, title, isLogout = false } = link;

	return isLogout ? (
		<button type='button'>{title}</button>
	) : (
		<Link href={href} as={as}>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
			<a>{title}</a>
		</Link>
	);
};

const UserNav = ({ render: View = ViewDefault, ...props }) => {
	const types = [
		'user-profile',
		'user-orders',
		'user-discount',
		'user-newsletters',
		'user-returns',
	];

	const links = types.map(type => {
		const [href, as, title] = getLinkProps({ type });
		return { href, as, title };
	});
	links.push({ title: 'Выход', isLogout: true });

	return (
		<>
			{links.map(link => {
				return <View key={link.title} link={link} {...props} />;
			})}
		</>
	);
};

export default UserNav;
