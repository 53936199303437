import styled, { css } from 'styled-components';
import { maxWidth, noTouch } from '@/assets/styles/mixins.css';
import theme from '@/assets/styles/theme';
import vars from '@/assets/styles/vars';
import Section from './StyledSection';
import Container from './Container';
import Title from './StyledTitle';

const Page = styled(Section)`
	margin-top: 16px;
`;

Page.Container = styled(Container)`
	${props =>
		props.center === true &&
		css`
			justify-content: center;
			display: flex;
		`};
`;

Page.Columns = styled.div`
	display: grid;

	${({ styledType }) => {
		switch (styledType) {
			case 'user':
				return css`
					grid-template-columns: 284px minmax(0, 1fr);
					grid-gap: 40px;

					${maxWidth(vars.breakpoints.tablet + 1)`
            grid-template-columns: minmax(0, 1fr);
            grid-gap: 0;
          `}
				`;
			default:
				break;
		}
	}}
`;

Page.Main = styled.div``;

Page.ContentLimiter = styled.div`
	max-width: 832px;
	margin: 0 auto;
`;

Page.Nav = styled.ul`
	margin-top: -4px;

	${maxWidth(vars.breakpoints.tablet + 1)`
    display: none;
  `}
`;

Page.NavItem = styled.li``;

Page.NavLink = styled.a`
	display: block;
	padding: 5px 0;
	font-size: 18px;
	line-height: 1.22;
	color: ${theme.text.accent};

	& > span {
		border-bottom: 1px solid ${theme.text.reverse};
		transition: border-color 0.2s ease;
		padding-left: 8px;
		padding-right: 8px;

		${props =>
			props.active &&
			css`
				border-left: 2px solid ${theme.brand.default};
				border-radius: 2px;
			`}
	}

	${noTouch()`
    &:hover {
      & > span {
        border-bottom-color: ${props => !props.active && theme.text.extra};
      }
    }
  `}

	${props =>
		props.active &&
		css`
			display: inline-block;
			font-family: ${vars.fonts.futura};
			font-weight: 500;
			cursor: default;
		`}
`;

Page.CustomWrapper = styled.div`
	margin-top: ${props => (props.isCustomOffsetBlock ? '96px' : '0px')};

	${maxWidth(vars.breakpoints.tablet)`
      margin-top: ${props => (props.isCustomOffsetBlock ? '72px' : '0px')};
    `}

	${maxWidth(vars.breakpoints.mTablet)`
      margin-top: ${props => (props.isCustomOffsetBlock ? '64px' : '0px')};
    `}

    &:last-child {
		margin-bottom: 144px;
	}
`;

Page.BreadCrumbs = styled.div`
	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;

Page.Title = styled.div`
	&:not(:last-child) {
		margin-bottom: 24px;
	}
`;

Page.Text = styled.div`
	&:not(:last-child) {
		margin-bottom: 32px;
	}
`;

Page.ArticleTitle = styled(Title).attrs({
	as: 'h1',
	size: 'x-large',
	mb: 'large',
	align: 'center',
})``;

Page.SubTitle = styled.div`
	font-size: 24px;
	text-align: center;
	margin-bottom: 56px;

	${maxWidth(vars.breakpoints.lMobile)`
    margin-bottom: 40px;
  `}

	${Page.ArticleTitle} + & {
		margin-top: -12px;
	}
`;

Page.Link = styled.div`
	display: flex;
	justify-content: center;
	--button-m-horizontal: 0;

	${props =>
		props.styledAlign === 'left' &&
		css`
			justify-content: flex-start;
		`};

	${Page.Title} ~ & {
		margin-top: 32px;
	}
`;

Page.ProductsSlider = styled.div`
	&:not(:first-child) {
		margin-top: 48px;

		${props =>
			props.withoutUpperMargin &&
			css`
				margin-top: 0px;
			`};

		${maxWidth(vars.breakpoints.mTablet)`
      margin-top: 32px;
    `}

		${maxWidth(vars.breakpoints.mobile)`
      margin-top: 24px;
    `}
	}

	&:not(:last-child) {
		margin-bottom: 32px;
	}
`;

Page.Type = styled.p`
	margin-bottom: 8px;
	font-family: ${vars.fonts.futura};
	font-weight: 500;
	letter-spacing: 0.15em;
	text-transform: uppercase;
	text-align: center;
	color: ${theme.text.secondary};
`;

Page.Image = styled.div`
	position: relative;

	margin-bottom: 40px;

	${maxWidth(vars.breakpoints.lMobile)`
    margin-bottom: 32px;
  `}

	&::after {
		content: '';
		padding-top: 66.66%;
		display: inline-block;
		vertical-align: top;
	}

	& img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
`;

Page.CarouselImageLink = styled.a`
	cursor: pointer;
`;

Page.CarouselImage = styled.img`
	${props =>
		props.padding &&
		css`
			padding: ${props.padding}px;
		`};
`;

Page.Share = styled(Section).attrs({
	pt: 'none',
	pb: 'xl-large',
})``;

Page.Articles = styled(Section).attrs({
	pt: 'medium',
	pb: 'xl-large',
})`
	border-top: 1px solid ${theme.bg.divider};
`;

Page.Date = styled.time`
	display: block;
	margin-bottom: 32px;
	font-size: 14px;
	color: ${theme.text.extra};
	text-align: center;

	${Page.ArticleTitle} + & {
		margin-top: -16px;
	}

	${Page.SubTitle} + & {
		margin-top: -18px;
	}
`;

Page.Label = styled.div`
	position: absolute;
	display: flex;
	background: #292c33;
	color: #ffffff;
	opacity: 0.9;
	border-radius: 4px;
	font-size: 22px;
	line-height: 32px;
	padding: 4px 8px;
	cursor: pointer;
	transform: translateY(50%);

	${maxWidth(vars.breakpoints.mTablet)`
    font-size: 16px;
    line-height: 24px;
  `}

	${maxWidth(vars.breakpoints.sMobile)`
    font-size: 14px;
    line-height: 18px;
  `}

  & > span {
		order: 1;
	}

	${props =>
		props.y &&
		css`
			top: ${props.y}%;
		`};

	${props =>
		props.x &&
		css`
			left: ${props.x}%;
		`};

	${props =>
		props.position === 'left' &&
		css`
			img {
				transform: rotate(180deg);
				order: 0;
				margin-right: 8px;
			}
		`};

	${props =>
		props.position === 'right' &&
		css`
			transform: translate(-100%, 50%);
			img {
				order: 2;
				margin-left: 8px;
			}
		`};
`;

export default Page;
