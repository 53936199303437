import React, { useState, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import noScroll from 'no-scroll';
import { Portal } from '@/components';
import clsx from 'clsx';

import styles from './Modal.module.scss';

const TriggerDefault = props => {
	return <button {...props}>Open</button>;
};

const Modal = ({
	children,
	hasTrigger = true,
	openOnTimeout,
	onOpen,
	timeoutInSec,
	render: TriggerView = TriggerDefault,
	generalVisible = null,
	setMobileVisible = null,
	isSizeTable = false,
	isBrandInfo = false,
}) => {
	const [visible, setVisible] = useState(false);
	const previouslyOpened = useRef(false);

	useEffect(() => {
		if (generalVisible === false) {
			setVisible(false);

			if (setMobileVisible) {
				setMobileVisible(false);
			}
		}
	}, [generalVisible, setMobileVisible]);

	useEffect(() => {
		let timeoutId;

		if (openOnTimeout && timeoutInSec >= 0) {
			timeoutId = setTimeout(() => {
				if (document.documentElement.style.position === 'fixed') {
					previouslyOpened.current = true;
				}
				showModal();
				onOpen && onOpen();
			}, timeoutInSec * 1000);
		}

		return () => {
			clearTimeout(timeoutId);
			noScroll.off();
		};
	}, []);

	const showModal = () => {
		noScroll.on();
		setVisible(true);

		if (setMobileVisible) {
			setMobileVisible(true);
		}
	};

	const closeModal = () => {
		setVisible(false);

		if (setMobileVisible) {
			setMobileVisible(false);
		}
		if (!previouslyOpened.current) {
			noScroll.off();
		}
	};

	return (
		<>
			{hasTrigger && <TriggerView onClick={showModal} />}

			<CSSTransition
				in={visible}
				timeout={{
					appear: 0,
					enter: 230,
					exit: 230,
				}}
				classNames={{
					enter: styles.onEnter,
					enterActive: styles.onEnterActive,
					exit: styles.onExit,
					exitActive: styles.onExitActive,
				}}
				unmountOnExit
			>
				<Portal>
					<div
						onClick={closeModal}
						className={clsx(
							styles.modal,
							isSizeTable && styles.sizeTable,
							isBrandInfo && styles.brandInfo,
						)}
					>
						<div className={styles.container}>
							<div className={styles.closeDialogWrapper}>
								<div
									className={styles.dialog}
									onClick={e => {
										e.stopPropagation();
									}}
								>
									{children}
								</div>
							</div>
						</div>
					</div>
				</Portal>
			</CSSTransition>
		</>
	);
};

export default Modal;
