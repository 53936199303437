import Head from 'next/head';
import { socialList } from '@/lib/config';

const socialLinks = socialList.map(item => item?.href);

const webSite = {
	'@context': 'https://schema.org',
	'@type': 'WebSite',
	name: 'FH.BY',
	url: 'https://fh.by',
	sameAs: socialLinks,
};

const organization = {
	'@context': 'https://schema.org',
	'@type': 'Organization',
	name: 'FH.BY',
	url: 'https://fh.by',
	logo: 'https://fh.by/android-chrome-512x512.png',
	sameAs: socialLinks,
	contactPoint: [
		{
			'@type': 'ContactPoint',
			telephone: '+375 (29) 633-2-633',
			contactType: 'FH.BY',
			areaServed: 'BY',
		},
		{
			'@type': 'ContactPoint',
			telephone: '+375 (17) 328-60-79',
			email: 'fh@fh.by',
			contactType: 'По общим вопросам',
			areaServed: 'BY',
		},
		{
			'@type': 'ContactPoint',
			contactType: 'По вопросам рекламы',
			email: 'reklama@fh.by',
			areaServed: 'BY',
		},
		{
			'@type': 'ContactPoint',
			email: 'otzyv@fh.by',
			contactType: 'По вопросам замечаний и предложений',
			areaServed: 'BY',
		},
		{
			'@type': 'ContactPoint',
			email: 'personal@fh.by',
			telephone: '+375 (44) 768-66-50',
			contactType: 'По вопросам трудоустройства',
			areaServed: 'BY',
		},
		{
			'@type': 'ContactPoint',
			email: 'personal@fh.by',
			telephone: '+375 (44) 566-47-66',
			contactType: 'По вопросам трудоустройства',
			areaServed: 'BY',
		},
		{
			'@type': 'ContactPoint',
			email: 'personal@fh.by',
			telephone: '+375 (44) 768-66-50',
			contactType: 'По вопросам трудоустройства',
			areaServed: 'BY',
		},
		{
			'@type': 'ContactPoint',
			email: 'personal@fh.by',
			telephone: '+375 (17) 328-63-27',
			contactType: 'По вопросам трудоустройства',
			areaServed: 'BY',
		},
	],
};

const breadCrumbsList = (list = [], join = true) => {
	let startId = 'https://fh.by';
	const listElements = list.reduce(
		(acc, item, ind) => {
			const { name, value } = item;
			const nextId = `${startId}/${value}`;
			if (join) startId = nextId;
			return acc.concat({
				'@type': 'ListItem',
				position: ind + 2,
				item: {
					'@id': nextId,
					name,
				},
			});
		},
		[
			{
				'@type': 'ListItem',
				position: 1,
				item: {
					'@id': startId,
					name: 'FH.BY',
				},
			},
		],
	);

	return {
		'@context': 'http://schema.org',
		'@type': 'BreadcrumbList',
		itemListElement: listElements,
	};
};

const getImageFromPic = pic => {
	return pic?.x2 ?? '';
};

const productData = data => {
	const { name, brand, description, gallery, external_id, slug, price, quantity, attributes } =
		data || {};
	const inStock = quantity !== 0;

	const trimmedDescription = (description || '').trim();
	const infoAttributes = (attributes || []).filter(attr => attr.type === 'info');
	const productDescription = infoAttributes.reduce(
		(acc, attr) => {
			const { name: attrName, value } = attr || {};
			return acc.concat(` ${attrName}: ${value}.`);
		},
		trimmedDescription.endsWith('.') ? trimmedDescription : trimmedDescription.concat('.'),
	);

	let currency = 'BYN';

	if (typeof window !== 'undefined' && localStorage.getItem('currency') === 'RU') {
		currency = '₽';
	}

	return {
		'@context': 'http://www.schema.org',
		'@type': 'Product',
		name,
		brand: {
			'@type': 'Brand',
			name: brand,
		},
		description: productDescription,
		image: getImageFromPic(gallery?.[0]),
		productID: external_id,
		sku: external_id,
		itemCondition: 'https://schema.org/NewCondition',
		offers: {
			'@type': 'Offer',
			priceCurrency: currency,
			price: Number(price),
			url: `https://fh.by/product/${slug}`,
			availability: `https://schema.org/${inStock ? 'InStock' : 'OutOfStock'}`,
			seller: {
				'@type': 'Organization',
				name: 'FH.BY',
				logo: {
					'@type': 'ImageObject',
					url: 'https://fh.by/android-chrome-512x512.png',
				},
			},
		},
	};
};

const categoryData = ({ category, totalProducts }) => {
	let currency = 'BYN';

	if (typeof window !== 'undefined' && localStorage && localStorage.getItem('currency') === 'RU') {
		currency = '₽';
	}

	return {
		'@context': 'http://www.schema.org',
		'@type': 'Product',
		name: category?.name ?? '',
		aggregateRating: {
			'@type': 'AggregateRating',
			ratingValue: category?.seo?.agregate_rating?.rating_value ?? 0,
			ratingCount: category?.seo?.agregate_rating?.rating_count ?? 0,
		},
		image: 'https://fh.by/android-chrome-512x512.png',
		offers: {
			'@type': 'AggregateOffer',
			priceCurrency: currency,
			offerCount: totalProducts || 0,
			lowPrice: category?.min_price ?? 0,
			highPrice: category?.max_price ?? 0,
		},
	};
};

const newsArticleData = data => {
	const { title, image, type, slug, published_at, modified_at, meta_description } = data || {};
	const fixedSlug = slug?.split('/').join('');

	return {
		'@context': 'https://schema.org',
		'@type': 'NewsArticle',
		mainEntityOfPage: {
			'@type': 'WebPage',
			'@id': `https://fh.by/${
				type === 'article' ? `news/${fixedSlug}` : `special-offers/${fixedSlug}`
			}`,
		},
		headline: title,
		datePublished: published_at,
		dateModified: modified_at,
		author: {
			'@type': 'Organization',
			name: 'FH.BY',
			logo: {
				'@type': 'ImageObject',
				url: 'https://fh.by/android-chrome-512x512.png',
			},
		},
		publisher: {
			'@type': 'Organization',
			name: 'FH.BY',
			logo: {
				'@type': 'ImageObject',
				url: 'https://fh.by/android-chrome-512x512.png',
			},
		},
		...(image && { image: getImageFromPic(image) }),
		...(meta_description && { description: meta_description }),
	};
};

export default {
	webSite,
	organization,
	breadCrumbsList,
	productData,
};

export const JsonLdData = ({ breadCrumbsProps, product, category, totalProducts, newsArticle }) => {
	// eslint-disable-next-line no-nested-ternary
	const type = product ? 'product' : category ? 'category' : newsArticle ? 'newsArticle' : null;
	let data = [];

	switch (type) {
		case 'product': {
			const breadCrumbs = (product?.breadcrumbs ?? []).map((item, ind, arr) => {
				return ind === arr.length - 1
					? {
							name: item?.name,
							value: `product/${product?.slug}`,
					  }
					: {
							name: item?.name,
							value: item?.slug,
					  };
			});

			data = [webSite, organization, productData(product), breadCrumbsList(breadCrumbs, false)];
			break;
		}
		case 'newsArticle': {
			const { articleType, title, slug } = newsArticle;
			const breadCrumbs = [
				{
					name: articleType === 'article' ? 'Новости' : 'Акции',
					value: articleType === 'article' ? 'news' : 'special-offers',
				},
				{
					name: title,
					value: slug?.split('/').join(''),
				},
			];

			data = [
				webSite,
				organization,
				newsArticleData(newsArticle),
				breadCrumbsList(breadCrumbs, false),
			];
			break;
		}
		case 'category': {
			const { name, path } = category || {};
			const breadCrumbs = [{ name, value: path }];

			data = [
				webSite,
				organization,
				categoryData({ category, totalProducts }),
				breadCrumbsList(breadCrumbs),
			];
			break;
		}
		default: {
			data = [webSite, organization, breadCrumbsList(breadCrumbsProps)];
			break;
		}
	}

	return (
		<Head>
			<script
				key='json-ld'
				type='application/ld+json'
				/* eslint-disable-next-line react/no-danger */
				dangerouslySetInnerHTML={{
					__html: JSON.stringify(data),
				}}
			/>
		</Head>
	);
};
