import * as Sentry from '@sentry/nextjs';
import getConfig from 'next/config';

const config = getConfig();

if (config && config.publicRuntimeConfig) {
	Sentry.init({
		dsn: config.publicRuntimeConfig.features.sentryDSN,
		tracesSampleRate: 0.1,
		environment: config.publicRuntimeConfig.environment,
		release: config.publicRuntimeConfig.version,
		autoSessionTracking: false,
		// beforeSend: (event, hint) => {
		//   console.warn('client');
		//   return event;
		// },
		// debug: true,
		sampleRate: 1,
		ignoreErrors: [
			/lastVisibilityStart/,
			/eventParam.trigger/,
			/Unexpected token '.'/,
			/DeviceUUID not set/,
			/t is undefined/,
			/runCustomize/,
			/processRandomSelector/,
			/illegal character U+009E/,
		],
	});
} else {
	console.warn(`publicRuntimeConfig not found. Sentry doesn't work`);
}
