import React from 'react';
import dynamic from 'next/dynamic';
import { Picture, Loader, PictureHtmlImage } from '@/components';
import { Block, Container } from '@/containers';
import clsx from 'clsx';

import styles from './PictureHtml.module.scss';

const Html = dynamic(() => import('../../html'), {
	loading: () => <Loader />,
});

const PictureHtml = ({ html, image, imagePos = 'left' }) => {
	if (!html || !image) return null;

	return (
		<Block>
			<Container>
				<div className={styles.main}>
					<PictureHtmlImage>
						<Picture {...image} />
					</PictureHtmlImage>

					<div className={clsx(imagePos === 'right' && styles.content)}>
						<Html html={html} />
					</div>
				</div>
			</Container>
		</Block>
	);
};

export default PictureHtml;
