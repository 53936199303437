import Icon from '@/assets/icons';
import clsx from 'clsx';

import styles from './Loader.module.scss';

const Loader = ({ visible = true }) => {
	return (
		<div className={clsx(styles.loader, visible && styles.visible)}>
			<Icon name='spinner' width={70} height={70} style={{ top: '50%' }} />
		</div>
	);
};

export default Loader;
