const SVG = ({ fill = '#74849C', width = '29', height = '27', viewBox = '0 0 29 27' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M29 13.79L26.535 0.595001H16.82L16.675 1.61C16.53 2.77 15.66 3.64 14.645 3.64C13.63 3.64 12.76 2.77 12.615 1.61V0.595001H2.465L0 13.645H5.22L3.77 26.405H25.23L23.78 13.79H29ZM6.235 24.23L7.975 7.7L5.945 7.555L5.365 11.47H2.61L4.205 2.77H10.73C11.31 4.51 12.905 5.815 14.79 5.815C16.675 5.815 18.27 4.51 18.85 2.77H24.94L26.535 11.615H23.78L23.345 7.41L21.17 7.7L23.055 24.23H6.235Z'
			fill={fill}
		/>
	</svg>
);

export default SVG;
