// @codegen
const fs = require('fs');
const path = require('path');

const directory = path.resolve(__dirname, '../pages');
// replace backslashes in windows path
const fixedDirectory = directory.replace(/\\/g, '/');

const getAllFiles = dir =>
	fs
		.readdirSync(dir)
		.sort()
		.reverse()
		.reduce((files, file) => {
			// replace backslashes in windows path
			const name = path.join(dir, file).replace(/\\/g, '/');
			const isDirectory = fs.statSync(name).isDirectory();
			if (isDirectory) {
				return [...files, ...getAllFiles(name)];
			}
			if (file === 'index.js') {
				const dirName = dir.replace(`${fixedDirectory}`, '');
				return dirName.length > 0 ? [...files, dir.replace(`${fixedDirectory}`, '')] : [...files];
			}
			if (file.slice(0, 1) === '_') {
				// next.js layout files
				return [...files];
			}
			return [...files, name.replace('.js', '').replace(`${fixedDirectory}`, '')];
		}, []);

const files = getAllFiles(fixedDirectory);

const dict = [];
// escape slashes, add regexp for square brackets content and create regexp from this string
files.forEach(file => {
	// eslint-disable-next-line no-useless-escape
	const regString = `^${file.replace(/\//g, '\\$&').replace(/\[[^\/]+\]/gi, '.+')}$`;
	const reg = new RegExp(regString);

	dict.push({ reg, route: file });
});

function convert(arr) {
	const isLast = arr.length - 1;
	const result = arr.reduce((acc, item, index) => {
		return index !== isLast
			? `${acc}{ reg: ${item.reg}, route: '${item.route}' },`
			: `${acc}{ reg: ${item.reg}, route: '${item.route}' }]`;
	}, '[');
	return result;
}

module.exports = `export default ${convert(dict)};`;
