/* eslint-disable jsx-a11y/anchor-is-valid */
import Link from 'next/link';
import clsx from 'clsx';
import getLinkProps from '@/lib/getLinkProps';

import styles from './BreadCrumbs.module.scss';

const BreadCrumbs = ({ breadcrumbs, type = 'catalog' }) => {
	return (
		<ul className={styles.list}>
			{[{ slug: '/', as: '/', name: 'FH.BY' }, ...breadcrumbs].map(({ name, slug }, ind) => {
				const [href, as] = getLinkProps({ type: ind === 0 ? 'home' : type, value: slug });

				return (
					<li className={styles.item} key={`${slug}${ind}`}>
						{ind === breadcrumbs.length ? (
							<span className={clsx(styles.link, styles.last)}>{name}</span>
						) : (
							<Link href={href} as={as} passHref>
								<a className={styles.link}>{name}</a>
							</Link>
						)}
					</li>
				);
			})}
		</ul>
	);
};

export default BreadCrumbs;
