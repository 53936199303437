import { useState, useCallback } from 'react';
// import noScroll from 'no-scroll';
import clsx from 'clsx';
import { ClickOutside, Button } from '@/components';
import { countries } from '@/lib/config';
import CountrySelectItem from './countrySelectItem';
import CountrySelectlist from './countrySelectlist';
import useWindowSize from '@/hooks/use-window-size';
import styles from './countrySelect.module.scss';

const list = Object.keys(countries);

const CountrySelect = ({
	selectedCountry: countryIso,
	onCountryChange,
	className = null,
	classNameList = null,
}) => {
	const [visible, setVisible] = useState(false);
	const { isMobile } = useWindowSize();
	const selectedCountry = countryIso ? countryIso : 'by';

	const onToggle = useCallback(() => {
		setVisible(prev => !prev);
		// if (isMobile) {
		//   noScroll.toggle();
		// }
	}, [isMobile]);

	const onOutsideClick = useCallback(() => {
		if (isMobile) {
			return;
		}

		setVisible(false);
	}, [isMobile]);

	const onClose = useCallback(() => {
		setVisible(false);
		// if (isMobile) {
		//   noScroll.off();
		// }
	}, [isMobile]);

	const onCodeClick = useCallback(
		country => () => {
			onCountryChange(country);
			onClose();
		},
		[onClose, onCountryChange],
	);

	return (
		<ClickOutside onClickOutside={onOutsideClick}>
			<div className={styles['country-select']}>
				<Button
					onClick={onToggle}
					action='button'
					classNames={{
						general: clsx(styles['country-select-trigger'], {
							[styles.active]: visible,
						}),
					}}
				>
					<img
						src={`/images/flags/${selectedCountry}.png`}
						className={styles['trigger-icon']}
						alt={selectedCountry ? countries[selectedCountry].name : ''}
						width={39}
						height={23}
					/>
				</Button>

				<CountrySelectlist
					visible={visible}
					onClose={onClose}
					isMobile={isMobile}
					className={className}
					classNameList={classNameList}
				>
					{list.map(country => {
						const { code, name } = countries[country];
						return (
							<CountrySelectItem
								key={code + name}
								code={code}
								name={name}
								countryISO={country}
								selectedCountry={selectedCountry}
								onCodeClick={onCodeClick}
							/>
						);
					})}
				</CountrySelectlist>
			</div>
		</ClickOutside>
	);
};

export default CountrySelect;
