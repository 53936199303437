import { useContext, createContext } from 'react';

const HostContext = createContext();

export const HostProvider = ({ host, children }) => {
	return <HostContext.Provider value={host}>{children}</HostContext.Provider>;
};

export const useHost = () => {
	return useContext(HostContext);
};
