import { useState, useEffect } from 'react';
import { Picture } from '@/components';

const placeHolder =
	'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const LazyPicture = ({ data, snippet, isSafari, ...props }) => {
	const [imageSrc, setImageSrc] = useState({
		x2: placeHolder,
		webp_x2: placeHolder,
	});
	const [imageRef, setImageRef] = useState();

	const loadClass = props?.classes?.loaded || 'loaded';
	const errorClass = props?.classes?.error || 'error';

	const onLoad = event => {
		if (event.target.src !== placeHolder) event.target?.classList.add(loadClass);
	};

	const onError = event => {
		if (event.target.src !== placeHolder) event.target?.classList.add(errorClass);
	};

	useEffect(() => {
		if (imageSrc?.x2 !== placeHolder) {
			setImageSrc(data);
		}
	}, [data, imageSrc?.x2]);

	useEffect(() => {
		let observer;
		let didCancel = false;

		if (imageRef && imageSrc?.x2 === placeHolder) {
			if (IntersectionObserver) {
				observer = new IntersectionObserver(
					entries => {
						entries.forEach(entry => {
							// when image is visible in the viewport + rootMargin
							if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
								setImageSrc(data);
								observer.unobserve(imageRef);
							}
						});
					},
					{
						threshold: 0.01,
						rootMargin: '20%',
					},
				);
				observer.observe(imageRef);
			} else {
				// Old browsers fallback
				setImageSrc(data);
			}
		}
		return () => {
			didCancel = true;
			// on component unmount, we remove the listner
			if (observer && observer.unobserve) {
				observer.unobserve(imageRef);
			}
		};
	}, [data, imageSrc, imageRef]);

	return (
		<Picture
			ref={setImageRef}
			snippet={snippet}
			{...imageSrc}
			{...props}
			onLoad={onLoad}
			isSafari={isSafari}
			onLoadError={onError}
			isLazy
		/>
	);
};

export default LazyPicture;
