import { StyledPage } from '@/components/styles';
import { ActiveLink, LogoutModal } from '@/components';
import styles from './UserNavItem.module.scss';

const UserNavItem = ({ link }) => {
	const { href, as, title, isLogout = false } = link;

	return (
		<StyledPage.NavItem>
			{isLogout ? (
				<StyledPage.NavLink as='button'>
					<LogoutModal isProfile />
				</StyledPage.NavLink>
			) : (
				<ActiveLink href={href} as={as}>
					<StyledPage.NavLink>
						<span>{title}</span>
					</StyledPage.NavLink>
				</ActiveLink>
			)}
		</StyledPage.NavItem>
	);
};

export default UserNavItem;
