import React from 'react';
import dynamic from 'next/dynamic';
import { Block, Container } from '@/containers';
import { ProductCard, Loader } from '@/components';

import styles from './SelectionsCatalog.module.scss';

const ScrollBtn = dynamic(() => import('../../scrollBtn'), {
	loading: () => <Loader />,
});

const SelectionsCatalog = ({ products }) => {
	return (
		<Block>
			<Container className={styles.container}>
				<ul className={styles.productList}>
					{products.map(product => {
						return (
							<li key={product.model.id}>
								<ProductCard {...product} mod='catalog' />
							</li>
						);
					})}
				</ul>
				{!!products?.length && <ScrollBtn />}
			</Container>
		</Block>
	);
};

export default SelectionsCatalog;
