const SVG = () => (
	<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<circle cx='9' cy='9' r='9' fill='#FF7878' />
		<path
			d='M5.08473 11.6705L11.7552 5L13.1695 6.41421L6.49894 13.0847L5.08473 11.6705Z'
			fill='white'
		/>
		<path d='M13.0847 11.6705L6.41421 5L5 6.41421L11.6705 13.0847L13.0847 11.6705Z' fill='white' />
	</svg>
);

export default SVG;
