import {
	getCategoryTreeBySlug,
	getLoading,
} from '@fh-components/fh-js-api-core/store/slices/categoriesSlice';
import {
	getCatalogData,
	fetchProducts,
	fetchFilters,
	saveSort,
} from '@fh-components/fh-js-api-core/store/slices/catalogSlice';
import { useMemo, useEffect, useCallback, useState } from 'react';
import {
	updateHistoryIds,
	getViewedData,
	fetchHistory,
} from '@fh-components/fh-js-api-core/store/slices/viewedSlice';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { useSelector, useDispatch } from 'react-redux';
import { animateScroll as scroll } from 'react-scroll';
import Router, { useRouter } from 'next/router';
import qs from 'qs';
import { handleError } from '@/lib/handleError';
import { getCorrectName, getObjectFromArray } from '@/utils';
import { useWindowSize, useLocalStorage } from '@/hooks';
import mindbox from '@/seo/Mindbox';
import { setCategoryData } from '@/seo/PopMechanicData';
import ecommerce from '@/seo/Ecommerce';
import CatalogPageView from './CatalogPageView';

const isEqualIds = (ids, historyIds) => {
	return ids.every(id => historyIds[id]);
};

const CatalogPage = ({ data, isSafari: isSafariSSR }) => {
	let isSafari = false;

	if (typeof window !== 'undefined') {
		isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
	} else {
		isSafari = isSafariSSR || false;
	}
	const [state, setState] = useState({
		breadcrumbs: data.breadcrumbs,
		sidebar: data.sidebar,
		current: data.current,
		parent: data.parent,
		tags: data.tags,
	});

	const {
		products: { data: products, meta, blocks, loading: loadingProducts, loadingCash },
		filters: { data: filters, loading: loadingFilters },
		sort: savedSort,
	} = useSelector(getCatalogData);
	const { loading: loadingCategory } = useSelector(getLoading);

	const { isDesktop } = useWindowSize();

	const router = useRouter();
	const { query } = router;
	const dispatch = useDispatch();
	const currency = useSelector(getCurrency);
	const [firstProductId, setFirstProductId] = useState(-1);

	const [history, setHistory] = useLocalStorage('history', { count: 0, ids: {}, products: [] });
	const [storageCurrency, _setCurrency] = useLocalStorage('currency', 'BY');
	const [newProductsLoading, setNewProductsLoading] = useState(false);
	const { count, ids, isFromSession } = useSelector(getViewedData);
	const [queryData, setQueryData] = useState({
		page: 1,
		selectedFilters: '{}',
	});

	useEffect(() => {
		if (isFromSession && (history.count !== count || !isEqualIds(ids, history.ids))) {
			dispatch(
				fetchHistory({
					onSuccess: items => {
						const newIds = items.map(item => item.model.id);
						const idsObj = getObjectFromArray(newIds);

						dispatch(updateHistoryIds({ ids: newIds, count: newIds.length }));
						setHistory({
							count: newIds.length,
							ids: idsObj,
							products: items,
						});
					},
				}),
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ids, history, dispatch, count, isFromSession]);

	const { typeSlug, page = 1, sort: selectedSorting, filters: selectedFilters = {} } = qs.parse(
		query,
		{ arrayLimit: 100 },
	);

	const categorySlug = typeSlug.join('/');

	const {
		// id,
		sort_fields: sortFields = [],
		description = null,
		meta_title: metaTitle = '',
		meta_description: metaDescription = '',
		name: categoryTitle = '',
		og = {},
		seo,
	} = state.current;

	const {
		title: ogTitle = '',
		description: ogDescription = '',
		image_big: ogImageRect = null,
		image_small: ogImageSquare = null,
	} = og;

	const isBrandsCategory = useMemo(() => categorySlug.includes('brands'), [categorySlug]);

	// eslint-disable-next-line no-shadow
	const withEncodedBrands = useCallback(
		filters =>
			filters.hasOwnProperty('brand')
				? { ...filters, brand: filters.brand.map(brand => encodeURIComponent(brand)) }
				: filters,
		[],
	);

	const sendToEcomerce = () => {
		if (Object.keys(currency).length > 0 && products) {
			let nameSlug = 'Категории';

			if (state.current.slug.includes('brand')) {
				nameSlug = 'Бренды';
			} else if (state.current.slug.includes('sale')) {
				nameSlug = 'Распродажи/Sale';
			}

			ecommerce.viewItemList(products, currency, `Каталог товаров: ${nameSlug}`);
		}
	};

	useEffect(() => {
		sendToEcomerce();
	}, [products, currency]);

	const [newPage, setNewPage] = useState(page);
	const [newSelectedFilters, setSelectedFilters] = useState(selectedFilters);
	const [newSelectedSorting, setSelectedSorting] = useState(selectedSorting);

	useEffect(async () => {
		setQueryData(prevState => ({
			...prevState,
			page: 1,
			selectedFilters: '{}',
		}));

		setNewProductsLoading(true);
		await dispatch(
			getCategoryTreeBySlug({
				slug: `/${categorySlug}`,
				onSuccess: res => {
					if (res.page.pageType === 'redirect') {
						Router.push(res.page.data.to);
					} else {
						setState({
							breadcrumbs: res?.page.data.breadcrumbs,
							sidebar: res?.page.data.sidebar,
							current: res?.page.data.current,
							parent: res?.page.data.parent,
							tags: res?.page.data.tags,
						});
					}
				},
				onError: err => {
					handleError(err);
				},
			}),
		);
	}, [categorySlug, dispatch]);

	useEffect(() => {
		setNewPage(page);
	}, [page]);

	useEffect(() => {
		const strNewSelectedFilters = JSON.stringify(newSelectedFilters);
		const strSelectedFilters = JSON.stringify(selectedFilters);

		if (strNewSelectedFilters !== strSelectedFilters) {
			setSelectedFilters(selectedFilters);
		}
	}, [selectedFilters]);

	useEffect(() => {
		if (newSelectedSorting !== selectedSorting) {
			setSelectedSorting(selectedSorting);
		}
	}, [selectedSorting]);

	useEffect(() => {
		const { hash, external_id, name } = state.current;
		const { selectedFilters, selectedSorting, page } = queryData || {};
		const hasDifferences =
			hash &&
			(hash !== queryData?.hash ||
				newPage !== page ||
				JSON.stringify(newSelectedFilters) !== selectedFilters ||
				newSelectedSorting !== selectedSorting);

		if (hasDifferences) {
			scroll.scrollToTop();
			newSelectedSorting !== savedSort && dispatch(saveSort(newSelectedSorting));

			const sortValue = sortFields.some(field => field.value === newSelectedSorting)
				? newSelectedSorting
				: (sortFields.length > 0 ? sortFields[0].value : 'new');
			const baseParams = { hash, filters: newSelectedFilters };
			const fetchProductsParams = {
				...baseParams,
				page: newPage,
				sort: sortValue,
				onSuccess: () => setNewProductsLoading(false),
				onError: () => setNewProductsLoading(false),
			};

			dispatch(fetchProducts(fetchProductsParams));
			(JSON.stringify(newSelectedFilters) !== selectedFilters || hash !== queryData?.hash) &&
				dispatch(fetchFilters(baseParams));

			setQueryData({
				hash,
				page: newPage,
				selectedFilters: JSON.stringify(newSelectedFilters),
				selectedSorting: newSelectedSorting,
			});

			mindbox?.displayCategory({ category: state.current });
			setCategoryData({ id: external_id, name });
		}
	}, [
		queryData,
		dispatch,
		newPage,
		savedSort,
		newSelectedFilters,
		newSelectedSorting,
		sortFields,
		state.current,
		currency,
	]);

	useEffect(() => {
		setState({
			breadcrumbs: data.breadcrumbs,
			sidebar: data.sidebar,
			current: data.current,
			parent: data.parent,
			tags: data.tags,
		});
	}, [data]);

	const handleChange = useCallback(
		type => values => {
			const isFiltersType = type === 'filters';
			let filters = withEncodedBrands(isFiltersType ? values : newSelectedFilters);
			let sort = isFiltersType ? newSelectedSorting : values;

			if (
				isFiltersType &&
				storageCurrency !== 'BY' &&
				filters.price &&
				Object.keys(currency).length
			) {
				const { filters: selectedFiltersCurrency = {} } = qs.parse(query, { arrayLimit: 100 });
				['min', 'max'].forEach(key => {
					if (
						filters.price[key] &&
						(!selectedFiltersCurrency.price?.[key] ||
							filters.price[key] !== selectedFiltersCurrency.price[key])
					) {
						filters.price[key] = (parseFloat(filters.price[key]) * currency.RUB.BYN).toFixed(2);
					}
				});
			}

			const queriesStringified = qs.stringify(
				{ filters, ...(sort && { sort }) },
				{ encode: false },
			);
			const baseUrl = queriesStringified
				? `${router.pathname}?${queriesStringified}`
				: router.pathname;
			const asPath = queriesStringified
				? `${window.location.pathname}?${queriesStringified}`
				: window.location.pathname;

			router.push(baseUrl, asPath, { shallow: true, scroll: false });
		},
		[currency, storageCurrency, newSelectedFilters, withEncodedBrands],
	);

	const textBlock = useMemo(
		() => (seo && seo.text_block ? getCorrectName(seo.text_block) : description),
		[seo, description],
	);

	const brandBlocks = useMemo(() => {
		if (blocks && !!blocks.length) {
			const newBlock = {
				is_active: true,
				type: 'brand-info',
				id: 'brand-info',
				data: {
					blocks: blocks.map(({ data }) => ({ ...data })),
					loading: loadingProducts || loadingCash
				},
			};

			return [newBlock];
		}

		return [];
	}, [blocks, loadingProducts, loadingCash]);

	return (
		<CatalogPageView
			metaTitle={metaTitle}
			categoryTitle={categoryTitle}
			metaDescription={metaDescription}
			seo={seo}
			ogTitle={ogTitle}
			ogDescription={ogDescription}
			ogImageRect={ogImageRect}
			ogImageSquare={ogImageSquare}
			meta={meta}
			state={state}
			isBrandsCategory={isBrandsCategory}
			savedSort={savedSort}
			newSelectedSorting={newSelectedSorting}
			filters={filters}
			newSelectedFilters={newSelectedFilters}
			handleChange={handleChange}
			loadingProducts={loadingProducts}
			loadingFilters={loadingFilters}
			isDesktop={isDesktop}
			blocks={brandBlocks}
			page={newPage}
			sortFields={sortFields.length > 0 ? sortFields : [{value: 'new', name: 'По новинкам', active: true}]}
			currency={currency}
			storageCurrency={storageCurrency}
			products={products}
			loadingCategory={loadingCategory}
			isSafari={isSafari}
			history={history}
			setFirstProductId={setFirstProductId}
			firstProductId={firstProductId}
			textBlock={textBlock}
			newProductsLoading={newProductsLoading}
		/>
	);
};

export default CatalogPage;
