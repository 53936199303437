const SVG = ({ fill = 'none', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M6.10662 18.3492L20.4559 4L21.8701 5.41421L7.52083 19.7635L6.10662 18.3492Z'
			fill='#0FB769'
		/>
		<path
			d='M8.87029 18.3492L3.91421 13.3932L2.5 14.8074L7.45607 19.7635L8.87029 18.3492Z'
			fill='#0FB769'
		/>
	</svg>
);

export default SVG;
