import { memo } from 'react';
import clsx from 'clsx';
import Icon from '@/assets/icons';
// import ecommerce from '@/seo/Ecommerce';
import styles from './socialList.module.scss';

const SocialList = memo(({ list, type, top }) => {
	if (!list || list.length === 0) return null;

	return (
		<ul className={clsx(styles.list, styles[type], { [styles.top]: top })}>
			{list.map(item => (
				<li className={styles['list-item']} key={item.icon}>
					<a
						href={item.href}
						target='_blank'
						aria-label={item.label}
						rel='noopener noreferrer'
						onClick={() => {
							// ecommerce.onClickExternalLink(item.href);
						}}
					>
						<div className={styles.icon}>
							{item.icon && (
								<Icon name={item.icon} width={top ? '32' : '24'} height={top ? '32' : '23'} />
							)}
						</div>
					</a>
				</li>
			))}
		</ul>
	);
});

export default SocialList;
