import Link from 'next/link';
import getLinkProps from '@/lib/getLinkProps';
import { Picture } from '@/components';
import getDate from '@/lib/getDate';
import clsx from 'clsx';
import styles from './News.module.scss';

const News = ({ items }) => {
	return (
		<ul className={styles.news}>
			{items?.map((item, ind) => {
				const { id, title, image, slug, published_at } = item;
				const { day, month_rus, year } = getDate(published_at);

				const [href, as] = getLinkProps({ type: 'news-item', value: slug });
				const special = ind % 3 === 0;

				return (
					<li className={clsx(styles.item, special && styles.special)} key={id}>
						<Link href={href} as={as} passHref>
							<a className={styles.link}>
								<div className={styles.content}>
									{special ? (
										<h3 className={styles.title}>{title}</h3>
									) : (
										<h4 className={styles.title}>{title}</h4>
									)}
									{published_at && (
										<time className={styles.date} dateTime={published_at}>
											{day} {month_rus} {year}
										</time>
									)}
								</div>

								{image && (
									<div className={styles.img}>
										<Picture {...image} alt={title} title={title} />
									</div>
								)}
							</a>
						</Link>
					</li>
				);
			})}
		</ul>
	);
};

export default News;
