const SVG = ({ fill = '#292C33', width = '20', height = '19', viewBox = '0 0 20 19' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M16.467 11.88l-.103.11.103-.11a9.195 9.195 0 0 0-3.194-1.962c1.263-.925 2.08-2.374 2.08-4.004 0-2.8-2.41-5.064-5.353-5.064-2.944 0-5.353 2.264-5.353 5.064 0 1.63.817 3.079 2.08 4.004a9.194 9.194 0 0 0-3.194 1.963l.103.109-.103-.11C1.804 13.514.85 15.688.85 18v.15H2.556V18c0-3.864 3.331-7.022 7.444-7.022 4.113 0 7.444 3.158 7.444 7.022v.15H19.15V18c0-2.313-.954-4.487-2.683-6.12zM10 9.35c-2.019 0-3.647-1.55-3.647-3.436S7.981 2.478 10 2.478s3.647 1.55 3.647 3.436c0 1.887-1.628 3.436-3.647 3.436z'
			fill={fill}
			stroke={fill}
			strokeWidth='0.3'
		/>
	</svg>
);

export default SVG;
