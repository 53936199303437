import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { hasSmartBannerSelector } from '@/store';
import clsx from 'clsx';
import { Portal } from '@/components';

import styles from './countrySelectlist.module.scss';

const CountrySelectlist = memo(
	({ visible, children, isMobile, onClose, className = null, classNameList = null }) => {
		const hasSmartBanner = useSelector(hasSmartBannerSelector);

		if (visible && isMobile) {
			return (
				<Portal>
					<div
						className={clsx(
							styles['country-modal-dialog'],
							hasSmartBanner && styles.smartBanner,
							className,
						)}
					>
						<div className={styles['modal-header']}>
							<h2>Выберите страну/регион</h2>
							<button type='button' className={styles['modal-close']} onClick={onClose} />
						</div>

						<div className={styles['modal-inner']}>
							<ul className={styles['country-list']}>{children}</ul>
						</div>
					</div>
				</Portal>
			);
		}

		return (
			<div
				className={clsx(
					styles['country-select-dropdown'],
					{ [styles.visible]: visible },
					classNameList,
				)}
			>
				<ul className={styles['country-select-list']}>{children}</ul>
			</div>
		);
	},
);

CountrySelectlist.displayName = 'CountrySelectlist';

export default CountrySelectlist;
