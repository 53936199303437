import * as Sentry from '@sentry/nextjs';

class ApiError extends Error {
	constructor(message, statusCode) {
		super(message);
		this.statusCode = statusCode;
	}
}

const getConfigInfo = ({ url, params, method }) => {
	return `${url ? `, URL = ${url}` : ''}${params ? `, PARAMS = ${JSON.stringify(params)}` : ''}${
		method ? `, METHOD = ${method}` : ''
	}`;
};

const getMessage = (error, isSentry) => {
	if (typeof error === 'string') {
		return `${isSentry ? '' : '. '}${error}`;
	}
	if (error.message && typeof error.message === 'string') {
		return `${isSentry ? '' : '. '}${error.message}`;
	}

	return '';
};

// eslint-disable-next-line import/prefer-default-export
export const handleError = error => {
	if (!error) return;
	const statusCode = error?.statusCode;
	const config = error?.config ?? {};

	if (statusCode != 404) {
		Sentry.captureException(new ApiError(getMessage(error, true), statusCode));
	}

	if (statusCode) {
		throw new ApiError(
			`Not Found, StatusCode = ${statusCode}${getConfigInfo(config)}${getMessage(error)}`,
			statusCode,
		);
	} else {
		throw new ApiError(
			`Internal Error, StatusCode = 500${getConfigInfo(config)}${getMessage(error)}`,
			500,
		);
	}
};
