const SVG = ({
	fill = 'currentColor',
	width = '11',
	height = '11',
	viewBox = '0 0 11 11',
	className,
	onClick,
	...props
}) => (
	<svg
		width='10'
		height='10'
		viewBox='0 0 10 10'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={className}
		onClick={onClick}
		{...props}
	>
		<path
			d='M1.06671 0.359375L9.64318 8.93585L8.93608 9.64296L0.359602 1.06648L1.06671 0.359375Z'
			fill='#171717'
		/>
		<path
			d='M8.93585 0.359375L0.359375 8.93585L1.06648 9.64296L9.64296 1.06648L8.93585 0.359375Z'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
