const SVG = ({ className }) => (
	<svg
		width='51'
		height='51'
		viewBox='0 0 51 51'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={className}
	>
		<circle cx='25.5' cy='25.5' r='25.5' fill='#F0F0F0' />
		<rect x='23.9805' y='13' width='3.01508' height='25' fill='#0FB769' />
		<rect
			x='38.0508'
			y='24'
			width='3'
			height='25.1256'
			transform='rotate(90 38.0508 24)'
			fill='#0FB769'
		/>
	</svg>
);

export default SVG;
