import { forwardRef } from 'react';
import Image from 'next/image';

import styles from './Picture.module.scss';

const base64Pixel =
	'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw==';

const Picture = forwardRef(
	(
		{
			x2: desktop,
			webp_x2: desktop_webp,
			noImageOnTouch,
			alt = 'Фото',
			title = 'Заголовок',
			onLoad,
			onLoadError,
			snippet: googleSnippet,
			isSafari,
			isLazy = false,
			width = null,
			height = null,
		},
		ref,
	) => {
		const snippet = googleSnippet?.x2;
		// const loading = isSafari ? {} : {loading: 'lazy'}
		const hasImage = !!(snippet || desktop_webp || desktop);

		if (isLazy) {
			return hasImage ? (
				<picture>
					{noImageOnTouch && (
						<source
							media='(hover: none) and (pointer: coarse), (hover: none) and (pointer: fine)'
							srcSet={base64Pixel}
							sizes='100%'
						/>
					)}
					<img
						layout='fill'
						ref={ref}
						src={snippet || desktop_webp || desktop}
						alt={alt}
						title={title}
						onLoad={onLoad}
						onError={onLoadError}
						loading={isSafari ? 'eager' : 'lazy'}
					/>
					<meta itemProp='image' content={snippet || desktop_webp || desktop} />
				</picture>
			) : (
				<img
					className={styles.patternImage}
					src='/images/svg/pattern-order-picture.svg'
					alt='image-pattern'
				/>
			);
		} else {
			let otherProps = {
				layout: 'fill',
			};

			if (width && height) {
				otherProps = {
					width,
					height,
				};
			}

			return hasImage ? (
				<picture>
					{noImageOnTouch && (
						<source
							media='(hover: none) and (pointer: coarse), (hover: none) and (pointer: fine)'
							srcSet={base64Pixel}
							sizes='100%'
						/>
					)}
					<Image
						ref={ref}
						src={snippet || desktop_webp || desktop}
						alt={alt}
						title={title}
						onLoad={onLoad}
						onError={onLoadError}
						loading={isSafari ? 'eager' : 'lazy'}
						{...otherProps}
					/>
					<meta itemProp='image' content={snippet || desktop_webp || desktop} />
				</picture>
			) : (
				<img
					className={styles.patternImage}
					src='/images/svg/pattern-order-picture.svg'
					alt='image-pattern'
				/>
			);
		}
	},
);

export default Picture;
