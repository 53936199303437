export const sortingByFavBrands = (items, favoriteBrands) => {
	const sortedByFavBrands = [...items].sort((a, b) => {
		if (favoriteBrands.includes(String(a.brandId)) && favoriteBrands.includes(String(b.brandId))) {
			return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
		}
		if (favoriteBrands.includes(String(a.brandId))) {
			return -1;
		}
		if (favoriteBrands.includes(String(b.brandId))) {
			return 1;
		}
		return 0;
	});
	return sortedByFavBrands;
};
