const SVG = ({
	width = '17',
	height = '16',
	viewBox = '0 0 17 16',
	fill = 'none',
	className,
	onClick = null,
	stroke = null,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
		className={className}
		onClick={onClick}
	>
		<path
			d='M1.24264 0.75736L15.7279 15.2426M1.24264 15.2426L15.7279 0.757356'
			stroke={stroke ? stroke : '#BBBBBB'}
			strokeWidth='2'
		/>
	</svg>
);

export default SVG;
