import { Button } from '@/components';
import { Block, Container } from '@/containers';
import { useRouter } from 'next/router';

import styles from './Button.module.scss';

const BButton = ({ title, url }) => {
	const router = useRouter();

	const handleClick = () => {
		router.push(url);
	};

	return (
		<Block>
			<Container>
				<Button classNames={{ general: styles.button }} type='defaultBlack' onClick={handleClick}>
					{title}
				</Button>
			</Container>
		</Block>
	);
};

export default BButton;
