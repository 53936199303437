import React, { useEffect, useState } from 'react';
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import getConfig from 'next/config';
import { useDispatch, useSelector } from 'react-redux';
import {
	createSocketId,
	subscribedChannel,
} from '@fh-components/fh-js-api-core/store/slices/sessionSlice';
import { sessionIdSelector } from '@/store';

const { publicRuntimeConfig } = getConfig();
const wsHost = publicRuntimeConfig.host;
const wsPort = 80;
const forceTLS = true;
let newChannels = null;
let privateChannels = null;
let newAuthToken = null;

const pusherConfig = {
	key: 'app',
	cluster: 'mt1',
	authEndpoint: (forceTLS ? 'https' : 'http') + `://${wsHost}/api/broadcasting/auth`,
};

const ChannelsContext = React.createContext(undefined);

const getChannels = (pusherConfig, authToken, dispatch, setChannels) => {
	if (newChannels) {
		return newChannels;
	}

	const client = new Pusher(pusherConfig.key, {
		cluster: pusherConfig.cluster,
		forceTLS,
		authEndpoint: pusherConfig.authEndpoint,
		auth: authToken
			? {
				headers: {
					Authorization: `Bearer ${authToken}`,
				},
			}
			: undefined,
		wsHost,
		pongTimeout: 10000,
		activityTimeout: 10000,
		wsPort,
	});

	client.connection.bind('connected', () => {
		privateChannels = null;
		dispatch(createSocketId({ socketId: newChannels.socketId() }));
	});

	client.connection.bind('state_change', state => {
		console.log('Connection state', state);

		if (state.current === 'disconnected') {
			if (document.hidden) {
				window.location.reload();
			} else {
				setTimeout(() => {
					const reconnectedChannels = getChannels(pusherConfig, newAuthToken, dispatch, setChannels);

					setChannels(reconnectedChannels);
				}, 3000);
			}
		}
	});

	newChannels = new Echo({
		broadcaster: 'pusher',
		client: client,
	});

	return newChannels;
};

export const useChannels = () => {
	const channels = React.useContext(ChannelsContext);

	return channels;
};

export const usePrivateChannels = (authUserId, dispatch) => {
	const channels = useChannels();

	if (!authUserId) {
		return;
	}

	if (privateChannels) {
		return privateChannels;
	}

	privateChannels =
		channels &&
		channels.private('session.' + authUserId).subscribed(() => {
			dispatch(subscribedChannel());
		});

	return privateChannels;
};

export const ChannelsProvider = ({ children, authUser, authToken }) => {
	const dispatch = useDispatch();
	const [channels, setChannels] = useState(undefined);
	const sessionID = useSelector(sessionIdSelector);

	useEffect(() => {
		if (sessionID) {
			privateChannels = null;
		}
	}, [sessionID]);

	useEffect(() => {
		setTimeout(() => {
			if (newAuthToken !== authToken) {
				newChannels = null;
				newAuthToken = authToken;
			}

			const channels = getChannels(pusherConfig, authToken, dispatch, setChannels);

			setChannels(channels);
		}, 0);
	}, [authUser, authToken]);

	return <ChannelsContext.Provider value={channels}>{children}</ChannelsContext.Provider>;
};
