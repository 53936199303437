import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';
import { getAuthData } from '@fh-components/fh-js-api-core/store/slices/userSlice';
import { useSelector } from 'react-redux';
import { Loader, Button, Input, CountrySelect } from '@/components';
import { countries } from '@/lib/config';
import clsx from 'clsx';

import styles from './phoneForm.module.scss';

const PHONE_REPLACE_MASK = /[^\d]+/g;

const FormError = dynamic(() => import('../../../components/formError'), {
	loading: () => <Loader />,
});

const PhoneForm = ({ onPhoneSubmit }) => {
	const { codeSending, error } = useSelector(getAuthData);

	const phoneRef = useRef(null);

	const [state, setState] = useState({
		phone: '',
		phoneFull: '',
		country: 'by',
		inputFocused: false,
	});

	const errors = useMemo(() => error?.errors ?? {}, [error?.errors]);
	const phone = useMemo(() => state.phone.replace(PHONE_REPLACE_MASK, ''), [state.phone]);
	const selectedCountry = state.country;
	const maskNumbers = countries[selectedCountry].mask.replace(PHONE_REPLACE_MASK, '');

	const [phoneError, setPhoneError] = useState(null);
	const [submitError, setSubmitError] = useState(errors[0]);

	useEffect(() => {
		if (errors && errors.length > 0) {
			setSubmitError(errors[0]);
		}
	}, [errors]);

	useEffect(() => {
		if (errors?.phone) {
			setSubmitError(errors?.phone);
			setPhoneError(null);
		}
	}, [errors?.phone]);

	useEffect(() => {
		setTimeout(() => {
			phoneRef?.current?.focus();

			if (typeof window !== 'undefined' && sessionStorage?.getItem('country')) {
				const country = sessionStorage.getItem('country').toLowerCase();
	
				if (countries[country]) {
					setState(prevState => ({
						...prevState,
						country,
					}));
				}
			}
		}, 0)
	}, []);

	const onSubmit = async e => {
		e.preventDefault();
		const phoneNumbers = phone;

		if (phoneNumbers.length === 0) {
			setPhoneError('Введите номер телефона');
			setSubmitError(null);
			phoneRef.current?.focus();
			return;
		}
		if (phoneNumbers.length < maskNumbers.length) {
			setPhoneError('Недостаточное количество символов');
			setSubmitError(null);
			phoneRef.current?.focus();
			return;
		}

		await onPhoneSubmit({ phone: state.phoneFull });
	};

	const onPhoneChange = useCallback(
		e => {
			const { value } = e.target;
			const fixedValue = value.replace(/[^\d+]+/g, '');

			if (fixedValue.replace('+', '').length === maskNumbers.length) {
				setPhoneError(null);
			}

			setState(prev => ({ ...prev, phone: fixedValue, phoneFull: value }));
		},
		[maskNumbers.length],
	);

	const onCountryChange = useCallback(country => {
		setState(prev => ({ ...prev, phone: '', country }));
		setPhoneError(null);
	}, []);

	const changeLabelState = focused => {
		setState(prev => ({ ...prev, inputFocused: focused }));
	};

	const inputLabel =
		state.inputFocused || state.phone.length > countries[selectedCountry].code.length
			? 'Номер телефона'
			: countries[selectedCountry].placeholder;

	return (
		<form autoComplete='off' className={styles['phone-form']} onSubmit={onSubmit}>
			<div className={clsx(styles['form-control-wrapper'], { [styles.withError]: submitError })}>
				<div className={styles['form-control']}>
					<CountrySelect selectedCountry={selectedCountry} onCountryChange={onCountryChange} />

					<div>
						<Input
							label={inputLabel}
							name='phone'
							value={state.phone}
							onChange={onPhoneChange}
							changeLabelState={changeLabelState}
							mask={countries[selectedCountry].mask}
							error={phoneError}
							ref={phoneRef}
							classNames={{
								subText: phoneError && phoneError.length > 25 && styles.errorText,
								labelClass: styles.label,
							}}
						/>
					</div>
				</div>

				<FormError error={submitError} />
			</div>

			<Button
				type='primary'
				action='submit'
				classNames={{ general: styles['submit-btn'] }}
				disabled={codeSending || phone.length < maskNumbers.length}
				loading={codeSending}
			>
				Получить код по SMS
			</Button>
		</form>
	);
};

export default PhoneForm;
