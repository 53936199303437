const SVG = ({ fill = 'none', width = '24', height = '24', viewBox = '0 0 24 24', className }) => (
	<svg
		width={width}
		height={height}
		fill={fill}
		viewBox={viewBox}
		className={className}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			stroke='currentColor'
			strokeWidth='1.3'
			strokeLinecap='round'
			d='M5.65 7.35h12.7M7.65 11.35h8.7M10.65 15.35h2.7'
		/>
	</svg>
);

export default SVG;
