import clsx from 'clsx';
import styles from './Text.module.scss';

const Text = ({
	children,
	className,
	size, // size-large(24px) | size-medium(18px) | size-small(16px) | size-x-small(14px)
	mb, // mb-large(48px) | mb-medium(32px) | mb-small(24px) | mb-x-small(16px) | mb-xx-small(12px) | mb-xxx-small(8) | mb-none(0)
	align, // center | left | right
	textDecoration, // underline | line-through
	...props
}) => {
	const classes = clsx(
		styles.text,
		styles[size],
		styles[textDecoration],
		styles[mb],
		styles[align],
		className,
	);

	return (
		<p className={classes} {...props}>
			{children}
		</p>
	);
};

export default Text;
