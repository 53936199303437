import React, { useState, useRef, useEffect, useCallback } from 'react';
import dynamic from 'next/dynamic';
import { userLoadingInfoSelector } from '@/store';
import { useSelector } from 'react-redux';
import clsx from 'clsx';
import { ClickOutside, Button, Input, Loader } from '@/components';
import { LoginTimer } from '@/containers/login';

import styles from './codeForm.module.scss';

const FormError = dynamic(() => import('../../../components/formError'), {
	loading: () => <Loader />,
});

const Timer = dynamic(() => import('../../../components/timer'), {
	loading: () => <Loader />,
});

const CodeForm = ({ onCodeSubmit, onCodeResend }) => {
	const { loggingIn, error } = useSelector(userLoadingInfoSelector);
	const seconds = error?.additional?.seconds;
	const [smsBan, setSmsBan] = useState(60);
	const [errors, setErrors] = useState(error?.message ?? '');
	const codeRef = useRef(null);
	const [code, setCode] = useState('');
	const [dropdownVisible, setDropdownVisible] = useState(false);

	useEffect(() => {
		if (error?.errors?.verification_code) {
			setErrors(error.errors.verification_code[0]);
		} else if (error?.errors && error.errors.length) {
			setErrors(error.errors[0]);
		} else {
			setErrors(error?.message ?? '');
		}
	}, [error]);

	useEffect(() => {
		const codeError = errors ?? null;

		if (codeError) {
			codeRef?.current?.focus();
		}
	}, [errors]);

	useEffect(() => {
		if (seconds && seconds >= 0) {
			setSmsBan(seconds);
		}
	}, [seconds]);

	useEffect(() => {
		codeRef?.current?.focus();
	}, []);

	const onSubmit = async e => {
		e.preventDefault();
		await onCodeSubmit({ code });
	};

	const onFieldChange = useCallback(e => {
		const { value } = e.target;
		setCode(value);
		setErrors('');
	}, []);

	const onTimerFinish = useCallback(() => {
		setSmsBan(-1);
	}, []);

	const onCodeResendClick = useCallback(() => {
		setSmsBan(60);
		setCode('');
		onCodeResend();
	}, [onCodeResend]);

	const handleToggleDropdownVisible = useCallback(() => {
		setDropdownVisible(prev => !prev);
	}, []);

	const handleCloseDropdownVisible = useCallback(() => {
		setDropdownVisible(false);
	}, []);

	return (
		<form className={styles['code-form']} autoComplete='off' onSubmit={onSubmit}>
			<div className={styles['form-control']}>
				<Input
					label='Код из SMS'
					name='verification_code'
					value={code}
					onChange={onFieldChange}
					ref={codeRef}
					classNames={{
						labelClass: styles.label,
					}}
				/>
				<FormError error={errors} />
				{smsBan > 0 ? (
					<div className={styles['form-info']}>
						Отправить код еще раз можно будет через{' '}
						<Timer seconds={smsBan} render={LoginTimer} onFinish={onTimerFinish} />
					</div>
				) : (
					<Button
						type='link'
						action='button'
						onClick={onCodeResendClick}
						classNames={{ general: styles['resend-code-btn'] }}
					>
						Отправить код еще раз
					</Button>
				)}
			</div>

			<Button
				type='primary'
				action='submit'
				disabled={loggingIn}
				loading={loggingIn}
				classNames={{ general: styles['submit-btn'] }}
			>
				Войти
			</Button>

			<ClickOutside onClickOutside={handleCloseDropdownVisible}>
				<div className={styles['tip-container']}>
					<Button
						action='button'
						type='link'
						classNames={{ general: styles['tip-btn'] }}
						onClick={handleToggleDropdownVisible}
					>
						Не приходит SMS
					</Button>
					<div className={clsx(styles['tip-dropdown'], { [styles.visible]: dropdownVisible })}>
						<p>
							Проверьте, что: <br />
							- ваш телефон включен и находится в зоне приема сети; <br />
							- вы ввели правильный номер телефона; <br />
							- сообщение не попало в спам, заблокированные (неизвестные); <br />- у вас не стоит
							запрет на прием SMS со стороны оператора.
						</p>
						<p>
							Иногда SMS приходит не сразу. Если SMS нет 20 минут, попробуйте получить код еще раз.
						</p>
					</div>
				</div>
			</ClickOutside>
		</form>
	);
};

export default CodeForm;
