const SVG = ({ fill = 'currentColor', width = '163', height = '14', viewBox = '0 0 163 14' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill || '#292C33'}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M7.14063 0.301291V2.55093H2.62226V5.54376H6.85823V7.7934H2.62226V13.6987H0V0.301291H7.14063Z'
			fill={fill}
		/>
		<path
			d='M18.0654 13.6987L16.8753 10.8867H11.4492L10.2591 13.6987H7.41499L13.2041 0.301291H15.2616L20.8894 13.6987H18.0654ZM12.2763 8.75753H16.1088L14.1925 3.93687L12.2763 8.75753Z'
			fill={fill}
		/>
		<path
			d='M31.0233 1.56671L29.6516 3.35438C29.0734 2.5911 28.3405 2.20947 27.4529 2.20947C26.8075 2.20947 26.3368 2.37016 26.041 2.69154C25.7451 2.99952 25.5972 3.36107 25.5972 3.77618C25.5972 4.13773 25.7384 4.43902 26.0208 4.68006C26.2359 4.88092 26.7738 5.13534 27.6345 5.44333C28.9658 5.89861 29.8735 6.3472 30.3576 6.7891C31.1241 7.47202 31.5074 8.40937 31.5074 9.60115C31.5074 10.9134 31.0703 11.978 30.1962 12.7948C29.3356 13.5983 28.2127 14 26.8276 14C24.8912 14 23.3447 13.1564 22.1882 11.4692L23.9028 9.84218C24.1986 10.4448 24.6088 10.8934 25.1332 11.1879C25.6577 11.4825 26.1889 11.6298 26.7268 11.6298C27.3319 11.6298 27.8295 11.4625 28.2194 11.1277C28.6094 10.7795 28.8044 10.3309 28.8044 9.78192C28.8044 9.31325 28.6296 8.91153 28.28 8.57676C27.9572 8.28216 27.2647 7.95409 26.2023 7.59254C25.2072 7.25777 24.4609 6.86944 23.9633 6.42755C23.2506 5.81157 22.8942 4.98135 22.8942 3.93687C22.8942 2.85222 23.264 1.92826 24.0037 1.16499C24.7433 0.388331 25.8123 0 27.2109 0C28.7305 0 30.0012 0.522238 31.0233 1.56671Z'
			fill={fill}
		/>
		<path
			d='M37.4413 0.301291V5.4835H43.1296V0.301291H45.7519V13.6987H43.1296V7.73314H37.4413V13.6987H34.8191V0.301291H37.4413Z'
			fill={fill}
		/>
		<path d='M49.8333 0.301291H52.4555V13.6987H49.8333V0.301291Z' fill={fill} />
		<path
			d='M57.8862 1.96844C59.2309 0.656145 60.9589 0 63.0702 0C65.1815 0 66.9027 0.656145 68.234 1.96844C69.5788 3.28073 70.2512 4.96126 70.2512 7.01004C70.2512 9.05882 69.5788 10.7394 68.234 12.0517C66.9027 13.3506 65.1815 14 63.0702 14C60.9589 14 59.2309 13.3506 57.8862 12.0517C56.5549 10.7394 55.8892 9.05882 55.8892 7.01004C55.8892 4.96126 56.5549 3.28073 57.8862 1.96844ZM59.8428 10.3443C60.6765 11.2013 61.7523 11.6298 63.0702 11.6298C64.3881 11.6298 65.4639 11.2013 66.2976 10.3443C67.1313 9.47394 67.5482 8.36251 67.5482 7.01004C67.5482 5.65758 67.1246 4.54615 66.2774 3.67575C65.4437 2.80536 64.3746 2.37016 63.0702 2.37016C61.7658 2.37016 60.69 2.80536 59.8428 3.67575C59.009 4.54615 58.5922 5.65758 58.5922 7.01004C58.5922 8.36251 59.009 9.47394 59.8428 10.3443Z'
			fill={fill}
		/>
		<path
			d='M76.2986 13.6987H73.6763V0.301291H75.4918L83.6813 9.2396V0.301291H86.3036V13.6987H84.4881L76.2986 4.74032V13.6987Z'
			fill={fill}
		/>
		<path
			d='M98.7234 0.301291V5.4835H104.412V0.301291H107.034V13.6987H104.412V7.73314H98.7234V13.6987H96.1011V0.301291H98.7234Z'
			fill={fill}
		/>
		<path
			d='M112.467 1.96844C113.812 0.656145 115.54 0 117.651 0C119.762 0 121.483 0.656145 122.815 1.96844C124.159 3.28073 124.832 4.96126 124.832 7.01004C124.832 9.05882 124.159 10.7394 122.815 12.0517C121.483 13.3506 119.762 14 117.651 14C115.54 14 113.812 13.3506 112.467 12.0517C111.135 10.7394 110.47 9.05882 110.47 7.01004C110.47 4.96126 111.135 3.28073 112.467 1.96844ZM114.423 10.3443C115.257 11.2013 116.333 11.6298 117.651 11.6298C118.969 11.6298 120.044 11.2013 120.878 10.3443C121.712 9.47394 122.129 8.36251 122.129 7.01004C122.129 5.65758 121.705 4.54615 120.858 3.67575C120.024 2.80536 118.955 2.37016 117.651 2.37016C116.346 2.37016 115.271 2.80536 114.423 3.67575C113.59 4.54615 113.173 5.65758 113.173 7.01004C113.173 8.36251 113.59 9.47394 114.423 10.3443Z'
			fill={fill}
		/>
		<path
			d='M128.257 0.301291H130.879V8.71736C130.879 9.56098 131.094 10.2238 131.525 10.7059C132.036 11.3219 132.748 11.6298 133.663 11.6298C134.577 11.6298 135.29 11.3219 135.801 10.7059C136.231 10.2238 136.447 9.56098 136.447 8.71736V0.301291H139.069V8.49641C139.069 10.023 138.733 11.2147 138.06 12.0717C137.59 12.6877 136.958 13.1631 136.164 13.4978C135.384 13.8326 134.55 14 133.663 14C132.775 14 131.935 13.8326 131.141 13.4978C130.362 13.1631 129.736 12.6877 129.266 12.0717C128.593 11.2147 128.257 10.023 128.257 8.49641V0.301291Z'
			fill={fill}
		/>
		<path
			d='M151.141 1.56671L149.769 3.35438C149.191 2.5911 148.458 2.20947 147.57 2.20947C146.925 2.20947 146.454 2.37016 146.159 2.69154C145.863 2.99952 145.715 3.36107 145.715 3.77618C145.715 4.13773 145.856 4.43902 146.138 4.68006C146.353 4.88092 146.891 5.13534 147.752 5.44333C149.083 5.89861 149.991 6.3472 150.475 6.7891C151.242 7.47202 151.625 8.40937 151.625 9.60115C151.625 10.9134 151.188 11.978 150.314 12.7948C149.453 13.5983 148.33 14 146.945 14C145.009 14 143.462 13.1564 142.306 11.4692L144.02 9.84218C144.316 10.4448 144.726 10.8934 145.251 11.1879C145.775 11.4825 146.306 11.6298 146.844 11.6298C147.449 11.6298 147.947 11.4625 148.337 11.1277C148.727 10.7795 148.922 10.3309 148.922 9.78192C148.922 9.31325 148.747 8.91153 148.398 8.57676C148.075 8.28216 147.382 7.95409 146.32 7.59254C145.325 7.25777 144.578 6.86944 144.081 6.42755C143.368 5.81157 143.012 4.98135 143.012 3.93687C143.012 2.85222 143.382 1.92826 144.121 1.16499C144.861 0.388331 145.93 0 147.328 0C148.848 0 150.119 0.522238 151.141 1.56671Z'
			fill={fill}
		/>
		<path
			d='M162.4 0.301291V2.55093H157.559V5.54376H162.198V7.7934H157.559V11.4491H162.4V13.6987H154.937V0.301291H162.4Z'
			fill={fill}
		/>
	</svg>
);

export default SVG;
