const SVG = ({ fill = 'currentColor', width = '32', height = '32', viewBox = '0 0 32 32' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M7.4 16.388A5.496 5.496 0 016 15.68V21.5c0 .146.054.24.122.357.024.043.051.09.078.143.1.1.3.2.5.2h1.6l.1.2c.2.6.5 1.1 1 1.4.4.3 1.1.5 1.6.5s1.1-.2 1.6-.5c.4-.4.8-.8 1-1.4l.1-.2h5.2l.1.2c.2.6.5 1.1 1 1.4.4.3 1 .5 1.6.5.6 0 1.2-.2 1.7-.5.4-.4.8-.8 1-1.4l.1-.2h1.5c.2 0 .4-.1.5-.2.1-.1.2-.3.2-.5v-5.3c0-.2-.1-.4-.2-.5l-3.2-3.2c-.1-.1-.3-.2-.5-.2h-4.3c-.2 0-.4.1-.5.2-.1.1-.2.3-.2.5v3.2c0 .2.1.4.2.5a.633.633 0 01.065.079c.05.067.089.121.235.121.2 0 .4-.1.5-.2.1-.1.2-.3.2-.5v-2.5h3.2l2.8 2.8v4.2h-.8l-.1-.2c-.2-.6-.5-1.1-1-1.4-.4-.3-1.1-.5-1.6-.5s-1.1.2-1.6.5c-.4.4-.8.8-1 1.4l-.1.2h-5.1l-.1-.2c-.2-.6-.5-1.1-1-1.4-.4-.3-1.1-.5-1.6-.5s-1.1.2-1.6.5c-.4.4-.8.8-1 1.4l-.1.2h-.8v-4.312zM20.6 20.5c.3-.3.6-.4 1-.4s.7.1 1 .4c.3.3.4.6.4 1s-.1.7-.4 1c-.3.3-.6.4-1 .4s-.7-.1-1-.4c-.3-.3-.4-.6-.4-1s.1-.7.4-1zm-10.6 0c.3-.3.6-.4 1-.4.3 0 .7.1 1 .4.3.3.4.6.4 1s-.1.7-.4 1c-.3.3-.6.4-1 .4s-.7-.1-1-.4c-.3-.3-.4-.6-.4-1s.1-.7.4-1z'
		/>
		<path d='M14.388 9.4H18.4c.2 0 .4-.1.5-.2.1-.1.2-.3.2-.5s-.1-.4-.2-.5c-.1-.1-.3-.2-.5-.2h-4.72c.298.426.539.897.708 1.4z' />
		<path d='M9 5a6.01 6.01 0 00-6 6c0 3.306 2.694 6 6 6s6-2.694 6-6-2.694-6-6-6zm0 1.333A4.657 4.657 0 0113.667 11 4.657 4.657 0 019 15.667 4.657 4.657 0 014.333 11 4.657 4.657 0 019 6.333zm0 1A.667.667 0 008.333 8v3c.027.258.134.4.292.552l1.792 1.802c.26.26.687.26.948 0a.673.673 0 000-.948l-1.698-1.698V8A.667.667 0 009 7.333z' />
	</svg>
);

export default SVG;
