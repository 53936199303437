const SVG = ({ className }) => (
	<svg
		className={className}
		width='20'
		height='20'
		viewBox='0 0 20 20'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M19.7659 17.5231L19.7634 17.5257V17.5193L18.8018 18.4825C17.5582 19.7524 15.7469 20.2748 14.0254 19.8604C12.291 19.3923 10.6422 18.6471 9.14155 17.6533C7.74735 16.7548 6.4553 15.7045 5.28884 14.5212C4.21557 13.4469 3.25136 12.2672 2.41036 10.9999C1.49048 9.63627 0.762386 8.15076 0.246769 6.58564C-0.344319 4.74699 0.145499 2.72945 1.51205 1.37401L2.63811 0.238571C2.95118 -0.0785361 3.4599 -0.0796582 3.77432 0.236028L3.77684 0.238571L7.33219 3.82355C7.64667 4.13924 7.64778 4.6522 7.33471 4.96923L7.33219 4.97177L5.24451 7.07685C4.64549 7.67428 4.57017 8.62366 5.0674 9.30952C5.82248 10.3544 6.65806 11.3377 7.56631 12.2503C8.57893 13.2757 9.67978 14.2086 10.856 15.0379C11.5356 15.5159 12.4569 15.4353 13.0448 14.8466L15.0629 12.7798C15.376 12.4626 15.8847 12.4615 16.1991 12.7772L16.2016 12.7798L19.7633 16.3775C20.0779 16.6931 20.079 17.206 19.7659 17.5231Z'
			fill='#0FB769'
		/>
	</svg>
);

export default SVG;
