import clsx from 'clsx';
import styles from './Title.module.scss';

const Title = ({
	children,
	className,
	mb, // large(48px) | medium(40px) | small(32px) | x-small(24px) | xx-small(16px)
	align, // center | left | right
	component: Component = 'h1',
	invisible,
	success,
	fail,
	dataBrand = null,
	...props
}) => {
	const classes = clsx(
		styles[Component],
		styles[mb],
		styles[align],
		{ [styles.invisible]: invisible },
		{ [styles.success]: success },
		{ [styles.fail]: fail },
		className,
	);

	const otherParams = dataBrand ? { 'data-brand': dataBrand } : {};

	return (
		<Component className={classes} {...props} {...otherParams}>
			{children}
		</Component>
	);
};

export default Title;
