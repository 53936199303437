const SVG = ({ fill = 'currentColor', width = '32', height = '32', viewBox = '0 0 32 32' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M26 16.1L24.3 7h-6.7l-.1.7c-.1.8-.7 1.4-1.4 1.4s-1.3-.6-1.4-1.4V7h-7L6 16h3.6l-1 8.8h14.8l-1-8.7H26zm-15.7 7.2l1.2-11.4-1.4-.1-.4 2.7H7.8l1.1-6h4.5c.4 1.2 1.5 2.1 2.8 2.1 1.3 0 2.4-.9 2.8-2.1h4.2l1.1 6.1h-1.9l-.3-2.9-1.5.2 1.3 11.4H10.3z' />
	</svg>
);

export default SVG;
