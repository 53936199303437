import { Picture, RoutedLink, Link, Button } from '@/components';
import { Block, Container } from '@/containers';
import clsx from 'clsx';

import styles from './OneImage.module.scss';

const OneImage = ({ items, noMargin }) => {
	if (!items || items.length === 0) return null;
	if (items.some(item => !item.image_desktop)) return null;
	// console.log("🚀 ~ OneImage ~ items:", items)

	return (
		<Block noMargin={noMargin}>
			<Container>
				<div className={styles.main}>
					{items.map((item, index) => {
						const {
							url,
							url_text = '',
							title,
							description,
							image_desktop,
							image_mobile,
							title_size,
							description_size,
							img_title = '',
						} = item || {};
						const ComponentTitle = title_size || 'h2';
						const ComponentDescription = description_size || 'h3';

						return (
							<div className={styles.item} key={`${url}-${title}-${index}`}>
								{url && <RoutedLink title={img_title} to={url} component={Link} />}

								<figure className={styles.image}>
									{image_desktop && image_desktop?.x2 && (
										<Picture {...image_desktop} title={img_title} />
									)}
									{image_mobile && image_mobile?.x2 && (
										<Picture {...image_mobile} title={img_title} />
									)}
								</figure>

								{(title || description) && (
									<div className={clsx(styles.content, styles[`${ComponentTitle}-content`])}>
										{title && (
											<ComponentTitle
												className={clsx(styles.title, styles[`${ComponentTitle}-title`])}
											>
												{title}
											</ComponentTitle>
										)}
										{description && (
											<ComponentDescription className={styles.desc}>
												{description}
											</ComponentDescription>
										)}
										{url_text && url_text.length > 0 && (
											<Button classNames={{ general: styles.button }}>{url_text}</Button>
										)}
									</div>
								)}
							</div>
						);
					})}
				</div>
			</Container>
		</Block>
	);
};

export default OneImage;
