const SVG = ({ fill = 'none', width = '25', height = '24', viewBox = '0 0 25 24', className }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		className={className}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			stroke='currentColor'
			strokeWidth='1.3'
			strokeLinecap='round'
			d='M13.272 6.65v4.711M13.306 6l2.058 2.059M13.262 6l-2.059 2.058M16.272 16.36v-4.71M16.306 17.01l2.058-2.058M16.262 17.011l-2.059-2.059'
		/>
	</svg>
);

export default SVG;
