import wordEnd from '@/lib/wordEnd';

const getCorrectName = str => {
	if (str.includes('endword')) {
		const arrayString = str.split(' ');
		const correctArray = [];
		let newStr = str;

		arrayString.forEach((item, ind) => {
			const endWordIndex = item.indexOf('endword');

			if (endWordIndex !== -1) {
				const wordVariants = ` ${arrayString[ind + 1]} ${arrayString[ind + 2]} ${
					arrayString[ind + 3]
				}`;
				const tempStr = item.substr(8) + wordVariants.substr(0, wordVariants.lastIndexOf(')'));
				const splittedString = tempStr.split(',');

				correctArray.push({
					oldVariant: `${item}${wordVariants.substr(0, wordVariants.lastIndexOf(')') + 1)}`,
					newVariant: `${splittedString[0]}${wordEnd(splittedString[0], splittedString.slice(1))}`,
				});
			}
		});

		correctArray.forEach(({ oldVariant, newVariant }) => {
			newStr = newStr.replace(oldVariant, newVariant);
		});

		return newStr;
	}

	return str;
};

export default getCorrectName;
