import { useEffect, useState, memo } from 'react';
import { ActiveLink, Control } from '@/components';
import getLinkProps from '@/lib/getLinkProps';

import styles from './CategoryTreeMobile.module.scss';
import { useSelector } from 'react-redux';
import { favoriteBrandsSelector } from '../../store';
import { sortingByFavBrands } from '../../utils/sorting';

const CategoryTreeMobile = memo(({ sidebar, savedQuery, isSearch = false }) => {
	const [currentSidebar, setCurrentSidebar] = useState([]);
	const favoriteBrands = useSelector(favoriteBrandsSelector);

	useEffect(() => {
		// if (isSearch) {
		//   setCurrentSidebar(sidebar);
		// } else {
		for (let i = 0; i < sidebar.length; i++) {
			const item = sidebar[i];

			if (item.childrens) {
				if (favoriteBrands.length > 0 && item.childrens.length > 0) {
					const sortedByFavBrands = sortingByFavBrands(item.childrens, favoriteBrands);
					setCurrentSidebar(sortedByFavBrands);
				} else {
					setCurrentSidebar(item.childrens);
				}
				break;
			}
		}
		// }
	}, [sidebar]);

	return (
		<div className={styles.main}>
			<div className={styles.wrap}>
				{currentSidebar.map(({ slug, name }) => {
					const [linkHref, linkAs] = getLinkProps({
						type: isSearch ? 'search' : 'catalog',
						value: slug,
					});

					return (
						<ActiveLink
							href={{ pathname: linkHref, query: savedQuery || {} }}
							as={{ pathname: linkAs, query: savedQuery || {} }}
							shallow
							disableActive
							key={slug}
						>
							<Control className={styles.point}>{name}</Control>
						</ActiveLink>
					);
				})}
			</div>
		</div>
	);
});

export default CategoryTreeMobile;
