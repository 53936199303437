export const validateEmail = value => /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(value);
export const validatePhone = value => {
	return value.replace(/[^\d;]/g, '').length >= 11 && value.indexOf('_') === -1;
};
export const clearErrors = (errors, scheme) => {
	const newErrors = { ...errors };
	scheme.forEach(item => {
		delete newErrors?.[item.field];
	});

	return newErrors;
};

const validateRequired = value => !!value;

export default {
	validatePhone,
	validateEmail,
	clearErrors,
	validateRequired,
};
