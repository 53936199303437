const SVG = () => (
	<svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9.9 13.5H8.1V11.7H9.9V13.5ZM9.9 9.9H8.1V4.5H9.9V9.9Z'
			fill='#EA5656'
		/>
	</svg>
);

export default SVG;
