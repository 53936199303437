import styled, { css } from 'styled-components';
import { maxWidth, rgba, scrollBar } from '@/assets/styles/mixins.css.js';
import theme from '@/assets/styles/theme';
import vars from '@/assets/styles/vars';

const StyledMobileNav = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	overflow: hidden;
	pointer-events: none;
	z-index: 9997;
	visibility: hidden;
	opacity: 0;
	cursor: pointer;
	background: ${rgba(theme.bg.accent, 0.8)};
	transition: visibility 0s 0.25s, opacity 0.25s ease;
	-ms-scroll-chaining: none;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
	user-select: none;

	${maxWidth(vars.breakpoints.tablet + 1)`
    ${props =>
			props.visible &&
			css`
				opacity: 1;
				visibility: visible;
				pointer-events: all;
				transition: none;
				transition: visibility 0s, opacity 0.25s ease;

				${StyledMobileNav.Menu} {
					opacity: 1;
					transform: none;
					transition: transform 0.25s ease-out, opacity 0.25s linear;
				}
			`}
  `}
`;

StyledMobileNav.Menu = styled.div`
	position: relative;
	height: 100%;
	min-width: 250px;
	width: 100%;
	max-width: 500px;
	padding-top: 58px;
	margin-right: auto;
	background: ${theme.bg.default};
	opacity: 0;
	overflow: hidden;
	cursor: default;
	transform: translateX(-100%);
	transition: transform 0.25s ease-in, opacity 0.25s linear;

	${maxWidth(vars.breakpoints.lMobile)`
    padding-top: 56px;

    ${props =>
			props.hasBanner &&
			css`
				padding-top: 114px;
			`}
  `}
`;

StyledMobileNav.MenuInner = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
	background: ${theme.bg.default};
`;

StyledMobileNav.Level = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	width: 100%;
	height: 100%;
	color: ${theme.text.accent};
	background: ${theme.bg.default};
	box-shadow: 2px 0px 2px 0px ${theme.bg.light};
	overflow: hidden;
	transition: opacity 200ms, transform 200ms;

	&.on-enter {
		opacity: 0;
		transform: translateX(-100%);
	}

	&.on-enter-active,
	&.on-enter-done {
		opacity: 1;
		transform: translateX(0);
	}

	&.on-exit {
		opacity: 1;
		transform: translateX(-100%);
	}

	&.on-exit-active,
	&.on-exit-done {
		opacity: 0;
		transform: translateX(-100%);
	}
`;

StyledMobileNav.LevelInner = styled.div`
	width: 100%;
	height: 100%;
	padding-left: 32px;
	overflow-x: hidden;
	overflow-y: auto;

	${scrollBar({ barColor: theme.bg.insistent, size: '4px', radius: '2px' })}
	-ms-scroll-chaining: none;
	overscroll-behavior: contain;
	-webkit-overflow-scrolling: touch;
	user-select: none;

	${maxWidth(vars.breakpoints.mTablet)`
    padding-left: 20px;
  `}
`;

StyledMobileNav.List = styled.ul`
  min-height: 550px;

  ${maxWidth(vars.breakpoints.mTablet)`
    padding-right: 20px;
  `}

  & > ${StyledMobileNav.Item} {
    &:first-child {
      border-top: none !important;
    }
  }
`;

StyledMobileNav.Item = styled.li`
	cursor: default;
	/* height: 58px; */
	border-bottom: 1px solid ${theme.bg.light};

	${props =>
		props.level &&
		css`
			${StyledMobileNav.Link} {
				font-size: 20px;
				line-height: 130%;
				font-weight: normal;
				font-family: ${vars.fonts.futuraBook};
			}
		`}
`;

StyledMobileNav.Link = styled.a`
  position: relative;
  display: flex;
  align-items: center;
  width: calc(100% + 32px);
  margin-left: -32px;
  padding: 16px 20px 19px 32px;
  height: 100%;
  color: ${theme.text.main};
  font-family: ${vars.fonts.futura};
  font-size: 18px;
  line-height: 130%;
  font-weight: 450;
  text-align: left;

  ${props =>
		props.parent &&
		css`
			&::after {
				content: '';
				position: absolute;
				top: 22px;
				right: 20px;
				width: 7px;
				height: 7px;
				border-top: 1px solid ${theme.text.secondary};
				border-right: 1px solid ${theme.text.secondary};
				transform: rotate(45deg);
			}
		`}

  ${props =>
		props.active &&
		css`
			font-weight: 500;
		`}

    ${({ styledView }) => {
			switch (styledView) {
				case 'premium':
					return css`
						font-family: ${vars.fonts.futura};
						font-weight: 500;
						font-size: 16px;
						letter-spacing: 0.12em;
						text-transform: uppercase;

						color: ${theme.bg.active};
					`;

				case 'sale':
					return css`
						font-family: ${vars.fonts.futura};
						font-weight: 500;
						font-size: 16px;
						letter-spacing: 0.12em;
						text-transform: uppercase;

						color: ${theme.warn.default};
					`;
				default:
					break;
			}
		}}

  ${maxWidth(vars.breakpoints.mTablet)`
    width: calc(100% + 20px);
    margin-left: -20px;
    padding-left: 20px;
  `}
`;

StyledMobileNav.SubList = styled.ul`
	padding-top: 25px;
	padding-bottom: 25px;
`;

StyledMobileNav.SubLink = styled.a`
	display: block;
	padding: 5px 0 6px;
	font-size: 18px;
	color: ${theme.text.secondary};

	${props =>
		props.active &&
		css`
			cursor: default;
			font-family: ${vars.fonts.futura};
			font-weight: 500;
		`}
`;

StyledMobileNav.Back = styled.button`
	position: relative;
	display: flex;
	align-items: center;
	color: ${theme.text.main};
	width: 100%;
	padding-top: 18px;
	padding-bottom: 29px;
	font-family: ${vars.fonts.futura};
	font-weight: 450;
	font-size: 18px;
	line-height: 130%;
	text-align: left;
`;

StyledMobileNav.BackIcon = styled.span`
	flex-shrink: 0;
	margin-right: 10px;
	color: ${theme.bg.accent};
`;

StyledMobileNav.ProfileList = styled.ul`
	padding-top: 12px;
	padding-bottom: 12px;
`;

StyledMobileNav.ProfileLink = styled.a`
	display: block;
	width: calc(100% + 32px);
	margin-left: -32px;
	padding: 11px 32px;
	font-size: 20px;
	color: ${theme.text.accent};
	text-align: left;

	${maxWidth(vars.breakpoints.mTablet)`
    width: calc(100% + 20px);
    margin-left: -20px;
    padding-left: 20px;
    padding-right: 20px;
  `}
`;

StyledMobileNav.Divider = styled.div`
	width: calc(100% - 32px);
	height: 1px;
	margin-bottom: 20px;
	background: ${theme.bg.divider};

	${maxWidth(vars.breakpoints.mTablet)`
    width: calc(100% - 20px);
  `}
`;

StyledMobileNav.ContactsOverview = styled.div`
	padding-right: 32px;

	${maxWidth(vars.breakpoints.mTablet)`
    padding-right: 20px;
  `}
`;

export default StyledMobileNav;
