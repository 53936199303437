import clsx from 'clsx';
import styles from './PictureHtmlImage.module.scss';

const PictureHtmlImage = ({ children, isLook, heightStretch }) => {
	return (
		<div
			className={clsx(
				{ [styles.isLook]: isLook, [styles.heightStretch]: heightStretch },
				styles.image,
			)}
		>
			{children}
		</div>
	);
};

export default PictureHtmlImage;
