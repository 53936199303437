import { useMemo } from 'react';
import dynamic from 'next/dynamic';
import { Loader } from '@/components';
import { Block, Container } from '@/containers';
import clsx from 'clsx';

import styles from './HtmlLinksBlock.module.scss';

const Html = dynamic(() => import('../../html'), {
	loading: () => <Loader />,
});

const HtmlLinksBlock = ({
	size: TitleComponent = 'h2',
	title,
	html,
	posTitle = 'center',
	widthText,
	url,
}) => {
	const titleBlock = useMemo(() => {
		return (
			<TitleComponent className={clsx(styles[TitleComponent], styles[posTitle], styles.title)}>
				{title}
			</TitleComponent>
		);
	}, [title]);

	return (
		<Block>
			<Container className={widthText === 'compressed' && styles.container}>
				{url && url.length > 0 ? (
					<a href={url} className={styles.link}>
						{titleBlock}
					</a>
				) : (
					<>{titleBlock}</>
				)}
				<Html html={html} />
			</Container>
		</Block>
	);
};

export default HtmlLinksBlock;
