import clsx from 'clsx';
import styles from './ControlledRadio.module.scss';

const ControlledRadio = ({ name, value, checked, onChange, disabled, children }) => {
	const handleChange = e => {
		const { value } = e.target;
		onChange(value);
	};

	return (
		<label className={styles.radio}>
			<input
				type='radio'
				className={styles.input}
				name={name}
				value={value}
				checked={checked}
				onChange={handleChange}
				disabled={disabled}
			/>
			<span
				className={clsx({
					[styles.title]: true,
					[styles.checked]: checked,
					[styles.focus]: !checked,
					[styles.disabled]: disabled,
					[styles.disabledChecked]: disabled && checked,
					[styles.notDisabledAndChecked]: !disabled & !checked,
				})}
			>
				{children}
			</span>
		</label>
	);
};

export default ControlledRadio;
