const SVG = () => (
	<svg width='20' height='27' viewBox='0 0 20 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10 1.97561C5.5148 1.97561 1.93548 5.55445 1.93548 9.89904C1.93548 12.5392 2.99474 14.8162 4.88431 16.2343C5.30077 16.5468 5.65705 17.0129 5.7935 17.6043C5.90548 18.0896 6.03365 18.681 6.1606 19.3077H9.03226V17.882C9.03226 17.6941 9.08478 17.51 9.18367 17.3515L10.2183 15.6928L7.89691 13.832C7.50564 13.5183 7.4111 12.953 7.67829 12.5246L9.18367 10.1111C9.47071 9.65094 10.0689 9.5154 10.5197 9.80839C10.9706 10.1014 11.1034 10.712 10.8163 11.1722L9.78173 12.8309L12.1031 14.6917C12.4944 15.0053 12.5889 15.5707 12.3217 15.9991L10.9677 18.1698V19.3077H13.8394C13.9663 18.681 14.0945 18.0896 14.2065 17.6043C14.3429 17.0129 14.6992 16.5468 15.1157 16.2343C17.0053 14.8162 18.0645 12.5392 18.0645 9.89904C18.0645 5.55445 14.4852 1.97561 10 1.97561ZM13.4693 21.2833H6.53068C6.67315 22.123 6.78491 22.9183 6.81876 23.4892C6.85438 24.0904 7.29453 24.6291 7.9435 24.7722L8.19637 24.828C9.38403 25.0899 10.616 25.0899 11.8036 24.828L12.0565 24.7722C12.7055 24.6291 13.1456 24.0904 13.1812 23.4892C13.2151 22.9183 13.3268 22.123 13.4693 21.2833ZM0 9.89904C0 4.40056 4.50844 0 10 0C15.4916 0 20 4.40056 20 9.89904C20 13.0914 18.7051 15.9927 16.2622 17.826C16.1508 17.9096 16.1042 17.9976 16.0904 18.0572C15.9377 18.7192 15.7516 19.5954 15.5819 20.4844C15.3461 21.7201 15.1541 22.9187 15.1132 23.6085C15.0228 25.1339 13.9233 26.3817 12.4652 26.7032L12.2124 26.759C10.7553 27.0803 9.24467 27.0803 7.78763 26.759L7.53476 26.7032C6.07666 26.3817 4.97722 25.1339 4.8868 23.6085C4.84591 22.9187 4.6539 21.7201 4.41806 20.4844C4.24839 19.5954 4.06232 18.7192 3.90956 18.0572C3.89581 17.9976 3.84923 17.9096 3.73782 17.826C1.29493 15.9927 0 13.0914 0 9.89904Z'
			fill='#74849C'
		/>
	</svg>
);

export default SVG;
