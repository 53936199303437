import { Block, Container } from '@/containers';
import { Picture, RoutedLink, Link } from '@/components';
import styles from './WidePicture.module.scss';
import generalStyles from '../WidePictures.module.scss';

const WidePicture = props => {
	const { title, description, url_text: linkText, url: linkUrl, image } = props;
	if (!image) return null;

	return (
		<Block styledType='wide-picture'>
			<div>
				<div className={generalStyles['outer-container']}>
					{linkUrl && <RoutedLink to={linkUrl} component={Link} />}

					<div className={generalStyles.image}>{image && <Picture {...image} />}</div>

					{(title || description || linkText) && (
						<Container className={styles['inner-container']}>
							<div className={styles.content}>
								{title && <span className={styles.title}>{title}</span>}
								{description && <p className={styles.description}>{description}</p>}
								{linkText && <span className={styles.text}>{linkText}</span>}
							</div>
						</Container>
					)}
				</div>
			</div>
		</Block>
	);
};

export default WidePicture;
