const SVG = ({ fill = 'none', width = '21', height = '22', viewBox = '0 0 21 22' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M15.188 14.39c2.606-1.43 4.598-2.52 4.766-2.606.546-.288 1.108-1.056 0-1.652-.356-.19-2.28-1.242-4.766-2.607l-3.422 3.44 3.422 3.425z'
			fill='#FFD900'
		/>
		<path
			d='M11.765 10.968L.833 21.983c.254.034.545-.035.885-.223.716-.391 8.309-4.547 13.466-7.373l-3.419-3.419z'
			fill='#F43249'
		/>
		<path
			d='M11.766 10.968l3.422-3.44S2.486.599 1.722.19C1.434.02 1.108-.032.82.02l10.946 10.95z'
			fill='#00EE76'
		/>
		<path
			d='M11.765 10.969L.816.023C.374.123 0 .513 0 1.316V20.69c0 .73.288 1.259.833 1.31L11.765 10.97z'
			fill='#00D3FF'
		/>
	</svg>
);

export default SVG;
