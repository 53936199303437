import getConfig from 'next/config';

const {
	publicRuntimeConfig: { host },
} = getConfig();

export default function getHost(req) {
	if (process.env.NODE_ENV === 'development') {
		return host;
	}

	return req ? `${req.hostname}` : window.location.host;
}
