import { createSelector } from '@reduxjs/toolkit';

export const cartDataSelector = createSelector(
	state => state.cart.data,
	data => data,
);

export const cartLoadingSelector = createSelector(
	state => state.cart.loading,
	loading => loading,
);

export const cartCountSelector = createSelector(
	state => state.cart.count,
	count => count,
);

export const cartTemporaryPromocodeSelector = createSelector(
	state => state.cart.temporaryPromocode,
	temporaryPromocode => temporaryPromocode,
);

export const cartErrorSelector = createSelector(
	state => state.cart.error,
	error => error,
);

export const cartIdsSelector = createSelector(
	state => state.cart.ids,
	ids => ids,
);

export const cartInfoSelector = createSelector(
	cartIdsSelector,
	cartCountSelector,
	cartLoadingSelector,
	(ids, count, loading) => ({ count, ids, loading }),
);

export const cartLoadingDataSelector = createSelector(
	cartDataSelector,
	cartLoadingSelector,
	(data, loading) => ({ data, loading }),
);
