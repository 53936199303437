const SVG = ({ fill, width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg width={width} height={height} viewBox={viewBox}>
		<g filter='url(#m-arrow-left-filter)' fill={fill}>
			<path d='M6 12.314L15.314 3l1.414 1.414-9.314 9.314L6 12.314z' />
			<path d='M6 12.414l9.314 9.314 1.414-1.414L7.414 11 6 12.414z' />
		</g>
		<defs>
			<filter
				id='m-arrow-left-filter'
				x='5'
				y='3'
				width='12.728'
				height='20.728'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
				<feOffset dy='1' />
				<feGaussianBlur stdDeviation='.5' />
				<feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0' />
				<feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
				<feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
			</filter>
		</defs>
	</svg>
);

export default SVG;
