import clsx from 'clsx';
import { useCallback, useState } from 'react';
import styles from './LikeButton.module.scss';

const ICONS = {
	UNACTIVE: '/images/svg/unlike.svg',
	ACTIVE: '/images/svg/like.svg',
	HOVER: '/images/svg/like-hover.svg',
};

const LikeButton = ({
	onClick,
	active = false,
	large = true,
	border,
	loading,
	isCatalog = false,
}) => {
	const { UNACTIVE, ACTIVE, HOVER } = ICONS;
	const [activeIcon, setActiveIcon] = useState(ACTIVE);

	const handleMouseEnter = useCallback(() => {
		setActiveIcon(HOVER);
	}, [HOVER]);

	const handleMouseLeave = useCallback(() => {
		setActiveIcon(ACTIVE);
	}, [ACTIVE]);

	return (
		<button
			className={clsx(
				styles.button,
				large && styles.large,
				border && styles.border,
				isCatalog && styles.catalogLike,
			)}
			type='button'
			disabled={loading}
			onClick={onClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
		>
			<img
				className={clsx(styles.img, large && styles.largeImg)}
				src={active ? activeIcon : UNACTIVE}
				alt='like'
			/>
		</button>
	);
};

export default LikeButton;
