import styled from 'styled-components';
import { maxWidth } from '@/assets/styles/mixins.css';
import vars from '@/assets/styles/vars';

const StyledNotifications = styled.div`
	position: fixed;
	left: 0;
	bottom: 0;
	z-index: 99999;
	width: 100%;
	height: 100%;
	max-height: 100vh;
	overflow: hidden;
	pointer-events: none;
`;

StyledNotifications.List = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  display: grid;
  grid-template-columns: minmax(0, 360px);
  grid-gap: 10px;
  width: 100%;
  padding: 32px;

  ${maxWidth(vars.breakpoints.tablet)`
    padding: 24px calc((100 - ${vars.container.tablet}) / 2 * 1%);
  `}

    ${maxWidth(vars.breakpoints.mTablet)`
     padding: 24px calc((100 - ${vars.container.mTablet}) / 2 * 1%);
   `}

   ${maxWidth(vars.breakpoints.lMobile)`
     padding: 24px calc((100 - ${vars.container.lMobile}) / 2 * 1%);
   `}
`;

export default StyledNotifications;
