const SVG = ({ fill = '#292C33', width = '20', height = '19', viewBox = '0 0 20 19', fullRed }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		{fullRed ? (
			<>
				<path
					d='M16.0001 5.00011C16.0001 8.86611 9.00006 15 9.00006 15C9.00006 15 2.00006 10.8659 2.00006 6.99996C2.00006 3.13396 5.00006 1.00017 9.00006 3.49996C12.5001 2.50017 16.0001 1.13412 16.0001 5.00011Z'
					fill='#EA5656'
				/>
				<path
					d='M9.02712 2.6084C9.28962 2.3459 9.55212 2.0834 9.90212 1.8209C11.5646 0.508398 13.9271 0.595898 15.4146 2.0834C16.3771 2.9584 16.9021 4.0959 16.9896 5.4084C17.0771 6.8084 16.7271 8.1209 15.7646 9.1709C15.2396 9.7834 14.7146 10.3084 14.1896 10.9209C13.4896 11.7084 12.7896 12.4959 12.0896 13.1959C11.3021 14.0709 10.4271 14.9459 9.63962 15.8209C9.28962 16.1709 8.76462 16.1709 8.41462 15.8209C7.36462 14.6834 6.31462 13.5459 5.26462 12.4084C4.30212 11.3584 3.25212 10.2209 2.28962 9.1709C1.58962 8.3834 1.15212 7.4209 1.06462 6.4584C0.889619 5.4084 1.06462 4.4459 1.50212 3.4834C2.20212 2.0834 3.25212 1.1209 4.82712 0.858398C6.31462 0.595898 7.53962 1.1209 8.58962 2.1709C8.76462 2.3459 8.85212 2.4334 9.02712 2.6084ZM9.02712 13.8084C9.72712 13.1084 10.3396 12.4084 10.9521 11.7959C12.1771 10.4834 13.3146 9.2584 14.5396 7.9459C15.5896 6.8084 15.6771 4.9709 14.7146 3.7459C13.6646 2.4334 11.9146 2.3459 10.6896 3.4834C10.3396 3.8334 10.0771 4.1834 9.72712 4.5334C9.28962 4.9709 8.76462 4.9709 8.32712 4.5334C7.98076 4.09 7.73469 3.85077 7.45212 3.4834C6.57712 2.5209 5.08962 2.3459 4.03962 3.1334C3.25212 3.6584 2.81462 4.4459 2.72712 5.4084C2.63962 6.3709 2.90212 7.2459 3.51462 7.9459C4.38962 8.9084 5.35212 9.9584 6.31462 10.9209C7.18962 11.8834 8.06462 12.8459 9.02712 13.8084Z'
					fill={fill}
				/>
			</>
		) : (
			<path
				d='M10.03 2.838c.3-.3.6-.6 1-.9 1.9-1.5 4.6-1.4 6.3.3 1.1 1 1.7 2.3 1.8 3.8.1 1.6-.3 3.1-1.4 4.3-.6.7-1.2 1.3-1.8 2-.8.9-1.6 1.8-2.4 2.6-.9 1-1.9 2-2.8 3-.4.4-1 .4-1.4 0l-3.6-3.9c-1.1-1.2-2.3-2.5-3.4-3.7-.8-.9-1.3-2-1.4-3.1-.2-1.2 0-2.3.5-3.4.8-1.6 2-2.7 3.8-3 1.7-.3 3.1.3 4.3 1.5l.5.5zm0 12.8c.8-.8 1.5-1.6 2.2-2.3 1.4-1.5 2.7-2.9 4.1-4.4 1.2-1.3 1.3-3.4.2-4.8-1.2-1.5-3.2-1.6-4.6-.3-.4.4-.7.8-1.1 1.2-.5.5-1.1.5-1.6 0-.395-.507-.676-.78-1-1.2-1-1.1-2.7-1.3-3.9-.4-.9.6-1.4 1.5-1.5 2.6-.1 1.1.2 2.1.9 2.9 1 1.1 2.1 2.3 3.2 3.4 1 1.1 2 2.2 3.1 3.3z'
				fill={fill}
			/>
		)}
	</svg>
);

export default SVG;
