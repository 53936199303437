const SVG = ({ fill = 'currentColor', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg width={width} height={height} viewBox={viewBox}>
		<g filter='url(#m-arrow-right-filter)' fill={fill}>
			<path d='M18 12.314L8.686 3 7.272 4.414l9.314 9.314L18 12.314z' />
			<path d='M18 12.414l-9.314 9.314-1.414-1.414L16.586 11 18 12.414z' />
		</g>
		<defs>
			<filter
				id='m-arrow-right-filter'
				x='6.271'
				y='3'
				width='12.728'
				height='20.728'
				filterUnits='userSpaceOnUse'
				colorInterpolationFilters='sRGB'
			>
				<feFlood floodOpacity='0' result='BackgroundImageFix' />
				<feColorMatrix in='SourceAlpha' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' />
				<feOffset dy='1' />
				<feGaussianBlur stdDeviation='.5' />
				<feColorMatrix values='0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.6 0' />
				<feBlend in2='BackgroundImageFix' result='effect1_dropShadow' />
				<feBlend in='SourceGraphic' in2='effect1_dropShadow' result='shape' />
			</filter>
		</defs>
	</svg>
);

export default SVG;
