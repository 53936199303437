const SVG = ({
	fill = 'currentColor',
	width = '11',
	height = '11',
	viewBox = '0 0 11 11',
	className,
	onClick,
}) => (
	<svg width='27' height='27' viewBox='0 0 27 27' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M13.4987 7.04427C14.2091 7.04427 14.7904 7.62552 14.7904 8.33594V13.5026C14.7904 14.213 14.2091 14.7943 13.4987 14.7943C12.7883 14.7943 12.207 14.213 12.207 13.5026V8.33594C12.207 7.62552 12.7883 7.04427 13.4987 7.04427ZM13.4858 0.585938C6.35578 0.585938 0.582031 6.3726 0.582031 13.5026C0.582031 20.6326 6.35578 26.4193 13.4858 26.4193C20.6287 26.4193 26.4154 20.6326 26.4154 13.5026C26.4154 6.3726 20.6287 0.585938 13.4858 0.585938ZM13.4987 23.8359C7.78953 23.8359 3.16536 19.2118 3.16536 13.5026C3.16536 7.79344 7.78953 3.16927 13.4987 3.16927C19.2079 3.16927 23.832 7.79344 23.832 13.5026C23.832 19.2118 19.2079 23.8359 13.4987 23.8359ZM14.7904 19.9609H12.207V17.3776H14.7904V19.9609Z'
			fill='#0FB769'
		/>
	</svg>
);

export default SVG;
