const SVG = ({ fill = 'currentColor', width = '32', height = '32', viewBox = '0 0 32 32' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M12.7455 10.3272C12.0678 9.94777 11.3779 10.1839 11.0926 10.5567L10.4959 11.3037C10.1927 11.6832 9.62621 11.6327 9.62621 11.6327C5.4919 10.5753 4.38631 6.39076 4.38631 6.39076C4.38631 6.39076 4.33556 5.82424 4.71415 5.52042L5.46036 4.92338C5.83346 4.63681 6.06939 3.9469 5.68943 3.26893C4.67437 1.49507 3.99264 0.88344 3.6456 0.413772C3.28072 -0.0280348 2.73204 -0.127541 2.16142 0.170977H2.14907C0.962551 0.842311 -0.336448 2.09874 0.0791767 3.39232C0.788346 4.75621 2.09146 9.10396 6.24497 12.389C8.1969 13.9426 11.286 15.5347 12.5973 15.9022L12.6097 15.9208C13.9018 16.3374 15.1583 15.0319 15.829 13.8497V13.8404C16.1267 13.2686 16.0279 12.7273 15.5931 12.3731C14.8222 11.646 13.659 10.8433 12.7455 10.3272Z'
			fill={fill}
		/>
	</svg>
);

export default SVG;
