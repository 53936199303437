import clsx from 'clsx';
import { memo } from 'react';
import styles from './Burger.module.scss';

const Burger = memo(({ open, onClick, children }) => (
	<div
		className={clsx(styles.burger, { [styles.open]: open })}
		onClick={onClick}
		role='button'
		tabIndex={0}
	>
		<div className={styles.box}>
			<div className={styles.line} />
		</div>

		{children}
	</div>
));

export default Burger;
