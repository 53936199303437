import { useEffect, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { useSelector, useDispatch } from 'react-redux';
import {
	addedFavoriteBrand,
	removeFavoriteBrand,
} from '@fh-components/fh-js-api-core/store/slices/userSlice';
import { favoriteBrandsSelector } from '@/store';
import { Block } from '@/containers';
import { Loader, Button, FullWidthSlider } from '@/components';
import Cookie from 'js-cookie';
import clsx from 'clsx';

import styles from './BrandInfo.module.scss';

const Html = dynamic(() => import('../../html'), {
	loading: () => <Loader />,
});

const BrandBlock = ({
	data,
	onClickLink,
	changeFavorites,
	imageMobileVisible = false,
	isFavorite = false,
}) => (
	<div className={styles.main}>
		<div className={styles.mainText}>
			<div className={styles.textBlock}>
				<div className={styles.title}>{data.title}</div>
				<div className={styles.text}>
					<Html html={data.text} />
				</div>
				{data.button === 'link' ? (
					<div className={styles.buttonBlock}>
						<Button
							type='defaultBlack'
							classNames={{ general: styles.button }}
							onClick={() => {
								onClickLink(data.link_url);
							}}
						>
							{data.link_text}
						</Button>
					</div>
				) : data.button !== null && (
					<div className={styles.buttonBlock}>
						<Button
							type={isFavorite ? 'primaryBlack' : 'defaultBlack'}
							classNames={{ general: styles.button }}
							onClick={() => {
								changeFavorites(data.brand_subscription[0].value);
							}}
						>
							{isFavorite ? 'Добавлен в любимые бренды' : 'Добавить в любимые бренды'}
						</Button>
					</div>
				)}
			</div>
		</div>
		<div className={clsx(styles.imageBlock, imageMobileVisible && styles.imageBlockVisible)}>
			<img
				title={data.image?.name}
				key={data.image?.uid}
				alt={data.image?.name}
				src={data.image?.url}
			/>
		</div>
	</div>
);

const BrandInfo = ({ blocks, loading: loadingProducts }) => {
	const router = useRouter();
	const dispatch = useDispatch();
	const [loading, setLoading] = useState(true);
	const favoriteBrands = useSelector(favoriteBrandsSelector);
	const mindboxDeviceUUID = Cookie.get('mindboxDeviceUUID') ?? '';

	const onClickLink = useCallback(url => {
		setLoading(true);
		router.push(url);
	}, []);

	const addedToFavorites = useCallback(id => {
		dispatch(
			addedFavoriteBrand({
				params: {
					brand_id: id,
					mindboxDeviceUUID,
				},
				onSuccess: () => { },
				onError: () => { },
			}),
		);
	}, []);

	const deleteFromFavorites = useCallback(id => {
		dispatch(
			removeFavoriteBrand({
				params: {
					brand_id: id,
					mindboxDeviceUUID,
				},
				onSuccess: () => { },
				onError: () => { },
			}),
		);
	}, []);

	useEffect(() => {
		if (!loadingProducts) {
			setTimeout(() => {
				setLoading(false);
			}, 250);
		}
	}, [router, loadingProducts]);

	useEffect(() => {
		if (loadingProducts) {
			setLoading(true);
		}
	}, [loadingProducts]);


	if (loading) {
		return (
			<Block className={clsx(styles.mainBlock, styles.loader)}>
				<Loader />
			</Block>
		);
	}

	return (
		<Block className={styles.mainBlock}>
			{blocks.length > 1 ? (
				<FullWidthSlider
					bigMargin
					widthLimit='100%'
					isBrandDescription
					isBrandDescriptionLoading={loading}
					isCarousel
					fullWidth
					noSlideWidth
					pagination={{
						el: '.swiper-pagination',
						type: 'bullets',
						clickable: true,
					}}
				>
					{blocks.map((block, idx) => {
						const isFavorite =
						block.button === 'subscription' &&
						favoriteBrands.includes(block.brand_subscription[0].value);						
						return (
							<div key={block?.image?.uid || idx}>
								<BrandBlock
									key={block?.image?.uid || idx}
									data={block}
									isFavorite={isFavorite}
									onClickLink={onClickLink}
									changeFavorites={isFavorite ? deleteFromFavorites : addedToFavorites}
									imageMobileVisible={block.mobile_visible == 'true'}
								/>
							</div>
						);
					})}
				</FullWidthSlider>
			) : (
				<BrandBlock
					data={blocks[0]}
					isFavorite={
						blocks[0].button === 'subscription' &&
						favoriteBrands.includes(blocks[0].brand_subscription[0].value)
					}
					onClickLink={onClickLink}
					addedToFavorites={addedToFavorites}
					changeFavorites={
						blocks[0].button === 'subscription' &&
							favoriteBrands.includes(blocks[0].brand_subscription[0].value)
							? deleteFromFavorites
							: addedToFavorites
					}
					imageMobileVisible={blocks[0].mobile_visible == 'true'}
				/>
			)}
		</Block>
	);
};

export default BrandInfo;
