const breakpoints = {
	xsMobile: 325,
	sMobile: 429,
	mobile: 500,
	lMobile: 650,
	mTablet: 767,
	tablet: 1023,
	sDesktop: 1279,
	xmDesktop: 1365,
	mDesktop: 1439,
	desktop: 1919,
};

const vars = {
	// Media
	breakpoints,
	// Fonts
	fonts: {
		futura: "'Futura PT', Arial, sans-serif",
		futuraBook: "'Futura PT Book', Arial, sans-serif",
		futuraDemi: "'Futura PT Demi', Arial, sans-serif",
	},
	container: {
		default: 92.53,
		tablet: 93.75,
		mTablet: 91.66,
		lMobile: 89.3,
	},
	sideOffset: {
		default: 3.735,
		tablet: 3.125,
		mTablet: 4.17,
		lMobile: 5.35,
	},
	// Other
	maxContentWidth: 1264,
	maxTwoColumnWidth: 1600,
};

export default vars;
