const SVG = ({ fill = 'currentColor', width = '20', height = '12', viewBox = '0 0 20 12' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M18.6356 0.780506C18.7656 0.338219 18.6356 0 18.0118 0H15.9325C15.4127 0 15.1528 0.286186 15.0229 0.598388C15.0229 0.598388 13.9572 3.20008 12.4497 4.86515C11.9559 5.35948 11.748 5.51558 11.4881 5.51558C11.3581 5.51558 11.1762 5.35948 11.1762 4.91719V0.780506C11.1762 0.260169 11.0202 0 10.5784 0H7.27751C6.93962 0 6.75768 0.234152 6.75768 0.468304C6.75768 0.962624 7.51143 1.09271 7.5894 2.49762V5.56761C7.5894 6.24405 7.45944 6.37413 7.19953 6.37413C6.49777 6.37413 4.78236 3.77245 3.74272 0.806523C3.53479 0.234152 3.32686 0 2.80704 0H0.727751C0.129955 0 0 0.286186 0 0.598388C0 1.14474 0.701759 3.90253 3.30087 7.51888C5.01628 9.99048 7.45944 11.3434 9.66869 11.3434C10.9942 11.3434 11.1502 11.0572 11.1502 10.5368V8.66362C11.1502 8.06523 11.2801 7.93515 11.696 7.93515C12.0079 7.93515 12.5277 8.09125 13.7753 9.28802C15.1788 10.6929 15.4127 11.3434 16.2184 11.3434H18.2977C18.8955 11.3434 19.1814 11.0572 19.0255 10.4588C18.8435 9.86039 18.1678 9.02785 17.2581 8.0132C16.7643 7.44082 16.0365 6.81642 15.8286 6.50422C15.5167 6.11396 15.5947 5.93185 15.8286 5.56761C15.8286 5.56761 18.3757 1.97728 18.6356 0.780506Z'
			fill={fill}
		/>
	</svg>
);

export default SVG;
