const SVG = () => (
	<svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M3.00459 6.24451H17.8533H19.2403H25.9939C26.913 6.24451 27.6134 6.9481 27.6134 7.82814V21.4854C27.6134 22.3654 26.913 23.0709 25.9939 23.0709H19.2403C19.2403 23.0709 18.7292 23.0712 17.8537 23.0712H3.00496C2.08814 23.0712 1.38696 22.3658 1.38696 21.4857V15.3613C1.38696 14.9669 1.38659 13.9522 1.38659 13.9522V7.82851C1.38659 6.9481 2.08777 6.24451 3.00459 6.24451ZM3.00459 4.83398C1.36349 4.83398 0 6.17065 0 7.82814V21.4854C0 23.1425 1.36386 24.4791 3.00459 24.4791H25.9939C27.6365 24.4791 29 23.1425 29 21.4854V7.82851C29 6.17102 27.6361 4.83436 25.9939 4.83436L3.00459 4.83398Z'
			fill='#74849C'
		/>
		<path
			d='M22.9557 8.45898C22.2344 8.45898 21.5864 8.777 21.1432 9.27974C20.7001 8.777 20.052 8.45898 19.3307 8.45898C17.9982 8.45898 16.9141 9.54309 16.9141 10.8757C16.9141 12.2082 17.9982 13.2923 19.3307 13.2923C20.052 13.2923 20.7001 12.9743 21.1432 12.4716C21.5864 12.9743 22.2344 13.2923 22.9557 13.2923C24.2883 13.2923 25.3724 12.2082 25.3724 10.8757C25.3724 9.54309 24.2883 8.45898 22.9557 8.45898ZM19.3307 12.084C18.6645 12.084 18.1224 11.5419 18.1224 10.8757C18.1224 10.2094 18.6645 9.66732 19.3307 9.66732C19.997 9.66732 20.5391 10.2094 20.5391 10.8757C20.5391 11.5419 19.997 12.084 19.3307 12.084ZM22.9557 12.084C22.2895 12.084 21.7474 11.5419 21.7474 10.8757C21.7474 10.2094 22.2895 9.66732 22.9557 9.66732C23.622 9.66732 24.1641 10.2094 24.1641 10.8757C24.1641 11.5419 23.622 12.084 22.9557 12.084Z'
			fill='#74849C'
		/>
		<path
			d='M7.65278 8.45898H5.63889C4.5284 8.45898 3.625 9.38828 3.625 10.5304V11.2209C3.625 12.363 4.5284 13.2923 5.63889 13.2923H7.65278C8.76327 13.2923 9.66667 12.363 9.66667 11.2209V10.5304C9.66667 9.38828 8.76327 8.45898 7.65278 8.45898ZM8.32407 11.2209C8.32407 11.6016 8.02291 11.9114 7.65278 11.9114H5.63889C5.26875 11.9114 4.96759 11.6016 4.96759 11.2209V10.5304C4.96759 10.1497 5.26875 9.83994 5.63889 9.83994H7.65278C8.02291 9.83994 8.32407 10.1497 8.32407 10.5304V11.2209Z'
			fill='#74849C'
		/>
		<line
			x1='4.275'
			y1='19.891'
			x2='7.20417'
			y2='19.891'
			stroke='#74849C'
			strokeWidth='1.3'
			strokeLinecap='round'
		/>
		<line
			x1='10.3141'
			y1='19.891'
			x2='13.2432'
			y2='19.891'
			stroke='#74849C'
			strokeWidth='1.3'
			strokeLinecap='round'
		/>
		<line
			x1='16.3609'
			y1='19.891'
			x2='19.2901'
			y2='19.891'
			stroke='#74849C'
			strokeWidth='1.3'
			strokeLinecap='round'
		/>
		<line
			x1='22.4'
			y1='19.891'
			x2='25.3292'
			y2='19.891'
			stroke='#74849C'
			strokeWidth='1.3'
			strokeLinecap='round'
		/>
	</svg>
);

export default SVG;
