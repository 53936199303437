import { changeRedirectUrl } from '@fh-components/fh-js-api-core/store/slices/userSlice';
import { Children, cloneElement } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import getLinkProps from '@/lib/getLinkProps';
import { ActiveLink } from '@/components';

export default function LoginLink({ children }) {
	const router = useRouter();
	const href = router.pathname;
	const asPath = router.asPath.split('?')[0];
	const { query } = router;

	const dispatch = useDispatch();
	const [loginHref, loginAs] = getLinkProps({ type: 'login' });

	const onClick = () => {
		const queryNext = {};

		Object.keys(query).forEach(key => {
			if (href.indexOf(`[${key}]`) === -1 && href.indexOf(`[...${key}]`) === -1) {
				queryNext[key] = query[key];
			}
		});

		dispatch(changeRedirectUrl({ href, asPath, query: queryNext }));
	};

	return (
		<ActiveLink href={loginHref} as={loginAs}>
			{Children.map(children, child => {
				return cloneElement(child, { onClick });
			})}
		</ActiveLink>
	);
}
