import { ActiveLink, Control, LogoutModal } from '@/components';
import styles from './UserNavItem.module.scss';

const UserNavItem = ({ link }) => {
	const { href, as, title, isLogout = false } = link;

	return isLogout ? (
		<Control tag='button' type='button' className={styles['control-link']}>
			<LogoutModal />
		</Control>
	) : (
		<ActiveLink href={href} as={as}>
			<Control className={styles['control-link']}>{title}</Control>
		</ActiveLink>
	);
};

export default UserNavItem;
