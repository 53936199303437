const SVG = ({ fill = '#292C33', width = '20', height = '19', viewBox = '0 0 20 19' }) => (
	<svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M11.0001 2.1001C9.39847 2.1001 8.1001 3.39847 8.1001 5.0001V5.1001H5.0001H4.0001C3.50304 5.1001 3.1001 5.50304 3.1001 6.0001C3.1001 6.49715 3.50304 6.9001 4.0001 6.9001H5.0001H5.60962L6.43377 19.1529L6.43384 19.154C6.51509 20.4447 7.58562 21.4501 8.879 21.4501H15.1212C16.4146 21.4501 17.4851 20.4447 17.5664 19.154L17.5664 19.1529L18.3906 6.9001H19.0001H20.0001C20.4972 6.9001 20.9001 6.49715 20.9001 6.0001C20.9001 5.50304 20.4972 5.1001 20.0001 5.1001H19.0001H15.9001V5.0001C15.9001 3.39847 14.6017 2.1001 13.0001 2.1001H11.0001ZM14.1001 5.1001H9.9001V5.0001C9.9001 4.39258 10.3926 3.9001 11.0001 3.9001H13.0001C13.6076 3.9001 14.1001 4.39258 14.1001 5.0001V5.1001ZM10.5993 9.96156C10.578 9.46496 10.1582 9.07964 9.66156 9.10092C9.16496 9.12221 8.77964 9.54203 8.80092 10.0386L9.10092 17.0386C9.12221 17.5352 9.54203 17.9206 10.0386 17.8993C10.5352 17.878 10.9206 17.4582 10.8993 16.9616L10.5993 9.96156ZM15.1993 10.0386C15.2206 9.54203 14.8352 9.12221 14.3386 9.10092C13.842 9.07964 13.4222 9.46496 13.4009 9.96156L13.1009 16.9616C13.0796 17.4582 13.465 17.878 13.9616 17.8993C14.4582 17.9206 14.878 17.5352 14.8993 17.0386L15.1993 10.0386Z'
			fill='#CCCDD0'
		/>
	</svg>
);

export default SVG;
