import YouTube from 'react-youtube';
import { Block, Container } from '@/containers';
import useWindowSize from '@/lib/hooks/useWindowSize';
import styles from './ShortsYoutubePlayer.module.scss';

const ShortsYoutubePlayer = ({ id }) => {
	if (id === null) return null;
	const windowSize = useWindowSize();

	return (
		<Block>
			<Container>
				<div className={styles.shorts}>
					<YouTube
						videoId={id}
						containerClassName={styles['video-wrapper']}
						opts={{ height: windowSize.height - 60 - 0.1 * windowSize.height, width: '100%' }}
					/>
				</div>
			</Container>
		</Block>
	);
};

export default ShortsYoutubePlayer;
