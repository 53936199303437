const getLinkProps = ({ type, value: valueFromProps, title: propsTitle }) => {
	let href = '';
	let as = '';
	let title = '';

	let value;

	if (typeof valueFromProps === 'string') {
		value = valueFromProps?.startsWith('/') ? valueFromProps?.slice(1) : valueFromProps;
	} else {
		value = valueFromProps;
	}

	switch (type) {
		case 'home':
			href = '/';
			as = '/';
			break;
		case 'wishlist':
			href = '/wishlist';
			as = '/wishlist';
			title = 'Избранное';
			break;
		case 'cart':
			href = '/cart';
			as = '/cart';
			title = 'Корзина';
			break;
		case 'catalog':
			href = value ? '/[...typeSlug]' : '/';
			as = `/${value}`;
			title = propsTitle || '';
			break;
		case 'product':
			href = '/product/[productId]';
			as = `/product/${value}`;
			break;
		case 'login':
			href = '/login';
			as = '/login';
			title = 'Авторизация';
			break;
		case 'shops-map':
		case 'shops':
			href = '/shops';
			as = '/shops';
			title = 'Адреса магазинов';
			break;
		case 'shops-list':
			href = '/store-addresses';
			as = '/store-addresses';
			title = 'Адреса магазинов';
			break;
		case 'checkout':
			href = '/checkout';
			as = '/checkout';
			title = 'Оформление заказа';
			break;
		case 'checkout-contacts':
			href = '/checkout/contacts';
			as = '/checkout/contacts';
			title = 'Личная информация';
			break;
		case 'checkout-delivery':
			href = '/checkout/delivery';
			as = '/checkout/delivery';
			title = 'Способ доставки';
			break;
		case 'checkout-payment':
			href = '/checkout/payment';
			as = '/checkout/payment';
			title = 'Способ оплаты';
			break;
		case 'last-order':
			href = `/checkout/success/${value}`;
			as = `/checkout/success/${value}`;
			break;
		case 'search':
			href = `${value}`;
			as = `${value}`;
			break;
		case 'user-profile':
			href = '/user/profile';
			as = '/user/profile';
			title = 'Мои данные';
			break;
		case 'profile-remove':
			href = '/user/profile/remove';
			as = '/user/profile/remove';
			title = 'Удаление профиля';
			break;
		case 'user-discount':
			href = '/user/discount';
			as = '/user/discount';
			title = 'Персональная скидка';
			break;
		case 'user-newsletters':
			href = '/user/newsletters';
			as = '/user/newsletters';
			title = 'Рассылки';
			break;
		case 'user-returns':
			href = '/user/returns';
			as = '/user/returns';
			title = 'Возврат товара';
			break;
		case 'user-orders':
			href = '/user/orders';
			as = '/user/orders';
			title = 'Заказы';
			break;
		case 'news':
			href = '/news';
			as = '/news';
			title = 'Новости';
			break;
		case 'contacts':
			href = '/contacts';
			as = '/contacts';
			title = 'Наши контакты';
			break;
		case 'news-item':
			href = '/news/[slug]';
			as = `/news/${value}`;
			break;
		case 'special-offers':
			href = '/special-offers';
			as = '/special-offers';
			title = 'Акции';
			break;
		case 'special-offer':
			href = '/special-offers[slug]';
			as = `/special-offers/${value}`;
			break;
		case 'brands':
			href = '/brands';
			as = `/brands`;
			title = 'Бренды';
			break;
		case 'order':
			href = '/user/order/[orderId]';
			as = `/user/order/${value}`;
			break;
		case 'return-policy':
			href = '/return-policy';
			as = `/return-policy`;
			break;
		case 'new-return':
			href = '/new-return';
			as = `/new-return`;
			break;
		case 'success-return':
			href = '/success-return[hash]';
			as = `/success-return/${value}`;
			break;
		default:
			break;
	}

	return [href, as, title];
};

export default getLinkProps;
