import styled, { css } from 'styled-components';
import { maxWidth } from '@/assets/styles/mixins.css.js';
import theme from '@/assets/styles/theme';
import vars from '@/assets/styles/vars';

const StyledTitle = styled.h2`
  display: block;
  font-family: ${vars.fonts.futura};
  font-weight: 500;
  color: ${theme.text.accent};

  ${props =>
		props.invisible &&
		css`
			position: absolute;
			width: 1px;
			height: 1px;
			margin: -1px;
			border: 0;
			padding: 0;
			clip: rect(0 0 0 0);
			clip-path: inset(50%);
			overflow: hidden;
			pointer-events: none;
			white-space: nowrap;
		`};

  ${({ font }) => {
		switch (font) {
			case 'normal': {
				return css`
					font-family: ${vars.fonts.futuraBook};
					font-weight: 400;
				`;
			}
			default:
				break;
		}
	}}

  ${({ size }) => {
		switch (size) {
			case 'x-large':
				return css`
					font-size: 48px;
					line-height: 1.1;

					${maxWidth(vars.breakpoints.lMobile)`
            font-size: 32px;
          `}
				`;
			case 'large':
				return css`
					font-size: 40px;
					line-height: 1.1;

					${maxWidth(vars.breakpoints.lMobile)`
            font-size: 32px;
          `}
				`;
			case 'medium':
				return css`
					font-size: 32px;
					line-height: 1.1;

					${maxWidth(vars.breakpoints.tablet)`
            font-size: 24px;
          `}
				`;
			case 'small':
				return css`
					font-size: 24px;
					line-height: 1.3;

					${maxWidth(vars.breakpoints.lMobile)`
            font-size: 18px;
          `}
				`;
			case 'x-small':
				return css`
					font-size: 20px;
					line-height: 1.3;
				`;
			case 'xx-small':
				return css`
					font-size: 18px;
					line-height: 1.3;
				`;
			case 'custom':
				return css`
					font-size: var(--title-fz, 18px);
				`;
			default:
				return css`
					font-size: 32px;
					line-height: 1.2;
					${maxWidth(vars.breakpoints.sMobile)`
            font-size: 30px;
          `}
				`;
		}
	}}

  ${({ mb }) => {
		switch (mb) {
			case 'large':
				return css`
					margin-bottom: 48px;
					${maxWidth(vars.breakpoints.tablet)`
            margin-bottom: 40px;
          `}
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 32px;
          `}
				`;
			case 'small':
				return css`
					margin-bottom: 32px;
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 24px;
          `}
				`;
			case 'x-small':
				return css`
					margin-bottom: 24px;
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 16px;
          `}
				`;
			case 'xx-small':
				return css`
					margin-bottom: 18px;
				`;
			case 'none':
				return css`
					margin-bottom: 0;
				`;
			case 'custom':
				return css`
					margin-bottom: var(--title-mb, 16px);
				`;
			case 'medium':
				return css`
					margin-bottom: 40px;
					${maxWidth(vars.breakpoints.lMobile)`
            margin-bottom: 32px;
          `}
				`;
			default:
		}
	}}

  ${({ align }) => {
		switch (align) {
			case 'center':
				return css`
					text-align: center;
				`;
			case 'left':
			default:
				return css`
					text-align: left;
				`;
		}
	}}

  ${props =>
		props.special &&
		css`
			letter-spacing: 0.15em;
			text-transform: uppercase;
		`}

  ${props =>
		props.success &&
		css`
			color: ${theme.brand.default};

			&::before {
				content: '';
				display: inline-block;
				vertical-align: top;
				width: 40px;
				height: 14px;
				margin-right: 19px;
				background: 0 0;
				border-bottom: 4px solid currentColor;
				border-left: 4px solid currentColor;
				transform: translate(4px, 14px) rotate(-45deg);

				${maxWidth(vars.breakpoints.lMobile)`
          width: 34px;
          height: 12px;
          border-width: 3px;
          transform: translate(3px,9px) rotate(-45deg);
        `}
			}
		`}

  ${props =>
		props.fail &&
		css`
			position: relative;
			padding-left: 60px;
			color: ${theme.text.accent};

			&::before,
			&::after {
				content: '';
				position: absolute;
				top: 21px;
				left: 4px;
				display: block;
				width: 40px;
				height: 4px;
				background-color: currentColor;

				${maxWidth(vars.breakpoints.lMobile)`
          top: 17px;
          left: -2px;
          width: 30px;
          height: 3px;
        `}
			}

			&::before {
				transform: rotate(45deg);
			}

			&::after {
				transform: rotate(-45deg);
			}

			${maxWidth(vars.breakpoints.lMobile)`
        padding-left: 42px;
      `}
		`}
`;

export default StyledTitle;
