import getLinkProps from '@/lib/getLinkProps';

const getProductInfo = ({
	_id: id,
	price,
	quantity,
	media,
	slug,
	brand,
	name,
	category,
	old_price,
	price_diff_percent,
	brand_id,
}) => {
	const [linkHref, linkAs] = getLinkProps({ type: 'product', value: slug });

	return {
		id,
		price: old_price,
		priceWithDiscount: price,
		name,
		quantity,
		pictureUrl: media && media.length && media[0]?.x2 > 0 ? media[0].x2 : null,
		url: linkHref,
		brand,
		brandId: brand_id,
		hasDiscount: price_diff_percent > 0,
		category: category && category.name ? category.name : null,
		categoryId: category && category.external_menu_item_id ? category.external_menu_item_id : null,
	};
};

export const setCartData = data => {
	// if (data) {
	//   const { total_amount, total_amount_with_discount, products } = data;
	//   window.PopMechanicCartData = {
	//     total: total_amount,
	//     totalWithDiscount: total_amount_with_discount,
	//     products: products.map(product => {
	//       return getProductInfo(product);
	//     }),
	//   };
	//   if (window.PopMechanic && window.PopMechanic.update) {
	//     window.PopMechanic.update();
	//   }
	// }
};

export const addToCartPopMechanic = data => {
	if (data && window.PopMechanicAddToCart && data.products.length > 0) {
		const { products } = data;

		window.PopMechanicAddToCart(getProductInfo(products[products.length - 1]));
	}
};

export const setPageProductData = product => {
	window.PopMechanicPageData = {
		type: 'product',
		product: getProductInfo(product),
	};
};

export const setCategoryData = categoryData => {
	window.PopMechanicPageData = {
		type: 'category',
		category: categoryData,
	};

	if (window.PopMechanic && window.PopMechanic.update) {
		window.PopMechanic.update();
	}
};
