import { memo, useMemo } from 'react';
import { useRouter } from 'next/router';
import { Control, UserNav } from '@/components';
import { ContactsOverview } from '@/containers';
import Icon from '@/assets/icons';
import useWindowSize from '@/hooks/use-window-size';
import UserNavItem from '../userNavItem';
import clsx from 'clsx';

import styles from './UserControl.module.scss';

const UserControl = memo(
	({
		label,
		iconName,
		href,
		as,
		itemsQuantity,
		fullRed,
		loginType,
		onClickMobileProfile,
		phoneType,
	}) => {
		const { isDesktop } = useWindowSize();
		const router = useRouter();

		const disabled = useMemo(() => router.pathname === href, [href, router.pathname]);

		const handleClick = () => {
			if (!disabled) {
				router.push(href);
			}
		};

		if (phoneType) {
			return (
				<div className={clsx(styles.control, styles.controlPhone)}>
					<button data-nav='Phone' type='button' className={clsx(styles.controlTrigger, styles.arrow)}>
						{label}
					</button>
					<div className={styles.controlDropdown}>
						<div className={styles.controlInner}>
							<ContactsOverview />
						</div>
					</div>
				</div>
			);
		}

		if (loginType) {
			return (
				<Control
					tag='div'
					role='button'
					tabIndex={0}
					aria-label={label}
					onClick={!isDesktop ? onClickMobileProfile : null}
					className={clsx(styles.control, styles.controlUser)}
				>
					<span className={styles.controlTrigger}>
						<Icon name={iconName} fill='' />
						<span className={styles.text}>{label}</span>
					</span>

					<div className={clsx(styles.controlDropdown, styles.login)}>
						<div className={styles.controlInner}>
							<UserNav render={UserNavItem} />
						</div>
					</div>
				</Control>
			);
		}

		return (
			<div className={styles.link} onClick={handleClick}>
				<Control
					aria-label={label}
					className={clsx(styles.control, styles.controlUser, disabled && styles.disabled)}
				>
					<span data-nav={label} className={styles.controlTrigger}>
						<Icon name={iconName} fullRed={fullRed} fill='' />
						{itemsQuantity > 0 && <div data-label={iconName} className={styles.quantity}>{itemsQuantity}</div>}
						<span data-label={label} className={styles.text}>{label}</span>
					</span>
				</Control>
			</div>
		);
	},
);

export default UserControl;
