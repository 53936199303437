import { GalleryContainer, GallerySlider, ContentSlider } from '@/components';
import { Block, Container } from '@/containers';
// import ecommerce from '@/seo/Ecommerce';

const Gallery = ({ slides }) => {
	if (!slides || slides.length === 0) return null;

	// const onClickSlide = () => {
	//   ecommerce.onClickMainBanner();
	// };

	return (
		<Block>
			<Container>
				<GalleryContainer>
					<GallerySlider>
						<ContentSlider onClick={() => {}} slides={slides} />
					</GallerySlider>
				</GalleryContainer>
			</Container>
		</Block>
	);
};

export default Gallery;
