const SVG = ({ fill, width = '18', height = '18', viewBox = '0 0 18 18' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<circle cx='9' cy='9' r='9' fill='#0FB769' />
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M12.75 7.05295L7.83866 12L5.25 9.38706L6.2657 8.33411L7.83866 9.92396L11.7343 6L12.75 7.05295Z'
			fill='white'
		/>
	</svg>
);

export default SVG;
