import Swiper from 'react-id-swiper';
import { useRef } from 'react';
import { Picture, RoutedLink, Link } from '@/components';
import styles from './ContentSlider.module.scss';

const getRandom = (min, max) => {
	const newMin = Math.ceil(min);
	const newMax = Math.floor(max);
	return +(Math.random() * (newMax - newMin + 1) + newMin).toFixed(1);
};

const ContentSlider = ({ slides, onClick }) => {
	const gallerySliderRef = useRef(null);

	const disabledClass = slides.length <= 1 ? ' disabled' : '';

	const params = {
		loop: true,
		speed: 300,
		effect: 'fade',
		keyboard: true,
		autoplay: {
			delay: 1000 * getRandom(2.5, 3),
			disableOnInteraction: false,
		},
		navigation: {
			nextEl: `.swiper-button-next${disabledClass}`,
			prevEl: `.swiper-button-prev${disabledClass}`,
		},
		pagination: {
			el: `.swiper-pagination${disabledClass}`,
			type: 'bullets',
			clickable: true,
		},
		slidesPerView: 1,
		resistanceRatio: 0.2,
		watchOverflow: true,
	};

	const onMouseEnter = () => {
		gallerySliderRef?.current?.swiper.autoplay.stop();
	};

	const onMouseLeave = () => {
		gallerySliderRef?.current?.swiper.autoplay.start();
	};

	const onClickSlide = () => {
		if (onClick) {
			onClick();
		}
	};

	return (
		<div className={styles.main}>
			<Swiper
				{...params}
				ref={el => {
					gallerySliderRef.current = el;
					return el;
				}}
			>
				{slides.map((slide, index) => {
					const { image, url } = slide;
					return (
						<div
							className={styles.slide}
							key={`${image}-${url}-${index}`}
							role='button'
							tabIndex={0}
							onMouseEnter={onMouseEnter}
							onMouseLeave={onMouseLeave}
							onClick={onClickSlide}
						>
							{url && <RoutedLink to={url} component={Link} />}
							<div className={styles.image}>{image && <Picture {...image} />}</div>
						</div>
					);
				})}
			</Swiper>
		</div>
	);
};

export default ContentSlider;
