const SVG = ({
	fill = 'currentColor',
	width = '70',
	height = '70',
	viewBox = '0 0 100 100',
	...props
}) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
		preserveAspectRatio='xMidYMid'
		{...props}
	>
		<path d='M28.145 18.533A40 40 0 0075.25 83.194a40 44.2 53.927 01-47.105-64.661' />
	</svg>
);

export default SVG;
