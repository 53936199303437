import { useState, useEffect, useMemo, useCallback } from 'react';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { fetchMarketProducts } from '@fh-components/fh-js-api-core/store/slices/pagesSlice';
import { Blocks, Share, Title, Button, Loader } from '@/components';
import { BreadCrumbs, Container, News, MetaData } from '@/containers';
import qs from 'qs';
import getLinkProps from '@/lib/getLinkProps';
import { useDispatch } from 'react-redux';
import { JsonLdData } from '@/seo/JsonLd';
import getDate from '@/lib/getDate';
import mindbox from '@/seo/Mindbox';
import { useRouter } from 'next/router';
import { getCorrectName } from '@/utils';
import clsx from 'clsx';

import styles from './TextPage.module.scss';

const ProductsModal = dynamic(() => import('../../components/productsModal'), {
	loading: () => <Loader />,
});

const camelize = str => {
	return str.replace(/#|_|-/g, ' ').replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
		if (+match === 0) return '';
		return index === 0 ? match?.toLowerCase() : match.toUpperCase();
	});
};

const ShareBlock = ({ hasBlocks }) => {
	const [shareUrl, setShareUrl] = useState('');

	useEffect(() => {
		setShareUrl(document.location.href);
	}, []);

	return (
		<section className={clsx(styles.share, !hasBlocks && styles.shareEmpty)}>
			<Container>
				<Share url={shareUrl} />
			</Container>
		</section>
	);
};

const ArticlesBlock = ({ articles }) => {
	const [href, as] = getLinkProps({ type: 'news' });

	return (
		<Container>
			<div className={styles.articles}>
				<Title component='h2' className={styles['articles-title']}>
					Другие новости
				</Title>

				<News items={articles} />

				<div className={styles['link-button']}>
					<Link href={href} as={as} passHref>
						<Button type='primary'>Все новости</Button>
					</Link>
				</div>
			</div>
		</Container>
	);
};

const TextPage = ({ isMain, slug, data, redirectTo }) => {
	const {
		title,
		sub_title,
		blocks,
		meta_description,
		meta_title,
		seo,
		type,
		image,
		articles,
		published_at,
		index,
		follow,
		id,
	} = data || {};

	const router = useRouter();

	useEffect(() => {
		if (redirectTo) {
			router.push(redirectTo);
		}
	}, [redirectTo]);

	const dispatch = useDispatch();
	const { query } = router;
	const [openModal, setOpenModal] = useState(false);
	const [loading, setLoading] = useState(false);
	const [products, setProducts] = useState([]);
	const { model_ids = null } = qs.parse(query);

	const isNews = type === 'article';
	const isSpecialOffer = type === 'special_offer';

	const [newsHref, , newsTitle] = getLinkProps({ type: 'news' });
	const [specialOffersHref, , specialOffersTitle] = getLinkProps({
		type: 'special-offers',
	});
	const breadCrumbsNews = [{ slug: newsHref, name: newsTitle }, { slug: newsHref, name: title }];

	const breadCrumbsSpecialOffers = [
		{ slug: specialOffersHref, name: specialOffersTitle },
		{ slug: specialOffersHref, name: title },
	];

	const { day, month_rus, year } = getDate(published_at);

	const robotsContent = [index, follow]
		.reduce((acc, cur) => {
			return cur ? acc.concat(cur) : acc;
		}, [])
		.join(', ');

	const ogTitle = data?.og_title ?? '';
	const ogDescription = data?.og_description ?? '';
	const ogImageRect = data?.og_image_big ?? null;
	const ogImageSquare = data?.og_image_small ?? null;
	const breadCrumbsType = useMemo(() => {
		if (type === 'article') {
			return 'news';
		}
		if (type === 'special_offer') {
			return 'special-offers';
		}
	}, [type]);

	const handleOpenModal = useCallback(() => {
		setOpenModal(true);
	}, []);

	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
	}, []);

	useEffect(() => {
		if (model_ids) {
			setLoading(true);

			dispatch(
				fetchMarketProducts({
					ids: model_ids,
					onSuccess: productList => {
						setLoading(false);

						if (productList.length) {
							setProducts(productList);
							handleOpenModal();
						}
					},
					onError: () => {
						setLoading(false);
					},
				}),
			);
		}
	}, [model_ids, handleOpenModal]);

	useEffect(() => {
		if (isNews) {
			mindbox?.viewPageNews({ article: data });
		} else if (slug) {
			mindbox?.viewCustomPage(id);
		}
	}, [isNews, slug, data, id]);

	return (
		<>
			<MetaData
				title={meta_title || title}
				description={meta_description}
				seo={seo}
				ogTitle={ogTitle}
				ogDescription={ogDescription}
				image={ogImageRect}
				imageSmall={ogImageSquare}
				{...image && { image: image?.x2 }}
				{...(isNews || isSpecialOffer) && { articleData: data, type: 'article' }}
				{...robotsContent && { robotsContent }}
			/>
			{(isNews || isSpecialOffer) && <JsonLdData newsArticle={{ ...data, meta_description }} />}

			<section>
				{!isMain && (
					<Container>
						{!isNews && !isSpecialOffer && (
							<div className={styles['bread-crumbs']}>
								<BreadCrumbs breadcrumbs={[{ slug: data?.slug, name: title }]} />
							</div>
						)}

						{(isNews || isSpecialOffer) && (
							<div className={styles['bread-crumbs']}>
								<BreadCrumbs
									type={breadCrumbsType}
									breadcrumbs={isNews ? breadCrumbsNews : breadCrumbsSpecialOffers}
								/>
							</div>
						)}

						{sub_title && <div className={styles['sub-title']}>{sub_title}</div>}

						<div className={styles['text-page-article']}>
							{(isNews || isSpecialOffer) && (
								<p className={styles.type}>{isNews ? 'Новость;' : 'Акция;'}</p>
							)}

							{published_at && (isNews || isSpecialOffer) && (
								<time className={styles.date} dateTime={published_at}>
									{day} {month_rus} {year}
								</time>
							)}
						</div>
					</Container>
				)}

				{isMain && (
					<Title component='h1' invisible>
						{seo && seo.h1 ? getCorrectName(seo.h1) : title}
					</Title>
				)}

				{blocks && blocks.length > 0 && (
					<div className={clsx(styles.blocks, (isNews || isSpecialOffer) && styles['news-blocks'])}>
						<Blocks blocks={blocks} />
					</div>
				)}

				{(isNews || isSpecialOffer) && <ShareBlock hasBlocks={blocks.length > 0} />}

				{articles && articles.length > 0 && <ArticlesBlock articles={articles} />}

				<ProductsModal
					showDialog={openModal}
					onDismiss={handleCloseModal}
					products={products}
					loading={loading}
				/>
			</section>
		</>
	);
};

export default TextPage;
