import { forwardRef } from 'react';
import Link from 'next/link';
import styles from './linkList.module.scss';

const MenuLink = forwardRef(({ href, title }, ref) => {
	return (
		<a href={href} ref={ref} className={styles.link}>
			<span>{title}</span>
		</a>
	);
});

const Linklist = ({ links }) => {
	if (!links || links.length === 0) return null;

	return (
		<ul className={styles.list}>
			{links.map((item, ind) => {
				const { title, url } = item;
				const itemId = title + ind;
				return (
					<li key={itemId} className={styles.item}>
						<Link href={url} passHref>
							<MenuLink title={title} />
						</Link>
					</li>
				);
			})}
		</ul>
	);
};

export default Linklist;
