const SVG = ({ fill = 'none', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M2.48656 5.16733H14.7752H15.923H21.5122C22.2728 5.16733 22.8525 5.74961 22.8525 6.47792V17.7805C22.8525 18.5088 22.2728 19.0926 21.5122 19.0926H15.923C15.923 19.0926 15.5 19.0929 14.7755 19.0929H2.48686C1.72812 19.0929 1.14783 18.5091 1.14783 17.7808V12.7122C1.14783 12.3859 1.14753 11.5461 1.14753 11.5461V6.47823C1.14753 5.74961 1.72781 5.16733 2.48656 5.16733ZM2.48656 4C1.12841 4 0 5.1062 0 6.47792V17.7805C0 19.1519 1.12871 20.2581 2.48656 20.2581H21.5122C22.8716 20.2581 24 19.1519 24 17.7805V6.47823C24 5.10651 22.8713 4.00031 21.5122 4.00031L2.48656 4Z'
			fill='#292C33'
		/>
		<path
			d='M19 7C18.4031 7 17.8668 7.26319 17.5 7.67925C17.1332 7.26319 16.5969 7 16 7C14.8972 7 14 7.89719 14 9C14 10.1028 14.8972 11 16 11C16.5969 11 17.1332 10.7368 17.5 10.3208C17.8668 10.7368 18.4031 11 19 11C20.1028 11 21 10.1028 21 9C21 7.89719 20.1028 7 19 7ZM16 10C15.4486 10 15 9.55137 15 9C15 8.44863 15.4486 8 16 8C16.5514 8 17 8.44863 17 9C17 9.55137 16.5514 10 16 10ZM19 10C18.4486 10 18 9.55137 18 9C18 8.44863 18.4486 8 19 8C19.5514 8 20 8.44863 20 9C20 9.55137 19.5514 10 19 10Z'
			fill='#292C33'
		/>
		<path
			d='M6.33333 7H4.66667C3.74764 7 3 7.76907 3 8.71429V9.28571C3 10.2309 3.74764 11 4.66667 11H6.33333C7.25236 11 8 10.2309 8 9.28571V8.71429C8 7.76907 7.25236 7 6.33333 7ZM6.88889 9.28571C6.88889 9.60079 6.63965 9.85714 6.33333 9.85714H4.66667C4.36035 9.85714 4.11111 9.60079 4.11111 9.28571V8.71429C4.11111 8.39921 4.36035 8.14286 4.66667 8.14286H6.33333C6.63965 8.14286 6.88889 8.39921 6.88889 8.71429V9.28571Z'
			fill='#292C33'
		/>
		<line
			x1='3.65'
			y1='16.35'
			x2='5.85'
			y2='16.35'
			stroke='#292C33'
			strokeWidth='1.3'
			strokeLinecap='round'
		/>
		<line
			x1='8.65'
			y1='16.35'
			x2='10.85'
			y2='16.35'
			stroke='#292C33'
			strokeWidth='1.3'
			strokeLinecap='round'
		/>
		<line
			x1='13.65'
			y1='16.35'
			x2='15.85'
			y2='16.35'
			stroke='#292C33'
			strokeWidth='1.3'
			strokeLinecap='round'
		/>
		<line
			x1='18.65'
			y1='16.35'
			x2='20.85'
			y2='16.35'
			stroke='#292C33'
			strokeWidth='1.3'
			strokeLinecap='round'
		/>
	</svg>
);

export default SVG;
