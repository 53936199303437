import { useState, forwardRef, useRef } from 'react';
import clsx from 'clsx';
import InputMask from 'react-input-mask';
import Icon from '@/assets/icons';
import styles from './Input.module.scss';
import statuses from './statuses';

const TextArea = props => <textarea maxLength={256} {...props} />;

const Input = forwardRef((props, ref) => {
	const {
		type = 'text',
		status = 'default',
		freezed,
		value,
		disabled,
		readOnly,
		mask,
		label,
		onChange,
		changeLabelState,
		error,
		info,
		name,
		classNames,
		tooltip,
		loading,
		required = false,
		onClick = null,
		onBlur = null,
		maskPlaceholder = null,
		isSearch = false,
		isClear = false,
		clearClick = false,
		maxLength = 256,
	} = props;

	const refNode = useRef(null);

	const getGeneralStatus = () => {
		if (readOnly) {
			return statuses.readOnly;
		}

		if (disabled) {
			return statuses.disabled;
		}

		if (error) {
			return statuses.error;
		}

		return status || statuses.default;
	};

	const generalStatus = getGeneralStatus();

	const subTextClass = classNames?.subText;
	const fieldClass = classNames?.field;
	const labelClass = classNames?.labelClass;
	const generalClass = classNames?.general;

	const tooltipHeader = tooltip?.header;
	const tooltipDescription = tooltip?.desc;

	const [focused, setFocused] = useState(false);
	const labelIsInside = !focused && !value?.length;

	const isFreezed = freezed || loading;
	const isDisabled =
		statuses.readOnly === generalStatus || statuses.disabled === generalStatus || freezed;

	const setFocusedInput = () => {
		if (!disabled) {
			if (!onClick) {
				refNode.current?.focus();
				setFocused(true);

				if (isSearch) {
					refNode.current?.setSelectionRange(0, 0);
				}

				if (changeLabelState) {
					changeLabelState(true);
				}
			} else {
				refNode.current.blur();
			}
		}
	};

	const setBluredInput = () => {
		setFocused(false);
		if (changeLabelState) {
			changeLabelState(false);
		}

		if (onBlur) {
			onBlur();
		}
	};

	const subText = error || info;

	let ElemToShow = null;

	if (type === 'textarea') {
		ElemToShow = TextArea;
	} else {
		ElemToShow = InputMask;
	}

	return (
		<div
			className={clsx(
				{
					[styles.input]: true,
					[styles[generalStatus]]: true,
					[styles[type]]: true,
					[styles.freezed]: isFreezed,
					[styles.loading]: loading,
				},
				generalClass,
			)}
			onClick={e => {
				if (onClick) {
					e.stopPropagation();
					e.preventDefault();
					onClick();
					setFocused(false);
				}
			}}
		>
			<Icon name='circle-success' />
			{isSearch && <Icon name='search' fill='#BBBBBB' className={styles.search} />}

			<ElemToShow
				disabled={isDisabled}
				id={name}
				onBlur={setBluredInput}
				onFocus={setFocusedInput}
				inputRef={el => {
					refNode.current = el;
				}}
				name={name}
				className={clsx(styles.field, fieldClass, type === 'textarea' && styles.textAreaInput)}
				value={value}
				onChange={onChange}
				required={required}
				mask={mask}
				maskChar={maskPlaceholder || '_'}
				type={type}
				maxLength={type === 'textarea' ? maxLength : null}
			/>
			{isClear && (
				<Icon onClick={clearClick} name='close' stroke='#171717' className={styles.clear} />
			)}
			<label
				onClick={EO => {
					setFocusedInput();
				}}
				htmlFor={name}
				className={clsx(
					{
						[styles.label]: true,
						[styles.labelIsInside]: labelIsInside,
						[styles.labelIsInsideSearch]: labelIsInside && isSearch,
						[styles.labelError]: error,
					},
					labelClass,
				)}
			>
				{label}
				{required && <span>*</span>}
			</label>
			{subText && (
				<div className={clsx(styles['info-text'], { [styles.error]: error }, subTextClass)}>
					{subText}
				</div>
			)}
			{statuses.success === status && (
				<div className={styles.tooltip}>
					<h5 className={styles.header}>{tooltipHeader}</h5>
					<span className={styles.description}>{tooltipDescription}</span>
				</div>
			)}
		</div>
	);
});

Input.displayName = 'Input';

export default Input;
