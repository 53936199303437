import YouTube from 'react-youtube';
import { Block, Container } from '@/containers';
import styles from './YoutubePlayer.module.scss';

const YoutubePlayer = ({ id }) => {
	if (id === null) return null;

	return (
		<Block>
			<Container>
				<div className={styles.youtube}>
					<YouTube
						videoId={id}
						containerClassName={styles['video-wrapper']}
						className={styles.video}
						opts={{ width: '100%', height: '100%' }}
					/>
				</div>
			</Container>
		</Block>
	);
};

export default YoutubePlayer;
