import { memo } from 'react';
import Script from 'next/script';
import { CONTAINER_ID } from '@/seo/Ecommerce';

const Scripts = () => {
	const isGTMEnable = !!CONTAINER_ID;

	return (
		<>
			<Script
				id='popmechanic-script'
				strategy='lazyOnload'
				src='https://static.popmechanic.ru/service/loader.js?c=30980'
				onError={err => {
					console.error('Error', err);
				}}
				onLoad={() => {
					console.log('popmechanic upload success');
				}}
			/>
			<Script
				id='diginetica-script'
				strategy='lazyOnload'
				src={`//cdn.diginetica.net/3205/client.js?ts=${Date.now()}`}
				onError={err => {
					console.error('Error', err);
				}}
				onLoad={() => {
					console.log('diginetica upload success');
				}}
			/>
			{isGTMEnable && (
				<Script
					strategy='afterInteractive'
					dangerouslySetInnerHTML={{
						__html: `
                            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                                new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
                                j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                                'https://www.googletagmanager.com/gtm.js?id='+i+dl;setTimeout(function(){f?.parentNode?.insertBefore(j,f)}, 3*1000);
                            })(window,document,'script','dataLayer', '${CONTAINER_ID}');
                        `,
					}}
				/>
			)}
		</>
	);
};

export default memo(Scripts);
