const SVG = ({ fill = 'currentColor', width = '24', height = '23', viewBox = '0 0 40 40' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M20.0154 0C8.97795 0 0.000495867 8.97746 0.000495867 20.0149C0.000495867 23.4617 0.891104 26.8508 2.58054 29.8387L0.031089 38.925C-0.0504933 39.2241 0.031089 39.5436 0.245243 39.7646C0.411807 39.938 0.639558 40.0297 0.870708 40.0297C0.938693 40.0297 1.01008 40.0195 1.07806 40.0059L10.562 37.6571C13.4582 39.2105 16.7181 40.0297 20.0154 40.0297C31.0528 40.0297 40.0302 31.0523 40.0302 20.0149C40.0302 8.97746 31.0528 0 20.0154 0ZM30.084 27.0785C29.6557 28.2615 27.6025 29.3459 26.6167 29.4886C25.7295 29.6178 24.6078 29.6756 23.3772 29.2881C22.6328 29.0535 21.6742 28.7408 20.4471 28.2207C15.2938 26.0214 11.9285 20.8953 11.6702 20.5588C11.4152 20.2188 9.57283 17.8087 9.57283 15.3103C9.57283 12.8152 10.8985 11.5847 11.371 11.0782C11.8435 10.5683 12.3976 10.4425 12.7409 10.4425C13.0843 10.4425 13.4276 10.4459 13.7267 10.4595C14.0429 10.4765 14.4678 10.3406 14.8825 11.3331C15.3108 12.3495 16.3408 14.8446 16.4665 15.0995C16.5957 15.3545 16.6807 15.6502 16.5107 15.9901C16.3408 16.3267 16.2558 16.5408 15.9974 16.8366C15.7391 17.1323 15.457 17.496 15.2258 17.7238C14.9675 17.9787 14.7023 18.2541 15.0015 18.7605C15.3006 19.2704 16.3306 20.9293 17.8602 22.2754C19.8216 24.0056 21.4771 24.5393 21.9903 24.7942C22.5036 25.0492 22.8028 25.005 23.1053 24.6685C23.4044 24.3285 24.3868 23.1864 24.7301 22.6765C25.0735 22.17 25.4134 22.255 25.8859 22.4249C26.3584 22.5915 28.884 23.8186 29.3973 24.0736C29.9106 24.3285 30.254 24.4543 30.3831 24.6651C30.5123 24.8758 30.5123 25.8922 30.084 27.0785Z'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
