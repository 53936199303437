export const YM_TRACKING_ID = '56654761';

// https://yandex.ru/support/metrica/objects/hit.html
const pageview = url => {
	try {
		window.ym(YM_TRACKING_ID, 'hit', url);
	} catch (error) {
		// eslint-disable-next-line no-console
		console.error(error);
	}
};

export default {
	pageview,
};
