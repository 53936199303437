const SVG = ({ fill = 'currentColor', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M12 20.856a.92.92 0 01-.738-.36c-.972-1.296-4.014-5.778-5.058-9.162a5.815 5.815 0 01-.342-1.836v-.216A6.146 6.146 0 0112 3.144c1.638 0 3.186.648 4.338 1.8a6.115 6.115 0 011.8 4.338v.234a6.369 6.369 0 01-.342 1.836c-1.044 3.366-4.086 7.848-5.058 9.144a.92.92 0 01-.738.36zm0-16.83a5.262 5.262 0 00-5.256 5.256v.18c.018.54.126 1.08.306 1.584v.018c.99 3.276 3.96 7.632 4.914 8.91 0 0 .018.018.036.018s.018-.018.036-.018c.954-1.278 3.924-5.634 4.914-8.892v-.018a5.26 5.26 0 00.306-1.584v-.198c0-1.404-.54-2.736-1.548-3.726-.972-.99-2.304-1.53-3.708-1.53zm0 8.01a2.766 2.766 0 01-2.754-2.754A2.766 2.766 0 0112 6.528a2.766 2.766 0 012.754 2.754A2.766 2.766 0 0112 12.036zm0-4.626a1.882 1.882 0 00-1.872 1.872c0 1.026.846 1.872 1.872 1.872a1.882 1.882 0 001.872-1.872A1.882 1.882 0 0012 7.41z' />
	</svg>
);

export default SVG;
