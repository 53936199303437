import styled, { css } from 'styled-components';
import { maxWidth } from '@/assets/styles/mixins.css.js';
import vars from '@/assets/styles/vars';

const Container = styled.div`
  min-width: 280px;
  width: ${vars.container.default}%;
  max-width: ${vars.maxContentWidth}px;
  margin-left: auto;
  margin-right: auto;
  
  ${maxWidth(vars.breakpoints.tablet + 1)`
	  width: ${vars.container.tablet}%;
  `}
  
  ${maxWidth(vars.breakpoints.mTablet + 1)`
	  width: ${vars.container.mTablet}%;
  `}
  
  ${maxWidth(vars.breakpoints.lMobile + 1)`
    width: ${vars.container.lMobile}%;
  `}

  ${props =>
		props.maxWidth &&
		css`
			max-width: ${props.maxWidth};
		`}

  ${props =>
		props.align &&
		css`
			text-align: ${props.align};
		`}
`;

export default Container;
