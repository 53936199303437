const SVG = ({ fill = 'currentColor', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M4.724 6A.735.735 0 004 6.724v10.284c0 .392.333.725.724.725h14.55c.392 0 .721-.333.721-.725V6.724a.732.732 0 00-.72-.724H4.725zm.343 1.067h13.865v9.599H5.067v-9.6z'
			fill='#fff'
		/>
		<path d='M4.536 5.933a.533.533 0 00-.311.968l7.494 5.333a.533.533 0 00.621 0l7.435-5.333a.533.533 0 00-.31-.968H4.534zM6.205 7h11.602l-5.781 4.144L6.205 7z' />
	</svg>
);

export default SVG;
