const SVG = ({ fill = 'none', width = '24', height = '24', viewBox = '0 0 24 24', className }) => (
	<svg
		className={className}
		width='65'
		height='64'
		viewBox='0 0 65 64'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<rect
			width='16'
			height='2'
			transform='matrix(-0.707107 -0.707107 -0.707107 0.707107 40.592 32.0005)'
			fill='#171717'
		/>
		<rect
			width='16'
			height='2'
			transform='matrix(-0.707107 0.707107 0.707107 0.707107 39.1778 30.5852)'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
