const SVG = ({ fill = 'none', width = '24', height = '24', viewBox = '0 0 24 24', className }) => (
	<svg
		className={className}
		width='64'
		height='64'
		viewBox='0 0 64 64'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<rect
			x='24.136'
			y='32.0005'
			width='16'
			height='2'
			transform='rotate(-45 24.136 32.0005)'
			fill='#171717'
		/>
		<rect
			x='25.5502'
			y='30.5852'
			width='16'
			height='2'
			transform='rotate(45 25.5502 30.5852)'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
