import { forwardRef } from 'react';
import Select, { components } from 'react-select';
import clsx from 'clsx';
import styles from './Select.module.scss';

const { ValueContainer, Placeholder, Menu } = components;

const theme = {
	colors: {
		primary: '#59896D',
		neutral0: '#fff',
		neutral20: '#F0F0F0',
		neutral50: '#BBBBBB',
	},
};

const CustomMenuList = props => {
	return (
		<div className={styles.scrollbar}>
			<div>{props.children}</div>
		</div>
	);
};

const CustomValueContainer = ({ children, ...props }) => {
	const value = props.getValue();
	const disabledCurrentValue = props.selectProps?.value?.has === false;

	let bigClass = null;

	if (value && value[0]?.label) {
		if (value[0].label.length > 25) {
			bigClass = styles.valueContainer80;
		}
		if (value[0].label.length > 35) {
			bigClass = styles.valueContainer90;
		}
		if (value[0].label.length > 45) {
			bigClass = styles.valueContainer115;
		}
		if (value[0].label.length > 60) {
			bigClass = styles.valueContainer105;
		}
		if (value[0].label.length > 80) {
			bigClass = styles.valueContainer125;
		}
	}

	return (
		<ValueContainer
			{...props}
			className={
				value.length
					? clsx(
							styles.valueContainer,
							bigClass,
							disabledCurrentValue && styles.valueContainerDisabled,
					  )
					: null
			}
		>
			<Placeholder {...props} isFocused={props.isFocused}>
				{props.selectProps.placeholder}
			</Placeholder>
			<span>{value.length ? value[0].label : ''}</span>
			{children[1]}
		</ValueContainer>
	);
};

const CustomOption = props => {
	return (
		<components.Option {...props} className={styles.option} key={props.data.label}>
			<div className={styles.optionLabel}>{props.data.label}</div>
			{props?.data?.info && (
				<div
					className={clsx(
						styles.info,
						props?.currentValue?.value === props.data.value && styles.selected,
					)}
				>
					{props.data.info}
				</div>
			)}
		</components.Option>
	);
};

const customStyles = {
	placeholder: (provided, state) => {
		const hasValue = !!state.selectProps?.value?.value;

		const content = state.selectProps?.name.includes('size')
			? {}
			: {
					'::after': {
						content: '"*"',
					},
			  };

		return {
			...provided,
			position: 'absolute',
			top: hasValue ? -15 : provided.top,
			transition: 'top 0.1s, font-size 0.1s',
			fontSize: hasValue ? 14 : 20,
			background: hasValue && '#fff',
			paddingLeft: 2,
			paddingRight: 2,
			...content,
		};
	},
	valueContainer: (_provided, state) => ({
		height: 44,
		display: 'flex',
		alignItems: 'center',
		marginLeft: 16,
		fontSize: 20,
		color: state.isDisabled ? '#959595' : '#171717',
	}),
	menu: (provided, state) => ({
		...provided,
		marginTop: 1,
		borderRadius: 16,
		zIndex: 2,
	}),
	control: (provided, state) => {
		const background = state.isDisabled ? '#F8F8F8' : provided.background;
		const border = state.isDisabled ? '1px solid #BBBBBB' : provided.border;

		return {
			...provided,
			background,
			border,
			flexWrap: 'nowrap',
			borderRadius: 16,
			boxShadow: 'none',
		};
	},
};

const errorStyles = {
	...customStyles,
	control: (provided, state) => {
		const hasValue = !!state.selectProps?.value?.value;
		const border = '1px solid #EA5656';
		const backgroundColor = hasValue ? provided.backgroundColor : '#F8F8F8';

		return { ...provided, border, backgroundColor };
	},
	placeholder: (provided, state) => {
		const hasValue = !!state.selectProps?.value?.value;
		const color = hasValue ? provided.color : '#EA5656';

		return {
			...provided,
			position: 'absolute',
			top: hasValue ? -15 : provided.top,
			transition: 'top 0.1s, font-size 0.1s',
			fontSize: hasValue && 14,
			background: hasValue && '#fff',
			paddingLeft: 2,
			paddingRight: 2,
			color,
			'::after': {
				content: '"*"',
			},
		};
	},
};

const RSelect = forwardRef(
	(
		{
			value,
			name,
			placeholder,
			options,
			onChange,
			isDisabled,
			isSearchable = false,
			className,
			error,
			noOptionsMessage,
			...props
		},
		ref,
	) => {
		return (
			<Select
				value={value}
				ref={ref}
				className={clsx(styles.select, className)}
				name={name}
				theme={theme}
				options={options}
				isSearchable={isSearchable ?? false}
				styles={error ? errorStyles : customStyles}
				onChange={onChange}
				isDisabled={isDisabled}
				placeholder={placeholder}
				noOptionsMessage={() => noOptionsMessage ?? null}
				components={{
					IndicatorSeparator: null,
					ValueContainer: CustomValueContainer,
					Option: props => <CustomOption {...props} currentValue={value} />,
					MenuList: CustomMenuList,
				}}
				{...props}
			/>
		);
	},
);

export default RSelect;
