import { useMemo } from 'react';
import { Block, Container } from '@/containers';
import { Picture, RoutedLink, Link, Title } from '@/components';
import useWindowSize from '@/hooks/use-window-size';
import styles from './TextWithSidePicture.module.scss';

const TextWithSidePicture = props => {
	const {
		title,
		description,
		url_text: linkText,
		url: linkUrl,
		image,
		noMargin,
		imagePos = 'left',
		alt = 'Фото',
		title_img = 'Фото',
	} = props;

	if (!image) {
		return null;
	}

	const { isSmallMobile } = useWindowSize();

	const imageBlock = useMemo(() => {
		return (
			<div className={styles.image}>
				{image && <Picture {...image} alt={alt} title={title_img} />}
			</div>
		);
	}, [image, alt, title_img]);

	const contentBlock = useMemo(() => {
		if (title || description || linkText) {
			return (
				<div className={styles.contentBlock}>
					<div className={styles.content}>
						{title && (
							<Title component='h2' className={styles.title}>
								{title}
							</Title>
						)}
						{description && <p className={styles.description}>{description}</p>}
						{linkText && <p className={styles.text}>{linkText}</p>}
					</div>
				</div>
			);
		}

		return null;
	}, [title, description, linkText]);

	return (
		<Block noMargin={noMargin}>
			<Container>
				<div className={styles.main}>
					{linkUrl && <RoutedLink to={linkUrl} component={Link} />}
					{imagePos === 'left' || isSmallMobile ? (
						<>
							{imageBlock}
							{contentBlock}
						</>
					) : (
						<>
							{contentBlock}
							{imageBlock}
						</>
					)}
				</div>
			</Container>
		</Block>
	);
};

export default TextWithSidePicture;
