import { Button } from '@/components';

const LinkButton = ({ children, ...props }) => {
	return (
		<Button type='primary' {...props}>
			{children}
		</Button>
	);
};

export default LinkButton;
