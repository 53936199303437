import { memo } from 'react';
import { useRouter } from 'next/router';
import Search from '../search';
import Icon from '@/assets/icons';
import clsx from 'clsx';

import styles from './SearchControl.module.scss';

const SearchControl = memo(
	({
		label,
		type,
		isShowSearch,
		onToggleSearch,
		hideSearch,
		onUnVisibleSearch,
		showSearch,
		searchVisible,
	}) => {
		const router = useRouter();
		const isSearchPage = router.asPath.includes('search?');

		const handleShowSearch = () => {
			showSearch();
		};

		if (type === 'mobile') {
			return (
				<>
					<Search
						visible={searchVisible}
						onReset={onUnVisibleSearch}
						onResultClick={onUnVisibleSearch}
						className={styles.searchFieldMobile}
						hideSearch={onUnVisibleSearch}
					/>

					<button
						type='button'
						className={clsx(styles.search, styles[type])}
						aria-label='search'
						onClick={onToggleSearch}
					>
						<span className={styles.showSearch}>
							<Icon name='search' fill={isSearchPage ? '#0D9A58' : ''} />
							<span className={clsx(styles.text, isSearchPage && styles.greenText)}>{label}</span>
						</span>
					</button>
				</>
			);
		}

		return (
			<div className={clsx(styles.search, styles[type], { [styles.show]: isShowSearch })}>
				<Search visible={isShowSearch} hideSearch={hideSearch} />
				<button
					type='button'
					aria-label='search'
					id='search-button'
					className={clsx(styles.showSearch, { [styles.hidden]: isShowSearch })}
					onClick={handleShowSearch}
				>
					<Icon name='search' fill={isSearchPage ? '#0D9A58' : ''} />
					<span className={clsx(isSearchPage && styles.greenText)}>{label}</span>
				</button>
			</div>
		);
	},
);

export default SearchControl;
