import React, { useMemo, useRef, useEffect } from 'react';
import dynamic from 'next/dynamic';
import clsx from 'clsx';
import { getCorrectName } from '@/utils';
import { useNav } from '@/contexts/NavContext';
import { JsonLdData } from '@/seo/JsonLd';
import wordEnd from '@/lib/wordEnd';
import Icon from '@/assets/icons';
import { Blocks, Loader, Title, Text, MobileFilters, MobileSort } from '@/components';
import { Container, BreadCrumbs, MetaData, CategoryTreeMobile } from '@/containers';

import styles from './CatalogPage.module.scss';

const ScrollBtn = dynamic(() => import('../../components/scrollBtn'), {
	loading: () => <Loader />,
});

const AppliedFilters = dynamic(() => import('../../components/appliedFilters'));

const SelectSort = dynamic(() => import('../../components/selectSort'), {
	loading: () => <Loader />,
});

const Filters = dynamic(() => import('../../components/filters'), {
	loading: () => <Loader />,
});

const Products = dynamic(() => import('../../containers/products'), {
	loading: () => <Loader />,
});

const Pagination = dynamic(() => import('../../components/pagination'), {
	loading: () => <Loader />,
});

const CategoryTree = dynamic(() => import('../../containers/categoryTree'), {
	loading: () => <Loader />,
});

const Viewed = dynamic(() => import('../../containers/viewed'), {
	loading: () => <Loader />,
});

const TagsRow = dynamic(() => import('../../containers/tagsRow'), {
	loading: () => <Loader />,
});

const CatalogMobileFilters = props => {
	const { visibility, toggleMobileFilter } = useNav();

	return (
		<MobileFilters
			{...props}
			visibility={visibility.mobileFilter}
			toggleMobileFilter={toggleMobileFilter}
		/>
	);
};

const CatalogMobileSort = props => {
	const { visibility, toggleMobileSort } = useNav();
	return (
		<MobileSort {...props} visibility={visibility.mobileSort} toggleMobileSort={toggleMobileSort} />
	);
};

const CatalogPageView = ({
	metaTitle,
	categoryTitle,
	metaDescription,
	seo,
	ogTitle,
	ogDescription,
	ogImageRect,
	ogImageSquare,
	meta,
	state,
	isBrandsCategory,
	savedSort,
	newSelectedSorting,
	filters,
	newSelectedFilters,
	handleChange,
	loadingProducts,
	loadingFilters,
	isDesktop,
	blocks,
	page,
	sortFields,
	currency,
	storageCurrency,
	products,
	loadingCategory,
	isSafari,
	history,
	setFirstProductId,
	firstProductId,
	textBlock,
	newProductsLoading,
}) => {
	const ref = useRef(null);

	const breadcrumbs = useMemo(
		() => (
			<div className={styles.breadCrumbs}>
				<BreadCrumbs breadcrumbs={state.breadcrumbs} />
			</div>
		),
		[state.breadcrumbs],
	);

	const tags = useMemo(() => {
		return state.tags && state.tags.length > 0 ? (
			<div className={styles.tags}>
				<TagsRow tags={state.tags} parentId={state.current.id} />
			</div>
		) : null;
	}, [state.tags, state.current]);

	const textBlockUI = useMemo(() => {
		return textBlock && page === 1 ? (
			<div className={styles.seo} dangerouslySetInnerHTML={{ __html: textBlock }} />
		) : null;
	}, [textBlock, page]);

	useEffect(() => {
		ref?.current?.scroll({
			top: 0,
			behavior: 'smooth',
		});
	}, [state.sidebar]);

	return (
		<>
			<MetaData
				title={metaTitle || categoryTitle}
				description={metaDescription}
				seo={seo}
				ogTitle={ogTitle}
				ogDescription={ogDescription}
				image={ogImageRect}
				imageSmall={ogImageSquare}
				type='product.group'
				withPage
				// eslint-disable-next-line no-unsafe-optional-chaining
				{...+meta?.total === 0 && { robotsContent: 'noindex, follow' }}
			/>
			<JsonLdData category={state.current} totalProducts={meta?.total} />

			<div className={styles.page}>
				<Container>
					{breadcrumbs}
					{!!blocks?.length && page === 1 && <Blocks blocks={blocks} isCatalog />}
					<div className={styles.header}>
						<div className={styles.left}>
							<Title
								component='h1'
								className={styles.title}
								dataBrand={isBrandsCategory && products?.length ? products[0]?.brand : null}
								data-test='h1'
							>
								{seo && seo.h1 ? getCorrectName(seo.h1) : categoryTitle}
							</Title>

							<Text className={styles.productsQuantity}>
								{+(meta?.total ?? 0) > 0
									? `${meta.total} ${wordEnd(+(meta?.total ?? 0), ['товар', 'товара', 'товаров'])}`
									: 'Ничего не найдено'}
							</Text>
						</div>

						<div className={styles.right}>
							<Icon name='sort' />
							<span className={styles.sortLabel}>Сортировка:</span>
							<SelectSort
								name='catalog-sort'
								items={sortFields}
								selectedSorting={newSelectedSorting}
								onChange={handleChange('sort')}
								onOpen={() => {}}
							/>
						</div>
					</div>

					<div className={styles.treeMobile}>
						<CategoryTreeMobile
							sidebar={state.sidebar}
							{...savedSort && { savedQuery: { sort: savedSort } }}
						/>
					</div>

					<div className={styles.content}>
						<div className={styles.aside}>
							<div
								ref={ref}
								className={clsx(
									styles.scrollBox,
									(loadingProducts || loadingFilters) && styles.disabledBlock,
								)}
							>
								<CategoryTree
									sidebar={state.sidebar}
									parent={state.parent}
									currentSlug={state.current.slug}
									{...newSelectedSorting && { savedQuery: { sort: newSelectedSorting } }}
								/>

								{!!filters?.length && (
									<Filters
										filters={filters}
										selectedFilters={newSelectedFilters}
										onChange={handleChange('filters')}
										loading={loadingProducts || loadingFilters}
									/>
								)}
							</div>
						</div>

						<div className={styles.main}>
							<div className={styles.filterToggles}>
								<CatalogMobileFilters
									selectedFilters={newSelectedFilters}
									filters={filters}
									onFiltersChange={handleChange('filters')}
									total={meta?.total ?? 0}
									loading={loadingProducts || loadingFilters}
								/>

								<CatalogMobileSort
									sortName='catalog-sort-mobile'
									sortItems={sortFields}
									selectedSorting={newSelectedSorting}
									onSortChange={handleChange('sort')}
									loading={loadingProducts || loadingFilters}
								/>
							</div>

							{!!filters?.length && (
								<div className={styles.appliedFilters}>
									<AppliedFilters
										filters={filters}
										selectedFilters={newSelectedFilters}
										onChange={handleChange('filters')}
										currency={currency}
										isRU={storageCurrency == 'RU'}
									/>
								</div>
							)}

							{products?.length ? (
								<div className={styles.products}>
									<Products
										products={products}
										loading={
											loadingProducts || loadingCategory || newProductsLoading
										}
										isSafari={isSafari}
										slug={state.current.slug}
										productCardProps={{ mod: 'catalog' }}
										currency={currency}
									/>
								</div>
							) : (
								<div
									className={clsx(
										styles.products,
										styles.productsEmpty,
										loadingProducts && styles.productsLoading,
									)}
								/>
							)}

							{meta && meta.total > meta.per_page && (
								<div>
									<Pagination lastPage={meta.last_page} currentPage={page} />
								</div>
							)}
						</div>
					</div>

					{tags}

					{state.current.slug !== '/brands' && textBlockUI}

					{history.products && history.products.length > 0 && (
						<Viewed
							products={history.products}
							currency={currency}
							setFirstProductId={setFirstProductId}
							firstProductId={firstProductId}
						/>
					)}

					{!!products?.length && <ScrollBtn />}
				</Container>
			</div>
		</>
	);
};

export default CatalogPageView;
