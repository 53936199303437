import { createSelector } from '@reduxjs/toolkit';

export const productLookLoadingSelector = createSelector(
	state => state.product.loadingLooks,
	loading => loading,
);

export const productDataSelector = createSelector(
	state => state.product.cachedQueries[state.product.currentQuery],
	data => data,
);

export const productErrorSelector = createSelector(
	state => state.product.errorsInfo[state.product.currentQuery] || null,
	error => error,
);

export const productDataStateSelector = createSelector(
	productDataSelector,
	state => state.product.loadingInfo[state.product.currentQuery] ?? false,
	productErrorSelector,
	(data, loading, error) => ({
		data,
		loading,
		error,
	}),
);

export const productShopsSelector = createSelector(
	state => state.product.cachedShopsQueries[state.product.currentQuery],
	state => state.product.loadingShopsInfo[state.product.currentQuery] ?? false,
	state => state.product.errorsShopsInfo[state.product.currentQuery] || null,
	(data, loading, error) => ({
		data,
		loading,
		error,
	}),
);
