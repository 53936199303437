const SVG = ({ fill = 'currentColor', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M12 1C5.95496 1 1 5.95496 1 12C1 18.045 5.95496 23 12 23C18.045 23 23 18.045 23 12C23 5.95496 18.045 1 12 1ZM12 21.8108C6.64865 21.8108 2.18919 17.4504 2.18919 12C2.18919 6.54955 6.54955 2.18919 12 2.18919C17.4504 2.18919 21.8108 6.64865 21.8108 12C21.8108 17.3514 17.3514 21.8108 12 21.8108ZM7.73874 8.53153C7.73874 7.93694 8.23423 7.44144 8.92793 7.44144C9.62162 7.44144 10.018 7.93694 10.018 8.53153C10.018 9.12613 9.52252 9.72072 8.92793 9.72072C8.33333 9.72072 7.73874 9.12613 7.73874 8.53153ZM16.5586 15.4685C16.5586 16.0631 16.0631 16.5586 15.4685 16.5586C14.8739 16.5586 14.3784 16.0631 14.3784 15.4685C14.3784 14.8739 14.8739 14.3784 15.4685 14.3784C16.0631 14.3784 16.5586 14.8739 16.5586 15.4685ZM16.955 6.84685C17.0541 6.94595 17.1532 7.14414 17.1532 7.24324C17.1532 7.44144 17.0541 7.54054 16.955 7.63964L7.93694 16.7568C7.83784 16.8559 7.63964 16.955 7.54054 16.955C7.34234 16.955 7.24324 16.8559 7.14414 16.7568C7.04504 16.6577 6.94595 16.4595 6.94595 16.3604C6.94595 16.2613 7.04504 16.0631 7.14414 15.964L16.1622 6.94595C16.2613 6.84685 16.4595 6.74775 16.5586 6.74775C16.6577 6.74775 16.8559 6.74775 16.955 6.84685Z'
			fill='#959595'
		/>
	</svg>
);

export default SVG;
