import { useState, useEffect } from 'react';

const useVisibility = triggerRef => {
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		let observer;
		let didCancel = false;

		if (triggerRef) {
			if (IntersectionObserver) {
				observer = new IntersectionObserver(
					entries => {
						entries.forEach(entry => {
							if (!didCancel && !entry.isIntersecting) {
								setVisible(true);
							}
							if (!didCancel && entry.isIntersecting) {
								setVisible(false);
							}
						});
					},
					{
						threshold: 0,
						rootMargin: '0px',
					},
				);
				observer.observe(triggerRef);
			} else {
				setVisible(true);
			}
		}

		return () => {
			didCancel = true;

			if (observer && observer.unobserve) {
				observer.unobserve(triggerRef);
			}
		};
	}, [setVisible, triggerRef]);

	return { visible };
};

export default useVisibility;
