const SVG = ({ fill = '#292C33', width = '15', height = '15', viewBox = '0 0 15 15' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M14.375 0H3.125A.625.625 0 002.5.625V5a.625.625 0 101.25 0V1.25h10v12.5h-10V10a.625.625 0 00-1.25 0v4.375c0 .345.28.625.625.625h11.25c.345 0 .625-.28.625-.625V.625A.625.625 0 0014.375 0z' />
		<path d='M5.808 9.558a.625.625 0 10.884.884l2.5-2.5a.64.64 0 00.041-.046l.017-.022.02-.027.016-.027.015-.025.014-.029.012-.027.01-.028.011-.03.007-.028.008-.03.005-.034.004-.027a.628.628 0 000-.124l-.004-.027-.005-.033-.008-.03-.007-.03-.01-.029-.01-.028-.013-.027-.014-.028c-.004-.01-.01-.017-.015-.026l-.016-.027c-.006-.01-.013-.018-.02-.027-.006-.007-.01-.015-.017-.022a.624.624 0 00-.04-.045l-2.501-2.5a.625.625 0 10-.884.883l1.433 1.433H.625a.625.625 0 100 1.25h6.616L5.808 9.558z' />
	</svg>
);

export default SVG;
