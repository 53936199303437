import React from 'react';
import dynamic from 'next/dynamic';
import { Loader } from '@/components';
import { Block, Container } from '@/containers';

import styles from './HtmlBlock.module.scss';

const Html = dynamic(() => import('../../html'), {
	loading: () => <Loader />,
});

const HtmlBlock = props => {
	const { html } = props;

	if (!html) return null;

	return (
		<Block>
			<Container>
				<div className={styles.addToCartPopMechanic}>
					<Html html={html} />
				</div>
			</Container>
		</Block>
	);
};

export default HtmlBlock;
