const SVG = ({ fill = 'none', width = '24', height = '24', viewBox = '0 0 24 24', className }) => (
	<svg
		className={className}
		width='44'
		height='45'
		viewBox='0 0 44 45'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<rect
			width='24'
			height='2'
			transform='matrix(0.707107 -0.707107 -0.707107 -0.707107 14.2218 30.7432)'
			fill='#171717'
		/>
		<rect
			x='29.7782'
			y='30.7432'
			width='24'
			height='2'
			transform='rotate(-135 29.7782 30.7432)'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
