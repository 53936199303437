import { useState, useEffect, useRef, memo, createRef, useMemo, useCallback } from 'react';
import Swiper from 'react-id-swiper';
import Lightbox from 'yet-another-react-lightbox';
import { Picture } from '@/components';
import Icon from '@/assets/icons';
import useWindowSize from '@/hooks/use-window-size';
import Thumbnails from 'yet-another-react-lightbox/plugins/thumbnails';
import Counter from 'yet-another-react-lightbox/plugins/counter';
import Zoom from 'yet-another-react-lightbox/plugins/zoom';
import Video from 'yet-another-react-lightbox/plugins/video';

import 'yet-another-react-lightbox/plugins/thumbnails.css';
import 'yet-another-react-lightbox/styles.css';
import 'yet-another-react-lightbox/plugins/counter.css';

import clsx from 'clsx';
import styles from './ProductGallery.module.scss';

const gallerySwiperParams = {
	navigation: {
		nextEl: styles.swiperButtonNext,
		prevEl: styles.swiperButtonPrev,
	},
	pagination: {
		el: `.swiper-pagination.${styles.swiperPagination}`,
		clickable: true,
	},
	renderPrevButton: () => (
		<div className={styles.swiperButtonPrev}>
			<Icon name='m-arrow-left' />
		</div>
	),
	renderNextButton: () => (
		<div className={styles.swiperButtonNext}>
			<Icon name='m-arrow-right' />
		</div>
	),
	preventInteractionOnTransition: true,
	watchSlidesVisibility: true,
	watchOverflow: true,
	resistanceRatio: 0,
};

const thumbnailSwiperParams = {
	direction: 'vertical',
	slidesPerView: 'auto',
	spaceBetween: 16,
	watchOverflow: true,
	watchSlidesVisibility: true,
	resistanceRatio: 0,
	grabCursor: true,
};

const lightBoxStyle = {
	container: {
		backgroundColor: '#fff',
	},
	thumbnailsContainer: {
		backgroundColor: '#fff',
	},
	thumbnail: {
		backgroundColor: '#fff',
		border: 'none',
	},
};

export default memo(function ProductGallery({ images, thumbs, zoom, alt, title, onLoadingError }) {
	const refs = useRef(
		images.reduce((acc, _img, ind) => {
			acc[ind] = createRef();
			return acc;
		}, {}),
	);

	const [activeThumb, setActiveThumb] = useState(0);
	const [zoomedImageIndex, setZoomedImageIndex] = useState(-1);
	const [thumbsState, setThumbsState] = useState({ isEnd: false, isBeginning: true });
	const gallerySwiperRef = useRef(null);
	const thumbsSwiperRef = useRef(null);
	const { isMobile } = useWindowSize();

	const plugins = isMobile ? [Zoom, Video, Counter] : [Thumbnails, Zoom, Video];

	const slides = useMemo(() => {
		return zoom.map(image => {
			if (image.url) {
				return {
					type: 'video',
					poster: zoom[0].x2,
					sources: [
						{
							src: image.url,
							type: 'video/mp4',
						},
					],
				};
			}

			return {
				src: image.x2,
			};
		});
	}, [zoom]);

	const updateThumbsState = () => {
		if (!thumbsSwiperRef.current) return;
		const thumbsInstance = thumbsSwiperRef.current.swiper;
		const totalSlides = thumbsInstance.slides.length;
		const isBeginning = activeThumb === 0;
		const isEnd = activeThumb === totalSlides - 1;

		setThumbsState({ isEnd, isBeginning });
	};

	useEffect(() => {
		updateThumbsState();
	}, [activeThumb]);

	const onThumbClick = pos => {
		const galleryInstance = gallerySwiperRef.current.swiper;
		const thumbsInstance = thumbsSwiperRef.current.swiper;
		const totalSlides = thumbsInstance.slides.length;

		if (pos !== activeThumb && pos >= 0 && pos <= totalSlides - 1) {
			galleryInstance.slideTo(pos, 0);
			thumbsInstance.slideTo(pos);
			setActiveThumb(pos);
		}
	};

	const onThumbNext = () => {
		onThumbClick(activeThumb + 1);
	};

	const onThumbPrev = () => {
		onThumbClick(activeThumb - 1);
	};

	const handleZoomClick = index => {
		setZoomedImageIndex(index);
	};

	const onGallerySlideChange = useCallback(() => {
		const galleryInstance = gallerySwiperRef.current.swiper;
		const thumbsInstance = thumbsSwiperRef.current.swiper;

		const activeSlide = galleryInstance.activeIndex;
		if (images[activeSlide]?.type === 'video') {
			const video = refs.current[activeSlide]?.current;
			if (video) video.play();
		}
		thumbsInstance.slideTo(activeSlide);
		setActiveThumb(activeSlide);
		updateThumbsState();

		if (galleryInstance.zoom.scale > 1) {
			galleryInstance.zoom.out();
			galleryInstance.zoom.disable();
		}
	}, []);

	useEffect(() => {
		const galleryInstance = gallerySwiperRef.current.swiper;

		if (galleryInstance) {
			galleryInstance.on('slideChange', onGallerySlideChange);
		}

		return () => {
			if (galleryInstance) {
				galleryInstance.off('slideChange', onGallerySlideChange);
			}
		};
	}, []);

	return (
		<>
			<div className={styles.gallery}>
				<div className={styles.thumbs}>
					<Swiper ref={thumbsSwiperRef} {...thumbnailSwiperParams}>
						{thumbs?.length > 0 ? (
							thumbs.map((thumb, ind) => {
								const thumbAlt = alt ? `${alt} | Фото ${ind + 1}` : '';
								const thumbTitle = title || '';
								const isVideo = thumb?.type === 'video';

								return (
									<div
										key={thumbAlt}
										className={clsx(
											styles.thumb,
											activeThumb === ind ? styles.thumbActive : styles.thumbNotActive,
											isVideo && styles.thumbIsVideo,
										)}
										onClick={() => onThumbClick(ind)}
									>
										{isVideo ? (
											<div className={styles.thumbVideo}>
												<Icon name='play' />
												<span>Видео</span>
											</div>
										) : (
											<Picture
												{...thumb}
												alt={thumbAlt}
												title={thumbTitle}
												onLoadError={onLoadingError}
											/>
										)}
									</div>
								);
							})
						) : (
							<div className={clsx(styles.thumb, styles.thumbActive)}>
								<Picture src='/images/svg/pattern-order-picture.svg' alt='image-pattern' />
							</div>
						)}
					</Swiper>

					<button
						className={clsx(styles.thumbNavBtn, styles.thumbsPrev)}
						disabled={thumbsState.isBeginning}
						onClick={onThumbPrev}
					>
						<Icon name='m-arrow-left' />
					</button>

					<button
						className={clsx(styles.thumbNavBtn, styles.thumbsNext)}
						disabled={thumbsState.isEnd}
						onClick={onThumbNext}
					>
						<Icon name='m-arrow-left' />
					</button>
				</div>

				<div className={styles.galleryMain}>
					<Swiper ref={gallerySwiperRef} {...gallerySwiperParams}>
						{images?.length > 0 ? (
							images.map((image, idx) => {
								const x2 = image?.x2;
								const imageAlt = alt ? `${alt} | Фото ${idx + 1}` : '';
								const imageTitle = title || '';
								const isVideo = image?.type === 'video';

								return (
									<div
										className={clsx(styles.galleryImg, isVideo && styles.blockVideo)}
										key={imageAlt}
										video={isVideo}
									>
										{isVideo ? (
											<video
												playsInline
												autoplay
												ref={refs.current[idx]}
												muted
												loop
												src={image.url}
												className={styles.image}
											/>
										) : (
											<div>
												<img
													className={styles.image}
													src={x2}
													alt={imageAlt}
													title={imageTitle}
													onClick={() => handleZoomClick(idx)}
													onError={onLoadingError}
												/>
											</div>
										)}
									</div>
								);
							})
						) : (
							<div className={clsx(styles.galleryImg)}>
								<img
									className={styles.patternImage}
									src='/images/svg/pattern-order-picture.svg'
									alt='image-pattern'
								/>
							</div>
						)}
					</Swiper>
				</div>
			</div>

			<Lightbox
				styles={lightBoxStyle}
				index={zoomedImageIndex}
				slides={slides}
				open={zoomedImageIndex >= 0}
				close={() => setZoomedImageIndex(-1)}
				plugins={plugins}
				render={{
					iconPrev: () =>
						isMobile ? null : <Icon className={styles.galleryIcon} name='arrow-left-gallery' />,
					iconNext: () =>
						isMobile ? null : <Icon className={styles.galleryIcon} name='arrow-right-gallery' />,
					iconClose: () => <Icon name='close-gallery' />,
					buttonZoom: () => null,
				}}
				thumbnails={{
					position: isMobile ? 'bottom' : 'start',
					vignette: false,
				}}
				zoom={{
					maxZoomPixelRatio: 2,
					zoomInMultiplier: 2,
				}}
				video={{
					controls: false,
					playsInline: true,
					autoPlay: true,
					loop: true,
				}}
				counter={{
					container: {
						style: {
							color: '#171717',
							filter: 'none',
							width: '96%',
							marginTop: 16,
							textAlign: 'center',
						},
					},
				}}
			/>
		</>
	);
});
