const SVG = ({ fill = 'currentColor', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={'#171717'}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M2.487 5.167h12.288V8.37a.579.579 0 00.574.593c.32 0 .58-.267.574-.593V5.167h5.59c.76 0 1.34.583 1.34 1.31V17.78c0 .729-.58 1.313-1.34 1.313h-5.59v-3.121a.58.58 0 00-.582-.59.58.58 0 00-.566.59v3.12H2.487c-.759 0-1.34-.583-1.34-1.311v-5.069h5.558a.576.576 0 00.583-.582.578.578 0 00-.583-.584H1.148V6.478c0-.728.58-1.31 1.339-1.31zm0-1.167A2.49 2.49 0 000 6.478V17.78a2.49 2.49 0 002.487 2.478h19.025A2.49 2.49 0 0024 17.781V6.477A2.49 2.49 0 0021.512 4H2.487z' />
		<path d='M19.348 9.27c.052 0 .103.003.152.01.393.058.793.392 1.048.858.256.466.326 1.026.212 1.365-.116.337-.307.566-1.04.562l-3.194-.019 1.442-1.858c.558-.72 1.012-.92 1.38-.919zm-7.995 0c.368-.002.82.197 1.38.918l1.44 1.859-3.191.019c-.736.004-.927-.225-1.04-.563-.117-.339-.046-.898.21-1.365.256-.466.654-.8 1.047-.856a.86.86 0 01.154-.013zm.006-1.165a1.913 1.913 0 00-.322.023c-.854.125-1.502.735-1.89 1.44-.387.705-.55 1.546-.29 2.316.26.769 1.056 1.354 2.13 1.35l2.591-.017a8.449 8.449 0 00-1.789 2.322c-.357.688.658 1.233 1.015.545a7.36 7.36 0 012.545-2.772 7.373 7.373 0 012.548 2.772.568.568 0 00.79.27.588.588 0 00.222-.815 8.464 8.464 0 00-1.79-2.322l2.595.016c1.073.005 1.87-.58 2.13-1.35.261-.77.096-1.61-.292-2.315-.386-.705-1.035-1.315-1.889-1.44-.852-.128-1.797.305-2.596 1.338l-1.716 2.217-1.717-2.217c-.698-.903-1.511-1.348-2.274-1.36z' />
	</svg>
);

export default SVG;
