import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import Swiper from 'react-id-swiper';
import { StyledFullWidthSlider } from '@/components/styles';
import useWindowSize from '@/hooks/use-window-size';
import Icon from '@/assets/icons';
import vars from '@/assets/styles/vars';

const FullWidthSlider = ({
	widthLimit = '100%',
	children,
	noFull = false,
	loading = false,
	isBigSlider = false,
	isCarousel = false,
	bigMargin,
	noSlideWidth = false,
	hideNavigationCount,
	fullWidth,
	nextImagesPage = null,
	mod,
	isCollection = false,
	isBrandDescription = false,
	isBrandDescriptionLoading = false,
	pagination = {},
	infiniteTouchLoading = false,
}) => {
	const swiperRef = useRef(null);
	const hideButtons = children.length <= hideNavigationCount;
	const { isMobile, isLaptop } = useWindowSize();

	const [navigationState, setNavigationState] = useState({
		prevDisabled: true,
		nextDisabled: false,
		locked: true,
	});

	const notHaveButtons = useMemo(() => isBrandDescription && isMobile, [
		isBrandDescription,
		isMobile,
	]);

	const countSlides = useMemo(() => {
		if (isBigSlider) {
			if (isMobile) {
				return 1;
			}

			return 2;
		}

		if (isBrandDescription) {
			return 1;
		}

		return 'auto';
	}, [isBigSlider, isMobile, isBrandDescription]);

	const spaceBetween = useMemo(() => {
		if (isBigSlider) {
			if (isLaptop) {
				return 24;
			}

			return 32;
		}

		return 0;
	}, [isBigSlider, isLaptop]);

	const params = {
		resistanceRatio: 0,
		slidesPerView: countSlides,
		pagination,
		watchOverflow: true,
		rebuildOnUpdate: /*isCollection || */ isBrandDescriptionLoading,
		observer: isCollection ? true : false,
		spaceBetween,
		watchSlidesVisibility: true,
		autoplay: isBigSlider
			? {
					delay: 5000,
					disableOnInteraction: false,
			  }
			: false,
		// allowTouchMove: !isCollection,
	};

	const updateNavigation = useCallback(() => {
		const swiperInstance = swiperRef.current.swiper;

		setNavigationState({
			prevDisabled: swiperInstance.isBeginning,
			nextDisabled: nextImagesPage ? false : swiperInstance.isEnd,
			locked: nextImagesPage ? false : swiperInstance.isLocked,
		});
	}, [nextImagesPage]);

	useEffect(() => {
		if (swiperRef.current.swiper && swiperRef.current.swiper.disabled && !loading) {
			setTimeout(() => {
				swiperRef.current.swiper.disabled = false;
			}, 500);
		}
	}, [loading]);

	const goNextOnTouch = useCallback(() => {
		if (!infiniteTouchLoading) return;
		if (!nextImagesPage) return;

		const onSuccessLoaded = index => {
			setTimeout(() => {
				swiperRef.current?.swiper.slideTo(index);
			}, 0);

			setNavigationState(prevState => ({
				...prevState,
				prevDisabled: false,
			}));
		};

		if (swiperRef?.current?.swiper) {
			const visibleSlidesCount = swiperRef.current.swiper.visibleSlidesIndexes.length;

			const sliderWidth = swiperRef.current.swiper.width;
			const slideWidth = swiperRef.current.swiper.slides[0].getBoundingClientRect().width;
			const spaceWidth = sliderWidth < 429 ? 6 : slideWidth < 250 ? 24 : 32;
			const isHalfVisibleSlide =
				sliderWidth <= vars.breakpoints.mobile
					? slideWidth * 3 + spaceWidth * 2 > sliderWidth
					: false;

			if (swiperRef.current.swiper.isEnd && !swiperRef.current.swiper.disabled) {
				swiperRef.current.swiper.disabled = true;
				const activeIndex = swiperRef.current.swiper.activeIndex;
				nextImagesPage(() => {
					onSuccessLoaded(
						activeIndex + (isHalfVisibleSlide ? visibleSlidesCount - 1 : visibleSlidesCount),
					);
				});
			}
		}
	}, [infiniteTouchLoading, nextImagesPage]);

	useEffect(() => {
		const swiperInstance = swiperRef.current.swiper;

		if (swiperInstance) {
			updateNavigation();
			swiperInstance.on('resize', updateNavigation);
			swiperInstance.on('fromEdge', updateNavigation);
			swiperInstance.on('slideChange', updateNavigation);
			swiperInstance.on('reachEnd', updateNavigation);
			swiperInstance.on('reachBeginning', updateNavigation);
			swiperInstance.on('touchEnd', goNextOnTouch);
		}

		return () => {
			if (swiperInstance) {
				swiperInstance.off('resize', updateNavigation);
				swiperInstance.off('fromEdge', updateNavigation);
				swiperInstance.off('slideChange', updateNavigation);
				swiperInstance.off('reachEnd', updateNavigation);
				swiperInstance.off('reachBeginning', updateNavigation);
				swiperInstance.off('touchEnd', goNextOnTouch);
			}
		};
	}, [updateNavigation, goNextOnTouch]);

	const goNext = () => {
		const onSuccessLoaded = newIndex => {
			setTimeout(() => {
				swiperRef.current?.swiper.slideTo(newIndex);
			}, 0);

			setNavigationState(prevState => ({
				...prevState,
				prevDisabled: false,
			}));
		};

		if (swiperRef?.current?.swiper) {
			const visibleSlidesCount = swiperRef.current.swiper.visibleSlidesIndexes.length;

			if (swiperRef.current.swiper.isEnd && nextImagesPage) {
				const activeIndex = swiperRef.current.swiper.activeIndex;

				nextImagesPage(() => {
					onSuccessLoaded(activeIndex + visibleSlidesCount);
				});
			} else {
				setTimeout(() => {
					swiperRef.current.swiper.slideNext();
				}, 100);

				if (isCollection) {
					if (swiperRef.current.swiper.activeIndex === 0) {
						setNavigationState(prevState => ({
							...prevState,
							prevDisabled: false,
						}));
					}
				}
			}
		}
	};

	const goPrev = () => {
		if (swiperRef?.current?.swiper) {
			setTimeout(() => {
				swiperRef.current.swiper.slidePrev();
			}, 0);
			if (isCollection && swiperRef.current.swiper.activeIndex === 1) {
				setNavigationState(prevState => ({
					...prevState,
					prevDisabled: true,
				}));
			}
		}
	};
	const isMini = mod === 'mini';

	const NavButtons = () => {
		return (
			<>
				<StyledFullWidthSlider.BtnWrap hide={hideButtons} centered={isCarousel && !isBigSlider}>
					<StyledFullWidthSlider.Prev
						disabled={loading || navigationState.prevDisabled}
						onClick={goPrev}
						isBigSlider
					>
						<Icon name='m-arrow-up' />
					</StyledFullWidthSlider.Prev>
				</StyledFullWidthSlider.BtnWrap>

				<StyledFullWidthSlider.BtnWrap
					right
					hide={nextImagesPage ? false : hideButtons}
					centered={isCarousel && !isBigSlider}
				>
					<StyledFullWidthSlider.Next
						disabled={loading || navigationState.nextDisabled}
						onClick={goNext}
						isBigSlider
					>
						<Icon name='m-arrow-up' />
					</StyledFullWidthSlider.Next>
				</StyledFullWidthSlider.BtnWrap>
			</>
		);
	};

	return (
		<StyledFullWidthSlider mod={mod}>
			<StyledFullWidthSlider.Container noFull={noFull} fullWidth={fullWidth}>
				<StyledFullWidthSlider.Swiper
					locked={navigationState.locked}
					widthLimit={widthLimit}
					bigMargin={bigMargin}
					isBrandDescription={isBrandDescription}
					noSlideWidth={noSlideWidth}
				>
					<Swiper ref={swiperRef} {...params}>
						{children}
					</Swiper>

					{!isMini && !notHaveButtons && <NavButtons />}
				</StyledFullWidthSlider.Swiper>
			</StyledFullWidthSlider.Container>

			{isMini && !notHaveButtons && <NavButtons isMini />}
		</StyledFullWidthSlider>
	);
};

export default FullWidthSlider;
