import { useCallback, memo } from 'react';
import { useSelector } from 'react-redux';
import { catalogSortSelector } from '@/store';
import clsx from 'clsx';
import { useNav } from '@/contexts/NavContext';
import { Burger, ClickOutside } from '@/components';
import { Container } from '@/containers';
import NavList from './navList';
import styles from './Navigate.module.scss';

const Navigate = memo(() => {
	const { visibility, toggleMenu } = useNav();
	const sort = useSelector(catalogSortSelector);

	const visible = visibility.menu;

	const onOpenMenu = useCallback(() => {
		if (!visible) {
			toggleMenu();
		}
	}, [visible, toggleMenu]);

	const onCloseMenu = useCallback(() => {
		if (visible) {
			toggleMenu();
		}
	}, [visible, toggleMenu]);

	return (
		<nav className={styles.nav}>
			<div className={styles.toggle}>
				<Burger open={visible} onClick={onOpenMenu}>
					<span>Каталог</span>
				</Burger>
			</div>
			<div className={clsx(styles.wrapper, { [styles.visible]: visible })}>
				<Container>
					<ClickOutside onClickOutside={onCloseMenu}>
						<div className={styles.listWrapp}>
							<div className={styles.listScroll}>
								<NavList toggleVisibility={onCloseMenu} savedQuery={sort || {}} visible={visible} />
							</div>
						</div>
					</ClickOutside>
				</Container>
			</div>
		</nav>
	);
});

export default Navigate;
