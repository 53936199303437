// pages
export { default as CartPage } from './CartPage';
// export { default as SuccessReturnPage } from './SuccessReturnPage';
export { default as CatalogPage } from './CatalogPage';
// export { default as ContactsPage } from './ContactsPage';
// export { default as CheckoutSuccessPage } from './CheckoutSuccessPage';
// export { default as CheckoutRedirectPage } from './CheckoutRedirectPage';
// export { default as CheckoutFailurePage } from './CheckoutFailurePage';
export { default as LoginPage } from './LoginPage';
// export { default as NewsPage } from './NewsPage';
// export { default as ProductPage } from './ProductPage';
// export { default as OrderPage } from './OrderPage';
// export { default as RemoveProfilePage } from './RemoveProfilePage';
// export { default as SpecialOffersPage } from './SpecialOffersPage';
export { default as TextPage } from './TextPage';
// export { default as UserPage } from './UserPage';
export { default as WishlistPage } from './WishlistPage';
// export { default as ReturnProcessPage } from './ReturnProcessPage';
// export { default as CatalogSeoPage } from './CatalogSeoPage';
// export { default as ReturnPolicyPage } from './ReturnPolicyPage';
export { default as Page } from './Page';
export { default as Scripts } from './Scripts';
