import clsx from 'clsx';
import styles from './Block.module.scss';

const Block = ({ children, noMargin, className, shopsOnMap }) => {
	return (
		<div
			className={clsx(
				styles.block,
				noMargin && styles.noMargin,
				shopsOnMap && styles['shops-on-map'],
				className,
			)}
		>
			{children}
		</div>
	);
};

export default Block;
