import NProgress from 'nprogress';
import { useEffect } from 'react';
import Router from 'next/router';
import Head from 'next/head';
import * as Sentry from '@sentry/nextjs';
import { MobileNav, MobileUserNav, Title, Button } from '@/components';
import { Footer, Container, Header } from '@/containers';
// import Header from '@/containers/header/OldHeader';
import { ActiveLink, Control } from '@/components';

import styles from './Error.module.scss';

const Error = ({ statusCode = 500 }) => {
	const is404page = +statusCode === 404;
	const title404 = 'Ошибка 404. Страница не найдена';
	const title500 = 'Ошибка обращения к серверу';

	useEffect(() => {
		NProgress.done(true);
	}, []);

	return (
		<>
			<Head>
				<title>{is404page ? title404 : title500}</title>
			</Head>
			{is404page && (
				<>
					<MobileNav />
					<MobileUserNav />
					<Header />
				</>
			)}
			<div className={styles.error}>
				{!is404page && (
					<div className={styles.logo}>
						<ActiveLink href='/' as='/'>
							<Control>
								<img
									src='/images/fhby.webp'
									className={styles.logoImg}
									alt='FH.BY - интернет-магазин одежды, обуви и аксессуаров мировых брендов'
								/>
							</Control>
						</ActiveLink>
					</div>
				)}

				<Container className={styles.container}>
					<div className={styles.number}>{is404page ? '404' : '500'}</div>
					<Title component='h2' className={styles.title500}>
						{title500}
					</Title>

					<div className={styles.text500}>
						{is404page
							? 'Возможно, она была удалена или ее адрес введен неверно. Попробуйте перейти на другую страницу или вернуться на главную.'
							: 'Мы уже устраняем неисправность, попробуйте обновить страницу через некоторое время. Приносим извинения за временные неудобства.'}
					</div>

					<Button
						type='primary'
						onClick={() => {
							if (is404page) {
								Router.push('/');
							} else {
								Router.reload();
							}
						}}
						classNames={{ general: styles.button500 }}
					>
						{is404page ? 'Вернуться на главную' : 'Обновить страницу'}
					</Button>
					{!is404page && (
						<a href='/' className={styles.link}>
							На главную
						</a>
					)}
				</Container>

				{is404page && <Footer />}
			</div>
		</>
	);
};

Error.getInitialProps = ({ res, err }) => {
	let statusCode;

	if (err) {
		Sentry.captureException(err);
	}

	if (res) {
		statusCode = res.statusCode;
	} else {
		statusCode = err ? err.statusCode : 404;
	}

	return { statusCode };
};

export default Error;
