import { useRef, useEffect, useCallback, memo } from 'react';
import { useNav } from '@/contexts/NavContext';
import { ActiveLink, UserNav, LogoutModal } from '@/components';
import clsx from 'clsx';
import styles from './MobileUserNav.module.scss';

const UserNavItem = ({ link, onClick }) => {
	const { href, as, title, isLogout = false } = link;

	return (
		<li>
			{isLogout ? (
				<button className={styles.profileLink} aria-label='Выйти'>
					<LogoutModal />
				</button>
			) : (
				<ActiveLink href={href} as={as} activeClassName={styles.profileLink}>
					<a className={styles.profileLink} onClick={onClick}>
						{title}
					</a>
				</ActiveLink>
			)}
		</li>
	);
};

const MemoizedMobileUserNav = memo(function MobileUserNav({
	visibility,
	toggleMobileUserNav,
	hasBanner,
}) {
	const isVisible = visibility;

	const wrapper = useRef(null);

	const onClickOutside = e => {
		if (isVisible && (!wrapper?.current?.contains(e.target) || wrapper.current === e.target)) {
			toggleMobileUserNav();
		}
	};

	const onToggleClick = useCallback(e => {
		e?.stopPropagation();
		toggleMobileUserNav();
	}, []);

	useEffect(() => {
		window.addEventListener('click', onClickOutside);
		return () => window.removeEventListener('click', onClickOutside);
	}, [isVisible]);

	return (
		<div className={clsx(styles.container, isVisible && styles.visibleContainer)} ref={wrapper}>
			<div className={clsx(styles.menu, hasBanner && styles.menuBanner)}>
				<div className={styles.menuInner}>
					<div className={styles.level}>
						<div className={styles.levelInner}>
							<ul className={styles.profileList}>
								<UserNav render={UserNavItem} onClick={onToggleClick} />
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
});
const MobileUserNav = ({ hasBanner }) => {
	const { visibility, toggleMobileUserNav } = useNav();
	return (
		<MemoizedMobileUserNav
			visibility={visibility.mobileUserNav}
			toggleMobileUserNav={toggleMobileUserNav}
			hasBanner={hasBanner}
		/>
	);
};

export default MobileUserNav;
