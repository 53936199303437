import styled from 'styled-components';
import qs from 'qs';
import routesDict from '@/utils/routesDict.js';
import { useHost } from '@/contexts/HostContext';
import { ActiveLink } from '@/components';

const TemplateLink = styled.a`
	display: block;
`;

export default function RoutedLink({
	to,
	component: Component = TemplateLink,
	children,
	shallow = false,
	...props
}) {
	const host = useHost();

	const origin = `^(http|https):\/\/${host}`;
	const relativeAs = to.replace(new RegExp(origin), '');
	const relativeAsWithoutQueries = relativeAs.split('?')[0] || '';
	const queries = qs.parse(relativeAs.split('?')[1] || '');

	let href = '';
	for (let index = 0; index < routesDict.length; index++) {
		const { reg } = routesDict[index];
		if (reg.test(relativeAsWithoutQueries)) {
			href = routesDict[index].route;
			break;
		}
	}

	return href ? (
		<ActiveLink
			href={{ pathname: href, query: queries || {} }}
			as={{ pathname: relativeAsWithoutQueries, query: queries || {} }}
			shallow={shallow}
		>
			<Component {...props}>{children}</Component>
		</ActiveLink>
	) : (
		<Component href={to} {...props}>
			{children}
		</Component>
	);
}
