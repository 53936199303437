const SVG = ({
	fill = '#292C33',
	width = '18',
	height = '19',
	viewBox = '0 0 18 19',
	className,
}) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		className={className}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M15.426 17.794a.956.956 0 0 0 1.377.027c.385-.38.397-1.01.027-1.405l-.073.068.073-.068-3.885-4.145a7.05 7.05 0 0 0 1.537-4.407C14.482 4.026 11.438.9 7.692.9 3.943.9.9 4.026.9 7.864c0 3.837 3.044 6.964 6.791 6.964 1.377 0 2.69-.418 3.82-1.21l3.915 4.176zM7.69 2.89c2.67 0 4.846 2.228 4.846 4.973 0 2.744-2.176 4.973-4.846 4.973s-4.845-2.229-4.845-4.973c0-2.745 2.176-4.973 4.845-4.973z'
			fill={fill}
			stroke={fill}
			strokeWidth='0.2'
		/>
	</svg>
);

export default SVG;
