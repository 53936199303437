const SVG = ({ fill = 'none', width = '24', height = '24', viewBox = '0 0 24 24' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M6 15L11.6569 9.34315L17.3137 15' stroke='#292C33' />
	</svg>
);

export default SVG;
