const wordEnd = (number, textFormsFor125 = []) => {
	const num = Math.abs(number) % 100;
	const num1 = num % 10;
	if (num > 10 && num < 20) {
		return textFormsFor125[2];
	}
	if (num1 > 1 && num1 < 5) {
		return textFormsFor125[1];
	}
	if (num1 === 1) {
		return textFormsFor125[0];
	}
	return textFormsFor125[2];
};

export default wordEnd;
