const SVG = ({
	fill = 'currentColor',
	width = '11',
	height = '11',
	viewBox = '0 0 11 11',
	className,
	onClick,
	...props
}) => (
	<svg
		width='12'
		height='12'
		viewBox='0 0 12 12'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
		className={className}
		onClick={onClick}
	>
		<rect width='12' height='12' rx='6' fill='white' />
		<rect
			x='0.25'
			y='0.25'
			width='11.5'
			height='11.5'
			rx='5.75'
			fill='white'
			stroke='#F0F0F0'
			stroke-width='0.5'
		/>
		<path d='M3.45714 3L9 8.543L8.54301 9L3.00015 3.457L3.45714 3Z' fill='#959595' />
		<path d='M8.54286 3L3 8.543L3.45699 9L8.99985 3.457L8.54286 3Z' fill='#959595' />
	</svg>
);

export default SVG;
