const SVG = ({
	fill = 'currentColor',
	width = '19',
	height = '20',
	viewBox = '0 0 19 20',
	className = '',
}) => (
	<svg
		className={className}
		width={width}
		height={height}
		viewBox={viewBox}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path d='M18 2L17.5 1.5L0.5 8V9L8.5 11L10.5 19H11.5L18 2Z' stroke='#171717' />
	</svg>
);

export default SVG;
