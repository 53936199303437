const SVG = ({ fill = 'currentColor', width = '24', height = '23', viewBox = '0 0 40 40' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M20.241 0C9.20314 0 0.253906 8.94924 0.253906 19.9871C0.253906 28.455 5.52218 35.687 12.9574 38.5985C12.7825 37.0178 12.6243 34.5911 13.0273 32.8655C13.3904 31.3065 15.3708 22.9302 15.3708 22.9302C15.3708 22.9302 14.7729 21.7327 14.7729 19.9638C14.7729 17.1839 16.3835 15.1103 18.3889 15.1103C20.0945 15.1103 20.9173 16.3911 20.9173 17.9251C20.9173 19.6407 19.8263 22.204 19.2617 24.5792C18.7903 26.5696 20.2594 28.1918 22.2214 28.1918C25.7741 28.1918 28.504 24.4459 28.504 19.0394C28.504 14.2542 25.0663 10.908 20.1561 10.908C14.4697 10.908 11.1319 15.1736 11.1319 19.5824C11.1319 21.2996 11.7931 23.1418 12.6193 24.1444C12.7825 24.3427 12.8058 24.5159 12.7575 24.7174C12.6059 25.3487 12.2695 26.7061 12.2029 26.9843C12.1163 27.3507 11.9131 27.4273 11.535 27.2508C9.03825 26.0882 7.47759 22.4389 7.47759 19.5074C7.47759 13.2032 12.058 7.41189 20.6841 7.41189C27.6179 7.41189 33.0061 12.352 33.0061 18.9561C33.0061 25.845 28.6623 31.3881 22.6345 31.3881C20.6091 31.3881 18.7054 30.3355 18.0541 29.0929C18.0541 29.0929 17.0514 32.9088 16.8082 33.8449C16.3569 35.5804 15.1393 37.7573 14.3232 39.0848C16.1936 39.6611 18.1807 39.9742 20.241 39.9742C31.2789 39.9742 40.2282 31.025 40.2282 19.9871C40.2282 8.94924 31.2789 0 20.241 0Z'
			fill='black'
		/>
	</svg>
);

export default SVG;
