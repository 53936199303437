const SVG = ({
	fill = 'currentColor',
	width = '14',
	height = '8',
	viewBox = '0 0 11 11',
	className,
	onClick,
}) => (
	<svg
		className={className}
		onClick={onClick}
		width='12'
		height='12'
		viewBox='0 0 12 12'
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6C1 8.76142 3.23858 11 6 11Z'
			stroke='#EA5656'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path
			d='M4.54492 4.49906C4.66247 4.1649 4.8945 3.88312 5.1999 3.70363C5.5053 3.52414 5.86437 3.45853 6.21351 3.51842C6.56265 3.57831 6.87933 3.75983 7.10746 4.03083C7.33559 4.30183 7.46045 4.64483 7.45992 4.99906C7.45992 5.99906 5.95992 6.49906 5.95992 6.49906'
			stroke='#EA5656'
			strokeLinecap='round'
			strokeLinejoin='round'
		/>
		<path d='M6 8.5H6.00563' stroke='#EA5656' strokeLinecap='round' strokeLinejoin='round' />
	</svg>
);

export default SVG;
