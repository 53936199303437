import {
	BrandInfoBlock,
	ButtonBlock,
	DividerBlock,
	GalleryBlock,
	DoubleGalleryBlock,
	HtmlBlock,
	MarqueeBlock,
	NavigationPicturesOldBlock,
	NavigationPicturesBlock,
	PictureHtmlBlock,
	ProductsSliderBlock,
	ShopsOnMapBlock,
	TitleDescLinkBlock,
	WidePictureBlock,
	WidePictureNoTextBlock,
	YoutubePlayerBlock,
	FourPicturesBlock,
	NavigationThreePicturesBlock,
	H1Block,
	LookImageBlock,
	LookImageSliderBlock,
	OffsetBlock,
	CarouselBlock,
	TextWithSidePictureBlock,
	NoResultBlock,
	OneImageBlock,
	HtmlLinksBlock,
	SelectionsCatalogBlock,
	ShortsYoutubePlayer,
} from '@/components';

import styles from './Blocks.module.scss';
import useWindowSize from '@/hooks/use-window-size';
import getShortsId from '@/utils/getShortsId';
import { useEffect, useState } from 'react';

const Blocks = ({ blocks = [], isCatalog = false }) => {
	// Please, don't move this object outside, thank you
	const components = {
		one_slider: GalleryBlock,
		button: ButtonBlock,
		two_sliders: DoubleGalleryBlock,
		wysiwyg: HtmlBlock,
		map_with_shops: ShopsOnMapBlock,
		marquee: MarqueeBlock,
		separator: DividerBlock,
		title_desc_link: TitleDescLinkBlock,
		youtube: YoutubePlayerBlock,
		one_picture: NavigationPicturesOldBlock,
		one_picture_different_device: OneImageBlock,
		two_pictures: NavigationPicturesBlock,
		three_pictures: NavigationThreePicturesBlock,
		wide_picture: WidePictureBlock,
		text_with_picture: TextWithSidePictureBlock,
		wide_picture_no_text: WidePictureNoTextBlock,
		picture_wysiwyg: PictureHtmlBlock,
		products: ProductsSliderBlock,
		selections_products: ProductsSliderBlock,
		look_with_image: LookImageBlock,
		look_slider: ProductsSliderBlock,
		look_slider_with_image: LookImageSliderBlock,
		'brand-info': BrandInfoBlock,
		four_pictures: FourPicturesBlock,
		no_result_text_for_search: NoResultBlock,
		catalog_based_on_selections: SelectionsCatalogBlock,
		h1: H1Block,
		offset: OffsetBlock,
		carousel: CarouselBlock,
		big_slides: CarouselBlock,
		text_with_links: HtmlLinksBlock,
	};

	const { isSmallMobile } = useWindowSize();
	const [isMobile, setIsMobile] = useState(false);
	useEffect(() => {
		setIsMobile(isSmallMobile)
	}, [isSmallMobile])
	// console.log("🚀 ~ {blocks.map ~ blocks:", blocks)

	return (
		<div className={!isCatalog ? styles.blocks : undefined}>
			{blocks.map((block, ind) => {
				let { type, is_active, id, data = {}, ...other } = block || {};
				const Component = components[type];
				// const blockPosition = ind + 1;
				// const isCustomOffsetBlock =
				//   isCustomOffsetRoute && positionsForCustomOffsets.includes(blockPosition);

				if (!Component || !is_active) {
					return null;
				}

				if (type === 'one_picture' && blocks[ind - 1]?.type === 'offset') {
					return <Component {...data} {...other} key={id || ind} noMargin />;
				}

				if (type === 'youtube') {

					// for mobile
					if (isMobile) {
						if (!data.shorts && !data.video_url) {
							return null;
						}

						if (data.shorts) {
							return (
								<div className={styles.customWrapper} key={id || ind}>
									<ShortsYoutubePlayer id={getShortsId(data.shorts)} />
								</div>
							);
						}
					}

					// for desktop
					if (!data.video_url) {
						return null;
					}

					return (
						<div className={styles.customWrapper} key={id || ind}>
							<Component {...data} {...other} key={id || ind} />
						</div>
					);
				}

				if (type === 'one_picture') {
					const onePictureData = {
						items: [
							{
								image: data.image.links,
								title: data.title.text,
								description: data.description.text,
								url: data.url.link,
							},
						],
					};

					return <Component {...onePictureData} {...other} key={id || ind} noMargin />;
				}

				if (type === 'big_slides') {
					return (
						<div className={styles.customWrapper} key={id || ind}>
							<Component {...data} {...other} isBigSlider key={id || ind} />
						</div>
					);
				}

				return (
					<div className={styles.customWrapper} key={id || ind}>
						<Component {...data} {...other} key={id || ind} />
					</div>
				);
			})}
		</div>
	);
};

export default Blocks;
