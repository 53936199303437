import { memo, useRef, useState } from 'react';
import clsx from 'clsx';
import { ActiveLink, RoutedLink, Control, Button } from '@/components';
import getLinkProps from '@/lib/getLinkProps';
import styles from './ItemWithDropdown.module.scss';

const MAX_NAV_LEVEL = 3;

const ItemLink = ({ category: { path, link, type }, savedQuery, children, ...props }) => {
	const notCatalogLink = type !== 'menu' && type !== 'category' && type !== 'brand';
	const routeType = 'catalog';

	const linkHref = notCatalogLink ? link : getLinkProps({ type: routeType, value: path })[0];
	const linkAs = notCatalogLink ? null : getLinkProps({ type: routeType, value: path })[1];

	return notCatalogLink ? (
		<RoutedLink to={linkHref} component={Control} {...props}>
			{children}
		</RoutedLink>
	) : (
		<ActiveLink
			href={{ pathname: linkHref, query: savedQuery || {} }}
			as={{ pathname: linkAs, query: savedQuery || {} }}
		>
			<Control {...props}>{children}</Control>
		</ActiveLink>
	);
};

const ItemWithDropdown = memo(props => {
	const [openedAll, setOpenedAll] = useState({});

	const {
		id,
		name,
		path,
		children,
		type,
		link,
		onClick,
		lastItemId,
		setLastItemId,
		is_blanked,
		view_type,
		banner,
		savedQuery,
		color,
	} = props;
	const visible = id === lastItemId.opened;

	const openTimeoutId = useRef(null);
	const closeTimeoutId = useRef(null);

	const onLinkEnter = () => {
		if (closeTimeoutId.current) {
			// enter the same link again, clear close timeout
			clearTimeout(closeTimeoutId.current);
			openTimeoutId.current = null;
			closeTimeoutId.current = null;
		} else {
			openTimeoutId.current = setTimeout(() => {
				// open menu
				setLastItemId(prev => ({ ...prev, opened: id }));
				openTimeoutId.current = null;
			}, 150);
		}
	};

	const onLinkLeave = () => {
		if (openTimeoutId.current) {
			// leave before open
			clearTimeout(openTimeoutId.current);
			openTimeoutId.current = null;
			closeTimeoutId.current = null;
		} else {
			setLastItemId(prev => ({ ...prev, leaved: id }));

			closeTimeoutId.current = setTimeout(() => {
				// close menu
				openTimeoutId.current = null;
				closeTimeoutId.current = null;
				setLastItemId(prev => ({ ...prev, opened: id }));
			}, 150);
		}
	};

	const onClickItem = () => {
		clearTimeout(openTimeoutId.current);
		clearTimeout(closeTimeoutId.current);
		openTimeoutId.current = null;
		closeTimeoutId.current = null;

		if (onClick) {
			onClick();
		}
	};

	return (
		<li className={styles.item} onMouseLeave={onLinkLeave} onMouseEnter={onLinkEnter} key={id}>
			<ItemLink
				category={{ path, type, link }}
				className={clsx(styles.link, styles.level1, styles[view_type], {
					[styles.isOpened]:
						id === lastItemId.opened ||
						(lastItemId.opened === null && id === lastItemId.leaved && visible),
				})}
				onClick={onClickItem}
				{...is_blanked && { target: '_blank', rel: 'noopener noreferrer' }}
				savedQuery={savedQuery}
			>
				<span style={color ? { color } : {}}>{name}</span>
			</ItemLink>

			{(function renderCategories(categories, level, catId, bannerData) {
				if (level > MAX_NAV_LEVEL) return;
				return children && children.length > 0 ? (
					<div
						className={clsx(
							styles[`listWrap${level}`],
							{ [styles.visible]: visible },
							{ [styles.leaved]: id === lastItemId.leaved },
						)}
					>
						{visible && (
							<div className={styles[`listScroll${level}`]}>
								<ul className={styles[`list${level}`]}>
									{categories.map((category, idx) => {
										const {
											id,
											name,
											path,
											children,
											type,
											link,
											is_blanked,
											view_type,
											color,
										} = category;

										return (
											<li
												className={clsx(styles.item, {
													[styles.hidden]: idx > 4 && level === 3 && !openedAll[catId],
												})}
												key={id}
											>
												<ItemLink
													category={{ path, type, link }}
													className={clsx(styles.link, styles[`level${level}`], styles[view_type])}
													onClick={onClickItem}
													savedQuery={savedQuery}
													{...is_blanked && { target: '_blank', rel: 'noopener noreferrer' }}
												>
													<span style={color && color !== '' ? { color } : {}}>{name}</span>
												</ItemLink>

												{children &&
													children.length > 0 &&
													renderCategories(children, level + 1, category.id)}

												{children.length > 5 && level === 2 && (
													<div className={styles.btnContainer}>
														<Button
															type='link'
															action='button'
															classNames={{ general: styles.sectionButton }}
															onClick={() => {
																setOpenedAll(prev => ({
																	...prev,
																	[category.id]: !prev[category.id],
																}));
															}}
														>
															{openedAll[id] ? 'Свернуть разделы' : 'Все разделы'}
														</Button>
													</div>
												)}
											</li>
										);
									})}
								</ul>
							</div>
						)}
					</div>
				) : null;
			})(children, 2, banner)}
		</li>
	);
});

export default ItemWithDropdown;
