import { memo } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { MobileNav, MobileUserNav, Notifications } from '@/components';
import { Footer, NavPopular, Header } from '@/containers';
import { hasSmartBannerSelector } from '@/store';
import { useWindowSize } from '@/hooks';

import styles from './Page.module.scss';

const Page = ({ isMobile, children }) => {
	const hasSmartBanner = useSelector(hasSmartBannerSelector);
	const { isDesktop } = useWindowSize();
	const router = useRouter();
	const isReturnProcess =
		router.pathname.includes('new-return') || router.pathname.includes('success-return');

	return (
		<div className={styles.container}>
			{!isDesktop && <MobileNav hasBanner={hasSmartBanner} />}
			<MobileUserNav hasBanner={hasSmartBanner} />
			<Header hasServerBanner={isMobile} />

			<main className={styles.content}>
				{!isReturnProcess && <NavPopular />}
				{children}
			</main>

			<Footer />
			<Notifications />
		</div>
	);
};

export default memo(Page);
