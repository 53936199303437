const SVG = ({ fill = 'currentColor', width = '24', height = '23', viewBox = '0 0 40 40' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill='none'
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M20.8523 0C9.81607 0 0.837402 9.03514 0.837402 20.1408C0.837402 30.2172 8.2369 38.5645 17.85 40.0297V25.1759H13.8471C13.2947 25.1759 12.8463 24.7258 12.8463 24.1689V21.1478C12.8463 20.5909 13.2947 20.1408 13.8471 20.1408H17.85V16.4832C17.85 11.6988 20.2788 9.06334 24.6891 9.06334C26.4765 9.06334 27.8865 9.18821 27.9455 9.19324C28.4619 9.23957 28.8582 9.67461 28.8582 10.1963V13.595C28.8582 14.1519 28.4099 14.602 27.8575 14.602H25.856C24.7522 14.602 23.8545 15.5054 23.8545 16.6161V20.1408H27.8575C28.1447 20.1408 28.4179 20.2646 28.608 20.4811C28.7982 20.6986 28.8862 20.9867 28.8512 21.2727L28.4759 24.2938C28.4129 24.7973 27.9866 25.1759 27.4822 25.1759H23.8545V40.0297C33.4676 38.5645 40.8671 30.2172 40.8671 20.1408C40.8671 9.03514 31.8885 0 20.8523 0Z'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
