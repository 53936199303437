import { Block } from '@/containers';
import styles from './Marquee.module.scss';

const Marquee = props => {
	const { text } = props;

	if (!text) {
		return null;
	}
	const animationDuration = (text.length / 14).toFixed(0);

	return (
		<Block>
			<div className={styles.marquee}>
				<div
					className={styles['marquee-content']}
					style={{ animationDuration: `${animationDuration >= 20 ? animationDuration : 20}s` }}
				>
					{text}
				</div>
			</div>
		</Block>
	);
};

export default Marquee;
