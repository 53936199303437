const SVG = ({ fill = 'currentColor', width = '11', height = '11', viewBox = '0 0 11 11' }) => (
	<svg width='10' height='8' viewBox='0 0 10 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M10 1.40394L3.45155 8L0 4.51608L1.35426 3.11215L3.45155 5.23195L8.64574 0L10 1.40394Z'
			fill='#0FB769'
		/>
	</svg>
);

export default SVG;
