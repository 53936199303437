import React from 'react';
import { Block, Container } from '@/containers';
import useLocalStorage from '@/hooks/use-local-storage';
import { Button } from '@/components';

import styles from './NoResult.module.scss';

const NoResult = ({
	button_text: button_textBlock,
	description: descriptionBlock,
	title: titleBlock,
}) => {
	const [searchString, _setSearchString] = useLocalStorage('searchString', '');

	const buttonText = button_textBlock ? button_textBlock : 'Изменить запрос';
	const description = descriptionBlock
		? descriptionBlock
		: 'К сожалению, в данный момент по вашему запросу ничего не нашлось. Но мы подготовили для вас много интересных рекомендаций, которые должны вам понравится.';
	const title = titleBlock ? titleBlock : 'Результаты поиска';

	const handleClick = () => {
		const search = document.getElementById('search-button');

		search.click();
	};

	return (
		<Block>
			<Container className={styles.container}>
				<div className={styles.textBlock}>
					<div className={styles.title}>
						{title} {searchString && searchString.length > 0 ? `«${searchString}»` : ''}
					</div>
					<div className={styles.description}>{description}</div>
				</div>
				<div className={styles.buttonBlock}>
					<Button type='defaultBlack' classNames={{ general: styles.button }} onClick={handleClick}>
						{buttonText}
					</Button>
				</div>
			</Container>
		</Block>
	);
};

export default NoResult;
