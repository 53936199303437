import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import useEventListener from './use-event-listener';

const FPS = 1000 / 60;
const initialWindowWidth = 9999;

const useWindowSize = () => {
	const [windowWidth, setWindowWidth] = useState(
		typeof window !== 'undefined' ? window.innerWidth : initialWindowWidth,
	);
	const lastUpdate = useRef(Date.now());
	const raf = useRef(0);

	const handleResize = useCallback(() => {
		if (typeof window === 'undefined') {
			return;
		}

		if (Date.now() - lastUpdate.current < FPS) {
			raf.current = requestAnimationFrame(handleResize);
			return;
		}

		cancelAnimationFrame(raf.current);

		document.documentElement.style.setProperty('--vh', `${window.innerHeight / 100}px`);
		setWindowWidth(window.innerWidth || 0);
		lastUpdate.current = Date.now();
	}, []);

	useEffect(() => {
		handleResize();
	}, [handleResize]);

	useEffect(() => {
		if (typeof window !== 'undefined' && window.innerWidth !== windowWidth) {
			setWindowWidth(window.innerWidth);
		}
	}, [windowWidth]);

	useEventListener('resize', handleResize, typeof window !== 'undefined' ? window : undefined);

	const isSmallMobile = useMemo(() => windowWidth <= 500, [windowWidth]);
	const isCollectionMobile = useMemo(() => windowWidth <= 650, [windowWidth]);
	const isMobile = useMemo(() => windowWidth <= 767, [windowWidth]);
	const isMobileTablet = useMemo(() => windowWidth <= 768, [windowWidth]);
	const isMobileMenu = useMemo(() => windowWidth <= 1024, [windowWidth]);
	const isSmallTablet = useMemo(() => windowWidth >= 768 && windowWidth < 883, [windowWidth]);
	const isLaptop = useMemo(() => windowWidth >= 768 && windowWidth <= 1024, [windowWidth]);
	const isTablet = useMemo(() => windowWidth >= 768 && windowWidth < 1280, [windowWidth]);
	const isDesktop = useMemo(() => windowWidth >= 1280, [windowWidth]);
	const isWidthForSnowflakesCount = useMemo(
		() => isTablet || (windowWidth < 650 && windowWidth > 520) || windowWidth >= 1440,
		[windowWidth, isTablet],
	);

	return {
		isDesktop,
		isTablet,
		isMobile,
		isSmallMobile,
		isMobileTablet,
		isSmallTablet,
		isCollectionMobile,
		isLaptop,
		windowWidth,
		isMobileMenu,
		initialWindowWidth,
		isWidthForSnowflakesCount,
	};
};

export default useWindowSize;
