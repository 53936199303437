import { useContext, useState, createContext, useCallback } from 'react';
import { nanoid } from 'nanoid';

const NotificationContext = createContext();

const getNotificationConfig = config => {
	return {
		content: config.content || <span />,
		id: config.id || nanoid(),
		closable: config.closable ?? true,
		onClose: config.onClose || null,
		duration: config.duration || null,
		style: config.style || 'error', // 'error' || 'warning'
	};
};

export const NotificationProvider = ({ children }) => {
	const [notifications, setNotifications] = useState([]);

	const close = useCallback(id => {
		let onClose = null;

		setNotifications(prev =>
			prev.filter(item => {
				if (item.id === id) {
					onClose = item.onClose;
					return false;
				}
				return true;
			}),
		);

		if (onClose) onClose();
	}, []);

	const add = useCallback(
		config => {
			const cfg = getNotificationConfig(config);

			if (cfg.duration) {
				setTimeout(() => {
					close(cfg.id);
				}, cfg.duration);
			}

			setNotifications(prev => [...prev, cfg]);
		},
		[close],
	);

	return (
		/* eslint-disable-next-line react/jsx-no-constructed-context-values */
		<NotificationContext.Provider value={{ list: notifications, add, close }}>
			{children}
		</NotificationContext.Provider>
	);
};

export const useNotification = () => {
	return useContext(NotificationContext);
};
