const SVG = ({ fill = 'currentColor', width = '24', height = '23', viewBox = '0 0 40 40' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<path
			d='M20.5242 0C9.51597 0 0.509277 9.00669 0.509277 20.0149C0.509277 31.0231 9.51597 40.0297 20.5242 40.0297C31.5323 40.0297 40.539 31.0231 40.539 20.0149C40.539 9.00669 31.5323 0 20.5242 0ZM26.9289 30.4226C26.7288 31.2232 25.728 31.4234 25.1276 31.2232H24.9274L19.5234 27.0201L15.9207 30.0223C15.7206 30.2225 15.5204 30.2225 15.3203 30.0223L15.9207 24.0178V23.8177C15.9207 23.8177 25.728 15.0112 26.1283 14.6109C26.7288 14.4107 26.5286 14.2106 26.5286 14.2106C26.5286 13.8103 25.728 14.2106 25.728 14.2106L12.7183 22.6168L7.31433 20.8155C7.31433 20.8155 6.51374 20.6153 6.31359 19.8147C6.31359 19.2143 7.31433 18.814 7.31433 18.814L28.7302 10.2076C28.7302 10.2076 30.5316 9.40699 30.5316 10.808L26.9289 30.4226Z'
			fill='#171717'
		/>
	</svg>
);

export default SVG;
