const SVG = ({ fill = 'none', width = '14', height = '21', viewBox = '0 0 14 21' }) => (
	<svg
		width={width}
		height={height}
		viewBox={viewBox}
		fill={fill}
		xmlns='http://www.w3.org/2000/svg'
	>
		<mask id='path-1-inside-1_10754_197926' fill='white'>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.21846 1.1176C11.3104 1.78869 13.0123 3.55324 13.6596 5.72169C14.357 8.05592 13.972 10.4789 12.6041 12.3694L7.79982 18.7559C7.39978 19.2876 6.60164 19.2877 6.20158 18.7559L1.39732 12.37C0.483301 11.1075 0 9.60298 0 8.01939C0 5.72385 1.06548 3.53903 2.85038 2.17515C4.64853 0.801786 7.00431 0.40666 9.21846 1.1176ZM7 11.2467C8.61083 11.2467 9.91667 9.91421 9.91667 8.27051C9.91667 6.6268 8.61083 5.29432 7 5.29432C5.38917 5.29432 4.08333 6.6268 4.08333 8.27051C4.08333 9.91421 5.38917 11.2467 7 11.2467Z'
			/>
		</mask>
		<path
			fillRule='evenodd'
			clipRule='evenodd'
			d='M9.21846 1.1176C11.3104 1.78869 13.0123 3.55324 13.6596 5.72169C14.357 8.05592 13.972 10.4789 12.6041 12.3694L7.79982 18.7559C7.39978 19.2876 6.60164 19.2877 6.20158 18.7559L1.39732 12.37C0.483301 11.1075 0 9.60298 0 8.01939C0 5.72385 1.06548 3.53903 2.85038 2.17515C4.64853 0.801786 7.00431 0.40666 9.21846 1.1176ZM7 11.2467C8.61083 11.2467 9.91667 9.91421 9.91667 8.27051C9.91667 6.6268 8.61083 5.29432 7 5.29432C5.38917 5.29432 4.08333 6.6268 4.08333 8.27051C4.08333 9.91421 5.38917 11.2467 7 11.2467Z'
			fill='#0FB769'
		/>
		<path
			d='M13.6596 5.72169L12.7014 6.00775L12.7015 6.00794L13.6596 5.72169ZM9.21846 1.1176L8.91274 2.06972L8.913 2.0698L9.21846 1.1176ZM12.6041 12.3694L13.4032 12.9706L13.4088 12.9632L13.4143 12.9556L12.6041 12.3694ZM7.79982 18.7559L7.00069 18.1547H7.00069L7.79982 18.7559ZM6.20158 18.7559L7.00069 18.1547L7.00069 18.1547L6.20158 18.7559ZM1.39732 12.37L0.587319 12.9564L0.592698 12.9638L0.598213 12.9712L1.39732 12.37ZM2.85038 2.17515L2.2434 1.38043L2.24323 1.38057L2.85038 2.17515ZM14.6178 5.43563C13.8795 2.96251 11.9413 0.940864 9.52392 0.165393L8.913 2.0698C10.6796 2.63652 12.145 4.14396 12.7014 6.00775L14.6178 5.43563ZM13.4143 12.9556C14.9736 10.8006 15.3996 8.0525 14.6178 5.43544L12.7015 6.00794C13.3143 8.05934 12.9704 10.1573 11.794 11.7832L13.4143 12.9556ZM8.59895 19.357L13.4032 12.9706L11.805 11.7683L7.00069 18.1547L8.59895 19.357ZM5.40247 19.3571C6.20258 20.4206 7.79887 20.4206 8.59895 19.357L7.00069 18.1547H7.00069L5.40247 19.3571ZM0.598213 12.9712L5.40247 19.3571L7.00069 18.1547L2.19643 11.7688L0.598213 12.9712ZM-1 8.01939C-1 9.81002 -0.452281 11.5205 0.587319 12.9564L2.20733 11.7836C1.41888 10.6945 1 9.39594 1 8.01939H-1ZM2.24323 1.38057C0.204394 2.93848 -1 5.42031 -1 8.01939H1C1 6.02739 1.92657 4.13958 3.45754 2.96973L2.24323 1.38057ZM9.52418 0.165474C7.00669 -0.642861 4.30855 -0.19686 2.2434 1.38043L3.45736 2.96987C4.98851 1.80043 7.00193 1.45618 8.91274 2.06972L9.52418 0.165474ZM8.91667 8.27051C8.91667 9.38107 8.0396 10.2467 7 10.2467V12.2467C9.18207 12.2467 10.9167 10.4474 10.9167 8.27051H8.91667ZM7 6.29432C8.0396 6.29432 8.91667 7.15995 8.91667 8.27051H10.9167C10.9167 6.09366 9.18207 4.29432 7 4.29432V6.29432ZM5.08333 8.27051C5.08333 7.15995 5.9604 6.29432 7 6.29432V4.29432C4.81793 4.29432 3.08333 6.09366 3.08333 8.27051H5.08333ZM7 10.2467C5.9604 10.2467 5.08333 9.38107 5.08333 8.27051H3.08333C3.08333 10.4474 4.81793 12.2467 7 12.2467V10.2467Z'
			fill='#0FB769'
			mask='url(#path-1-inside-1_10754_197926)'
		/>
	</svg>
);

export default SVG;
