import React, { useCallback, useState, useEffect } from 'react';
import dynamic from 'next/dynamic';
import { getCurrency } from '@fh-components/fh-js-api-core/store/slices/currencySlice';
import { useSelector } from 'react-redux';
import { Picture, ProductCard, FullWidthSlider, Button, Title, Loader } from '@/components';
import getProductWithoutQuantity from '@/lib/getProductWithoutQuantity';
import { Container, Block } from '@/containers';
import clsx from 'clsx';
import ecommerce from '@/seo/Ecommerce';

import styles from './LookImageSlider.module.scss';

const LooksModal = dynamic(() => import('../../../containers/looksModal'), {
	loading: () => <Loader />,
});

const LookImageSlider = ({ products, image, selection, productPage, alignment = 'left' }) => {
	const [openModal, setOpenModal] = useState(false);
	const currency = useSelector(getCurrency);

	const handleCloseModal = useCallback(() => {
		setOpenModal(false);
	}, []);

	const handleOpenModal = useCallback(() => {
		setOpenModal(true);
	}, []);

	const handleClickCard = (product, index) => {
		const newProducts = getProductWithoutQuantity([product]);

		ecommerce.viewItemList(newProducts, currency, 'Идеи для образа', 'select_item', index);
	};

	useEffect(() => {
		if (products && products.length) {
			ecommerce.viewItemList(getProductWithoutQuantity(products), currency, 'Идеи для образа');
		}
	}, []);

	const Slider =
		products?.length > 0 ? (
			<Container>
				<Title component='h2' className={styles.collectionTitle}>
					Идеи для образа
				</Title>
				<div>
					<div
						className={clsx(
							styles.container,
							alignment && alignment === 'right' && styles.reverseContainer,
						)}
					>
						<div
							className={clsx(
								styles.imageBlock,
								alignment && alignment === 'right' && styles.rightSideImage,
							)}
						>
							{image && <Picture {...image.card} />}
						</div>
						<div className={clsx(styles.lookSlider, productPage && styles.lookSliderProductPage)}>
							<FullWidthSlider fullWidth hideNavigationCount={3}>
								{products.map((product, index) => {
									return (
										<div
											key={product?.external_id}
											className={styles.cart}
											onClick={() => {
												handleClickCard(product, index + 1);
											}}
										>
											<ProductCard
												{...product}
												mod='looks'
												pageName={'Идеи для образа'}
												isSafari
												indexCard={index + 1}
											/>
										</div>
									);
								})}
							</FullWidthSlider>

							{openModal && (
								<LooksModal showDialog={openModal} onDismiss={handleCloseModal} id={selection} />
							)}
						</div>
					</div>
					<Button
						classNames={{ general: styles.button }}
						type='primary'
						as='button'
						onClick={handleOpenModal}
					>
						Заказать весь образ
					</Button>
				</div>
			</Container>
		) : null;

	return productPage ? Slider : <Block>{Slider}</Block>;
};

export default LookImageSlider;
