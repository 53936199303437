import { Block, Container } from '@/containers';
import { Title } from '@/components';

// заменить StyledTitle

const H1 = ({ h1 }) => {
	return (
		<Block>
			<Container>
				<Title component='h1' mb='large' align='center'>
					{h1}
				</Title>
			</Container>
		</Block>
	);
};

export default H1;
