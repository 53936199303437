import styled, { css } from 'styled-components';
import { noTouch } from '@/assets/styles/mixins.css';
import theme from '@/assets/styles/theme';

const StyledNotification = styled.div`
	pointer-events: all;

	${({ viewType }) => {
		switch (viewType) {
			case 'warning': {
				return css`
					--notification-bg-color: ${theme.notification.warning};
				`;
			}
			case 'error': {
				return css`
					--notification-bg-color: ${theme.notification.error};
				`;
			}
			default:
				break;
		}
	}};

	${props =>
		!props.closable &&
		css`
			${StyledNotification.Content} {
				padding-right: 24px;
			}
		`};

	&.on-enter {
		opacity: 0;
		transform: scale(0.95);
	}

	&.on-enter-active {
		opacity: 1;
		transform: scale(1);
		transition: opacity 0.23s ease-out, transform 0.23s ease-out;
	}

	&.on-exit {
		opacity: 1;
		transform: scale(1);
	}

	&.on-exit-active {
		opacity: 0;
		transform: scale(0.95);
		transition: opacity 0.23s ease-in, transform 0.23s ease-in;
	}
`;

StyledNotification.Body = styled.div`
	display: flex;
	font-size: 17px;
	color: #fff;
	background-color: var(--notification-bg-color, ${theme.notification.error});
	border-radius: 12px;
`;

StyledNotification.Content = styled.div`
	flex-grow: 1;
	padding: 20px 0px 24px 24px;
`;

StyledNotification.Close = styled.button`
	flex-shrink: 0;
	display: block;
	width: 48px;
	height: 62px;
	background-image: url("data:image/svg+xml,%3Csvg width='10' height='10' viewBox='0 0 10 10' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.065.358l8.577 8.577-.707.707L.358 1.066l.707-.708z' fill='%23fff'/%3E%3Cpath d='M8.935.358L.358 8.935l.707.707 8.577-8.576-.707-.708z' fill='%23fff'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: 50% 50%;
	opacity: 0.7;
	transition: opacity 0.23s ease;

	${noTouch()`
    &:hover {
      opacity: 1;
    }
  `}
`;

StyledNotification.Button = styled.button`
	display: inline-block;
	padding: 1px 8px 3px;
	font-size: 14px;
	color: #fff;
	border: 1px solid #ffffff;
	border-radius: 4px;
	transition: opacity 0.16s;

	&:not(:first-child) {
		margin-top: 16px;
	}

	${noTouch()`
    &:hover {
      opacity: 0.7;
    }
  `}
`;

export default StyledNotification;
