import React, { useRef, useEffect, useCallback, useState } from 'react';
import dynamic from 'next/dynamic';
import { useSelector } from 'react-redux';
import { hasSmartBannerSelector } from '@/store';
import { CSSTransition } from 'react-transition-group';
import { Button, Loader, Portal } from '@/components';
import { useRouter } from 'next/router';
import clsx from 'clsx';
import wordEnd from '@/lib/wordEnd';
import Icon from '@/assets/icons';

import styles from './MobileFilters.module.scss';

const FilterRange = dynamic(() => import('../filters/FilterRange'), {
	loading: () => <Loader />,
});

const FilterMultipleCollapsableSearchable = dynamic(
	() => import('../filters/FilterMultipleCollapsableSearchable'),
	{
		loading: () => <Loader />,
	},
);

const Accordion = dynamic(() => import('../accordion'), {
	loading: () => <Loader />,
});

const FullScreenTip = dynamic(() => import('../fullScreenTip'), {
	loading: () => <Loader />,
});

const MobileFilters = ({
	filters,
	selectedFilters,
	onFiltersChange,
	total,
	loading,
	visibility,
	toggleMobileFilter,
	isSearchPage = false,
}) => {
	const hasSmartBanner = useSelector(hasSmartBannerSelector);
	const isVisible = visibility;
	const wrapper = useRef(null);
	const [disabled, setDisabled] = useState(false);

	useEffect(() => {
		window.addEventListener('click', onClickOutside);
		window.addEventListener('orientationchange', onOrientationChange);
		return () => {
			window.removeEventListener('click', onClickOutside);
			window.removeEventListener('orientationchange', onOrientationChange);
		};
	}, [isVisible]);

	const router = useRouter();

	useEffect(() => {
		const handleRouteChange = url => {
			if (isVisible) {
				if (!url.includes('filters') && url.slice(0, 4) !== router.asPath.slice(0, 4)) {
					toggleMobileFilter();
				}
			}
		};

		router.events.on('routeChangeStart', handleRouteChange);

		return () => {
			router.events.off('routeChangeStart', handleRouteChange);
		};
	}, [isVisible, toggleMobileFilter]);

	const onClickOutside = e => {
		if (isVisible && wrapper.current === e.target) {
			toggleMobileFilter();
		}
	};

	const onOrientationChange = useCallback(
		e => {
			e.stopPropagation();
			if (isVisible) toggleMobileFilter();
		},
		[isVisible, toggleMobileFilter],
	);

	const onToggleClick = useCallback(
		e => {
			e.stopPropagation();
			toggleMobileFilter();
		},
		[toggleMobileFilter],
	);

	const hasSelectedFilters = Object.keys(selectedFilters).length > 0;

	const handleFilterChange = value => {
		setDisabled(value);
	};

	return (
		<div>
			<div onClick={onToggleClick} className={styles.filterContainer}>
				<Icon name='filter' className={styles.filterIcon} />
				<div>Фильтры</div>
				{hasSelectedFilters && (
					<div className={styles.filterTitleTotal}>
						<div className={styles.filterTitleTotalCount}>
							{Object.keys(selectedFilters).length}
						</div>
					</div>
				)}
			</div>

			<CSSTransition
				in={isVisible}
				timeout={{ appear: 0, enter: 180, exit: 180 }}
				classNames={{
					enter: styles.onEnter,
					enterActive: styles.onEnterActive,
					enterDone: styles.onEnterDone,
					exit: styles.onExit,
					exitActive: styles.onExitActive,
				}}
				unmountOnExit
			>
				<Portal>
					<div className={clsx(styles.wrapper, hasSmartBanner && styles.smartBanner)} ref={wrapper}>
						<div className={styles.container}>
							<div className={styles.header}>
								<div className={styles.title}>
									<div>Фильтр</div>
									{hasSelectedFilters && (
										<div className={styles.filterTitleTotal}>
											<div className={styles.filterTitleTotalCount}>
												{Object.keys(selectedFilters).length}
											</div>
										</div>
									)}
								</div>

								{hasSelectedFilters && (
									<Button
										classNames={{ general: styles.clearAll }}
										onClick={e => {
											e.stopPropagation();
											onFiltersChange({});
										}}
									>
										Очистить фильтр
									</Button>
								)}
							</div>

							<div className={styles.main}>
								{filters && filters.length > 0 && (
									<div>
										{filters.map((filter, index) => {
											const { type, id } = filter;
											const filterKey = id || index;

											switch (type) {
												case 'checkbox': {
													const { slug, name: title, options: items, info } = filter;
													if (!items || items.length === 0) return null;

													// move items with positive count on top
													const sortedItems = [...items].sort((a, b) => {
														if (a.count === 0 && b.count > 0) {
															return 1;
														}
														if (b.count === 0 && a.count > 0) {
															return -1;
														}
														return 0;
													});

													return (
														<Accordion
															key={filterKey}
															title={<span className={styles.filterTitle}>{title}</span>}
															unmountOnClose
															isFilter
															headChild={
																<>
																	{info && (
																		<FullScreenTip>
																			<h2>{title}</h2>
																			<p>{info}</p>
																		</FullScreenTip>
																	)}
																	<Button
																		visible={selectedFilters[slug]}
																		onClick={e => {
																			e.stopPropagation();
																			const nextFilters = { ...selectedFilters };
																			delete nextFilters[slug];
																			onFiltersChange(nextFilters);
																		}}
																		classNames={{
																			general: clsx(
																				styles.clearButton,
																				selectedFilters[slug] && styles.clearButtonVisible,
																			),
																		}}
																	>
																		Сбросить
																	</Button>
																</>
															}
														>
															<div className={disabled || loading ? styles.disabled : null}>
																<FilterMultipleCollapsableSearchable
																	name={slug}
																	isMobile
																	items={sortedItems}
																	selectedFilters={selectedFilters}
																	onChange={onFiltersChange}
																	isSearchPage={isSearchPage}
																	title={title}
																/>
															</div>
														</Accordion>
													);
												}
												case 'range': {
													const { slug, min, max, name: title, info } = filter;

													return (
														<Accordion
															key={filterKey}
															title={<span className={styles.filterTitle}>{title}</span>}
															mod={index === 0 && 'firstBorder'}
															isFilter
															unmountOnClose
															headChild={
																<>
																	{info && (
																		<FullScreenTip>
																			<h2>{title}</h2>
																			<p>{info}</p>
																		</FullScreenTip>
																	)}
																	<Button
																		onClick={e => {
																			e.stopPropagation();
																			const nextFilters = { ...selectedFilters };
																			delete nextFilters[slug];
																			onFiltersChange(nextFilters);
																		}}
																		classNames={{
																			general: clsx(
																				styles.clearButton,
																				selectedFilters[slug] && styles.clearButtonVisible,
																			),
																		}}
																	>
																		Сбросить
																	</Button>
																</>
															}
														>
															<FilterRange
																name={slug}
																onChange={onFiltersChange}
																selectedFilters={selectedFilters}
																range={[min, max]}
																isSearchPage={isSearchPage}
																disabledClick={handleFilterChange}
															/>
														</Accordion>
													);
												}
											}
										})}
									</div>
								)}
							</div>

							<div className={styles.footer}>
								<Button
									withLoader
									onClick={onToggleClick}
									{...loading && { className: 'loading', disabled: true }}
									classNames={{ general: styles.showAllButton }}
									type='primary'
								>
									{+total > 0 ? (
										<span>
											Показать {total} {wordEnd(+total, ['товар', 'товара', 'товаров'])}
										</span>
									) : (
										<span>Ничего не найдено</span>
									)}
								</Button>
							</div>
						</div>
					</div>
				</Portal>
			</CSSTransition>
		</div>
	);
};

export default MobileFilters;
