import { createSelector } from '@reduxjs/toolkit';

export const menuTopSelector = createSelector(
	state => state.menu.top,
	top => top,
);

export const menuMiddleSelector = createSelector(
	state => state.menu.middle,
	middle => middle,
);

export const menuFooterSelector = createSelector(
	state => state.menu.footer,
	footer => footer,
);
