import { Block } from '@/containers';
import { Picture, RoutedLink, Link } from '@/components';
import clsx from 'clsx';
import styles from './WidePictureNoText.module.scss';
import generalStyles from '../WidePictures.module.scss';

const WidePictureNoText = props => {
	const { url: linkUrl, image } = props;
	if (!image) return null;

	return (
		<Block>
			<div>
				<div className={generalStyles['outer-container']}>
					{linkUrl && <RoutedLink to={linkUrl} component={Link} />}

					<div className={clsx(generalStyles.image, styles.image)}>
						<Picture {...image} />
					</div>
				</div>
			</div>
		</Block>
	);
};

export default WidePictureNoText;
