import { useState, useRef, useEffect, useCallback, memo } from 'react';
import { getCategoriesTree } from '@fh-components/fh-js-api-core/store/slices/categoriesSlice';
import { CSSTransition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { ActiveLink, RoutedLink } from '@/components';
import { catalogSortSelector } from '@/store';
import { ContactsOverview } from '@/containers';
import { StyledMobileNav } from '@/components/styles';
import { useNav } from '@/contexts/NavContext';
import getLinkProps from '@/lib/getLinkProps';

const ItemLink = ({ category: { path, link, type }, savedQuery, title, ...props }) => {
	const notCatalogLink = type !== 'menu' && type !== 'category' && type !== 'brand';
	const routeType = 'catalog';

	const linkHref = notCatalogLink ? link : getLinkProps({ type: routeType, value: path })[0];
	const linkAs = notCatalogLink ? null : getLinkProps({ type: routeType, value: path })[1];

	return notCatalogLink ? (
		<RoutedLink to={linkHref} component={StyledMobileNav.Link} {...props}>
			{title}
		</RoutedLink>
	) : (
		<ActiveLink
			href={{ pathname: linkHref, query: savedQuery || {} }}
			as={{ pathname: linkAs, query: savedQuery || {} }}
		>
			<StyledMobileNav.Link {...props}>{title}</StyledMobileNav.Link>
		</ActiveLink>
	);
};

const MobileNav = ({ hasBanner }) => {
	const { visibility, toggleMobileNav } = useNav();
	const sort = useSelector(catalogSortSelector);

	return visibility.mobileNav ? (
		<MemoizedMobileNav
			visibility={visibility.mobileNav}
			toggleMobileNav={toggleMobileNav}
			hasBanner={hasBanner}
			{...sort && { savedQuery: { sort } }}
		/>
	) : null;
};

const MemoizedMobileNav = memo(({ visibility, toggleMobileNav, savedQuery, hasBanner }) => {
	const dispatch = useDispatch();
	const wrapper = useRef(null);
	const [tree, setTree] = useState([]);
	const [loading, setLoading] = useState(false);

	const onClickOutside = useCallback(
		e => {
			if (visibility && (!wrapper?.current?.contains(e.target) || wrapper.current === e.target)) {
				toggleMobileNav();
			}
		},
		[toggleMobileNav, visibility],
	);

	const onMenuLinkClick = useCallback(
		e => {
			e?.stopPropagation();
			toggleMobileNav();
		},
		[toggleMobileNav],
	);

	useEffect(() => {
		setLoading(true);

		dispatch(
			getCategoriesTree({
				onSuccess: res => {
					setLoading(false);
					setTree(res.data);
				},
			}),
		);
	}, [dispatch]);

	useEffect(() => {
		window.addEventListener('click', onClickOutside);
		return () => window.removeEventListener('click', onClickOutside);
	}, [visibility, onClickOutside]);

	return (
		<StyledMobileNav visible={visibility} ref={wrapper}>
			<StyledMobileNav.Menu visible={visibility} hasBanner={hasBanner}>
				<StyledMobileNav.MenuInner>
					<StyledMobileNav.Level visible>
						<StyledMobileNav.LevelInner>
							{loading ? (
								<div style={{ padding: '16px 20px 0 0', width: '100%' }}>
									<Skeleton count={10} height={46} width='100%' style={{ marginBottom: '8px' }} />
								</div>
							) : (
								<StyledMobileNav.List>
									{tree.map(category => {
										const { id } = category;
										return (
											<MobileNavSwitch
												key={id}
												{...category}
												onClick={onMenuLinkClick}
												savedQuery={savedQuery}
											/>
										);
									})}
								</StyledMobileNav.List>
							)}

							<StyledMobileNav.ContactsOverview>
								<ContactsOverview isMobile onMenuLinkClick={onMenuLinkClick} />
							</StyledMobileNav.ContactsOverview>
						</StyledMobileNav.LevelInner>
					</StyledMobileNav.Level>
				</StyledMobileNav.MenuInner>
			</StyledMobileNav.Menu>
		</StyledMobileNav>
	);
});

const MobileNavSwitch = memo(({ allItemsTitle = 'Все товары', savedQuery, level, ...category }) => {
	const [isVisible, setIsVisible] = useState(false);

	const { name, path, children, type, link, is_blanked, view_type, onClick } = category;

	const toggleVisible = () => {
		setIsVisible(prev => !prev);
	};

	return (
		<StyledMobileNav.Item level={level}>
			{children && children.length > 0 ? (
				<>
					<StyledMobileNav.Link as='button' parent styledView={view_type} onClick={toggleVisible}>
						{name}
					</StyledMobileNav.Link>

					<CSSTransition
						in={isVisible}
						timeout={{
							appear: 0,
							enter: 200,
							exit: 200,
						}}
						classNames='on'
						unmountOnExit
					>
						<StyledMobileNav.Level>
							<StyledMobileNav.LevelInner>
								<StyledMobileNav.Back onClick={toggleVisible}>
									<StyledMobileNav.BackIcon>
										<svg width='30' height='11' viewBox='0 0 30 11' fill='currentColor'>
											<path d='M3.254 5.951L6.304 9 5.1 10.202 0 5.101 5.101 0l1.202 1.202-3.049 3.049h25.948v1.7H3.254z' />
										</svg>
									</StyledMobileNav.BackIcon>
									<span>{name}</span>
								</StyledMobileNav.Back>

								<ul>
									<StyledMobileNav.Item level>
										<ItemLink
											category={{ path, link, type }}
											onClick={onClick}
											title={allItemsTitle}
											savedQuery={savedQuery}
											{...is_blanked && { target: '_blank', rel: 'noopener noreferrer' }}
										/>
									</StyledMobileNav.Item>

									{children.map(child => {
										const { id } = child;
										return (
											<MobileNavSwitch
												key={id}
												{...child}
												onClick={onClick}
												savedQuery={savedQuery}
												level
											/>
										);
									})}
								</ul>
							</StyledMobileNav.LevelInner>
						</StyledMobileNav.Level>
					</CSSTransition>
				</>
			) : (
				<ItemLink
					category={{ path, link, type }}
					onClick={onClick}
					styledView={view_type}
					title={name}
					savedQuery={savedQuery}
					{...is_blanked && { target: '_blank', rel: 'noopener noreferrer' }}
				/>
			)}
		</StyledMobileNav.Item>
	);
});

export default MobileNav;
