import { useState } from 'react';

const useLocalStorage = (key, initialValue) => {
	const [storedValue, setStoredValue] = useState(() => {
		if (typeof window === 'undefined') {
			return initialValue;
		}
		try {
			const item = window.localStorage.getItem(key);
			if (item) {
				return typeof initialValue === 'string' ? item : JSON.parse(item);
			}

			return initialValue;
		} catch (error) {
			console.warn(error);
			return initialValue;
		}
	});

	const setValue = value => {
		try {
			const valueToStore = value instanceof Function ? value(storedValue) : value;
			setStoredValue(valueToStore);
			if (typeof window !== 'undefined') {
				window.localStorage.setItem(
					key,
					typeof valueToStore === 'string' ? valueToStore : JSON.stringify(valueToStore),
				);
			}
		} catch (error) {
			console.log(error);
		}
	};
	return [storedValue, setValue];
};

export default useLocalStorage;
