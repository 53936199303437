import { createSelector } from '@reduxjs/toolkit';

export const wishlistIdsSelector = createSelector(
	state => state.wishlist.ids || [],
	ids => ids,
);

export const wishlistCountSelector = createSelector(
	state => state.wishlist.count || 0,
	count => count,
);
