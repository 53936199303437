import { useRouter } from 'next/router';
import { Picture, RoutedLink, Link, FullWidthSlider, Button } from '@/components';
import { Block, Container } from '@/containers';
import clsx from 'clsx';

import styles from './NavigationThreePictures.module.scss';

const NavigationThreePictures = ({ items, noMargin, title = {}, description = {}, url = {} }) => {
	if (!items || items.length === 0) return null;
	if (items.some(item => !item.image)) return null;

	const router = useRouter();

	const { text: textTitle = null, size: TitleComponent = 'h2' } = title;
	const { text: textDescription = null, size: DescriptionComponent = 'h3' } = description;
	const { link = null, text = null } = url;

	const handleClick = () => {
		router.push(link);
	};

	return (
		<Block noMargin={noMargin}>
			<Container className={styles.container}>
				<div className={styles.main}>
					{items.map((item, index) => {
						const { url, url_text = '', title, description, image } = item || {};

						return (
							<div className={styles.item} key={`${image}-${url}-${title}-${index}`}>
								{url && <RoutedLink title={url_text} to={url} component={Link} />}

								<figure className={styles.image}>
									{image && <Picture {...image} title={url_text} />}
								</figure>

								{(title || description) && (
									<div className={styles.contentSlider}>
										{title && <span className={styles.title}>{title}</span>}
										{description && <p className={styles.desc}>{description}</p>}
									</div>
								)}
							</div>
						);
					})}
				</div>

				<div className={styles.slider}>
					<FullWidthSlider bigMargin>
						{items.map(({ image, title, url_text = '', url, description }) => {
							const imageComponent = (
								<>
									<img
										className={styles.image}
										title={url_text}
										key={title}
										alt={title}
										src={image?.x2}
									/>
									{(title || description) && (
										<div className={styles.contentSlider}>
											{title && <span className={styles.title}>{title}</span>}
											{description && <p className={styles.desc}>{description}</p>}
										</div>
									)}
								</>
							);

							return url ? (
								<a className={styles.link} href={url} key={title}>
									{imageComponent}
								</a>
							) : (
								<div className={styles.link}>{imageComponent}</div>
							);
						})}
					</FullWidthSlider>
				</div>

				{(textTitle || textDescription) && (
					<div className={clsx(styles.content, styles[`${TitleComponent}-content`])}>
						{textTitle && (
							<TitleComponent className={clsx(styles.title, styles[`${TitleComponent}-title`])}>
								{textTitle}
							</TitleComponent>
						)}
						{textDescription && (
							<DescriptionComponent className={styles.desc}>{textDescription}</DescriptionComponent>
						)}
						{link && link.length > 0 && (
							<Button
								type='defaultBlack'
								classNames={{ general: styles.button }}
								onClick={handleClick}
							>
								{text}
							</Button>
						)}
					</div>
				)}
			</Container>
		</Block>
	);
};

export default NavigationThreePictures;
