import Link from 'next/link';
import getLinkProps from '@/lib/getLinkProps';
import { Picture } from '@/components';
import styles from './SpecialOffers.module.scss';

const SpecialOffers = ({ items }) => {
	return (
		<ul className={styles.specialOffers}>
			{items?.map(item => {
				const { id, title, sub_title, image, slug } = item;
				const [href, as] = getLinkProps({ type: 'special-offer', value: slug });

				return (
					<li key={id}>
						<Link href={href} as={as}>
							{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
							<a>
								{image && (
									<div className={styles.img}>
										<Picture {...image} alt={title} title={title} />
									</div>
								)}

								{sub_title && <p className={styles.subTitle}>Акция; {sub_title}</p>}

								<h3 className={styles.title}>{title}</h3>
							</a>
						</Link>
					</li>
				);
			})}
		</ul>
	);
};

export default SpecialOffers;
