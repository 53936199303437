const SVG = () => (
	<svg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'>
		<path
			d='M13.7143 7.38776L4.97959 2.08163C4.89796 2 4.73469 2 4.65306 2C4.57143 2 4.40816 2 4.32653 2.08163C4.16327 2.16327 4 2.40816 4 2.65306V13.3469C4 13.5918 4.16327 13.8367 4.32653 13.9184C4.40816 14 4.57143 14 4.65306 14C4.73469 14 4.89796 14 4.97959 13.9184L13.7143 8.53061C13.8776 8.44898 14.0408 8.20408 14.0408 7.95918C14.0408 7.71429 13.8776 7.55102 13.7143 7.38776ZM5.30612 12.1224V3.79592L12.1633 7.95918L5.30612 12.1224Z'
			fill='#292C33'
		/>
		<path d='M13.3878 8.12245L4.81633 2.81633V13.0204L13.3878 8.12245Z' fill='#292C33' />
	</svg>
);

export default SVG;
