import { useCallback, memo } from 'react';
import Image from 'next/image';
import { useRouter } from 'next/router';
import Icon from '@/assets/icons';

import styles from './SmartBanner.module.scss';

const domain = 'https://fh.by';

const SmartBanner = memo(({ isVisibleBanner, setIsVisibleBanner }) => {
	const router = useRouter();
	const iconClick = useCallback(() => {
		if (typeof window !== 'undefined') {
			// sessionStorage.setItem('smart-banner', true);
			setIsVisibleBanner(false);
		}
	}, []);


	const getUrl = () => {
		const path = domain + router.asPath;
		let params = '';

		if (sessionStorage.getItem('utm_source')) {
			params += `&utm_source=${sessionStorage.getItem('utm_source')}`;
		}
		if (sessionStorage.getItem('utm_medium')) {
			params += `&utm_medium=${sessionStorage.getItem('utm_medium')}`;
		}
		if (sessionStorage.getItem('utm_campaign')) {
			params += `&utm_campaign=${sessionStorage.getItem('utm_campaign')}`;
		}
		if (sessionStorage.getItem('utm_content')) {
			params += `&utm_content=${sessionStorage.getItem('utm_content')}`;
		}
		if (sessionStorage.getItem('utm_term')) {
			params += `&utm_term=${sessionStorage.getItem('utm_term')}`;
		}
		if (sessionStorage.getItem('gclid')) {
			params += `&gclid=${sessionStorage.getItem('gclid')}`;
		}

		const link = encodeURIComponent(path);
		return `https://link.fh.by/?isi=1518240278&ibi=com%2Ewebsecret%2Efh&efr=0&imv=3%2E7%2E0&link=${link}&amv=74&ofl=${link}&apn=com%2Ewebsecret%2Efh${params}`;
	};

	const handleClick = () => {
		window.open(getUrl(), '_blank');
	};

	return isVisibleBanner ? (
		<div className={styles.banner}>
			<div className={styles.appInfo}>
				<Icon name='close' className={styles.icon} stroke='#fff' onClick={iconClick} />
				<div className={styles.logoImage}>
					<Image width={32} height={32} src='/images/monogram_logo.webp' alt='monogram logo' />
				</div>
				<div className={styles.text}>
					В приложении FH.BY еще удобнее покупать товары вашей мечты
				</div>
			</div>
			<button onClick={handleClick} className={styles.button}>
				Перейти
			</button>
		</div>
	) : null;
});

export default SmartBanner;
