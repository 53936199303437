import React from 'react';
import dynamic from 'next/dynamic';
import { Block } from '@/containers';
import { Loader } from '@/components';

const ShopsMap = dynamic(() => import('../../shopsMap/ShopsMap'), {
	loading: () => <Loader />,
});

const ShopsOnMap = ({ shops }) => {
	if (!shops || shops.length === 0) return null;

	return (
		<Block shopsOnMap>
			<ShopsMap shops={shops} hiddenTabs />
		</Block>
	);
};

export default ShopsOnMap;
