import { useRouter } from 'next/router';
import Head from 'next/head';
import getLinkProps from '@/lib/getLinkProps';
import JsonLdData from '@/seo/JsonLd';

const NextHead = () => {
	const router = useRouter();
	const breadCrumbsItems = router.asPath
		.split('/')
		.filter(item => !!item)
		.map(item => ({
			name: getLinkProps({ type: item })?.[2] || item,
			value: item,
		}));

	return (
		<Head>
			<meta httpEquiv='X-UA-Compatible' content='IE=Edge' />
			<meta name='viewport' content='width=device-width, initial-scale=1' />
			<meta name='p:domain_verify' content='774e464036290d45593e55029e98cc2c' />
			<meta name='format-detection' content='telephone=no' />
			<meta name='google-play-app' content='app-id=com.websecret.fh' />
			{/* Facebook pixel meta */}
			<meta name='facebook-domain-verification' content='ujpoxr2n3xvd4m69jri8wt8f7499q8' />
			{/* Facebook second pixel meta */}
			<meta name='facebook-domain-verification' content='e4atw2laylyv7on7efiacx8ljs4jvi' />

			<link rel='apple-touch-icon' sizes='180x180' href='/apple-touch-icon.webp' />
			<link sizes='180x180' href='/android-chrome-192x192.webp' />
			<link rel='icon' type='image/png' sizes='32x32' href='/favicon-32x32.webp' />
			<link rel='icon' type='image/png' sizes='16x16' href='/favicon-16x16.webp' />
			<link rel='manifest' href='/site.webmanifest' />
			<link rel='mask-icon' href='/safari-pinned-tab.svg' color='#0FB769' />
			<link rel='icon' href='/favicon.ico' />
			<meta name='msapplication-TileColor' content='#292c33' />
			<meta name='msapplication-config' content='/browserconfig.xml' />
			<meta name='theme-color' content='#292c33' />
			{/* JSON-LD */}
			<script
				key='json-ld'
				type='application/ld+json'
				dangerouslySetInnerHTML={{
					__html: JSON.stringify([
						JsonLdData.webSite,
						JsonLdData.organization,
						JsonLdData.breadCrumbsList(breadCrumbsItems),
					]),
				}}
			/>
		</Head>
	);
};

export default NextHead;
