/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useCallback, createContext } from 'react';
import noScroll from 'no-scroll';

const NavContext = createContext();

export const NavProvider = ({ children }) => {
	const [visibility, setVisibility] = useState({
		menu: false,
		mobileNav: false,
		mobileFilter: false,
		mobileSort: false,
		mobileUserNav: false,
	});

	const toggleVisibility = item => cb => {
		let shouldToggleScroll = true;

		setVisibility(prevState => {
			const newState = Object.keys(prevState).reduce(
				(acc, key) => {
					if (prevState[key] && key !== item) {
						shouldToggleScroll = false;
					}
					if (key === item) {
						acc[key] = !acc[key];
						return acc;
					}
					acc[key] = false;
					return acc;
				},
				{ ...prevState },
			);

			if (shouldToggleScroll) noScroll.toggle();
			// if (cb) cb?.(newState[item]);

			return { ...prevState, ...newState };
		});
	};

	const toggleMenu = useCallback(toggleVisibility('menu'), [visibility.menu]);
	const toggleMobileNav = useCallback(toggleVisibility('mobileNav'), [visibility.mobileNav]);
	const toggleMobileFilter = useCallback(toggleVisibility('mobileFilter'), [
		visibility.mobileFilter,
	]);
	const toggleMobileSort = useCallback(toggleVisibility('mobileSort'), [visibility.mobileSort]);
	const toggleMobileUserNav = useCallback(toggleVisibility('mobileUserNav'), [
		visibility.mobileUserNav,
	]);

	return (
		<NavContext.Provider
			/* eslint-disable-next-line react/jsx-no-constructed-context-values */
			value={{
				visibility,
				toggleMenu,
				toggleMobileNav,
				toggleMobileFilter,
				toggleMobileSort,
				toggleMobileUserNav,
			}}
		>
			{children}
		</NavContext.Provider>
	);
};

export const useNav = () => {
	return useContext(NavContext);
};
