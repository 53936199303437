import { CurrencyPrice } from '@/components';
import useWindowSize from '@/hooks/use-window-size';
import clsx from 'clsx';

import styles from './Price.module.scss';

const Price = ({
	price = '',
	oldPrice = '',
	oldPriceReason,
	priceDiscount,
	isCheckout = false,
	isProductCard = false,
	isCatalog = false,
	isLook = false,
	isProduct = false,
	isCollection = false,
	isSearch = false,
	isProductSlider = false,
	isWishlist = false,
	isLookProduct = false,
	...props
}) => {
	const hasDiscount = price !== oldPrice;
	const { isMobile, isSmallTablet } = useWindowSize();
	const isDiscountPosition = isCatalog || isWishlist || isProductSlider || isLook || isCollection;

	return (
		<div className={styles.priceContainer}>
			<div
				className={clsx(
					styles.font,
					styles.oldPrice,
					hasDiscount && styles.newPrice,
					isCheckout && styles.fontCheckout,
					isProductCard && styles.fontProductPage,
				)}
			>
				<CurrencyPrice
					price={oldPrice}
					isDiscount={
						(isMobile && isDiscountPosition) || (isLookProduct && isSmallTablet)
							? false
							: hasDiscount
					}
				/>
			</div>

			{hasDiscount && (
				<div
					className={clsx(
						styles.font,
						styles.discountPrice,
						isCheckout && styles.fontCheckout,
						isProductCard && styles.fontProductPage,
					)}
				>
					<CurrencyPrice
						price={price}
						isDiscount={(isMobile && isDiscountPosition) || (isLookProduct && isSmallTablet)}
					/>
				</div>
			)}
		</div>
	);
};

export default Price;
